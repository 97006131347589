import http from "http-common";

const HeuresService = class {
  /**
   * Fonction permettant de récuperer tous les echeances avec une possibilité de filtre via params
   * @returns la liste de toutes les heures
   */
  static getAll(params) {
    return http.post("/Heures/get",params);
  }

  /**
   * Fonction permettant de créer des heures
   * @param {*} params, la liste d'heure à ajouter
   * @returns la liste
   */
  static post(heures) {
    return http.post("/Heures", heures);
  }

  /**
   * Fonction permettant de mettre à jour un devis
   * @param {*} devis, le devis à mettre à jour
   * @returns
   */
  static put(heures) {
    return http.put("/Heures", heures);
  }


};

export { HeuresService };
