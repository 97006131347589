import React from "react";
import { PropTypes } from "prop-types";
import { App } from "App";

import "./InformationsConsultation.css";
import { ToLocaleDateString } from "_utils";
import { IfNullToText } from "_utils";
import {
  ContactService,
  ClientService,
  ProduitInterneService,
  StatutsConsultationService,
  MotifNonReponseService,
  SourceService,
  MissionService,
  FamilleAffaireService,
  PersonnelService,
  TypeOuvrageService,
} from "_services";
import { BoxInformationsInline, WarningBar } from "_components";
class InformationsConsultation extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      callbackForceUpdateContactMaitreDOeuvre: null,
      callbackForceUpdateContactMaitreDOuvrage: null,
      callbackForceUpdateContactEntreprise: null,
      callbackForceUpdateContactClient: null,
    };

    this.setForceUpdateContactClient =
      this.setForceUpdateContactClient.bind(this);
    this.setForceUpdateContactMaitreDOeuvre =
      this.setForceUpdateContactMaitreDOeuvre.bind(this);
    this.setForceUpdateContactMaitreDOuvrage =
      this.setForceUpdateContactMaitreDOuvrage.bind(this);
    this.setForceUpdateContactEntreprise =
      this.setForceUpdateContactEntreprise.bind(this);
  }

  setForceUpdateContactClient(callbackFunction) {
    this.setState({
      callbackForceUpdateContactClient: callbackFunction,
    });
  }

  setForceUpdateContactMaitreDOeuvre(callbackFunction) {
    this.setState({
      callbackForceUpdateContactMaitreDOeuvre: callbackFunction,
    });
  }

  setForceUpdateContactMaitreDOuvrage(callbackFunction) {
    this.setState({
      callbackForceUpdateContactMaitreDOuvrage: callbackFunction,
    });
  }

  setForceUpdateContactEntreprise(callbackFunction) {
    this.setState({ callbackForceUpdateContactEntreprise: callbackFunction });
  }

  render() {
    const right = App.RightsGuard?.current?.hasRight("Consultation", "General");
    if (right == "RW" || right == "RO") {
      const informationsGeneral = [
        {
          label: "N° Consultation",
          accessor: "reference",
          colSize: 3,
        },
        {
          label: "Statut",
          accessor: "statut",
          functionAppliedToValue: (value) => {
            return value ? value.designation : null;
          },
          type: "selectSearch",
          service: StatutsConsultationService.getAll,
          optionFieldToDisplay: "designation",
          valueFieldToDisplay: "designation",
          colSize: 3,
        },
        {
          label: "Date de réception",
          accessor: "date_Reception",
          type: "date",
          colSize: 3,
        },
        {
          label: "Actif",
          accessor: "actif",
          type: "checkbox",
          functionAppliedToValue: (value) => {
            return value ? "Oui" : "Non";
          },
          colSize: 3,
        },
      ];

      const informationsClient = [
        {
          label: "Nom consultation",
          accessor: "nom_Consultation",
          type: "text",
          required: true,
          colSize: 12,
        },
        {
          label: "Nom détaillé",
          accessor: "nom_Detaille",
          type: "text",
          colSize: 12,
        },
        {
          label: "Famille d'affaires",
          accessor: "famille_Affaire",
          functionAppliedToValue: (value) => {
            return value ? value.designation : null;
          },
          required: this.props.consultation.produit_Interne?.isPresta ?? false,
          type: "selectSearch",
          service: FamilleAffaireService.getAll,
          optionFieldToDisplay: "designation",
          valueFieldToDisplay: "designation",
          colSize: 6,
        },
        {
          label: "Produit interne",
          accessor: "produit_Interne",
          functionAppliedToValue: (value) => {
            return value ? value.designation : null;
          },
          required: true,
          type: "selectSearch",
          service: ProduitInterneService.getAll,
          optionFieldToDisplay: "designation",
          valueFieldToDisplay: "designation",
          colSize: 6,
        },
        {
          label: "Client",
          accessor: "client",
          functionAppliedToValue: (value) => {
            return value ? value.nom_Client : null;
          },
          required: true,
          type: "selectSearch",
          service: ClientService.getForSelectSearch,
          optionFieldToDisplay: "nom_Client",
          valueFieldToDisplay: "nom_Client",
          afterUpdateFunction: () => {
            this.props.handleChange(
              "contact",
              null,
              this.state.callbackForceUpdateContactClient
            );
          },
          colSize: 6,
          functionAppliedToValue: (value) => {
            return (
              <div className="d-flex justify-content-start align-items-center">
                {value ? (
                  <a
                    href={"/clients/" + value.id}
                    target="_blank"
                    data-text={value.nom_Client}
                    className="fixWidthHover"
                  >
                    {value.nom_Client}
                  </a>
                ) : (
                  "-"
                )}
              </div>
            );
          },
        },
        {
          label: "Contact",
          accessor: "contact",
          required:
            this.props.consultation.client?.compte_Tier != "CPARTICULIERS" &&
            this.props.consultation.client?.compte_Tier != "CPARTICULIERS63",
          type: "selectSearch",
          service: () =>
            ContactService.getByIdClient(this.props.consultation.client?.id),
          optionFieldToDisplay: ["nom_Contact", "prenom_Contact"],
          valueFieldToDisplay: ["nom_Contact", "prenom_Contact"],
          invalidText: "Vous devez sélectionner un contact.",
          setForceUpdateFunction: this.setForceUpdateContactClient,
          colSize: 6,
          functionAppliedToValue: (value) => {
            return (
              <div className="d-flex justify-content-start align-items-center">
                {value ? (
                  <a
                    href={"/contacts/" + value.id}
                    target="_blank"
                    data-text={
                      IfNullToText(value.nom_Contact, null) +
                      " " +
                      IfNullToText(value.prenom_Contact, null)
                    }
                    className="fixWidthHover"
                  >
                    {IfNullToText(value.nom_Contact, null) +
                      " " +
                      IfNullToText(value.prenom_Contact, null)}
                  </a>
                ) : (
                  "-"
                )}
              </div>
            );
          },
        },

        {
          label: "Date limite de réponse",
          accessor: "date_Limite_Reponse",
          type: "date",
          required:
            this.props.consultation.produit_Interne &&
            this.props.consultation.produit_Interne.designation == "MAT"
              ? false
              : true,
          colSize: 6,
        },
        {
          label: "Heure limite",
          accessor: "heure_Limite",
          type: "time",
          colSize: 6,
        },
        {
          label: "Chargé d'affaires",
          accessor: "commercial",
          functionAppliedToValue: (value) => {
            return value ? value.nom + " " + value.prenom : null;
          },
          required: true,
          type: "selectSearch",
          service: PersonnelService.getForSelectSearch,
          optionFieldToDisplay: ["nom", "prenom"],
          valueFieldToDisplay: ["nom", "prenom"],
          colSize: 6,
        },
        {
          label: "Source",
          accessor: "source",
          functionAppliedToValue: (value) => {
            return value ? value.designation : null;
          },
          required: true,
          type: "selectSearch",
          service: SourceService.getAll,
          optionFieldToDisplay: "designation",
          valueFieldToDisplay: "designation",
          colSize: 6,
        },
      ];
      const informationsTravailleurs = [
        {
          label: "Entreprise",
          accessor: "entreprise",
          type: "selectSearch",
          service: ClientService.getForSelectSearch,
          optionFieldToDisplay: "nom_Client",
          valueFieldToDisplay: "nom_Client",
          afterUpdateFunction: () => {
            this.props.handleChange(
              "entreprise_Contact",
              null,
              this.state.callbackForceUpdateContactEntreprise
            );
          },
          colSize: 6,
          functionAppliedToValue: (value) => {
            return (
              <div className="d-flex justify-content-start align-items-center">
                {value ? (
                  <a
                    href={"/clients/" + value.id}
                    target="_blank"
                    data-text={value.nom_Client}
                    className="fixWidthHover"
                  >
                    {value.nom_Client}
                  </a>
                ) : (
                  "-"
                )}
              </div>
            );
          },
        },
        {
          label: "Contact entreprise",
          accessor: "entreprise_Contact",
          type: "selectSearch",
          service: () =>
            ContactService.getByIdClient(
              this.props.consultation?.entreprise?.id
            ),
          optionFieldToDisplay: ["nom_Contact", "prenom_Contact"],
          valueFieldToDisplay: ["nom_Contact", "prenom_Contact"],
          setForceUpdateFunction: this.setForceUpdateContactEntreprise,
          colSize: 6,
          functionAppliedToValue: (value) => {
            return (
              <div className="d-flex justify-content-start align-items-center">
                {value ? (
                  <a
                    href={"/contacts/" + value.id}
                    target="_blank"
                    data-text={
                      IfNullToText(value.nom_Contact, null) +
                      " " +
                      IfNullToText(value.prenom_Contact, null)
                    }
                    className="fixWidthHover"
                  >
                    {IfNullToText(value.nom_Contact, null) +
                      " " +
                      IfNullToText(value.prenom_Contact, null)}
                  </a>
                ) : (
                  "-"
                )}
              </div>
            );
          },
        },
        {
          label: "Maitre d'oeuvre",
          accessor: "maitre_Oeuvre",
          type: "selectSearch",
          service: ClientService.getForSelectSearch,
          optionFieldToDisplay: "nom_Client",
          valueFieldToDisplay: "nom_Client",
          afterUpdateFunction: () => {
            this.props.handleChange(
              "maitre_Oeuvre_Contact",
              null,
              this.state.callbackForceUpdateContactMaitreDOeuvre
            );
          },
          colSize: 6,
          functionAppliedToValue: (value) => {
            return (
              <div className="d-flex justify-content-start align-items-center">
                {value ? (
                  <a
                    href={"/clients/" + value.id}
                    target="_blank"
                    data-text={value.nom_Client}
                    className="fixWidthHover"
                  >
                    {value.nom_Client}
                  </a>
                ) : (
                  "-"
                )}
              </div>
            );
          },
        },
        {
          label: "Contact maitre d'oeuvre",
          accessor: "maitre_Oeuvre_Contact",
          type: "selectSearch",
          service: () =>
            ContactService.getByIdClient(
              this.props.consultation?.maitre_Oeuvre?.id
            ),
          optionFieldToDisplay: ["nom_Contact", "prenom_Contact"],
          valueFieldToDisplay: ["nom_Contact", "prenom_Contact"],
          setForceUpdateFunction: this.setForceUpdateContactMaitreDOeuvre,
          colSize: 6,
          functionAppliedToValue: (value) => {
            return (
              <div className="d-flex justify-content-start align-items-center">
                {value ? (
                  <a
                    href={"/contacts/" + value.id}
                    target="_blank"
                    data-text={
                      IfNullToText(value.nom_Contact, null) +
                      " " +
                      IfNullToText(value.prenom_Contact, null)
                    }
                    className="fixWidthHover"
                  >
                    {IfNullToText(value.nom_Contact, null) +
                      " " +
                      IfNullToText(value.prenom_Contact, null)}
                  </a>
                ) : (
                  "-"
                )}
              </div>
            );
          },
        },
        {
          label: "Maitre d'ouvrage",
          accessor: "maitre_ouvrage",
          type: "selectSearch",
          service: ClientService.getForSelectSearch,
          optionFieldToDisplay: "nom_Client",
          valueFieldToDisplay: "nom_Client",
          afterUpdateFunction: () => {
            this.props.handleChange(
              "maitre_Ouvrage_Contact",
              null,
              this.state.callbackForceUpdateContactMaitreDOuvrage
            );
          },
          colSize: 6,
          functionAppliedToValue: (value) => {
            return (
              <div className="d-flex justify-content-start align-items-center">
                {value ? (
                  <a
                    href={"/clients/" + value.id}
                    target="_blank"
                    data-text={value.nom_Client}
                    className="fixWidthHover"
                  >
                    {value.nom_Client}
                  </a>
                ) : (
                  "-"
                )}
              </div>
            );
          },
        },
        {
          label: "Contact maitre d'ouvrage",
          accessor: "maitre_Ouvrage_Contact",
          type: "selectSearch",
          service: () =>
            ContactService.getByIdClient(
              this.props.consultation?.maitre_ouvrage?.id
            ),
          optionFieldToDisplay: ["nom_Contact", "prenom_Contact"],
          valueFieldToDisplay: ["nom_Contact", "prenom_Contact"],
          setForceUpdateFunction: this.setForceUpdateContactMaitreDOuvrage,
          colSize: 6,
          functionAppliedToValue: (value) => {
            return (
              <div className="d-flex justify-content-start align-items-center">
                {value ? (
                  <a
                    href={"/contacts/" + value.id}
                    target="_blank"
                    data-text={
                      IfNullToText(value.nom_Contact, null) +
                      " " +
                      IfNullToText(value.prenom_Contact, null)
                    }
                    className="fixWidthHover"
                  >
                    {IfNullToText(value.nom_Contact, null) +
                      " " +
                      IfNullToText(value.prenom_Contact, null)}
                  </a>
                ) : (
                  "-"
                )}
              </div>
            );
          },
        },
        {
          label: "Mission",
          accessor: "mission",
          functionAppliedToValue: (value) => {
            return value ? value.designation : null;
          },
          type: "selectSearch",
          service: MissionService.getAll,
          optionFieldToDisplay: "designation",
          valueFieldToDisplay: "designation",
          colSize: 6,
        },
        {
          label: "Type d'ouvrage",
          accessor: "type_Ouvrage",
          type: "selectSearch",
          service: TypeOuvrageService.getAll,
          functionAppliedToValue: (value) => {
            return value ? value.signe + " " + value.type : null;
          },
          optionFieldToDisplay: ["signe", "type"],
          valueFieldToDisplay: ["signe", "type"],
          invalidText: "Vous devez sélectionner un pays de facturation.",
          colSize: 6,
        },

        {
          label: "Adresse Chantier",
          functionAppliedToValue: () => {
            return (
              <>
                {this.props.consultation.adresseChantier?.intitule ?? ""}
                <br />
                {this.props.consultation.adresseChantier?.voie ?? ""}
                <br />
                {this.props.consultation.adresseChantier?.complement ?? ""}
                <br />
                {(this.props.consultation.adresseChantier?.cp ?? "") +
                  " " +
                  (this.props.consultation.adresseChantier?.ville ?? "")}
                <br />
                {this.props.consultation.adresseChantier?.pays?.nom_Fr ?? ""}
              </>
            );
          },
          accessor: "adresseChantier",
          colSize: 6,
          type: "address",
          optionFieldToDisplay: "nom_Fr",
        },

        {
          label: "Présence CCTP",
          accessor: "presence_Cctp",
          type: "checkbox",
          functionAppliedToValue: (value) => {
            return value ? "Oui" : "Non";
          },
          colSize: 6,
        },
        {
          label: "NB de Lots",
          accessor: "nbLot",
          type: "decimal",
          numberOfDecimals: 0,
          colSize: 6,
          handleBlur: this.props.handleChange,
        },
        {
          label: "International",
          accessor: "international",
          type: "checkbox",
          functionAppliedToValue: (value) => {
            return value ? "Oui" : "Non";
          },
          colSize: 6,
        },
      ];
      const informationsPostChantier = [
        {
          label: "Motif de non réponse",
          accessor: "motif_Non_Reponse",
          type: "selectSearch",
          functionAppliedToValue: (value) => {
            return value ? value.designation : null;
          },
          service: MotifNonReponseService.getAll,
          optionFieldToDisplay: "designation",
          valueFieldToDisplay: "designation",
        },
        {
          label: "Tache devis créée",
          accessor: "tache_Devis_Creee",
          type: "checkbox",
          functionAppliedToValue: (value) => {
            return value ? "Oui" : "Non";
          },
        },
      ];
      const informationsDescriptif = [
        {
          label: "Descriptif",
          accessor: "descriptif",
          type: "textArea",
          functionAppliedToValue: (el) => {
            if (el != null) {
              return <div className="keepWhiteSpace">{el}</div>;
            }
          },
          colSize: 12,
        },
      ];

      const informationsParticulier = [
        {
          label: "Nom",
          accessor: "nomParticulier",
          type: "text",
          colSize: 6,
        },
        {
          label: "Nom",
          accessor: "prenomParticulier",
          type: "text",
          colSize: 6,
        },
        {
          label: "Mail",
          value: this.props.consultation.adresseParticulier?.email,
          accessor: "adresseParticulier.email",
          type: "email",
          colSize: 6,
        },
        {
          label: "Téléphone",
          value: this.props.consultation.adresseParticulier?.telephone,
          accessor: "adresseParticulier.telephone",
          type: "text",
          colSize: 6,
        },
        {
          label: "Adresse Particulier",
          functionAppliedToValue: () => {
            return (
              <>
                {this.props.consultation.adresseParticulier?.intitule ?? ""}
                <br />
                {this.props.consultation.adresseParticulier?.voie ?? ""}
                <br />
                {this.props.consultation.adresseParticulier?.complement ?? ""}
                <br />
                {(this.props.consultation.adresseParticulier?.cp ?? "") +
                  " " +
                  (this.props.consultation.adresseParticulier?.ville ?? "")}
              </>
            );
          },
          accessor: "adresseParticulier",
          colSize: 12,
          type: "address",
          optionFieldToDisplay: "nom_Fr",
        },
      ];
      const informationsFiche = [
        {
          label: "Date de création",
          accessor: "date_Creation",
          functionAppliedToValue: (value) => {
            return value
              ? ToLocaleDateString(value) +
                  " par " +
                  IfNullToText(
                    this.props.consultation.user_Create?.nom,
                    "inconnu"
                  ) +
                  " " +
                  IfNullToText(this.props.consultation.user_Create?.prenom, "")
              : "-";
          },
          colSize: 4,
        },
        {
          label: "Date de modification",
          accessor: "date_Modif",
          functionAppliedToValue: (value) => {
            return value
              ? ToLocaleDateString(value) +
                  " par " +
                  IfNullToText(
                    this.props.consultation.user_Create?.nom,
                    "inconnu"
                  ) +
                  " " +
                  IfNullToText(this.props.consultation.user_Create?.prenom, "")
              : "-";
          },
          colSize: 4,
        },
      ];
      return (
        <>
          <div className="row">
            <div className="col-12">
              <BoxInformationsInline
                title="général"
                datas={informationsGeneral}
                state={this.props.consultation}
                stateFieldNameToUpdate={this.props.stateFieldNameToUpdate}
                service={this.props.service}
                handleChange={this.props.handleChange}
                colSize="4"
                editing={this.props.editing}
                handleEditing={this.props.handleEditing}
                NoEdition={right == "RO"}
              />
            </div>
            <div className="col-lg-6 col-md-12">
              <BoxInformationsInline
                title="Informations sur la consultation"
                datas={informationsClient}
                state={this.props.consultation}
                stateFieldNameToUpdate={this.props.stateFieldNameToUpdate}
                service={this.props.service}
                handleChange={this.props.handleChange}
                colSize="4"
                editing={this.props.editing}
                handleEditing={this.props.handleEditing}
                NoEdition={right == "RO"}
              />
              <BoxInformationsInline
                title="Informations sur le chantier"
                datas={informationsTravailleurs}
                state={this.props.consultation}
                stateFieldNameToUpdate={this.props.stateFieldNameToUpdate}
                service={this.props.service}
                handleChange={this.props.handleChange}
                colSize="4"
                editing={this.props.editing}
                handleEditing={this.props.handleEditing}
                NoEdition={right == "RO"}
              />
            </div>
            <div className="col-lg-6 col-md-12">
              <BoxInformationsInline
                title="Commentaires"
                datas={informationsDescriptif}
                state={this.props.consultation}
                stateFieldNameToUpdate={this.props.stateFieldNameToUpdate}
                service={this.props.service}
                handleChange={this.props.handleChange}
                colSize="12"
                editing={this.props.editing}
                handleEditing={this.props.handleEditing}
                NoEdition={right == "RO"}
              />
              <BoxInformationsInline
                title="Informations sur la réponse"
                datas={informationsPostChantier}
                state={this.props.consultation}
                stateFieldNameToUpdate={this.props.stateFieldNameToUpdate}
                service={this.props.service}
                handleChange={this.props.handleChange}
                colSize="6"
                editing={this.props.editing}
                handleEditing={this.props.handleEditing}
                NoEdition={right == "RO"}
              />

              {this.props.consultation.isParticulier ? (
                <BoxInformationsInline
                  title="Informations Particulier"
                  datas={informationsParticulier}
                  state={this.props.consultation}
                  stateFieldNameToUpdate={this.props.stateFieldNameToUpdate}
                  service={this.props.service}
                  handleChange={this.props.handleChange}
                  colSize="4"
                  editing={this.props.editing}
                  handleEditing={this.props.handleEditing}
                />
              ) : null}

              <BoxInformationsInline
                title="Informations sur la fiche"
                datas={informationsFiche}
                state={this.props.consultation}
                stateFieldNameToUpdate={this.props.stateFieldNameToUpdate}
                service={this.props.service}
                handleChange={this.props.handleChange}
                colSize="4"
                boxSize="small"
                NoEdition
              />
            </div>
          </div>
        </>
      );
    } else {
      return (
        <>
          <WarningBar
            active={false}
            content={"Vous n'avez pas le droit de voir cette page"}
          />
        </>
      );
    }
  }
}

InformationsConsultation.propTypes = {
  consultation: PropTypes.object,
  stateFieldNameToUpdate: PropTypes.string,
  handleChange: PropTypes.func,
  history: PropTypes.object,
  service: PropTypes.func,
};

export { InformationsConsultation };
