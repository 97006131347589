import React from "react";
import { LigneCommandeDetail } from "./LigneCommandeDetail";
import { App } from "App";

import { ButtonIcon, WarningBar } from "_components";

import {
  faPlus,
  faCheck,
  faTimes,
  faPen,
  faCommentDots,
} from "@fortawesome/free-solid-svg-icons";

import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";

import { formatValueDecimal } from "_utils/DecimalFunctions";

import "./Details.css";

class Details extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      savedCommande: null,
      modelToImport: null,
    };

    this.handleClickEdition = this.handleClickEdition.bind(this);
    this.handleClickUndo = this.handleClickUndo.bind(this);
    this.handleClickValidate = this.handleClickValidate.bind(this);
    this.renderLigneCommandeDetail = this.renderLigneCommandeDetail.bind(this);
  }

  handleClickEdition() {
    this.setState({
      savedCommande: { ...this.props.commande },
    });
    this.props.handleEditing(true);
  }

  handleClickUndo() {
    this.props.handleChange(
      this.props.stateFieldNameToUpdate,
      this.state.savedCommande
    );
    this.props.handleEditing(false);
  }

  handleClickValidate() {
    this.props.handleUpdate();
    this.props.handleEditing(false);
  }

  renderLigneCommandeDetail(commandeDetail, index) {
    return (
      <LigneCommandeDetail
        key={commandeDetail.id}
        index={index}
        pourcentageTVA={this.props.commande.client?.tva?.valeur ?? 0}
        langueClient={
          this.props.commande?.client?.langue?.langage == "Français"
            ? "fr"
            : this.props.commande?.client?.langue?.langage == "Anglais"
            ? "en"
            : this.props.commande?.client?.langue?.langage == "Espagnol"
            ? "es"
            : null
        }
        commandeDetail={commandeDetail}
        handleChange={this.props.handleChange}
        moveLigneCommandeDetail={this.props.changeLinePosition}
        addLineCommandeDetail={this.props.addLineCommandeDetail}
        deleteLineCommandeDetail={this.props.deleteLineCommandeDetail}
        getSousTotal={this.props.getSousTotal}
        editing={this.props.editing}
      />
    );
  }

  render() {
    const right = App.RightsGuard?.current?.hasRight("Commande", "Details");
    if (right == "RW" || right == "RO") {
      return (
        <>
          <div className="CommandeDetails shadow p-3 m-4 bg-body rounded bg-light pageClient">
            <div
              className="d-flex justify-content-between"
              id="CommandeDetailsHeader"
            >
              <div>
                <span
                  className="badge rounded-pill bg-info"
                  style={{ fontSize: "1em", margin: "6px" }}
                >
                  TVA Client : {this.props.commande.client?.tva?.valeur ?? 0} %
                </span>
              </div>
              <div>
                <ButtonIcon
                  icon={faCheck}
                  className="btn btn-success text-light ms-1"
                  style={
                    !this.props.editing
                      ? { display: "none" }
                      : { width: "42px" }
                  }
                  onClick={() => {
                    this.handleClickValidate();
                  }}
                ></ButtonIcon>
                <ButtonIcon
                  icon={faTimes}
                  className="btn btn-danger text-light ms-1"
                  onClick={this.handleClickUndo}
                  style={
                    !this.props.editing
                      ? { display: "none" }
                      : { width: "42px" }
                  }
                ></ButtonIcon>
                <ButtonIcon
                  icon={faPen}
                  className="btn btn-secondary text-light ms-1"
                  onClick={this.handleClickEdition}
                  style={
                    this.props.editing || right == "RO"
                      ? { display: "none" }
                      : { width: "42px" }
                  }
                ></ButtonIcon>
              </div>
            </div>
            <div id="CommandeDetailsTable">
              <DndProvider backend={HTML5Backend}>
                <table>
                  <thead>
                    <tr>
                      <th className="noPadding"></th>
                      <th className="borderStyle noPadding">
                        <span>Ref Article</span>
                      </th>
                      <th className="borderStyle noPadding">
                        <span>Description</span>
                      </th>
                      <th className="borderStyle noPadding">
                        <span>Prix Vente Unitaire</span>
                      </th>
                      <th className="borderStyle noPadding">
                        <span>Quantité</span>
                      </th>
                      <th className="borderStyle noPadding">
                        <span>Unité</span>
                      </th>
                      <th className="borderStyle noPadding">
                        <span>Remise (%)</span>
                      </th>
                      <th className="borderStyle noPadding">
                        <span>Prix Vente Total</span>
                      </th>
                      <th className="borderStyle noPadding">
                        <span>Montant TVA</span>
                      </th>
                      <th className="borderStyle noPadding">
                        <span>Prix Vente Total TTC</span>
                      </th>
                      <th className="col11 px-2 ">
                        <ButtonIcon
                          icon={faPlus}
                          iconSize="sm"
                          onClick={() => this.props?.addLineCommandeDetail(0)}
                          className="btn btn-success addLineCommandeDetail"
                          style={!this.props.editing ? { display: "none" } : {}}
                        ></ButtonIcon>
                      </th>
                      <th className="col12 px-2 ">
                        <ButtonIcon
                          icon={faCommentDots}
                          iconSize="sm"
                          onClick={() => this.props?.addLineCommandeDetail(1)}
                          className="btn btn-success addLineCommandeDetail"
                          style={!this.props.editing ? { display: "none" } : {}}
                        ></ButtonIcon>
                      </th>
                      <th className="col13 px-2 ">
                        <button
                          type="button"
                          onClick={() => this.props?.addLineCommandeDetail(2)}
                          className="btn btn-success addLineCommandeDetail"
                          style={!this.props.editing ? { display: "none" } : {}}
                        >
                          {"\u03A3"}
                        </button>
                      </th>
                      <th className="col14 px-2 "></th>
                    </tr>
                  </thead>

                  <tbody>
                    {this.props.commande.commandeDetails.map(
                      (commandeDetail, index) =>
                        this.renderLigneCommandeDetail(commandeDetail, index)
                    )}
                  </tbody>

                  <tfoot>
                    <tr>
                      <td
                        colSpan="6"
                        style={{ backgroundClip: "padding-box" }}
                      ></td>
                      <td className="borderStyle noPadding">
                        <span>Total</span>
                      </td>
                      <td className="borderStyle noPadding">
                        <span>
                          {formatValueDecimal(
                            this.props.commande.prixVenteTotal
                          )}
                        </span>
                      </td>
                      <td className="borderStyle noPadding">
                        <span>
                          {formatValueDecimal(
                            this.props.commande.montantTVATotal
                          )}
                        </span>
                      </td>
                      <td className="borderStyle noPadding">
                        <span>
                          {formatValueDecimal(
                            this.props.commande.prixVenteTTCTotal
                          )}
                        </span>
                      </td>
                    </tr>
                  </tfoot>
                </table>
              </DndProvider>
            </div>
          </div>
        </>
      );
    } else {
      return (
        <>
          <WarningBar
            active={false}
            content={"Vous n'avez pas le droit de voir cette page"}
          />
        </>
      );
    }
  }
}

export { Details };
