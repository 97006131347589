import React from "react";
import { PropTypes } from "prop-types";
import { App } from "App";

import {
  BoxInformationsInline,
  BoxAdresse,
  ButtonIcon,
  WarningBar,
} from "_components";

import { faPaste, faPlus } from "@fortawesome/free-solid-svg-icons";

class LogistiqueClient extends React.PureComponent {
  constructor(props) {
    super(props);

    this.renderAdresse = this.renderAdresse.bind(this);
  }

  renderAdresse(adresse, index) {
    const right = App.RightsGuard?.current?.hasRight("Client", "Logistique");

    return (
      <div key={adresse.id} className="col-4">
        <BoxAdresse
          title={"Adresse n°" + (index + 2)}
          datas={[
            {
              value: adresse,
              label: "Adresse de livraison",
              functionAppliedToValue: () => {
                return (
                  <>
                    {adresse.intitule ?? ""}
                    <br />
                    {adresse.voie ?? ""}
                    <br />
                    {adresse.complement ?? ""}
                    <br />
                    {(adresse.cp ?? "") + " " + (adresse.ville ?? "")}
                    <br />
                    {adresse.pays?.nom_Fr ?? ""}
                    <br />
                  </>
                );
              },
              accessor: "adresses_Livraison." + adresse.id,
              colSize: 12,
              type: "address",
              optionFieldToDisplay: "nom_Fr",
              requiredIntitule: true,
            },
            {
              value: adresse.nomContact,
              label: "Contact",
              accessor: "adresses_Livraison." + adresse.id + ".nomContact",
              functionAppliedToValue: () => adresse.nomContact,
              type: "text",
              colSize: 6,
            },
            {
              value: adresse.email,
              label: "Email",
              accessor: "adresses_Livraison." + adresse.id + ".email",
              functionAppliedToValue: () => adresse.email,
              type: "email",
              colSize: 6,
            },
            {
              value: adresse.telephone,
              label: "Téléphone",
              accessor: "adresses_Livraison." + adresse.id + ".telephone",
              functionAppliedToValue: () => adresse.telephone,
              type: "text",
              colSize: 6,
            },
            {
              value: adresse.fax,
              label: "Fax",
              accessor: "adresses_Livraison." + adresse.id + ".fax",
              functionAppliedToValue: () => adresse.fax,
              type: "text",
              colSize: 6,
            },
          ]}
          state={this.props.client}
          stateFieldNameToUpdate={this.props.stateFieldNameToUpdate}
          service={this.props.service}
          handleChange={this.props.handleChange}
          handleDelete={() => this.props.deleteAdresseLivraison(adresse.id)}
          colSize="4"
          editing={this.props.editing}
          handleEditing={this.props.handleEditing}
          swapWithAdressePrincipale={() =>
            this.props.swapWithAdressePrincipale(adresse.id)
          }
          isNew={this.props.idNewAdresse == adresse.id}
          NoEdition={right == "RO"}
          collerAdresse={() =>
            this.props.collerAdresse("adresses_Livraison." + adresse.id)
          }
        />
      </div>
    );
  }

  render() {
    const right = App.RightsGuard?.current?.hasRight("Client", "Logistique");
    if (right == "RW" || right == "RO") {
      const informationsAdresseLivraisonPrincipale = [
        {
          label: "Adresse de livraison",
          functionAppliedToValue: () => {
            return (
              <>
                {this.props.client.adresse_Livraison_Principale?.intitule ?? ""}
                <br />
                {this.props.client.adresse_Livraison_Principale?.voie ?? ""}
                <br />
                {this.props.client.adresse_Livraison_Principale?.complement ??
                  ""}
                <br />
                {(this.props.client.adresse_Livraison_Principale?.cp ?? "") +
                  " " +
                  (this.props.client.adresse_Livraison_Principale?.ville ?? "")}
                <br />
                {this.props.client.adresse_Livraison_Principale?.pays?.nom_Fr ??
                  ""}
                <br />
              </>
            );
          },
          accessor: "adresse_Livraison_Principale",
          colSize: 12,
          type: "address",
          optionFieldToDisplay: "nom_Fr",
          value: this.props.client.adresse_Livraison_Principale,
        },
        {
          value: this.props.client.adresse_Livraison_Principale?.nomContact,
          label: "Contact",
          accessor: "adresse_Livraison_Principale.nomContact",
          type: "text",
          colSize: 6,
        },
        {
          value: this.props.client.adresse_Livraison_Principale?.email,
          label: "Email",
          accessor: "adresse_Livraison_Principale.email",
          type: "email",
          colSize: 6,
        },
        {
          value: this.props.client.adresse_Livraison_Principale?.telephone,
          label: "Téléphone",
          accessor: "adresse_Livraison_Principale.telephone",
          type: "text",
          colSize: 6,
        },
        {
          value: this.props.client.adresse_Livraison_Principale?.fax,
          label: "Fax",
          accessor: "adresse_Livraison_Principale.fax",
          type: "text",
          colSize: 6,
        },
      ];

      var adressesLivraison = this.props.client.adresses_Livraison?.map(
        (adresse, index) => this.renderAdresse(adresse, index)
      );

      return (
        <>
          <div className="row">
            <div className="col-4">
              <BoxInformationsInline
                title="Adresse principale"
                datas={informationsAdresseLivraisonPrincipale}
                state={this.props.client}
                stateFieldNameToUpdate={this.props.stateFieldNameToUpdate}
                service={this.props.service}
                handleChange={this.props.handleChange}
                colSize="4"
                editing={this.props.editing}
                handleEditing={this.props.handleEditing}
                handleUpdate={this.props.handleUpdate}
                NoEdition={right == "RO"}
                boutonSupplementaire={
                  <>
                    <ButtonIcon
                      icon={faPaste}
                      className="btn btn-success btn-sm text-light ms-1"
                      onClick={() =>
                        this.props.collerAdresse("adresse_Livraison_Principale")
                      }
                      style={
                        this.props.collerAdresse && this.props.editing
                          ? null
                          : { display: "none" }
                      }
                      tooltip="Coller l'adresse"
                    ></ButtonIcon>
                  </>
                }
              />
            </div>
            {adressesLivraison}
            <div className="col-12">
              <ButtonIcon
                icon={faPlus}
                onClick={this.props.addNewAdresseLivraison}
                style={
                  this.props.editing || right == "RO"
                    ? { display: "none" }
                    : { width: "40px" }
                }
                className="form-control btn btn-primary ms-1"
                tooltip="Ajouter une adresse au client"
              ></ButtonIcon>
            </div>
          </div>
        </>
      );
    } else
      return (
        <>
          <WarningBar
            active={false}
            content={"Vous n'avez pas le droit de voir cette page"}
          />
        </>
      );
  }
}

LogistiqueClient.propTypes = {
  client: PropTypes.object.isRequired,
  stateFieldNameToUpdate: PropTypes.string,
  service: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  idNewAdresse: PropTypes.number,
  swapWithAdressePrincipale: PropTypes.func,
};

export { LogistiqueClient };
