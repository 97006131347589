// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#homepage td, #homepage th{
    padding-left: 0.4rem !important;
    padding-top: 0.25rem;
}

#homepage .shadowLine{
  box-shadow: inset 0 0 0 9999px rgba(0,0,0,0.05) !important
}

#homepage th {
  text-transform: uppercase;
  white-space: nowrap;
}

`, "",{"version":3,"sources":["webpack://./src/HomePage/HomePage.css"],"names":[],"mappings":"AAAA;IACI,+BAA+B;IAC/B,oBAAoB;AACxB;;AAEA;EACE;AACF;;AAEA;EACE,yBAAyB;EACzB,mBAAmB;AACrB","sourcesContent":["#homepage td, #homepage th{\n    padding-left: 0.4rem !important;\n    padding-top: 0.25rem;\n}\n\n#homepage .shadowLine{\n  box-shadow: inset 0 0 0 9999px rgba(0,0,0,0.05) !important\n}\n\n#homepage th {\n  text-transform: uppercase;\n  white-space: nowrap;\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
