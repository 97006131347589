import http from "http-common";

const ArborescenceArticleService = class {
  /**
   * Fonction permettant de récuperer toutes les arborescence article
   * @returns la liste de toutes les arborescence article
   */
  static getAll() {
    return http.get("/ArborescenceArticle");
  }

  /**
   * Fonction permettant de récupérer une arborescence article via son id
   * @param {*} id, id de la arborescence article à récupérer
   * @returns la arborescence article souhaitée
   */
  static getById(id) {
    return http.get("/ArborescenceArticle/" + id);
  }
};

export { ArborescenceArticleService };
