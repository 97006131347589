import React from "react";
import { PropTypes } from "prop-types";
import { Link } from "react-router-dom";
import { App } from "App";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTrash,
  faSpinner,
  faPrint,
  faEnvelopeOpenText,
  faFileSignature,
  faFileMedicalAlt,
  faPlus,
} from "@fortawesome/free-solid-svg-icons";

import { General, SuiviCVM, SAV } from ".";
import {
  WarningBar,
  ButtonIconWithValidation,
  ButtonIcon,
  DialogForm,
} from "_components";
import { Input } from "_components/Input";
import * as FileSaver from "file-saver";

import { MaterielService } from "_services";
import { Helmet } from "react-helmet";

import produce from "immer";
import TabSelector from "_components/FicheComponents/TabSelector";

class FicheMateriel extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      tabs: ["Général", "SAV", "Suivi CVM"],
      active: "Général",
      editing: false,
      editingElements: false,
      materiel: {},
      openDialogImpressionCVMFunc: null,
    };

    this.renderActive = this.renderActive.bind(this);
    this.generateTabs = this.generateTabs.bind(this);
    this.handleCreateFdS = this.handleCreateFdS.bind(this);
    this.handleCreateContrat = this.handleCreateContrat.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
    this.handleEditing = this.handleEditing.bind(this);
    this.handleEditingElements = this.handleEditingElements.bind(this);
    this.handleUpdate = this.handleUpdate.bind(this);
    this.addLineElement = this.addLineElement.bind(this);
    this.addLineSuivi = this.addLineSuivi.bind(this);
    this.deleteBlocSuivi = this.deleteBlocSuivi.bind(this);
    this.deleteLineElement = this.deleteLineElement.bind(this);
    this.setOpenDialogImpressionCVM =
      this.setOpenDialogImpressionCVM.bind(this);
    this.setOpenDialogImpressionRelanceCVM =
      this.setOpenDialogImpressionRelanceCVM.bind(this);
    this.setOpenDialogImpressionDCE =
      this.setOpenDialogImpressionDCE.bind(this);
  }

  componentDidMount() {
    if (sessionStorage.getItem("FicheMaterielOnglet") == null) {
      sessionStorage.setItem("FicheMaterielOnglet", this.state.active);
    } else {
      this.setState({ active: sessionStorage.getItem("FicheMaterielOnglet") });
    }

    let { id } = this.props.match.params;
    MaterielService.getById(id).then((res) => {
      this.setState({ materiel: res.data, loading: false });
    });
  }

  setOpenDialogImpressionCVM(openDialogFunc) {
    this.setState({ openDialogImpressionCVMFunc: openDialogFunc });
  }

  setOpenDialogImpressionRelanceCVM(openDialogFunc) {
    this.setState({ openDialogImpressionRelanceCVMFunc: openDialogFunc });
  }

  setOpenDialogImpressionDCE(openDialogFunc) {
    this.setState({ openDialogImpressionDCEFunc: openDialogFunc });
  }

  handleCreateFdS() {
    this.props.history.push({
      pathname: "/fichesDeSuivi/add",
      state: {
        utilisateur: this.state.materiel.utilisateur,
        materiel: this.state.materiel,
      },
    });
  }

  handleCreateContrat() {
    this.props.history.push({
      pathname: "/contrats/add",
      state: {
        materiel: this.state.materiel,
      },
    });
  }

  addLineElement() {
    this.setState(
      produce((prevState) => {
        // To keep the ids unique, if there is no other new line (with negative id), the new line id is -1, else, it's the minimum id - 1.
        let newLineId =
          Math.min(
            ...prevState.materiel.elementsMateriels.map((el) => el.id),
            0
          ) - 1;

        // Unshift to put it at the front
        prevState.materiel.elementsMateriels.unshift({
          id: newLineId,
          au_Rebus: false,
          date_Fin_Garantie: null,
          date_Rebus: null,
          date_Fabrication: null,
          num_Serie: "",
          reference: "",
          type: "",
        });
      })
    );
  }

  deleteLineElement(elementId) {
    this.setState(
      produce((prevState) => {
        prevState.materiel.elementsMateriels.splice(
          prevState.materiel.elementsMateriels.findIndex(
            (el) => el.id === elementId
          ),
          1
        );
      })
    );
  }

  addLineSuivi() {
    this.setState(
      produce((prevState) => {
        // To keep the ids unique, if there is no other new line (with negative id), the new line id is -1, else, it's the minimum id - 1.
        let newLineId =
          Math.min(...prevState.materiel.suiviElement.map((el) => el.id), 0) -
          1;

        let distinctTypesMateriels = [
          ...new Set(
            prevState.materiel.articleASuivre?.elementsMateriels?.map(
              (el) => el.type
            )
          ),
        ];

        let lignesSuivi = distinctTypesMateriels?.map((type) => {
          let elementNumSerie = prevState.materiel.elementsMateriels?.filter(
            (l) =>
              !l.au_Rebus &&
              type?.localeCompare(l?.type, "fr-FR", {
                sensitivity: "base",
              }) == 0
          )[0]?.num_Serie;

          return {
            id: newLineId--,
            typeElement: type,
            numSerieElement: elementNumSerie,
            erreurSensibilite: "0.0",
            erreurLinearite: "0.0",
            erreurFidelite: "0.0",
          };
        });

        // Unshift to put it at the front
        prevState.materiel.suiviElement.push({
          id: newLineId,
          date: new Date(),
          etalonnePar: "",
          lignes: lignesSuivi,
        });
      })
    );
  }

  deleteBlocSuivi(suiviId) {
    this.setState(
      produce((prevState) => {
        prevState.materiel.suiviElement.splice(
          prevState.materiel.suiviElement.findIndex((el) => el.id === suiviId),
          1
        );
      })
    );
  }

  /**
   * Permet de mettre a jour l'etat du fiche de suivi sans devoir le recharger
   * @param {*} accessor
   * @param {*} value
   */
  handleChange(accessor, value, callbackFunction) {
    if (accessor === "materiel") {
      //Undo
      this.setState({ materiel: value }, callbackFunction);
    } else if (accessor.includes(".")) {
      // Nested property
      var accessorSplit = accessor.split(".");

      if (accessorSplit[0] === "elementMateriel") {
        // Format element.id.accessor
        var elementId = accessorSplit[1];
        var elementAccessor = accessorSplit[2];

        // Special : Pour un élément, quand on set au_Rebus, on set aussi la date à maintenant
        if (elementAccessor === "au_Rebus" && value == true) {
          this.setState(
            produce((prevState) => {
              let prevElement = prevState.materiel.elementsMateriels.find(
                (el) => el.id == elementId
              );
              prevElement.au_Rebus = value;
              prevElement.date_Rebus = new Date();
            }),
            callbackFunction
          );
        } else {
          this.setState(
            produce((prevState) => {
              let prevElement = prevState.materiel.elementsMateriels.find(
                (el) => el.id == elementId
              );
              prevElement[elementAccessor] = value;
            }),
            callbackFunction
          );
        }
      } else if (accessorSplit[0] == "suivi") {
        // Format suivi.id.accessor
        var suiviId = accessorSplit[1];
        var suiviAccessor = accessorSplit[2];

        this.setState(
          produce((prevState) => {
            let prevSuivi = prevState.materiel.suiviElement.find(
              (se) => se.id == suiviId
            );
            prevSuivi[suiviAccessor] = value;
          }),
          callbackFunction
        );
      } else if (accessorSplit[0] == "ligne") {
        // Format ligne.id.accessor
        var ligneId = accessorSplit[1];
        var ligneAccessor = accessorSplit[2];

        this.setState(
          produce((prevState) => {
            let prevLigne = null;
            prevState.materiel.suiviElement.forEach((suivi) => {
              suivi.lignes.forEach((ligne) => {
                if (ligne.id == ligneId) {
                  prevLigne = ligne;
                }
              });
            });
            if (prevLigne) {
              prevLigne[ligneAccessor] = value;
            }
          }),
          callbackFunction
        );
      }
    } else if (accessor === "proprietaire") {
      this.setState(
        produce((prevState) => {
          prevState.materiel.proprietaire = value;
          prevState.materiel.multipleClientsPossible = false;
        }),
        callbackFunction
      );
    } else {
      //Update
      this.setState(
        (prevState) => ({
          materiel: {
            ...prevState.materiel,
            [accessor]: value,
          },
        }),
        callbackFunction
      );
    }
  }

  handleDelete() {
    return MaterielService.delete(this.state.materiel.id).then(() => {
      this.props.history.push({ pathname: "/materiels" });
    });
  }

  handleEditing(editing) {
    this.setState((prevState) => ({
      ...prevState,
      editing: editing,
    }));
  }

  handleEditingElements(editing) {
    this.setState((prevState) => ({
      ...prevState,
      editing: editing,
      editingElements: editing,
    }));
  }

  handleUpdate() {
    return MaterielService.put(this.state.materiel).then((res) => {
      this.setState({ materiel: res.data });
    });
  }

  /**
   * Génère les onglets de la fiche
   * @param {*} tabs
   * @returns
   */
  generateTabs(tabs) {
    return (
      <div>
        <TabSelector
          tabs={tabs}
          activeTab={this.state.active}
          onSelect={(tab) => {
            this.setState({ active: tab });
            sessionStorage.setItem("FicheMaterielOnglet", tab);
          }}
          notAllowed={this.state.editing}
        ></TabSelector>
        <div className="ms-auto align-self-center">
          <div className="text-end"></div>
        </div>
        <div>
          <DialogForm
            tooltip="Imprimer contrat"
            classNameButton="btn btn-success"
            dialogTitle="Impression"
            labelValidateButton="Valider"
            setOpenDialog={this.setOpenDialogImpressionCVM}
            validation={() => {
              if (this.state.impression != null) return true;
              else {
                App.Toaster.current?.createToast({
                  body: "Vous devez sélectionner un modèle",
                  header: "Echec",
                  type: "failure",
                });

                return false;
              }
            }}
            onValidate={() => {
              MaterielService.printCVM(
                this.state.impression.designation,
                this.state.materiel.id
              ).then((res) => {
                var blob = new Blob([res?.data], {
                  type: "	application/vnd.openxmlformats-officedocument.wordprocessingml.document",
                });
                FileSaver.saveAs(
                  blob,
                  "fiche_cvm_" + this.state.materiel.num_Serie + ".docx"
                );
              });
            }}
            onClose={() => {}}
            body={
              <div id="PopupCommande">
                <div>
                  Veuillez choisir un modèle d'impression <br />
                </div>

                <Input
                  label="Modèle"
                  accessor="impression"
                  type="selectSearch"
                  value={this.state.impression}
                  valueFieldToDisplay="designation"
                  optionFieldToDisplay="designation"
                  options={[
                    {
                      id: 0,
                      designation: "Panda 2",
                    },
                    {
                      id: 1,
                      designation: "Panda 2 - FUJI",
                    },
                    {
                      id: 2,
                      designation: "Panda 4",
                    },
                    {
                      id: 3,
                      designation: "Grizzly",
                    },
                  ]}
                  functionAppliedToValue={(value) => {
                    if (value) return value.designation;
                    return null;
                  }}
                  handleChange={(acc, value) =>
                    this.setState({ impression: value })
                  }
                  required={true}
                />
              </div>
            }
          />
          <DialogForm
            tooltip="Imprimer contrat"
            classNameButton="btn btn-success"
            dialogTitle="Impression"
            labelValidateButton="Valider"
            setOpenDialog={this.setOpenDialogImpressionRelanceCVM}
            validation={() => {
              if (this.state.impressionRelance != null) return true;
              else {
                App.Toaster.current?.createToast({
                  body: "Vous devez sélectionner un modèle",
                  header: "Echec",
                  type: "failure",
                });

                return false;
              }
            }}
            onValidate={() => {
              MaterielService.printRelanceCVM(
                this.state.impressionRelance.designation,
                this.state.materiel.id
              ).then((res) => {
                var blob = new Blob([res?.data], {
                  type: "	application/vnd.openxmlformats-officedocument.wordprocessingml.document",
                });
                FileSaver.saveAs(
                  blob,
                  "lettre_relance_cvm_" +
                    this.state.materiel.num_Serie +
                    ".docx"
                );
              });
            }}
            onClose={() => {}}
            body={
              <div id="PopupCommande">
                <div>
                  Veuillez choisir un modèle d'impression <br />
                </div>

                <Input
                  label="Modèle"
                  accessor="impressionRelance"
                  type="selectSearch"
                  value={this.state.impressionRelance}
                  valueFieldToDisplay="designation"
                  optionFieldToDisplay="designation"
                  options={[
                    {
                      id: 0,
                      designation: "Panda 4",
                    },
                    {
                      id: 1,
                      designation: "Plaque Dynamique Légère",
                    },
                    {
                      id: 2,
                      designation: "Grizzly",
                    },
                  ]}
                  functionAppliedToValue={(value) => {
                    if (value) return value.designation;
                    return null;
                  }}
                  handleChange={(acc, value) =>
                    this.setState({ impressionRelance: value })
                  }
                  required={true}
                />
              </div>
            }
          />
          <DialogForm
            tooltip="Imprimer DCE"
            classNameButton="btn btn-success"
            dialogTitle="Impression DCE"
            labelValidateButton="Valider"
            setOpenDialog={this.setOpenDialogImpressionDCE}
            validation={() => {
              if (this.state.impressionDCE != null) return true;
              else {
                App.Toaster.current?.createToast({
                  body: "Vous devez sélectionner un modèle",
                  header: "Echec",
                  type: "failure",
                });

                return false;
              }
            }}
            onValidate={() => {
              MaterielService.printDCE(
                this.state.impressionDCE.designation,
                this.state.materiel.id
              ).then((res) => {
                var blob = new Blob([res?.data], {
                  type: "application/pdf;base64",
                });
                FileSaver.saveAs(
                  blob,
                  "dce_" + this.state.materiel.num_Serie + ".pdf"
                );
              });
            }}
            onClose={() => {}}
            body={
              <div id="PopupCommande">
                <div>
                  Veuillez choisir un modèle d'impression <br />
                </div>

                <Input
                  label="Modèle"
                  accessor="impressionDCE"
                  type="selectSearch"
                  value={this.state.impressionDCE}
                  valueFieldToDisplay="designation"
                  optionFieldToDisplay="designation"
                  options={[
                    {
                      id: 0,
                      designation: "Panda",
                    },
                    {
                      id: 1,
                      designation: "Grizzly",
                    },
                    {
                      id: 2,
                      designation: "BEL",
                    },
                    {
                      id: 3,
                      designation: "e-Kodiak",
                    },
                    {
                      id: 4,
                      designation: "Kodiak",
                    },
                    {
                      id: 5,
                      designation: "Depthbox",
                    },
                  ]}
                  functionAppliedToValue={(value) => {
                    if (value) return value.designation;
                    return null;
                  }}
                  handleChange={(acc, value) =>
                    this.setState({ impressionDCE: value })
                  }
                  required={true}
                />
              </div>
            }
          />
        </div>
      </div>
    );
  }

  renderActive() {
    switch (this.state.active) {
      case "Général":
        return (
          <General
            materiel={this.state.materiel}
            stateFieldNameToUpdate="materiel"
            handleChange={this.handleChange}
            // Overrides the put method for the changes to the calculette implied by changes on produit_interne or type_mission
            service={{
              ...MaterielService,
              put: this.handleUpdate,
            }}
            history={this.props.history}
            editing={this.state.editing}
            editingElements={this.state.editingElements}
            handleEditing={this.handleEditing}
            handleEditingElements={this.handleEditingElements}
            handleUpdate={this.handleUpdate}
            addLineElement={this.addLineElement}
            deleteLineElement={this.deleteLineElement}
          />
        );
      case "Suivi CVM":
        return (
          <SuiviCVM
            materiel={this.state.materiel}
            stateFieldNameToUpdate="materiel"
            handleChange={this.handleChange}
            // Overrides the put method for the changes to the calculette implied by changes on produit_interne or type_mission
            service={{
              ...MaterielService,
              put: this.handleUpdate,
            }}
            history={this.props.history}
            editing={this.state.editing}
            handleEditing={this.handleEditing}
            handleUpdate={this.handleUpdate}
            addLineSuivi={this.addLineSuivi}
            deleteBlocSuivi={this.deleteBlocSuivi}
          />
        );
      case "SAV":
        return (
          <SAV
            materiel={this.state.materiel}
            stateFieldNameToUpdate="materiel"
            handleChange={this.handleChange}
            // Overrides the put method for the changes to the calculette implied by changes on produit_interne or type_mission
            service={{
              ...MaterielService,
              put: this.handleUpdate,
            }}
            history={this.props.history}
            editing={this.state.editing}
            handleEditing={this.handleEditing}
            handleUpdate={this.handleUpdate}
            addLineSuivi={this.addLineSuivi}
            deleteBlocSuivi={this.deleteBlocSuivi}
          />
        );
      default:
        return (
          <h4 className="mt-5">Désolé la page n&apos;a pas réussi à charger</h4>
        );
    }
  }

  render() {
    const isMaterielEmpty = !Object.entries(this.state.materiel)?.length;
    const dceRight = App.RightsGuard?.current?.hasRight("Materiel", "DCE");

    return (
      <>
        <Helmet>
          <title>{"Materiel : ".concat(this.state.materiel.num_Serie)}</title>
        </Helmet>
        {this.state.loading ? (
          <div
            style={{
              flexGrow: 1,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <FontAwesomeIcon icon={faSpinner} size="lg" className="fa-spin" />
          </div>
        ) : (
          <div className="p-3 m-4" id="ficheClient">
            <div className="d-flex overflow-auto justify-content-between py-1">
              <h4 className="nowrap pr-100">
                {"Materiel : ".concat(this.state.materiel.num_Serie)}
              </h4>
              <div className="d-flex">
                <Link to="/materiels" className="btn btn-primary nowrap ms-1">
                  Base materiel
                </Link>
                {this.props.history.length > 1 && (
                  <button
                    className="btn btn-primary nowrap ms-1"
                    onClick={() => {
                      this.props.history.goBack();
                    }}
                  >
                    Retour
                  </button>
                )}
                <ButtonIcon
                  icon={faPlus}
                  onClick={this.handleCreateContrat}
                  className="btn btn-success text-light ms-1"
                  tooltip="Créer un contrat LLD pour ce materiel"
                ></ButtonIcon>
                <ButtonIcon
                  icon={faFileMedicalAlt}
                  className="btn btn-success text-light ms-1"
                  tooltip="Créer une fiche de suivi"
                  onClick={() => {
                    this.handleCreateFdS();
                  }}
                ></ButtonIcon>
                <ButtonIcon
                  icon={faPrint}
                  className="btn btn-success text-light ms-1"
                  tooltip="Impression CVM"
                  onClick={() => {
                    this.state.openDialogImpressionCVMFunc();
                  }}
                />
                <ButtonIcon
                  icon={faEnvelopeOpenText}
                  className="btn btn-success text-light ms-1"
                  tooltip="Impression relance CVM"
                  onClick={() => {
                    this.state.openDialogImpressionRelanceCVMFunc();
                  }}
                />
                {dceRight == "RW" ? (
                  <ButtonIcon
                    icon={faFileSignature}
                    className="btn btn-success text-light ms-1"
                    tooltip="Impression DCE"
                    onClick={() => {
                      this.state.openDialogImpressionDCEFunc();
                    }}
                  />
                ) : null}
                <div className="btn-group ">
                  {App.RightsGuard.current?.hasRight(
                    "Materiel",
                    "Suppression"
                  ) == "RW" ? (
                    <ButtonIconWithValidation
                      icon={faTrash}
                      onClick={this.handleDelete}
                      className="form-control btn btn-danger btn-sm text-light ms-1"
                      alertTitle="Suppression"
                      alertBody="Souhaitez-vous réellement supprimer ce materiel ?"
                      alertButtonValidationText="Oui, je veux supprimer."
                    ></ButtonIconWithValidation>
                  ) : null}
                </div>
              </div>
            </div>
            {this.generateTabs(this.state.tabs)}
            <WarningBar
              active={!this.state.materiel.en_sommeil}
              content={"Ce Materiel n'est pas actif"}
            />
            <WarningBar
              active={!this.state.materiel.isStolen}
              content={"Ce Materiel a été volé"}
              background={"bg-danger"}
            />
            <WarningBar
              active={
                this.state.materiel?.pack == null ||
                (this.state.materiel?.contrat?.categorie?.designation !=
                  "LLD" &&
                  this.state.materiel?.contrat?.categorie?.designation != "ASS")
              }
              content={"Pack : " + this.state.materiel?.pack?.designation}
            />
            {!isMaterielEmpty && this.renderActive()}
          </div>
        )}
      </>
    );
  }
}

/**
 * Validation des props :
 */
FicheMateriel.propTypes = {
  history: PropTypes.object,
  match: PropTypes.object,
};

export { FicheMateriel };
