import React from "react";
import {
  ReactTableServerSide,
  CellWithRedirection,
  WarningBar,
} from "_components";

import { PropTypes } from "prop-types";
import {
  ChantierService,
  StatutChantierService,
  ProduitInterneService,
  PersonnelService,
} from "_services/";
import { Helmet } from "react-helmet";

import { App } from "App";

class BaseChantier extends React.PureComponent {
  constructor(props) {
    super(props);
  }

  render() {
    const right = App.RightsGuard?.current?.hasRight(
      "Chantiers",
      "Base chantiers"
    );

    return right == "RW" || right == "RO" ? (
      <>
        <Helmet>
          <title>Base chantier</title>
        </Helmet>
        <ReactTableServerSide
          title="Base Chantier"
          titreFiltre="Base Chantier"
          service={ChantierService.getAllViaPost}
          displayExtractButton={
            App.RightsGuard.current?.hasRight("Chantier", "Extraction") == "RW"
          }
          serviceExtract={ChantierService.Extract}
          module="Chantier"
          orderByDefault="reference"
          orderDefault="DESC"
          localStorageKeyColumns="BaseChantierColumns"
          columns={[
            {
              id: "tache",
              accessor: "hasTacheEnCours",
              Header: () => <div></div>,
              Cell: (row) => {
                console.log(row?.cell?.value);
                return (
                  <div className="text-center align-middle" id="selectOne">
                    <div
                      style={{
                        width: "20px",
                        height: "20px",
                        borderRadius: "10px",
                        backgroundColor: row?.cell?.value ? "red" : "green",
                        marginRight: "5px",
                        flexShrink: "0",
                      }}
                    />
                  </div>
                );
              },
            },
            {
              Header: "Statut",
              accessor: "statut",
              search: "statut",
              hideable: true,
              sortable: true,
              exportable: true,
              minWidth: "130px",
              type: "multipleSelectSearch",
              defaultValue: [
                {
                  id: 2,
                  designation: "En cours",
                },
              ],
              service: StatutChantierService.getAll,
              optionFieldToReturn: "id",
              optionFieldToDisplay: "designation",
              valueFieldToDisplay: "designation",
              Cell: (row) => {
                return row.cell.value ? (
                  <span>{row.cell.value.designation}</span>
                ) : null;
              },
            },

            {
              Header: "N° Affaire",
              accessor: "affaire",
              hideable: true,
              sortable: true,
              exportable: true,
              minWidth: "150px",
              type: "text",
              search: "affaire",

              Cell: (row) => {
                return (
                  <CellWithRedirection
                    history={this.props.history}
                    redirection="/affaires/"
                    id={row.cell.value?.id}
                    value={row.cell.value?.reference}
                  />
                );
              },
            },
            {
              Header: "Nom affaire",
              accessor: "affaire.nomAffaire",
              hideable: true,
              sortable: true,
              exportable: true,
              minWidth: "300px",
              type: "text",
              search: "nomAffaire",

              Cell: (row) => {
                return (
                  <CellWithRedirection
                    history={this.props.history}
                    redirection="/affaires/"
                    id={row.cell.row.original.affaire.id}
                    value={row.cell.row.original.affaire.nomAffaire}
                  />
                );
              },
            },
            {
              Header: "Produit interne",
              accessor: "affaire.produitInterne",
              search: "produitInterne",
              hideable: true,
              sortable: true,
              exportable: true,
              minWidth: "150px",
              type: "multipleSelectSearch",
              defaultValue: null,
              optionFieldToDisplay: "designation",
              optionFieldToReturn: "id",
              valueFieldToDisplay: "designation",
              service: ProduitInterneService.getAll,
              Cell: (row) => {
                return row.cell.value ? (
                  <span>{row.cell.value.designation}</span>
                ) : null;
              },
            },
            {
              Header: "Année",
              accessor: "anneeChantier",
              hideable: true,
              sortable: true,
              exportable: true,
              minWidth: "150px",
              type: "decimal",
              defaultValue: null,
              Cell: (row) => {
                return row.cell.value ? <span>{row.cell.value}</span> : null;
              },
            },
            {
              Header: "Semaine",
              accessor: "semaineChantier",
              hideable: true,
              sortable: true,
              exportable: true,
              minWidth: "150px",
              type: "decimal",
              defaultValue: null,
              Cell: (row) => {
                return row.cell.value ? <span>{row.cell.value}</span> : null;
              },
            },
            {
              Header: "Techniciens",
              accessor: "techniciens",
              hideable: true,
              exportable: true,
              minWidth: "150px",
              type: "multipleSelectSearch",
              defaultValue: null,

              optionFieldToDisplay: "initiales",
              valueFieldToDisplay: "initiales",
              service: PersonnelService.getTechniciens,
              Cell: (row) => {
                return row.cell.value ? <span>{row.cell.value}</span> : null;
              },
            },
            {
              Header: "Jours/Nuits prev",
              accessor: "nbJoursNuitsPrev",
              hideable: true,
              exportable: true,
              minWidth: "150px",
              type: "decimal",
              search: "JoursNuitsPrev",
              defaultValue: null,
              Cell: (row) => {
                return row.cell.value ? <span>{row.cell.value}</span> : null;
              },
            },
            {
              Header: "Jours/Nuits Réels",
              accessor: "nbJoursNuitsReels",
              hideable: true,
              exportable: true,
              minWidth: "150px",
              type: "decimal",
              search: "JoursNuitsReel",
              defaultValue: null,
              Cell: (row) => {
                return row.cell.value ? <span>{row.cell.value}</span> : null;
              },
            },
            {
              Header: "Sondages prev",
              accessor: "nbSondagesPrev",
              hideable: true,
              exportable: true,
              minWidth: "150px",
              type: "decimal",
              defaultValue: null,
              search: "SondagesPrev",
              Cell: (row) => {
                return row.cell.value ? <span>{row.cell.value}</span> : null;
              },
            },
            {
              Header: "Sondages réels",
              accessor: "nbSondagesReels",
              hideable: true,
              exportable: true,
              minWidth: "150px",
              type: "decimal",
              search: "SondagesReel",
              defaultValue: null,
              Cell: (row) => {
                return row.cell.value ? <span>{row.cell.value}</span> : null;
              },
            },
            {
              Header: "Dates d'interventions",
              accessor: "periodesDates",
              hideable: true,
              exportable: true,
              minWidth: "180px",
              type: "period",
              Cell: (row) => {
                return row.cell.value ? <span>{row.cell.value}</span> : null;
              },
            },
            {
              Header: "Zone réservation",
              accessor: "zoneReservation",
              hideable: true,
              sortable: true,
              exportable: true,
              minWidth: "160px",
              type: "text",
              search: "zoneReservation",
              defaultValue: null,
              Cell: (row) => {
                return row.cell.value ? <span>{row.cell.value}</span> : null;
              },
            },
            {
              Header: "Dates de réservation",
              accessor: "periodeDatesReservation",
              search: "periodeDatesReservation",
              hideable: true,
              exportable: true,
              minWidth: "180px",
              type: "period",
              Cell: (row) => {
                return row.cell.value ? <span>{row.cell.value}</span> : null;
              },
            },
            {
              Header: "Hôtel prévenu",
              accessor: "hotelPrevenu",
              search: "hotelPrevenu",
              hideable: true,
              exportable: true,
              defaultValue: null,
              minWidth: "130px",
              type: "selectSearch",
              optionFieldToReturn: "value",
              optionFieldToDisplay: "display",
              valueFieldToDisplay: "display",
              options: [
                {
                  id: 0,
                  display: "Oui",
                  value: true,
                },
                {
                  id: 1,
                  display: "Non",
                  value: false,
                },
              ],
              Cell: (row) => {
                if (row.cell.value == true) {
                  return <div>Oui</div>;
                } else {
                  return <div>Non</div>;
                }
              },
            },
          ]}
        ></ReactTableServerSide>
      </>
    ) : (
      <>
        <WarningBar
          active={false}
          content={"Vous n'avez pas le droit de voir cette page"}
        />
      </>
    );
  }
}

BaseChantier.propTypes = {
  history: PropTypes.object,
};

export { BaseChantier };
