import React from "react";
import { PropTypes } from "prop-types";
import { App } from "App";
import {
  BoxInformationsInline,
  Box,
  WarningBar,
  ButtonIcon,
  DialogForm,
  AlertDialog,
} from "_components";
import {
  RegionService,
  EcheanceService,
  ModePaiementService,
  CodeTarifService,
  FactureService,
  TVAService,
  DeviseService,
  SageDocumentService,
  ClientService,
} from "_services";
import { faCopy, faPaste, faPrint } from "@fortawesome/free-solid-svg-icons";
import { Input } from "_components/Input";
import { ToLocaleDateString } from "_utils";
import * as FileSaver from "file-saver";

class FinancierClient extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {};
    this.setOpenDialogImpression = this.setOpenDialogImpression.bind(this);
  }

  setOpenDialogImpression(openDialogFunc) {
    this.setState({ openDialogImpressionFunc: openDialogFunc });
  }

  render() {
    const right = App.RightsGuard?.current?.hasRight("Client", "Financier");
    if (right == "RW" || right == "RO") {
      const client = this.props.client;
      const informationsAdresseFacturation = [
        {
          value: client.adresse_Facturation,
          label: "Adresse de facturation",
          functionAppliedToValue: () => {
            return (
              <>
                {client.adresse_Facturation?.intitule ?? ""}
                <br />
                {client.adresse_Facturation?.voie ?? ""}
                <br />
                {client.adresse_Facturation?.complement ?? ""}
                <br />
                {(client.adresse_Facturation?.cp ?? "") +
                  " " +
                  client.adresse_Facturation?.ville ?? ""}
                <br />
                {client.adresse_Facturation?.pays?.nom_Fr ?? ""}
              </>
            );
          },
          accessor: "adresse_Facturation",
          colSize: 12,
          colSizeInnerFields: 6,
          type: "address",
          optionFieldToDisplay: "nom_Fr",
        },
        {
          value: client.adresse_Facturation?.region,
          label: "Région",
          accessor: "adresse_Facturation.region",
          functionAppliedToValue: (value) => {
            return value ? value.nouvelle_Region : null;
          },
          type: "selectSearch",
          service: RegionService.getAllNouvelleRegions,
          optionFieldToDisplay: "nouvelle_Region",
          valueFieldToDisplay: "nouvelle_Region",
          colSize: 4,
        },
        {
          value: client.adresse_Facturation?.nomContact,
          label: "Contact",
          accessor: "adresse_Facturation.nomContact",
          type: "text",
          colSize: 4,
        },
        {
          value: client.adresse_Facturation?.email,
          label: "Mail de Facturation",
          accessor: "adresse_Facturation.email",
          type: "email",
          colSize: 4,
        },
        {
          value: client.adresse_Facturation?.telephone,
          label: "Téléphone de facturation",
          accessor: "adresse_Facturation.telephone",
          type: "text",
          colSize: 4,
        },
        {
          value: client.adresse_Facturation?.fax,
          label: "Fax de facturation",
          accessor: "adresse_Facturation.fax",
          type: "text",
          colSize: 4,
        },
        {
          label: "Site plateforme",
          accessor: "site_Plateforme",
          type: "text",
          colSize: 4,
        },
        {
          label: "International",
          accessor: "international",
          functionAppliedToValue: (value) => {
            return value ? "Oui" : "Non";
          },
          type: "checkbox",
          colSize: 4,
        },
        {
          label: "Courrier",
          accessor: "courrier",
          functionAppliedToValue: (value) => {
            return value ? "Oui" : "Non";
          },
          type: "checkbox",
          colSize: 6,
        },
      ];
      const suiviImpayes = [
        {
          label: "Litige",
          accessor: "litige",
          functionAppliedToValue: (value) => {
            return value ? "Oui" : "Non";
          },
          type: "checkbox",
          colSize: 6,
        },
        {
          label: "Mail de relance impayés",
          accessor: "email_Relance_Impaye",
          type: "email",
          colSize: 6,
        },
        {
          label: "Date de mise en litige",
          accessor: "dateLitige",
          type: "date",
          colSize: 6,
        },
        {
          label: "Motif de litige",
          accessor: "commentaireLitige",
          type: "text",
          colSize: 6,
          required: this.props.client.litige,
        },
      ];
      const informationsComptables = [
        {
          label: "Compte tiers",
          accessor: "compte_Tier",
          type: this.props.client.compte_Tier == null ? "selectSearch" : null,
          value: null,
          service: SageDocumentService.getCompteTiers,
          optionFieldToDisplay: [
            "compte_Tier",
            "adresse_Facturation.cp",
            "adresse_Facturation.ville",
          ],
          valueFieldToDisplay: "compte_Tier",
          showClearButton: false,
          pattern: "^C{1}[A-Z0-9]{1,16}$",
          tooltip: (
            <>
              Vous pouvez lier ce client à un compte tier existant ou lui en
              créer un nouveau. <br />
              Pour rappel, un compte tier doit :<br />
              - Commencer par un 'C' majuscule.
              <br />
              - Contenir uniquement des majuscules sans accents et des chiffres.
              <br />- Être d'une longueur maximale de 17 caractères.
            </>
          ),
          customFirstOption: (query) => ({
            name: "* Ajouter le compte : ".concat(query),
            option: { compte_Tier: query },
          }),
        },
        {
          label: "échéance",
          accessor: "echeance",
          functionAppliedToValue: (value) => {
            return value ? value.designation : null;
          },
          type: "selectSearch",
          service: EcheanceService.getAll,
          optionFieldToDisplay: "designation",
          valueFieldToDisplay: "designation",
          colSize: 6,
        },
        {
          label: "Mode de paiement",
          accessor: "mode_Paiement",
          functionAppliedToValue: (value) => {
            return value ? value.mode : null;
          },
          type: "selectSearch",
          service: ModePaiementService.getAll,
          optionFieldToDisplay: "mode",
          valueFieldToDisplay: "mode",
          colSize: 6,
        },
        {
          label: "TVA",
          accessor: "tva",
          functionAppliedToValue: (value) => {
            return value ? value.valeur + "%" : null;
          },
          type: "selectSearch",
          service: TVAService.getAll,
          optionFieldToDisplay: "valeur",
          valueFieldToDisplay: "valeur",
          colSize: 6,
        },
        {
          label: "Code tarif",
          accessor: "code_Tarif",
          functionAppliedToValue: (value) => {
            return value ? value.designation : null;
          },
          type:
            this.props.client.compte_Tier == null ||
            this.props.client.code_Tarif == null
              ? "selectSearch"
              : null,
          service: CodeTarifService.getAll,
          optionFieldToDisplay: "designation",
          valueFieldToDisplay: "designation",
          colSize: 6,
        },
        {
          label: "Devise",
          accessor: "devise",
          functionAppliedToValue: (value) => {
            return value ? value.designation : null;
          },
          type:
            this.props.client.compte_Tier == null ||
            this.props.client.devise == null
              ? "selectSearch"
              : null,
          service: DeviseService.getAll,
          optionFieldToDisplay: "designation",
          valueFieldToDisplay: "designation",
          colSize: 6,
        },
        {
          label: "Facture",
          accessor: "facture",
          functionAppliedToValue: (value) => {
            return value ? value.designation : null;
          },
          type: "selectSearch",
          service: FactureService.getAll,
          optionFieldToDisplay: "designation",
          valueFieldToDisplay: "designation",
          colSize: 6,
        },
      ];
      return (
        <>
          <AlertDialog
            title="Attention"
            body={
              <>
                <div>
                  Ce client est déjà client de Sol Solution.
                  <br /> Vous ne pouvez pas modifier ses informations
                  financières.
                </div>
              </>
            }
            valider="Ok"
            open={this.state.open}
            handleClose={() => this.setState({ open: false })}
          />
          <div className="row">
            <div className="col-md-12 col-lg-6">
              <BoxInformationsInline
                title="Adresse de facturation"
                datas={informationsAdresseFacturation}
                state={this.props.client}
                stateFieldNameToUpdate={this.props.stateFieldNameToUpdate}
                service={this.props.service}
                handleChange={this.props.handleChange}
                colSize="4"
                editing={this.props.editing}
                handleEditing={this.props.handleEditing}
                handleUpdate={this.props.handleUpdate}
                NoEdition={right == "RO"}
                boutonSupplementaire={
                  <>
                    <ButtonIcon
                      icon={faCopy}
                      className="btn btn-success btn-sm text-light ms-1"
                      onClick={() =>
                        this.props.copierAdresse("adresse_Facturation")
                      }
                      style={
                        this.props.copierAdresse && this.props.editing
                          ? null
                          : { display: "none" }
                      }
                      tooltip="Copier l'adresse"
                    ></ButtonIcon>
                    <ButtonIcon
                      icon={faPaste}
                      className="btn btn-success btn-sm text-light ms-1"
                      onClick={() =>
                        this.props.collerAdresse("adresse_Facturation")
                      }
                      style={
                        this.props.collerAdresse && this.props.editing
                          ? null
                          : { display: "none" }
                      }
                      tooltip="Coller l'adresse"
                    ></ButtonIcon>
                  </>
                }
              />
              <BoxInformationsInline
                title="Informations comptables"
                datas={informationsComptables}
                state={this.props.client}
                stateFieldNameToUpdate={this.props.stateFieldNameToUpdate}
                service={this.props.service}
                handleChange={this.props.handleChange}
                colSize="4"
                editing={this.props.editing}
                handleEditing={(editing) => {
                  this.props.handleEditing(editing);
                  if (editing && this.props.client.compte_Tier != null)
                    this.setState({ open: true });
                }}
                handleUpdate={this.props.handleUpdate}
                NoEdition={!App.RightsGuard?.current?.hasRightCompteTier()}
              />
            </div>
            <div className="col-md-12 col-lg-6">
              <BoxInformationsInline
                title="Suivi impayés"
                datas={suiviImpayes}
                state={this.props.client}
                stateFieldNameToUpdate={this.props.stateFieldNameToUpdate}
                service={this.props.service}
                handleChange={this.props.handleChange}
                colSize="6"
                editing={this.props.editing}
                handleEditing={this.props.handleEditing}
                handleUpdate={this.props.handleUpdate}
                NoEdition={right == "RO"}
              />

              <Box
                noPaddingBottom={true}
                header={
                  <div className="col-12 align-self-center py-2">
                    <div>Factures Impayées</div>
                  </div>
                }
                body={
                  <>
                    <div>
                      <DialogForm
                        tooltip="Imprimer"
                        classNameButton="btn btn-success"
                        dialogTitle="Impression"
                        labelValidateButton="Valider"
                        setOpenDialog={this.setOpenDialogImpression}
                        validation={() => {
                          if (this.state.impression != null) return true;
                          else {
                            App.Toaster.current?.createToast({
                              body: "Vous devez sélectionner un modèle",
                              header: "Echec",
                              type: "failure",
                            });

                            return false;
                          }
                        }}
                        onValidate={() => {
                          ClientService.PrintImpaye(
                            this.state.facture,
                            this.props.client.id,
                            this.state.impression.designation
                          ).then((res) => {
                            var blob = new Blob([res?.data], {
                              type: "	application/vnd.openxmlformats-officedocument.wordprocessingml.document",
                            });
                            FileSaver.saveAs(
                              blob,
                              "LettreImpaye" +
                                this.state.facture.num_Document +
                                ".docx"
                            );
                          });
                        }}
                        onClose={() => {}}
                        body={
                          <div id="PopupCommande">
                            <div>
                              Veuillez choisir un modèle d'impression <br />
                            </div>

                            <Input
                              label="Modèle"
                              accessor="impression"
                              type="selectSearch"
                              value={this.state.impression}
                              valueFieldToDisplay="designation"
                              optionFieldToDisplay="designation"
                              functionAppliedToValue={(value) => {
                                if (value) return value.designation;
                                return null;
                              }}
                              handleChange={(acc, value) =>
                                this.setState({ impression: value })
                              }
                              options={[
                                {
                                  id: 0,
                                  designation: "Relance 1",
                                },
                                {
                                  id: 1,
                                  designation: "Relance 2",
                                },
                                {
                                  id: 2,
                                  designation: "Mise en demeure",
                                },
                              ]}
                              required={true}
                            />
                          </div>
                        }
                      />
                    </div>

                    <table className="table table-striped table-bordered table-sm ">
                      <thead
                        className={
                          "sticky-top" +
                          (this.props.facturesImpayees?.length == 0
                            ? " theadBorderRadius"
                            : "")
                        }
                      >
                        <tr>
                          <th>Num Document</th>
                          <th>Ref</th>
                          <th>Date Facture</th>
                          <th>Date Echéance</th>
                          <th>Prix Total TTC</th>
                          <th>Montant net à payer</th>
                        </tr>
                      </thead>
                      <tbody className="text-muted">
                        {this.props.facturesImpayees?.map((facture) => (
                          <tr key={facture.num_Document}>
                            <td>{facture.num_Document}</td>
                            <td>{facture.ref}</td>
                            <td>{ToLocaleDateString(facture.date_Facture)}</td>
                            <td>{ToLocaleDateString(facture.date_Echeance)}</td>
                            <td>{facture.prixTotalTTC}</td>
                            <td>
                              {facture.montantNetAPayer}{" "}
                              <ButtonIcon
                                icon={faPrint}
                                className="btn btn-success btn-sm text-light ms-1"
                                tooltip="Impression pochette"
                                style={{ width: "40px" }}
                                onClick={() => {
                                  this.state.openDialogImpressionFunc();
                                  this.setState({ facture: facture });
                                }}
                              />
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </>
                }
              />
            </div>
          </div>
        </>
      );
    } else
      return (
        <>
          <WarningBar
            active={false}
            content={"Vous n'avez pas le droit de voir cette page"}
          />
        </>
      );
  }
}

FinancierClient.propTypes = {
  client: PropTypes.object.isRequired,
  stateFieldNameToUpdate: PropTypes.string,
  service: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  facturesImpayees: PropTypes.array,
};

export { FinancierClient };
