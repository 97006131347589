import http from "http-common";

const EcheanceService = class {
  /**
   * Fonction permettant de récuperer tous les echeances avec une possibilité de filtre via params
   * @returns la liste de tous les echeances
   */
  static getAll() {
    return http.get("/echeances");
  }

  /**
   * Fonction permettant de récuperer un echeances via son id
   * @param {*} id, id de du echeances à récupérer
   * @returns le echeances souhaité
   */
  static getById(id) {
    return http.get("/echeances/" + id);
  }

  static getByValue(value) {
    return http.get("/echeances/get/" + value);
  }
};

export { EcheanceService };
