// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.input-multipleSelectSearch {
  width: calc(100% - 2px);
  height: 38px;
  border: 0;
  background-color: transparent;
  padding-left: 12px;
  padding-right: 0;
  overflow: hidden;
  text-overflow: ellipsis;
}

.input-multipleSelectSearch::placeholder {
  opacity: 1;
}`, "",{"version":3,"sources":["webpack://./src/_components/Input/InputTypes/MultipleSelectSearch.css"],"names":[],"mappings":"AAAA;EACE,uBAAuB;EACvB,YAAY;EACZ,SAAS;EACT,6BAA6B;EAC7B,kBAAkB;EAClB,gBAAgB;EAChB,gBAAgB;EAChB,uBAAuB;AACzB;;AAEA;EACE,UAAU;AACZ","sourcesContent":[".input-multipleSelectSearch {\n  width: calc(100% - 2px);\n  height: 38px;\n  border: 0;\n  background-color: transparent;\n  padding-left: 12px;\n  padding-right: 0;\n  overflow: hidden;\n  text-overflow: ellipsis;\n}\n\n.input-multipleSelectSearch::placeholder {\n  opacity: 1;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
