import React from "react";
import { App } from "App";

import { BoxInformationsInline, WarningBar } from "_components";

import { ClientService, ContactService } from "_services";

class InformationsChantier extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      callbackForceUpdateContactMaitreDOeuvre: null,
      callbackForceUpdateContactMaitreDOuvrage: null,
      callbackForceUpdateContactEntreprise: null,
    };

    this.serviceGetContactsByClientId =
      this.serviceGetContactsByClientId.bind(this);
    this.functionAppliedToValueContact =
      this.functionAppliedToValueContact.bind(this);
    this.functionAppliedToValueClient =
      this.functionAppliedToValueClient.bind(this);

    this.setForceUpdateContactMaitreDOeuvre =
      this.setForceUpdateContactMaitreDOeuvre.bind(this);
    this.setForceUpdateContactMaitreDOuvrage =
      this.setForceUpdateContactMaitreDOuvrage.bind(this);
    this.setForceUpdateContactEntreprise =
      this.setForceUpdateContactEntreprise.bind(this);
    this.setForceUpdateContactClient =
      this.setForceUpdateContactClient.bind(this);
  }

  setForceUpdateContactMaitreDOeuvre(callbackFunction) {
    this.setState({
      callbackForceUpdateContactMaitreDOeuvre: callbackFunction,
    });
  }

  setForceUpdateContactMaitreDOuvrage(callbackFunction) {
    this.setState({
      callbackForceUpdateContactMaitreDOuvrage: callbackFunction,
    });
  }

  setForceUpdateContactEntreprise(callbackFunction) {
    this.setState({ callbackForceUpdateContactEntreprise: callbackFunction });
  }
  setForceUpdateContactClient(callbackFunction) {
    this.setState({ callbackForceUpdateContactClient: callbackFunction });
  }

  serviceGetContactsByClientId(clientId) {
    if (clientId) {
      return () => {
        return ContactService.getByIdClient(clientId);
      };
    }
  }

  functionAppliedToValueContact(value) {
    return (
      <>
        <div className="d-flex justify-content-start align-items-center">
          {value != null ? (
            <a
              href={"/contacts/" + value.id}
              target="_blank"
              data-text={value.nom_Contact + " " + value.prenom_Contact}
              className="fixWidthHover"
            >
              {value.nom_Contact + " " + value.prenom_Contact}
            </a>
          ) : (
            "-"
          )}
        </div>
      </>
    );
  }

  functionAppliedToValueClient(value) {
    return (
      <>
        <div className="d-flex justify-content-start align-items-center">
          {value != null ? (
            <a
              href={"/clients/" + value.id}
              target="_blank"
              data-text={value.nom_Client}
              className="fixWidthHover"
            >
              {value.nom_Client}
            </a>
          ) : (
            "-"
          )}
        </div>
      </>
    );
  }

  render() {
    const right = App.RightsGuard?.current?.hasRight("Devis", "Info Chantier");
    if (right == "RW" || right == "RO") {
      const informationsClients = [
        {
          label: "Client",
          accessor: "client",
          type: "selectSearch",
          service: ClientService.getForSelectSearch,
          optionFieldToDisplay: "nom_Client",
          valueFieldToDisplay: "nom_Client",
          functionAppliedToValue: this.functionAppliedToValueClient,
          afterUpdateFunction: () => {
            this.props.handleChange(
              "contact_Client",
              null,
              this.state.callbackForceUpdateContactClient
            );
          },
          colSize: 6,
        },
        {
          label: "Contact Client",
          accessor: "contact_Client",
          type: "selectSearch",
          service: this.serviceGetContactsByClientId(
            this.props.devis.client?.id
          ),
          optionFieldToDisplay: ["nom_Contact", "prenom_Contact", "email"],
          valueFieldToDisplay: ["nom_Contact", "prenom_Contact", "email"],
          functionAppliedToValue: this.functionAppliedToValueContact,
          setForceUpdateFunction: this.setForceUpdateContactClient,
          colSize: 6,
        },
        {
          label: "Entreprise",
          accessor: "entreprise",
          type: "selectSearch",
          service: ClientService.getForSelectSearch,
          optionFieldToDisplay: "nom_Client",
          valueFieldToDisplay: "nom_Client",
          functionAppliedToValue: this.functionAppliedToValueClient,
          afterUpdateFunction: () => {
            this.props.handleChange(
              "contact_Entreprise",
              null,
              this.state.callbackForceUpdateContactEntreprise
            );
          },
          colSize: 6,
        },
        {
          label: "Contact Entreprise",
          accessor: "contact_Entreprise",
          type: "selectSearch",
          service: this.serviceGetContactsByClientId(
            this.props.devis.entreprise?.id
          ),
          optionFieldToDisplay: ["nom_Contact", "prenom_Contact", "email"],
          valueFieldToDisplay: ["nom_Contact", "prenom_Contact", "email"],
          functionAppliedToValue: this.functionAppliedToValueContact,
          setForceUpdateFunction: this.setForceUpdateContactEntreprise,
          colSize: 6,
        },
        {
          label: "Maitre d'Oeuvre",
          accessor: "maitre_Oeuvre",
          type: "selectSearch",
          service: ClientService.getForSelectSearch,
          optionFieldToDisplay: "nom_Client",
          valueFieldToDisplay: "nom_Client",
          functionAppliedToValue: this.functionAppliedToValueClient,
          afterUpdateFunction: () => {
            this.props.handleChange(
              "maitre_Oeuvre_Contact",
              null,
              this.state.callbackForceUpdateContactMaitreDOeuvre
            );
          },
          colSize: 6,
        },
        {
          label: "Contact Maitre d'Oeuvre",
          accessor: "contact_Maitre_Oeuvre",
          type: "selectSearch",
          service: this.serviceGetContactsByClientId(
            this.props.devis.maitre_Oeuvre?.id
          ),
          optionFieldToDisplay: ["nom_Contact", "prenom_Contact", "email"],
          valueFieldToDisplay: ["nom_Contact", "prenom_Contact", "email"],
          functionAppliedToValue: this.functionAppliedToValueContact,
          setForceUpdateFunction: this.setForceUpdateContactMaitreDOeuvre,
          colSize: 6,
        },
        {
          label: "Maitre d'Ouvrage",
          accessor: "maitre_Ouvrage",
          type: "selectSearch",
          service: ClientService.getForSelectSearch,
          optionFieldToDisplay: "nom_Client",
          valueFieldToDisplay: "nom_Client",
          functionAppliedToValue: this.functionAppliedToValueClient,
          afterUpdateFunction: () => {
            this.props.handleChange(
              "maitre_Ouvrage_Contact",
              null,
              this.state.callbackForceUpdateContactMaitreDOuvrage
            );
          },
          colSize: 6,
        },
        {
          label: "Contact Maitre d'Ouvrage",
          accessor: "contact_Maitre_Ouvrage",
          type: "selectSearch",
          service: this.serviceGetContactsByClientId(
            this.props.devis.maitre_Ouvrage?.id
          ),
          optionFieldToDisplay: ["nom_Contact", "prenom_Contact", "email"],
          valueFieldToDisplay: ["nom_Contact", "prenom_Contact", "email"],
          functionAppliedToValue: this.functionAppliedToValueContact,
          setForceUpdateFunction: this.setForceUpdateContactMaitreDOuvrage,
          colSize: 6,
        },
      ];

      const informationsChantier = [
        {
          label: "Date de visite du site",
          accessor: "dateVisiteChantier",
          type: "date",
          colSize: 4,
        },
        {
          label: "Superficie",
          accessor: "superficie",
          type: "text",
          colSize: 4,
        },
        {
          label: "Niveau",
          accessor: "niveau",
          type: "decimal",
          numberOfDecimals: 0,
          colSize: 4,
          handleBlur: this.props.handleChange,
        },
        {
          label: "Sous-sol",
          accessor: "sousSol",
          type: "checkbox",
          functionAppliedToValue: (value) => {
            return value ? "Oui" : "Non";
          },
          colSize: 4,
        },
        {
          label: "Site facile d'accès",
          accessor: "siteFacileDAcces",
          type: "checkbox",
          functionAppliedToValue: (value) => {
            return value ? "Oui" : "Non";
          },
          colSize: 4,
        },
        {
          label: "Descente charge",
          accessor: "descenteCharge",
          type: "checkbox",
          functionAppliedToValue: (value) => {
            return value ? "Oui" : "Non";
          },
          colSize: 4,
        },
        {
          label: "DICT",
          accessor: "dict",
          type: "checkbox",
          functionAppliedToValue: (value) => {
            return value ? "Oui" : "Non";
          },
          colSize: 4,
        },
        {
          label: "Date DICT",
          accessor: "dateDICT",
          type: "date",
          colSize: 4,
        },
        {
          label: "Sous-traitance",
          accessor: "sousTraitance",
          type: "checkbox",
          functionAppliedToValue: (value) => {
            return value ? "Oui" : "Non";
          },
          colSize: 4,
        },
        {
          label: "Adresse Chantier",
          functionAppliedToValue: () => {
            return (
              <>
                {this.props.devis.adresseChantier?.intitule ?? ""}
                <br />
                {this.props.devis.adresseChantier?.voie ?? ""}
                <br />
                {this.props.devis.adresseChantier?.complement ?? ""}
                <br />
                {(this.props.devis.adresseChantier?.cp ?? "") +
                  " " +
                  (this.props.devis.adresseChantier?.ville ?? "")}
              </>
            );
          },
          accessor: "adresseChantier",
          colSize: 12,
          type: "address",
          optionFieldToDisplay: "nom_Fr",
          intituleLabel: "Adresse chantier",
        },
        {
          label: "NB de Lots",
          accessor: "nbLot",
          type: "decimal",
          numberOfDecimals: 0,
          colSize: 6,
          handleBlur: this.props.handleChange,
        },
        {
          label: "Commentaire",
          accessor: "commentaireChantier",
          type: "textArea",
          functionAppliedToValue: (el) => {
            if (el != null) {
              return <div className="keepWhiteSpace">{el}</div>;
            }
          },
          colSize: 12,
        },
      ];

      const checkboxParticulier = [
        {
          label: "Particulier ?",
          accessor: "isParticulier",
          type: "checkbox",
          functionAppliedToValue: (value) => {
            return value ? "Oui" : "Non";
          },
        },
      ];

      const informationsParticulier = [
        {
          label: "Nom",
          accessor: "nomParticulier",
          type: "text",
          colSize: 6,
        },
        {
          label: "Prénom",
          accessor: "prenomParticulier",
          type: "text",
          colSize: 6,
        },
        {
          label: "Mail",
          accessor: "adresseParticulier.email",
          value: this.props.devis.adresseParticulier?.email,

          type: "email",
          colSize: 6,
        },
        {
          label: "Téléphone",
          accessor: "adresseParticulier.telephone",
          value: this.props.devis.adresseParticulier?.telephone,

          type: "text",
          colSize: 6,
        },
        {
          label: "Adresse Particulier",
          functionAppliedToValue: () => {
            return (
              <>
                {this.props.devis.adresseParticulier?.intitule ?? ""}
                <br />
                {this.props.devis.adresseParticulier?.voie ?? ""}
                <br />
                {this.props.devis.adresseParticulier?.complement ?? ""}
                <br />
                {(this.props.devis.adresseParticulier?.cp ?? "") +
                  " " +
                  (this.props.devis.adresseParticulier?.ville ?? "")}
              </>
            );
          },
          accessor: "adresseParticulier",
          colSize: 12,
          type: "address",
          optionFieldToDisplay: "nom_Fr",
        },
      ];

      return (
        <>
          <div className="row">
            <div className="col-12 BoxInformationsClients">
              <BoxInformationsInline
                title="Entreprises et contacts"
                datas={informationsClients}
                state={this.props.devis}
                stateFieldNameToUpdate={this.props.stateFieldNameToUpdate}
                service={this.props.service}
                handleChange={this.props.handleChange}
                editing={this.props.editing}
                handleEditing={this.props.handleEditing}
                NoEdition={right == "RO"}
              />
            </div>
            <div className="col-8 BoxInformationsChantier">
              <BoxInformationsInline
                title="Informations Chantier"
                datas={informationsChantier}
                state={this.props.devis}
                stateFieldNameToUpdate={this.props.stateFieldNameToUpdate}
                service={this.props.service}
                handleChange={this.props.handleChange}
                editing={this.props.editing}
                handleEditing={this.props.handleEditing}
                NoEdition={right == "RO"}
              />
            </div>
            <div className="col-4 BoxCheckboxParticulier">
              <BoxInformationsInline
                title="Particulier ?"
                datas={
                  this.props.devis.isParticulier
                    ? checkboxParticulier.concat(informationsParticulier)
                    : checkboxParticulier
                }
                state={this.props.devis}
                stateFieldNameToUpdate={this.props.stateFieldNameToUpdate}
                service={this.props.service}
                handleChange={this.props.handleChange}
                editing={this.props.editing}
                handleEditing={this.props.handleEditing}
                NoEdition={right == "RO"}
              />
            </div>
            {/* {
            
            this.props.devis.isParticulier ? (
              <div className="col-4 BoxInformationsParticulier">
                <BoxInformationsInline
                  title="Informations Particulier"
                  datas={informationsParticulier}
                  state={this.props.devis}
                  stateFieldNameToUpdate={this.props.stateFieldNameToUpdate}
                  service={this.props.service}
                  handleChange={this.props.handleChange}
                  colSize="4"
                  editing={this.props.editing}
                  handleEditing={this.props.handleEditing}
                />
              </div>
            ) : null
          } */}
          </div>
        </>
      );
    } else {
      return (
        <>
          <WarningBar
            active={false}
            content={"Vous n'avez pas le droit de voir cette page"}
          />
        </>
      );
    }
  }
}

export { InformationsChantier };
