import React from "react";
import { PropTypes } from "prop-types";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { ButtonIcon } from "_components";
import { IsoToEnglishDate } from "_utils";
import { isEqual, debounce } from "lodash";

/**
 * Génère le composant Input
 * @param {string} label, le label de l'input
 * @param {*} value, la valeur de l'input
 * @param {string} accessor, l'accesseur de l'objet lié à cet input
 * @param {function} handleChange, la fonction à appeler lors d'un changement
 * @returns le composant Input
 */
class Period extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      value: this.props.value,
      focus: false,
    };

    this.handleOnChange = this.handleOnChange.bind(this);
    this.onFocus = this.onFocus.bind(this);
    this.onBlur = this.onBlur.bind(this);

    this.parseValue = this.parseValue.bind(this);
    this.serializeValue = this.serializeValue.bind(this);

    this.handleOnChangeDebounced = debounce((value) => {
      this.props.handleChange(this.props.accessor, value);
    }, 300);
  }

  shouldComponentUpdate(nextProps, nextStates) {
    return !(isEqual(nextProps, this.props) && isEqual(nextStates, this.state));
  }

  // Pour éviter que la value récupérée par props vienne réécrire le champs que l'on est en train de modifier (le champ stutter),
  // on ne me à jour la valeur que si le composant n'a pas le focus.
  // Il devient donc impossible de changer la valeur du champs où l'on est en train d'écrire.
  componentDidUpdate(prevProps) {
    if (!this.state.focus && !isEqual(prevProps?.value, this.props?.value)) {
      this.setState({ value: this.props.value });
    }
  }

  onFocus() {
    this.setState({ focus: true });
  }

  onBlur() {
    this.setState({ focus: false });
    if (this.props.handleBlur)
      this.props.handleBlur(this.props.accessor, this.state.value);
  }

  handleOnChange(evenement, selector) {
    if (selector === "start") {
      var value = {
        start: evenement.target.value,
        end: this.parseValue(this.state.value)?.end,
      };
      var serializedValue = this.serializeValue(value);
      this.setState({
        value: serializedValue,
      });
      this.handleOnChangeDebounced(serializedValue);
    } else if (selector === "end") {
      var value = {
        start: this.parseValue(this.state.value)?.start,
        end: evenement.target.value,
      };
      var serializedValue = this.serializeValue(value);
      this.setState({
        value: serializedValue,
      });
      this.handleOnChangeDebounced(serializedValue);
    }
  }

  parseValue(value) {
    var vals = value?.split("|");
    return { start: vals?.[0] ?? "", end: vals?.[1] ?? "" };
  }

  serializeValue(value) {
    return value?.start + "|" + value?.end;
  }

  render() {
    return (
      <>
        {this.props.label ? (
          <div className="text-uppercase text-muted solwayFont">
            {this.props.label}
          </div>
        ) : (
          ""
        )}
        <div className="input-text input-group has-validation">
          <input
            className="form-control"
            name="Début"
            required={this.props.required}
            value={
              this.state.value
                ? IsoToEnglishDate(this.parseValue(this.state.value)?.start) // On doit formatter la value pour qu'elle soit acceptée par l'input
                : ""
            }
            onChange={(event) => {
              // Pour sauvegarder la date on doit aussi la formater au format français
              this.handleOnChange(event, "start");
            }}
            type="date"
            onBlur={this.onBlur}
            onFocus={this.onFocus}
            aria-describedby={
              "inputGroup" +
              this.props.accessor +
              " validation" +
              this.props.accessor
            }
            disabled={this.props.disabled}
          />
          <input
            className="form-control"
            name="Fin"
            required={this.props.required}
            value={
              this.state.value
                ? IsoToEnglishDate(this.parseValue(this.state.value)?.end) // On doit formatter la value pour qu'elle soit acceptée par l'input
                : ""
            }
            onChange={(event) => {
              // Pour sauvegarder la date on doit aussi la formater au format français
              this.handleOnChange(event, "end");
            }}
            type="date"
            onBlur={this.onBlur}
            onFocus={this.onFocus}
            aria-describedby={
              "inputGroup" +
              this.props.accessor +
              " validation" +
              this.props.accessor
            }
            disabled={this.props.disabled}
          />
          {this.props.showClearButton ? (
            <ButtonIcon
              id={"inputGroup" + this.props.accessor}
              smallText=""
              icon={faTimes}
              iconSize="sm"
              onClick={() => {
                this.setState({ value: null });
                this.props.handleChange(this.props.accessor, null);
              }}
              className="btn btn-danger"
              tabIndex="-1"
              style={this.props.disabled ? { display: "none" } : {}}
            ></ButtonIcon>
          ) : null}
          {this.props.showValidator ? (
            <div
              id={"validation" + this.props.accessor}
              className="invalid-feedback"
            >
              {this.props.invalidText}
            </div>
          ) : null}
        </div>
      </>
    );
  }
}

Period.propTypes = {
  value: PropTypes.any,
  accessor: PropTypes.string,
  handleChange: PropTypes.func,
  handleBlur: PropTypes.func,
  required: PropTypes.bool,
  type: PropTypes.string,
  invalidText: PropTypes.string,
  disabled: PropTypes.bool,
  showValidator: PropTypes.bool,
  showClearButton: PropTypes.bool,
};

Period.defaultProps = {
  required: false,
};

export { Period };
