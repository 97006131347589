export default function TabSelector({ tabs, activeTab, notAllowed, onSelect }) {
  return (
    <ul
      className="nav nav-tabs menu fix"
      style={{ overflowX: "auto", overflowY: "clip", marginBottom: "20px" }}
    >
      {tabs.map((tab, index) => {
        return (
          <li
            className={"nav-item nav-tab align-self-end menu-item".concat(
              notAllowed ? " not-allowed" : ""
            )}
            key={index}
          >
            <a
              className={
                activeTab == tab
                  ? "nav-link menu-link active bg-primary text-light fw-bold".concat(
                      notAllowed ? " disabled" : ""
                    )
                  : "nav-link menu-link text-black-50".concat(
                      notAllowed ? " disabled" : ""
                    )
              }
              onClick={() => {
                onSelect(tab);
              }}
              data-text={tab}
            >
              {tab}
            </a>
          </li>
        );
      })}
    </ul>
  );
}
