import React from "react";

// Validation des props
import { CompareStrings, makeEmailClickable } from "_utils";
import { PropTypes } from "prop-types";

class SubTableContacts extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      subObjects: [],
    };
  }

  componentDidMount() {
    this.setState({
      subObjects: this.props.subObjects.sort((a, b) => {
        return CompareStrings(a.nom_Contact, b.nom_Contact);
      }),
    });
  }

  render() {
    return (
      <div className="m-2">
        <table className="table mb-0 table-sm table-hover table-bordered border-dark">
          <thead className="bg-primary text-light">
            <tr>
              <th className="whiteText" scope="col">
                Nom
              </th>
              <th className="whiteText" scope="col">
                Prenom
              </th>
              <th className="whiteText" scope="col">
                Email
              </th>
              <th className="whiteText" scope="col">
                Téléphone
              </th>
              <th className="whiteText" scope="col">
                VIP
              </th>
            </tr>
          </thead>
          <tbody>
            {this.state.subObjects.map((contact, index) => {
              return (
                <tr scope="row" key={index}>
                  <td>{contact.nom_Contact}</td>
                  <td>{contact.prenom_Contact}</td>
                  <td>{makeEmailClickable(contact.email)}</td>
                  <td>{contact.portable}</td>
                  <td>{contact.vip ? "Oui" : "Non"}</td>
                </tr>
              );
            })}
            {!this.props.subObjects.length && (
              <tr>
                <td colSpan="5" className="text-center">
                  <h5 className="mb-0">Pas de contact</h5>
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    );
  }
}

SubTableContacts.propTypes = {
  subObjects: PropTypes.array,
};

export { SubTableContacts };
