import React from "react";

import { Input } from "_components/Input";

import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { ButtonIcon } from "_components";

function LigneHabilitationPersonnel(props) {
  return (
    <tr className="ligneHabilitationPersonnel">
      <td className="col1 background-color">
        <Input
          accessor={
            "habilitationPersonnel." + props.habilitation.id + ".dateObtention"
          }
          value={props.habilitation.dateObtention}
          type="date"
          placeholder=""
          handleChange={props.handleChange}
          showValidator={false}
          showClearButton={false}
          disabled={!props.editing}
        />
      </td>
      <td className="col2 background-color">
        <Input
          accessor={
            "habilitationPersonnel." + props.habilitation.id + ".intitule"
          }
          value={props.habilitation.intitule}
          type="text"
          placeholder=""
          handleChange={props.handleChange}
          showValidator={false}
          showClearButton={false}
          disabled={!props.editing}
        />
      </td>
      <td className="col3 background-color">
        <Input
          accessor={
            "habilitationPersonnel." + props.habilitation.id + ".dateEcheance"
          }
          value={props.habilitation.dateEcheance}
          type="date"
          placeholder=""
          handleChange={props.handleChange}
          showValidator={false}
          showClearButton={false}
          disabled={!props.editing}
        />
      </td>
      <td className="col4 background-color" style={{ width: "55px" }}>
        <ButtonIcon
          smallText=""
          icon={faTrash}
          iconSize="sm"
          onClick={() => {
            props?.deleteLineHabilitationPersonnel(props.habilitation.id);
          }}
          className="btn btn-danger deleteLineHabilitationPersonnel"
          style={!props.editing ? { display: "none" } : {}}
        />
      </td>
    </tr>
  );
}

export { LigneHabilitationPersonnel };
