import http from "http-common";

const UploadService = class {
  /**
   * Fonction permettant de récuperer tous les droits
   * @returns la liste de tous les droits
   */
  static getAll() {
    return http.get("/upload");
  }


};

export { UploadService };