import React from "react";
import { PropTypes } from "prop-types";
import { Link } from "react-router-dom";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSpinner,
  faFileInvoiceDollar,
} from "@fortawesome/free-solid-svg-icons";
import { App } from "App";

import { General, NotesDeFrais } from ".";
import { WarningBar } from "_components";

import { CarteBancaireService, NoteDeFraisService } from "_services";
import { ButtonIconWithValidation, DialogForm } from "_components";
import { faTrash } from "@fortawesome/free-solid-svg-icons";

import * as FileSaver from "file-saver";
import { Input } from "_components/Input";

import { Helmet } from "react-helmet";
import { produce } from "immer";
import TabSelector from "_components/FicheComponents/TabSelector";

class FicheCarteBancaire extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      tabs: ["Général", "Note de frais"],
      active: "Général",
      editing: false,
      cartebancaire: {},
      numeroFrais: null,
      lineACopier: null,
      exportAll: false,
    };

    this.renderActive = this.renderActive.bind(this);
    this.generateTabs = this.generateTabs.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleExportAll = this.handleExportAll.bind(this);
    this.handleEditing = this.handleEditing.bind(this);
    this.handleDelete = this.handleDelete.bind(this);

    this.handleUpdate = this.handleUpdate.bind(this);

    this.handleEditingNotesDeFrais = this.handleEditingNotesDeFrais.bind(this);
    this.addLineNoteDeFrais = this.addLineNoteDeFrais.bind(this);
    this.copierLineNoteDeFrais = this.copierLineNoteDeFrais.bind(this);
    this.collerLineNoteDeFrais = this.collerLineNoteDeFrais.bind(this);
    this.deleteLineNoteDeFrais = this.deleteLineNoteDeFrais.bind(this);
    this.filtrerSelonDate = this.filtrerSelonDate.bind(this);
    this.setOpenDialog = this.setOpenDialog.bind(this);
  }

  componentDidMount() {
    if (sessionStorage.getItem("CBOnglet") == null) {
      sessionStorage.setItem("CBOnglet", this.state.active);
    } else {
      this.setState({ active: sessionStorage.getItem("CBOnglet") });
    }

    let { id } = this.props.match.params;
    CarteBancaireService.getById(id).then((res) => {
      this.setState({ cartebancaire: res.data, loading: false });
    });
  }

  setOpenDialog(openDialogFunc) {
    this.setState({ openDialogFunc: openDialogFunc });
  }

  filtrerSelonDate(date) {
    this.setState({ dateFiltreNotesDeFraisAffichees: date });
  }

  handleEditingNotesDeFrais(editing) {
    this.setState((prevState) => ({
      ...prevState,
      editing: editing,
      editingNdF: editing,
    }));
  }

  addLineNoteDeFrais() {
    this.setState(
      produce((prevState) => {
        if (prevState.cartebancaire.notesDeFrais == null) {
          prevState.cartebancaire.notesDeFrais = [];
        }
        // To keep the ids unique, if there is no other new line (with negative id), the new line id is -1, else, it's the minimum id - 1.
        let newLineId =
          Math.min(
            ...prevState.cartebancaire.notesDeFrais.map((el) => el.id),
            0
          ) - 1;

        prevState.cartebancaire.notesDeFrais.push({
          id: newLineId,
          dateFrais: new Date(),
          libelle: "",
          affaire: null,
          nombre: 0,
          new: true,
        });
      })
    );
  }

  copierLineNoteDeFrais(id) {
    this.setState(
      produce((prevState) => {
        let aCopier = prevState.cartebancaire.notesDeFrais.find(
          (el) => el.id == id
        );
        prevState.lineACopier = aCopier;
      })
    );
  }

  collerLineNoteDeFrais(id) {
    this.setState(
      produce((prevState) => {
        if (prevState.lineACopier != null) {
          let aChanger = prevState.cartebancaire.notesDeFrais.find(
            (el) => el.id == id
          );

          aChanger.dateFrais = prevState.lineACopier.dateFrais;
          aChanger.libelle = prevState.lineACopier.libelle;
          aChanger.affaire = prevState.lineACopier.affaire;
          aChanger.nombre = prevState.lineACopier.nombre;
          aChanger.montant_TTC = prevState.lineACopier.montant_TTC;
          aChanger.montant_TVA = prevState.lineACopier.montant_TVA;
          aChanger.typeNoteDeFrais = prevState.lineACopier.typeNoteDeFrais;
        }
      })
    );
  }

  deleteLineNoteDeFrais(ndfId) {
    this.setState(
      produce((prevState) => {
        prevState.cartebancaire.notesDeFrais.splice(
          prevState.cartebancaire.notesDeFrais.findIndex(
            (el) => el.id === ndfId
          ),
          1
        );
      })
    );
  }

  handleChange(accessor, value, callbackFunction) {
    if (accessor == "cartebancaire") {
      this.setState({ cartebancaire: value });
    } else if (accessor.includes(".")) {
      // Nested property

      var accessorSplit = accessor.split(".");
      if (accessorSplit[0] === "ndf") {
        // Format heurePersonnel.id.accessor
        var ndfId = accessorSplit[1];
        var ndfAccessor = accessorSplit[2];

        if (ndfAccessor === "typeNoteDeFrais") {
          this.setState(
            produce((prevState) => {
              let prev = prevState.cartebancaire.notesDeFrais.find(
                (el) => el.id == ndfId
              );
              prev.typeNoteDeFrais = value;
            }),
            callbackFunction
          );
        } else if (ndfAccessor === "libelle") {
          this.setState(
            produce((prevState) => {
              let prev = prevState.cartebancaire.notesDeFrais.find(
                (el) => el.id == ndfId
              );
              prev.libelle = value;
            }),
            callbackFunction
          );
        } else if (ndfAccessor === "dateFrais") {
          this.setState(
            produce((prevState) => {
              let prev = prevState.cartebancaire.notesDeFrais.find(
                (el) => el.id == ndfId
              );
              prev.dateFrais = value;
            }),
            callbackFunction
          );
        } else if (ndfAccessor === "montant_TTC") {
          this.setState(
            produce((prevState) => {
              let prev = prevState.cartebancaire.notesDeFrais.find(
                (el) => el.id == ndfId
              );
              prev.montant_TTC = value;
            }),
            callbackFunction
          );
        } else if (ndfAccessor === "montant_TVA") {
          this.setState(
            produce((prevState) => {
              let prev = prevState.cartebancaire.notesDeFrais.find(
                (el) => el.id == ndfId
              );
              prev.montant_TVA = value;
            }),
            callbackFunction
          );
        } else if (ndfAccessor === "nombre") {
          this.setState(
            produce((prevState) => {
              let prev = prevState.cartebancaire.notesDeFrais.find(
                (el) => el.id == ndfId
              );
              prev.nombre = value;
            }),
            callbackFunction
          );
        } else if (ndfAccessor === "export") {
          this.setState(
            produce((prevState) => {
              let prev = prevState.cartebancaire.notesDeFrais.find(
                (el) => el.id == ndfId
              );
              prev.export = value;
            }),
            callbackFunction
          );
        } else if (ndfAccessor === "affaire") {
          this.setState(
            produce((prevState) => {
              let prev = prevState.cartebancaire.notesDeFrais.find(
                (el) => el.id == ndfId
              );
              prev.affaire = value;
            }),
            callbackFunction
          );
        } else if (ndfAccessor === "bdcExistant") {
          this.setState(
            produce((prevState) => {
              let prev = prevState.cartebancaire.notesDeFrais.find(
                (el) => el.id == ndfId
              );
              prev.bdcExistant = value;
            }),
            callbackFunction
          );
        }
      }
    } else if (accessor == "exportAll") {
      this.setState({ exportAll: !this.state.exportAll }, () =>
        this.handleExportAll()
      );
    } else {
      this.setState(
        (prevState) => ({
          cartebancaire: {
            ...prevState.cartebancaire,
            [accessor]: value,
          },
        }),
        callbackFunction
      );
    }
  }

  handleExportAll() {
    let start;
    let end;
    let dateArr = this.props.dateFiltreNotesDeFraisAffichees?.split("|");
    if (this.props.dateFiltreNotesDeFraisAffichees) {
      start = dateArr[0];
      end = dateArr[1];
    }
    let value = this.state.exportAll;
    this.state.cartebancaire.notesDeFrais
      ?.slice()
      ?.filter((e) => e.numeroFrais == null)
      ?.filter((e) => {
        let date = new Date(e.dateFrais);
        if (!this.state.dateFiltreNotesDeFraisAffichees)
          return (
            !e.new && date > new Date().setMonth(new Date().getMonth() - 2)
          );
        else {
          var bool = true;
          if (start && start !== "undefined") {
            bool = bool && date >= new Date(start);
          }
          if (end && end !== "undefined") bool = bool && date <= new Date(end);

          return bool && !e.new;
        }
      })
      .map((e) => {
        this.setState(
          produce((prevState) => {
            let prev = prevState.cartebancaire.notesDeFrais.find(
              (el) => el.id == e.id
            );
            prev.export = value;
          })
        );
      });
  }

  handleUpdate() {
    return CarteBancaireService.put(this.state.cartebancaire).then((res) => {
      this.setState({ cartebancaire: res.data });
    });
  }

  handleDelete() {
    return CarteBancaireService.delete(this.state.cartebancaire.id).then(
      (res) => {
        this.setState({ cartebancaire: res.data });
      }
    );
  }

  handleEditing(editing) {
    this.setState((prevState) => ({
      ...prevState,
      editing: editing,
    }));
  }

  /**
   * Génère les onglets de la fiche
   * @param {*} tabs
   * @returns
   */
  generateTabs(tabs) {
    return (
      <div>
        <TabSelector
          tabs={tabs}
          activeTab={this.state.active}
          onSelect={(tab) => {
            this.setState({ active: tab });
            sessionStorage.setItem("CBOnglet", tab);
          }}
          notAllowed={this.state.editing}
        ></TabSelector>
      </div>
    );
  }

  renderActive() {
    switch (this.state.active) {
      case "Général":
        return (
          <General
            cartebancaire={this.state.cartebancaire}
            stateFieldNameToUpdate="cartebancaire"
            handleChange={this.handleChange}
            service={CarteBancaireService}
            history={this.props.history}
            editing={this.state.editing}
            handleEditing={this.handleEditing}
          />
        );
      case "Note de frais":
        return (
          <NotesDeFrais
            cartebancaire={this.state.cartebancaire}
            stateFieldNameToUpdate="cartebancaire"
            handleChange={this.handleChange}
            service={CarteBancaireService}
            history={this.props.history}
            exportAll={this.state.exportAll}
            editing={this.state.editing}
            handleEditing={this.handleEditing}
            handleEditingNotesDeFrais={this.handleEditingNotesDeFrais}
            editingNotesDeFrais={this.state.editingNdF}
            addLineNoteDeFrais={this.addLineNoteDeFrais}
            copierLineNotesDeFrais={this.copierLineNoteDeFrais}
            collerLineNotesDeFrais={this.collerLineNoteDeFrais}
            deleteLineNoteDeFrais={this.deleteLineNoteDeFrais}
            handleUpdate={this.handleUpdate}
            filtrerSelonDate={this.filtrerSelonDate}
            dateFiltreNotesDeFraisAffichees={
              this.state.dateFiltreNotesDeFraisAffichees
            }
            openDialogue={this.state.openDialogFunc}
          />
        );
      default:
        return (
          <h4 className="mt-5">Désolé la page n&apos;a pas réussi à charger</h4>
        );
    }
  }

  render() {
    const isContactEmpty = !Object.entries(this.state.cartebancaire).length;
    return (
      <>
        <Helmet>
          <title>
            {"Carte bancaire : ".concat(this.state.cartebancaire.name)}
          </title>
        </Helmet>
        {this.state.loading ? (
          <div
            style={{
              flexGrow: 1,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <FontAwesomeIcon icon={faSpinner} size="lg" className="fa-spin" />
          </div>
        ) : (
          <div className=" p-3 m-4" id="ficheClient">
            <div className="d-flex overflow-auto justify-content-between py-1">
              <h4 className="nowrap pr-100">
                {"Carte bancaire : ".concat(this.state.cartebancaire.name)}{" "}
              </h4>
              <div className="d-flex">
                <Link
                  to="/cartesbancaires"
                  className="btn btn-primary nowrap ms-1"
                >
                  Base Carte Bancaire
                </Link>
                {this.props.history.length > 1 && (
                  <button
                    className="btn btn-primary nowrap ms-1"
                    onClick={() => {
                      this.props.history.goBack();
                    }}
                  >
                    Retour
                  </button>
                )}
                {App.RightsGuard.current?.hasRight(
                  "Cartes bleues",
                  "Suppression"
                ) == "RW" ? (
                  <ButtonIconWithValidation
                    icon={faTrash}
                    onClick={this.handleDelete}
                    className="form-control btn btn-danger btn-sm text-light ms-1"
                    alertTitle=" Suppression"
                    alertBody={
                      <div>
                        Souhaitez-vous réellement désactiver cette carte ?{" "}
                        <br />
                      </div>
                    }
                    alertButtonValidationText="Oui, je veux supprimer."
                  ></ButtonIconWithValidation>
                ) : null}
              </div>
            </div>
            {this.generateTabs(this.state.tabs)}
            <>
              <DialogForm
                icon={faFileInvoiceDollar}
                tooltip="Générer une commande"
                classNameButton="btn btn-success"
                dialogTitle="Numéro de bordereau"
                labelValidateButton="Lier les notes de frais"
                setOpenDialog={this.setOpenDialog}
                onValidate={() => {
                  let exportCB = this.state.cartebancaire.notesDeFrais.filter(
                    (e) => e.export
                  );
                  CarteBancaireService.numeroter(
                    this.state.numeroFrais,
                    exportCB
                  ).then((res) => {
                    this.setState(
                      produce((prevState) => {
                        prevState.cartebancaire.notesDeFrais = res.data;
                      })
                    );
                    NoteDeFraisService.extract(exportCB).then((res) => {
                      var blob = new Blob([res?.data], {
                        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
                      });
                      FileSaver.saveAs(blob, "extractionNdF" + ".xlsx");
                    });
                  });
                }}
                onClose={() => {}}
                body={
                  <div>
                    <Input
                      label="Numéro de bordereau"
                      accessor="numeroFrais"
                      type="decimal"
                      numberOfDecimals={0}
                      value={this.state.numeroFrais}
                      showClearButton={false}
                      handleBlur={(accessor, value) => {
                        this.setState(
                          produce((prevState) => {
                            prevState.numeroFrais = value;
                          })
                        );
                      }}
                      required={true}
                    />
                  </div>
                }
              ></DialogForm>
            </>
            <WarningBar
              active={this.state.cartebancaire.actif}
              content={"Cette carte n'est pas active"}
            >
              {" "}
            </WarningBar>
            {!isContactEmpty && this.renderActive()}
          </div>
        )}
      </>
    );
  }
}

/**
 * Validation des props :
 */
FicheCarteBancaire.propTypes = {
  history: PropTypes.object,
  match: PropTypes.object,
};

export { FicheCarteBancaire };
