import { App } from "App";
import React, { useState } from "react";
import { useHistory } from "react-router-dom/cjs/react-router-dom";

import {
  faCheck,
  faExclamationTriangle,
  faExternalLinkAlt,
  faFileContract,
  faFileInvoice,
  faFileInvoiceDollar,
  faPen,
  faPlus,
  faTimes,
  faUser,
  faUserSlash,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import {
  AlertDialog,
  Box,
  ButtonIcon,
  DataFieldColumn,
  DialogForm,
  FormWithValidation,
} from "_components";
import { Input } from "_components/Input";
import {
  getIconLicence,
  isNullOrWhiteSpaces,
  RedirectToDetailsNewTab,
  ToLocaleDateString,
} from "_utils";

import {
  ArticleService,
  ClientService,
  ContactService,
  DevisService,
} from "_services";

const BoxLicence = (props) => {
  let licence = props.licence;
  let actionsAvailable = props.actionsAvailable;
  let handleClickAddModules = props.handleClickAddModules;
  let handleClickAddUserLogiciels = props.handleClickAddUserLogiciels;
  let handleClickRemoveUserLogiciels = props.handleClickRemoveUserLogiciels;
  let handleClickRemoveModules = props.handleClickRemoveModules;
  let handleUpdateLicence = props.handleUpdateLicence;
  let handleClickSuspend = props.handleClickSuspend;

  let [changedLicence, setChangedLicence] = useState(licence);
  let [editing, setEditing] = useState(false);
  let [openAlerte, setOpenAlerte] = useState(false);
  let [openDialogConfirmTrial, setOpenDialogConfirmTrial] = useState(
    () => () => null
  );
  let [openDialogSelectClient, setOpenDialogSelectClient] = useState(
    () => () => null
  );
  let history = useHistory();

  //Pour les essais gratuits uniquement
  let [selectedClient, setSelectedClient] = useState(null);
  let [isNewClient, setIsNewClient] = useState(false);
  let [nouvellesLicences, setNouvellesLicences] = useState([]);

  //Constantes et droits
  const droitsDevis = App.RightsGuard?.current.hasRight("Devis", "Creation");
  const droitsSuppression = App.RightsGuard?.current.hasRight(
    "Client - Logiciel",
    "Suppression"
  );
  const droitsGestionUtilisateur = App.RightsGuard?.current.hasRight(
    "Client - Logiciel",
    "Gestion utilisateur"
  );
  const droitsAjoutModules = App.RightsGuard?.current.hasRight(
    "Client - Logiciel",
    "Ajout modules"
  );
  const droitsRetraitModules = App.RightsGuard?.current.hasRight(
    "Client - Logiciel",
    "Retrait modules"
  );
  const droitsResiliation = App.RightsGuard?.current.hasRight(
    "Client - Logiciel",
    "Résiliation abonnement"
  );
  const droitsInfosAnnexes = App.RightsGuard?.current.hasRight(
    "Client - Logiciel",
    "Modification informations"
  );
  const droitDates = App.RightsGuard?.current.hasRight(
    "Client - Logiciel",
    "Date de fin"
  );
  async function handleSubmit(event) {
    event.preventDefault();

    const date = new Date(Date.parse(changedLicence.dateFin));
    const now = new Date();

    //On ne prends par en compte les heures/minutes/secondes/millièmes dans la comparaison
    date.setUTCHours(0, 0, 0, 0);
    now.setUTCHours(0, 0, 0, 0);
    //Si la nouvelle date de fin est antérieure à aujourd'hui (compris), on affiche un message d'avertissement
    if (date <= now) {
      setOpenAlerte(true);
    } else {
      handleValidate();
    }
  }

  async function handleValidate() {
    await handleUpdateLicence(changedLicence);
    setEditing(false);
  }

  function handleConvertNewClient() {
    history.push(
      {
        pathname: "/clients/add",
      },
      {
        origin: "freeTrial",
        contact: licence.user,
        licenceToDelete: licence,
        licencesToCreate: nouvellesLicences,
      }
    );
  }

  function handleConvertExistingClient() {
    ContactService.link(licence.user, selectedClient.id)
      .then(() =>
        DevisService.postDevisLicence(
          { client: selectedClient },
          nouvellesLicences
        )
      )
      .then((res) => {
        props.history.push({ pathname: "/devis/" + res.data.id });
      })
      .catch((error) => {
        console.log(error);
        App.Toaster.current?.createToast({
          body: "Erreur lors du processus",
          header: "Echec",
          type: "failure",
        });
      });
  }

  return (
    <div id={"licence" + props.id}>
      <FormWithValidation onSubmit={handleSubmit}>
        <Box
          header={
            <>
              {/* Numéro/nom de la licence */}
              <div className="col-4 align-self-center py-2 d-flex">
                {licence.classification.categorie.id == 1
                  ? getIconLicence("Websprint")
                  : getIconLicence("Geosprint")}
                <div className="align-self-center ms-2">
                  Licence n°{licence.numero}
                  <ButtonIcon
                    icon={faExternalLinkAlt}
                    className="btn btn-secondary btn-sm text-light ms-1"
                    style={{ width: "32px" }}
                    type="button"
                    onClick={() =>
                      props.history.push("/licences/" + licence.numero)
                    }
                  />
                </div>
              </div>
              {/* Bandeau warnings */}
              <div className="col-4 align-self-center text-center py-2 text-warning">
                {licence.impayee ? (
                  <>
                    <FontAwesomeIcon
                      icon={faExclamationTriangle}
                      className="mx-2"
                    />
                    Licence impayée
                    <br />
                  </>
                ) : null}
                {licence.devisModules?.resultat.valeur == "En cours" ||
                licence.devisModules?.resultat.valeur == "Suspendu" ? (
                  <>
                    <FontAwesomeIcon icon={faFileInvoice} className="mx-2" />
                    Devis en cours
                    <ButtonIcon
                      icon={faExternalLinkAlt}
                      className="btn btn-primary p-0 mx-2 align-self-center exportToDetails ficheClientTab"
                      onClick={() => {
                        RedirectToDetailsNewTab(
                          "/devis/",
                          licence.devisModules.id
                        );
                      }}
                    />
                  </>
                ) : null}
              </div>
              {/* Boutons d'action selon le contexte */}
              <div className="col-4 text-end py-2">
                {actionsAvailable && licence.active ? (
                  editing ? (
                    <>
                      {/* Bouton de validation des modifications */}
                      <ButtonIcon
                        icon={faCheck}
                        className="btn btn-success btn-sm text-light ms-1"
                        style={{ width: "40px" }}
                        type="submit"
                      />
                      {/* Bouton d'annulation des modifications */}
                      <ButtonIcon
                        icon={faTimes}
                        className="btn btn-danger btn-sm text-light ms-1"
                        style={{ width: "40px" }}
                        onClick={() => {
                          setEditing(false);
                        }}
                      />
                    </>
                  ) : licence.client.compte_Tier == "CTEST" ? (
                    <>
                      {/* Bouton de 'transformation' d'un essai gratuit */}
                      {droitsDevis == "RW" ? (
                        <ButtonIcon
                          icon={faFileInvoiceDollar}
                          className={"btn btn-success btn-sm sm-1"}
                          style={{
                            width: "40px",
                          }}
                          tooltip="Transformer en licence payante"
                          onClick={() => openDialogConfirmTrial()}
                        />
                      ) : null}
                      {/* Bouton d'édition des informations */}
                      {droitsInfosAnnexes == "RW" ? (
                        <ButtonIcon
                          icon={faPen}
                          className={"btn btn-success btn-sm ms-1"}
                          style={{ width: "40px" }}
                          tooltip="Editer des informations"
                          onClick={() => {
                            setChangedLicence(licence);
                            setEditing(true);
                          }}
                        />
                      ) : null}
                      {/* Bouton de suppression instantanée de la licence */}
                      {droitsSuppression == "RW" ? (
                        <ButtonIcon
                          className={"btn btn-danger btn-sm ms-1"}
                          style={{ width: "40px" }}
                          icon={faExclamationTriangle}
                          tooltip="Supprimer la licence"
                          onClick={() => {
                            setOpenAlerte(true);
                          }}
                        />
                      ) : null}
                    </>
                  ) : (
                    <>
                      {/* Bouton d'ajout de modules, seulement pour les abonnements */}
                      {droitsAjoutModules == "RW" ? (
                        <ButtonIcon
                          icon={faFileContract}
                          className={"btn btn-success btn-sm ms-1"}
                          style={{
                            width: "40px",
                            display:
                              licence.devisModules?.resultat.valeur ==
                                "En cours" ||
                              licence.devisModules?.resultat.valeur ==
                                "Suspendu"
                                ? "none"
                                : null,
                          }}
                          tooltip="Ajouter des modules"
                          onClick={handleClickAddModules}
                        />
                      ) : null}
                      {/* Bouton d'édition des informations */}
                      {droitsInfosAnnexes == "RW" ||
                      droitsResiliation == "RW" ? (
                        <ButtonIcon
                          icon={faPen}
                          className={"btn btn-success btn-sm ms-1"}
                          style={{ width: "40px" }}
                          tooltip="Editer des informations"
                          onClick={() => {
                            setChangedLicence(licence);
                            setEditing(true);
                          }}
                        />
                      ) : null}
                      {/* Bouton d'ajout/retrait d'un utilisateur à la licence */}
                      {droitsGestionUtilisateur == "RW" ? (
                        !licence.user ? (
                          <ButtonIcon
                            className={"btn btn-success btn-sm ms-1"}
                            icon={faUser}
                            style={{ width: "40px" }}
                            tooltip="Assigner un utilisateur"
                            onClick={handleClickAddUserLogiciels}
                          />
                        ) : (
                          <ButtonIcon
                            className={"btn btn-danger btn-sm ms-1"}
                            style={{ width: "40px" }}
                            icon={faUserSlash}
                            tooltip="Retirer l'utilisateur"
                            onClick={handleClickRemoveUserLogiciels}
                          />
                        )
                      ) : null}
                      {/* Bouton de retrait de module, seulement pour les abonnements */}
                      {licence.classification.categorie?.id == 1 &&
                      droitsRetraitModules == "RW" ? (
                        <ButtonIcon
                          className={"btn btn-danger btn-sm ms-1"}
                          style={{
                            width: "40px",
                          }}
                          icon={faFileContract}
                          tooltip="Retirer des modules"
                          onClick={handleClickRemoveModules}
                        />
                      ) : null}
                      {/* Bouton de suppression instantanée de la licence */}
                      {droitsSuppression == "RW" ? (
                        <ButtonIcon
                          className={"btn btn-danger btn-sm ms-1"}
                          style={{ width: "40px" }}
                          icon={faExclamationTriangle}
                          tooltip="Supprimer la licence"
                          onClick={() => {
                            setOpenAlerte(true);
                          }}
                        />
                      ) : null}
                    </>
                  )
                ) : null}
              </div>
            </>
          }
          body={
            <>
              {/* Partie informations sur la licence */}
              <div className="col-8">
                <div className="row pt-2">
                  <div className="col-3 align-self-center">
                    <DataFieldColumn
                      label="Catégorie logiciel"
                      value={
                        licence.classification.categorie.designation ?? "-"
                      }
                    />
                  </div>
                  <div className="col-3 align-self-center">
                    <DataFieldColumn
                      label="type de licence"
                      value={licence.classification.type.designation}
                    />
                  </div>
                  <div className="col-3 align-self-center">
                    <DataFieldColumn
                      label="Canal de vente"
                      value={
                        licence.classification.canalVente?.designation ?? "-"
                      }
                    />
                  </div>
                  {licence.classification.id == 2 ? (
                    <div className="col align-self-center">
                      {editing && droitsResiliation == "RW" ? (
                        <Input
                          type="checkbox"
                          label="à résilier"
                          value={changedLicence.resiliee}
                          handleChange={(accessor, value) =>
                            setChangedLicence({
                              ...changedLicence,
                              resiliee: value,
                            })
                          }
                        />
                      ) : (
                        <DataFieldColumn
                          label="à résilier"
                          value={licence.resiliee ? "oui" : "non"}
                        />
                      )}
                    </div>
                  ) : null}
                </div>
                <div className="row pt-2">
                  <div className="col-3 align-self-center">
                    {editing &&
                    licence.classification.canalVente?.designation == "LLD" &&
                    droitDates == "RW" ? (
                      <Input
                        type="date"
                        label="date de début"
                        value={licence.dateDebut}
                        handleChange={(accessor, value) =>
                          setChangedLicence({
                            ...changedLicence,
                            dateDebut: value,
                          })
                        }
                      />
                    ) : (
                      <DataFieldColumn
                        label="date de début"
                        type="date"
                        value={licence.dateDebut ?? "-"}
                      />
                    )}
                  </div>
                  <div className="col-3 align-self-center">
                    {editing &&
                    (licence.classification.canalVente?.designation == "LLD" ||
                      licence.classification.canalVente?.designation ==
                        "Location") &&
                    droitDates == "RW" ? (
                      <Input
                        type="date"
                        label="date de fin"
                        value={licence.dateFin}
                        handleChange={(accessor, value) =>
                          setChangedLicence({
                            ...changedLicence,
                            dateFin: value,
                          })
                        }
                      />
                    ) : (
                      <DataFieldColumn
                        label="date de fin"
                        type="date"
                        value={licence.dateFin ?? "-"}
                      />
                    )}
                  </div>
                  <div className="col-3 align-self-center">
                    <DataFieldColumn
                      label="durée"
                      value={
                        licence.duree
                          ? dureeLicence(
                              licence.duree.duree_Jours,
                              licence.duree.duree_Mois
                            )
                          : ""
                      }
                    />
                  </div>
                  <div className="col align-self-center">
                    {editing &&
                    droitsInfosAnnexes == "RW" &&
                    licence.classification.type.id != 2 ? (
                      <Input
                        type="checkbox"
                        label="gratuit"
                        value={changedLicence.gratuit}
                        handleChange={(accessor, value) =>
                          setChangedLicence({
                            ...changedLicence,
                            gratuit: value,
                          })
                        }
                      />
                    ) : (
                      <DataFieldColumn
                        label="gratuit"
                        value={licence.gratuit ? "oui" : "non"}
                      />
                    )}
                  </div>
                </div>
                <div className="row pt-2">
                  <div className="col-3 align-self-start">
                    <DataFieldColumn
                      label="Modules"
                      value={licence.modules?.map((module) => (
                        <div key={module.nom}>
                          {module.nom}
                          {module.dateTest
                            ? " (essai : " +
                              ToLocaleDateString(module.dateTest) +
                              ")"
                            : null}
                        </div>
                      ))}
                    />
                  </div>
                  <div className="col">
                    <div className="row">
                      <div className="col">
                        {editing && droitsInfosAnnexes == "RW" ? (
                          <Input
                            type="textArea"
                            label="Commentaire"
                            value={changedLicence.commentaire}
                            accessor="commentaire"
                            handleChange={(accessor, value) =>
                              setChangedLicence({
                                ...changedLicence,
                                commentaire: value,
                              })
                            }
                          />
                        ) : (
                          <DataFieldColumn
                            label="Commentaire"
                            value={
                              !isNullOrWhiteSpaces(licence.commentaire)
                                ? licence.commentaire
                                : "-"
                            }
                            functionAppliedToValue={(text) => (
                              <div className="keepWhiteSpace">{text}</div>
                            )}
                          />
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* Partie informations sur l'utilisateur */}
              <div className="col-4">
                <div className="align-self-center text-center py-2 mt-2 border border-dark">
                  {licence.user ? (
                    <>
                      <div className="align-self-center pt-2">
                        <DataFieldColumn
                          label="Nom"
                          value={licence.user.nom_Contact}
                        />
                      </div>
                      <div className="align-self-center pt-2">
                        <DataFieldColumn
                          label="Prénom"
                          value={licence.user.prenom_Contact}
                        />
                      </div>
                      <div className="align-self-center pt-2">
                        <DataFieldColumn
                          label="Mail"
                          value={licence.user.email}
                        />
                      </div>
                    </>
                  ) : (
                    "Aucun utilisateur"
                  )}
                </div>
              </div>
            </>
          }
        />
      </FormWithValidation>

      {licence.classification.type.id == 2 ? (
        <>
          <AlertDialog
            title="Attention : Fin de la licence gratuite"
            body={
              <div>
                Vous allez supprimer la licence gratuite accordée à cet
                utilisateur. Cette action est irrévocable.
              </div>
            }
            valider="Valider"
            open={openAlerte}
            handleClose={() => {
              setOpenAlerte(false);
            }}
            onClick={async () => {
              await handleClickSuspend(licence.numero);
              setOpenAlerte(false);
            }}
          />

          <DialogForm
            dialogTitle={"Sélection des licences"}
            labelValidateButton="Valider"
            labelCancelButton="Annuler"
            fullScreen={true}
            setOpenDialog={(openDialogFunc) => {
              setOpenDialogConfirmTrial(() => openDialogFunc);
            }}
            validation={() => nouvellesLicences.length > 0}
            onValidate={() => {
              openDialogSelectClient();
            }}
            onClose={() => {
              setNouvellesLicences([]);
            }}
            body={
              <>
                {nouvellesLicences.map((licenceInfo, index) => (
                  <div key={index} className="py-2">
                    <Input
                      type="selectSearchDisabledValues"
                      label="article licence"
                      value={licenceInfo.article ?? ""}
                      valueFieldToDisplay="aR_Ref"
                      optionFieldToDisplay="aR_Ref"
                      required={true}
                      showClearButton={false}
                      service={ArticleService.getAllModelesLicences}
                      disableOption={(modele) =>
                        modele.classification.type.id == 2
                      }
                      handleChange={(acc, value) => {
                        setNouvellesLicences(
                          [...nouvellesLicences].map((e, i) =>
                            i == index ? { ...e, article: value } : e
                          )
                        );
                      }}
                    />
                    <Input
                      type="decimal"
                      label="quantite"
                      value={licenceInfo.quantite ?? ""}
                      required={true}
                      showClearButton={false}
                      numberOfDecimals={0}
                      handleBlur={(acc, value) => {
                        setNouvellesLicences(
                          [...nouvellesLicences].map((e, i) =>
                            i == index ? { ...e, quantite: value } : e
                          )
                        );
                      }}
                    />
                    <hr />
                  </div>
                ))}
                <ButtonIcon
                  className={"btn btn-success btn-sm ms-1"}
                  style={{ width: "40px" }}
                  icon={faPlus}
                  tooltip="Nouvelle licence"
                  onClick={() =>
                    setNouvellesLicences([
                      ...nouvellesLicences,
                      {
                        modele: null,
                        quantite: null,
                      },
                    ])
                  }
                />
              </>
            }
          />

          <DialogForm
            dialogTitle={"Choix du client auquel accorder la licence"}
            labelValidateButton="Valider"
            labelCancelButton="Annuler"
            contextual={true}
            setOpenDialog={(openDialogFunc) => {
              setOpenDialogSelectClient(() => openDialogFunc);
            }}
            onValidate={() => {
              if (isNewClient) {
                handleConvertNewClient();
              } else {
                handleConvertExistingClient();
              }
            }}
            onClose={() => {}}
            body={
              <>
                <Input
                  type="checkbox"
                  className="mb-4"
                  value={isNewClient}
                  label="Créer un nouveau client"
                  required={true}
                  handleChange={(accessor, value) => {
                    setIsNewClient(value);
                  }}
                />
                <br />
                {isNewClient ? null : (
                  <Input
                    type="selectSearch"
                    label="Client"
                    value={selectedClient ?? ""}
                    required={!isNewClient}
                    showClearButton={false}
                    service={ClientService.getForSelectSearch}
                    optionFieldToDisplay={"nom_Client"}
                    valueFieldToDisplay={"nom_Client"}
                    handleChange={(accessor, value) => {
                      setSelectedClient(value);
                    }}
                  />
                )}
              </>
            }
          />
        </>
      ) : (
        <AlertDialog
          title="Attention : suppression de la licence"
          body={
            <div>
              Vous allez provoquer la suppression immédiate de la licence. Le
              client ne disposera plus de cet article.
            </div>
          }
          valider="Valider"
          open={openAlerte}
          handleClose={() => {
            setOpenAlerte(false);
          }}
          onClick={async () => {
            await handleClickSuspend(licence.numero);
            setOpenAlerte(false);
          }}
        />
      )}
    </div>
  );
};

function dureeLicence(nbJours, nbMois) {
  var duree = "";

  if (nbMois > 0) {
    if (Math.floor(nbMois / 12) > 0)
      duree += Math.floor(nbMois / 12) + " an(s) ";
    if (nbMois % 12 > 0) duree += (nbMois % 12) + " mois ";
  }
  if (nbJours > 0) duree += nbJours + " jour(s) ";

  return duree;
}

export { BoxLicence };
