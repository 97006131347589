import React from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";

import { General } from ".";
import { WarningBar } from "_components";

import { LicencesService } from "_services";
import { Helmet } from "react-helmet";

import TabSelector from "_components/FicheComponents/TabSelector";

class FicheLicence extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      tabs: ["Général"],
      active: "Général",
      filters: {},
    };

    this.getFilteredLicence = this.getFilteredLicence.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  componentDidMount() {
    let { id } = this.props.match.params;

    LicencesService.getOneById(id).then((res) => {
      this.setState({ licence: res.data });
      LicencesService.getHistoriqueById({ licenceId: id }).then((res2) => {
        this.setState({ historiques: res2.data, loading: false });
      });
    });
  }

  handleChange(accessor, value) {
    // Shallow property
    this.setState(
      (prevState) => ({
        filters: {
          ...prevState.filters,
          [accessor]: value,
        },
      }),
      () => this.getFilteredLicence()
    );
  }

  getFilteredLicence() {
    LicencesService.getHistoriqueById({
      licenceId: this.state.licence.numero,
      ...this.state.filters,
    }).then((res2) => {
      this.setState({ historiques: res2.data, loading: false });
    });
  }

  getHistoriques() {
    LicencesService.getHistoriqueById(this.state.filters).then((res) => {
      this.setState({ historiques: res.data.historiques, loadingHeure: false });
    });
  }

  /**
   * Génère les onglets de la fiche
   * @param {*} tabs
   * @returns
   */
  generateTabs(tabs) {
    return (
      <div>
        <TabSelector
          tabs={tabs}
          activeTab={this.state.active}
          onSelect={(tab) => {
            this.setState({ active: tab });
            sessionStorage.setItem("FicheLicenceOnglet", tab);
          }}
          notAllowed={this.state.editing}
        ></TabSelector>
      </div>
    );
  }

  renderActive() {
    switch (this.state.active) {
      case "Général":
        return (
          <General
            licence={this.state.licence}
            historiques={this.state.historiques}
            filters={this.state.filters}
            getFilteredLicence={this.getFilteredLicence}
            handleChange={this.handleChange}
          />
        );

      default:
        return (
          <h4 className="mt-5">Désolé la page n&apos;a pas réussi à charger</h4>
        );
    }
  }

  render() {
    return (
      <>
        <Helmet>
          <title>{"Licence : ".concat(this.state?.licence?.numero)}</title>
        </Helmet>

        {this.state.loading ? (
          <div
            style={{
              flexGrow: 1,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <FontAwesomeIcon icon={faSpinner} size="lg" className="fa-spin" />
          </div>
        ) : (
          <div className="p-3 m-4" id="ficheClient">
            <div className="d-flex overflow-auto justify-content-between py-1">
              <h4 className="nowrap pr-100">
                {"Licence : ".concat(this.state?.licence?.numero)}
              </h4>
              <div className="d-flex">
                {this.props.history.length > 1 && (
                  <button
                    className="btn btn-primary nowrap ms-1"
                    onClick={() => {
                      this.props.history.goBack();
                    }}
                  >
                    Retour
                  </button>
                )}
              </div>
            </div>
            {this.generateTabs(this.state.tabs)}
            <div
              style={{
                height: "50px",
                display: "flex",
                marginBottom: "20px",
              }}
            >
              <WarningBar
                active={!this.state?.licence?.isActive}
                content={"Cette licence n'est pas active"}
              />
            </div>
            {this.renderActive()}
          </div>
        )}
      </>
    );
  }
}

export { FicheLicence };
