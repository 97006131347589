import React from "react";
import { PropTypes } from "prop-types";
import { App } from "App";

import "./Historique.css";
import { Box, ButtonIcon, WarningBar } from "_components";
import { Input } from "_components/Input";

import { faCheck, faTimes, faPen } from "@fortawesome/free-solid-svg-icons";
import { ToLocaleDateString } from "_utils";

class Historique extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {};

    this.handleClickEdition = this.handleClickEdition.bind(this);
    this.handleClickUndo = this.handleClickUndo.bind(this);

    this.handleClickValidate = this.handleClickValidate.bind(this);
  }

  handleClickEdition() {
    this.setState({
      savedfds: { ...this.props.ficheDeSuivi },
    });
    this.props.handleEditing(true);
  }

  handleClickUndo() {
    this.props.handleChange("ficheDeSuivi", this.state.savedfds);
    this.props.handleEditing(false);
  }

  handleClickValidate() {
    this.props.handleUpdate();
    this.props.handleEditing(false);
  }

  render() {
    const right = App.RightsGuard?.current?.hasRight(
      "Fiche de suivi",
      "Historique"
    );
    if (right == "RW" || right == "RO") {
      return (
        <>
          <div className=" boxElements ">
            <Box
              noPaddingBottom={true}
              header={
                <>
                  <div className="col-6 align-self-center py-2">
                    <div>Historique des etats d'avancement</div>
                  </div>
                  <div className="col-6 text-end py-2">
                    <ButtonIcon
                      icon={faCheck}
                      className="btn btn-success text-light ms-1"
                      style={
                        !this.props.editing
                          ? { display: "none" }
                          : { width: "42px" }
                      }
                      onClick={this.handleClickValidate}
                    ></ButtonIcon>
                    <ButtonIcon
                      icon={faTimes}
                      className="btn btn-danger text-light ms-1"
                      onClick={this.handleClickUndo}
                      style={
                        !this.props.editing
                          ? { display: "none" }
                          : { width: "42px" }
                      }
                    ></ButtonIcon>
                    <ButtonIcon
                      icon={faPen}
                      className="btn btn-secondary text-light ms-1"
                      onClick={this.handleClickEdition}
                      style={
                        this.props.editing || right != "RW"
                          ? { display: "none" }
                          : { width: "42px" }
                      }
                    ></ButtonIcon>
                  </div>
                </>
              }
              body={
                <table
                  className={
                    "table table-sm text-uppercase" +
                    (this.props.ficheDeSuivi.historique.length > 0
                      ? ""
                      : " table-bordered")
                  }
                >
                  <thead
                    className={
                      "sticky-top" +
                      (this.props.ficheDeSuivi.historique?.length == 0
                        ? " theadBorderRadius"
                        : "")
                    }
                  >
                    <tr>
                      <th>Date de début</th>
                      <th>Etat</th>
                      <th>Emplacement</th>

                      <th>Date fin prév</th>
                      <th>Date fin réelle</th>
                      <th>Ecart</th>
                      <th>Delai</th>
                      <th>Commentaire</th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.props.ficheDeSuivi.historique.map((histo) => (
                      <tr className="LigneHistorique" key={histo.id}>
                        <td>{ToLocaleDateString(histo.dateDebut)}</td>
                        <td>{histo.etatAvancement?.designation}</td>
                        <td>{histo.emplacement?.designation}</td>

                        <td>
                          <Input
                            accessor={"historique.".concat(
                              histo.id,
                              ".dateFinPrevisionnelle"
                            )}
                            value={histo.dateFinPrevisionnelle}
                            type="date"
                            placeholder=""
                            handleChange={this.props.handleChange}
                            showValidator={false}
                            showClearButton={false}
                            disabled={!this.props.editing}
                          />
                        </td>
                        <td>
                          {histo.dateFin
                            ? ToLocaleDateString(histo.dateFin)
                            : null}
                        </td>

                        <td>{histo.ecart}</td>
                        <td>{histo.delai}</td>

                        <td>
                          <Input
                            accessor={"historique.".concat(
                              histo.id,
                              ".commentaire"
                            )}
                            value={histo.commentaire}
                            type="text"
                            placeholder=""
                            handleChange={this.props.handleChange}
                            showValidator={false}
                            showClearButton={false}
                            disabled={!this.props.editing}
                          />
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              }
            />
          </div>
        </>
      );
    } else {
      return (
        <>
          <WarningBar
            active={false}
            content={"Vous n'avez pas le droit de voir cette page"}
          />
        </>
      );
    }
  }
}

Historique.propTypes = {
  ficheDeSuivi: PropTypes.object,
  stateFieldNameToUpdate: PropTypes.string,
  handleChange: PropTypes.func,
  history: PropTypes.object,
  service: PropTypes.func,
};

export { Historique };
