import http from "http-common";

class ContratPeriodiciteService {
  static cache = null;
  static loading = true;

  static getAll(params, refresh) {
    //Gestion de la valeur par défaut de la propriété "isActive" des paramètres
    let reqParams;
    if (!params) {
      // null
      reqParams = { isActive: true };
    } else if (typeof params === "object" && params.isActive === undefined) {
      // Objet ne contenant pas isActive
      reqParams = { ...params, isActive: true };
    } else if (typeof params === "object" && params.isActive !== undefined) {
      // Objet contenant isActive (null/true/false)
      reqParams = params;
    } else {
      reqParams = { isActive: true };
    }

    //On a des params (autres que isActive) ou isActive !=true => On ne veut pas les données stockées en cache.
    if (!(Object.keys(reqParams).length == 1) || reqParams.isActive !== true)
      return ContratPeriodiciteService.get(reqParams);

    //Si besoin, on (re)charge le cache avant de le renvoyer
    if (refresh == true || ContratPeriodiciteService.loading) {
      ContratPeriodiciteService.cache = ContratPeriodiciteService.get({
        isActive: true,
      });
      ContratPeriodiciteService.loading = false;
    }
    return ContratPeriodiciteService.cache;
  }
  /**
   * Fonction permettant de récuperer tous les familles d'affaire avec une possibilité de filtre via params
   * @returns la liste de tous les familles d'affaire
   */
  static get(params) {
    return http.get("/ContratPeriodicite", { params });
  }
}

export { ContratPeriodiciteService };
