// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#GeomarketPopup p {
  margin: 8px 0;
}

#filters {
  overflow: auto;
  font-size: 1rem !important;
  font-family: 'Poppins';
  
}

@media (max-width: 768px){
  #filters h5{
    font-size: 1.6rem !important;
  }
}

.symbol{
  font-family: 'Roboto';

}

#filters h5{
  font-size: 1.1rem;
  
}

@media (max-width: 768px){
  #filters h5{
    font-size: 2rem;
  }
}



#legende{
  display: flex;
  flex-direction: column;
  gap: 5px;
}
.legende{
  gap: 4px;
  display: flex;
  width: 45%;
  align-items: center;
}

.imageLegende{
  width: 20px
  
}

.blockLegende{
  gap: 10px;
  margin-bottom: 10px;
  display: flex;
}`, "",{"version":3,"sources":["webpack://./src/Geomarket/Geomarket.css"],"names":[],"mappings":"AAAA;EACE,aAAa;AACf;;AAEA;EACE,cAAc;EACd,0BAA0B;EAC1B,sBAAsB;;AAExB;;AAEA;EACE;IACE,4BAA4B;EAC9B;AACF;;AAEA;EACE,qBAAqB;;AAEvB;;AAEA;EACE,iBAAiB;;AAEnB;;AAEA;EACE;IACE,eAAe;EACjB;AACF;;;;AAIA;EACE,aAAa;EACb,sBAAsB;EACtB,QAAQ;AACV;AACA;EACE,QAAQ;EACR,aAAa;EACb,UAAU;EACV,mBAAmB;AACrB;;AAEA;EACE;;AAEF;;AAEA;EACE,SAAS;EACT,mBAAmB;EACnB,aAAa;AACf","sourcesContent":["#GeomarketPopup p {\n  margin: 8px 0;\n}\n\n#filters {\n  overflow: auto;\n  font-size: 1rem !important;\n  font-family: 'Poppins';\n  \n}\n\n@media (max-width: 768px){\n  #filters h5{\n    font-size: 1.6rem !important;\n  }\n}\n\n.symbol{\n  font-family: 'Roboto';\n\n}\n\n#filters h5{\n  font-size: 1.1rem;\n  \n}\n\n@media (max-width: 768px){\n  #filters h5{\n    font-size: 2rem;\n  }\n}\n\n\n\n#legende{\n  display: flex;\n  flex-direction: column;\n  gap: 5px;\n}\n.legende{\n  gap: 4px;\n  display: flex;\n  width: 45%;\n  align-items: center;\n}\n\n.imageLegende{\n  width: 20px\n  \n}\n\n.blockLegende{\n  gap: 10px;\n  margin-bottom: 10px;\n  display: flex;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
