import React from "react";

import { Input } from "_components/Input";

function InputValue(value, editable, editing) {
  return value == null
    ? "aucun(e)"
    : Array.isArray(value)
    ? editable && editing
      ? value
      : value.length <= 0
      ? "aucun(e)"
      : value
          .map((item) => item["designation"] ?? item["panne"] ?? "ERROR")
          .join(", ")
    : typeof value === "object"
    ? editable && editing
      ? value
      : value["designation"] ?? value["type"] ?? value["id"] ?? "objet non géré"
    : value === 0
    ? "0"
    : value === true
    ? "oui"
    : value === false
    ? "non"
    : value;
}

function SpecialDisplay(value, refName, columnName) {
  return value == null
    ? null
    : refName == "TypesTache" && columnName == "destinataire"
    ? Object.keys(value)
        .map((key) => (value[key] == true ? key.toUpperCase() : null))
        .filter((el) => el != null)
        .join(", ")
    : refName == "JoursFeries" && columnName == "jour"
    ? new Date(value).toLocaleDateString("fr-FR")
    : "erreur d'affichage";
}

function LigneRefSimple(props) {
  var i = 0;

  return (
    <tr>
      {props.infoColonnes.map((colonne) => {
        ++i;

        return colonne.nom.includes("dO_") ||
          colonne.nom.includes("typePanneId") ||
          colonne.nom.includes("familleArticleId") ||
          colonne.nom.includes("chantiers") ? null : colonne.nom != "actif" &&
          colonne.nom != "ordreTri" &&
          (!props.editing ||
            !props.editable ||
            colonne.nom.includes("id") ||
            colonne.nom.includes("Id")) ? (
          <td key={i}>
            <div>
              {(props.refName == "TypesTache" &&
                colonne.nom == "destinataire") ||
              (props.refName == "JoursFeries" && colonne.nom == "jour")
                ? SpecialDisplay(
                    props.referentiel[colonne.nom],
                    props.refName,
                    colonne.nom
                  )
                : InputValue(
                    props.referentiel[colonne.nom],
                    props.editable,
                    props.editing
                  )}
            </div>
          </td>
        ) : (
          <td key={i}>
            <Input
              accessor={
                "referentiel." + props.referentiel.id + "." + colonne.nom
              }
              value={
                colonne.nom == "actif"
                  ? props.referentiel[colonne.nom]
                  : InputValue(
                      props.referentiel[colonne.nom],
                      props.editable,
                      props.editing
                    )
              }
              type={
                colonne.nom == "actif" || props.editing ? colonne.type : "text"
              }
              service={colonne.service}
              placeholder=""
              handleChange={
                colonne.type != "decimal" ? props.handleChange : null
              }
              handleBlur={props.handleChange}
              showValidator={false}
              showClearButton={false}
              disabled={
                !props.editing ||
                (!props.editable &&
                  colonne.nom != "actif" &&
                  colonne.nom != "ordreTri") ||
                colonne.nom.includes("id") ||
                colonne.nom.includes("Id")
              }
              valueFieldToDisplay={colonne.field}
              optionFieldToDisplay={colonne.field}
              required={colonne.required}
            />
          </td>
        );
      })}
    </tr>
  );
}

export { LigneRefSimple };
