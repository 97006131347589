
import ImgSmallProspect from "_assets/TypesClient/petit-picto-prospect.svg";
import ImgSmallSuspect from "_assets/TypesClient/petit-picto-suspect.svg";
import ImgSmallClient from "_assets/TypesClient/petit-picto-client.svg";
import ImgSmallAbonne from "_assets/TypesClient/petit-picto-abonne.svg";
import ImgSmallDistributeur from "_assets/TypesClient/petit-picto-distributeur.svg";
import ImgWebsprintIcon from "_assets/CategoriesLicence/websprint_icon.jpg";
import ImgGeosprintIcon from "_assets/CategoriesLicence/geosprint_icon.jpg";

function getIconClient(qualification, size = "small", className = "") {
  switch (qualification) {
    case "Client":
      return (
        <img
          src={ ImgSmallClient}
          alt="CLIENT"
          height={size === "small" ? "30px" : "40px"}
          className={className + " d-block borderIconTypeClient"}
        ></img>
      );
    case "Prospect":
      return (
        <img
          src={ImgSmallProspect}
          alt="PROSPECT"
          height={size === "small" ? "30px" : "40px"}
          className={className + " d-block borderIconTypeClient"}
        ></img>
      );
    case "Suspect":
      return (
        <img
          src={ImgSmallSuspect }
          alt="SUSPECT"
          height={size === "small" ? "30px" : "40px"}
          className={className + " d-block borderIconTypeClient"}
        ></img>
      );
    case "Abonné":
      return (
        <img
          src={ImgSmallAbonne}
          alt="ABONNE"
          height={size === "small" ? "30px" : "40px"}
          className={className + " d-block borderIconTypeClient"}
        ></img>
      );
    case "Distributeur":
      return (
        <img
          src={ImgSmallDistributeur}
          alt="DISTRIBUTEUR"
          height={size === "small" ? "30px" : "40px"}
          className={className + " d-block borderIconTypeClient"}
        ></img>
      );
    default:
      return <div>Indisponible</div>;
  }
}

function getIconLicence(catégorie) {
  switch (catégorie) {
    case "Websprint":
      return (
      <img
        src={ImgWebsprintIcon}
        alt="WEBSPRINT"
        height="35px"
        className="d-block borderIconTypeClient"
      />);
    case "Geosprint":
      return (
        <img
          src={ImgGeosprintIcon}
          alt="WEBSPRINT"
          height="35px"
          className="d-block borderIconTypeClient"
        />);
    default:
      return;
  }
}

export { getIconClient, getIconLicence };
