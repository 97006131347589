import React from "react";
import { AlertDialog, Box, FormWithValidation } from "_components";

import { Input } from "_components/Input";

import { PropTypes } from "prop-types";

import { ButtonIcon } from "_components";
import {
  faArrowCircleDown,
  faSpinner,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { isEqual } from "lodash";
import {
  ClientService,
  CodeTarifService,
  ContactService,
  DeviseService,
  DevisService,
  EcheanceService,
  EtatClientService,
  FactureService,
  LangueService,
  ModePaiementService,
  OrigineService,
  PaysService,
  ProduitInterneService,
  ReferentGeotechniqueService,
  TypeClientService,
  TVAService,
  TypeLigneService,
  TailleEntrepriseService,
} from "_services";

import { Helmet } from "react-helmet";

import { faExternalLinkAlt } from "@fortawesome/free-solid-svg-icons";

import produce from "immer";

class AjoutClient extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loaded: false,
      errorText: null,
      error: false,
      isSubmitting: false,
      similarClients: [],
      alertOpened: false,
      client: {
        id: 0,
        n_Client: 0,
        contacts: [],
        produitInternes: [],
        nom_Client: null,
        origine: null,
        groupe: null,
        type_Ligne: null,
        type_Client: null,
        international: false,
        adresse_Livraison_Principale: {
          intitule: null,
          complement: null,
          voie: null,
          ville: null,
          pays: null,
          region: null,
          cp: null,
          nomContact: null,
          email: null,
          telephone: null,
          fax: null,
        },
        adresse_Facturation: {
          intitule: null,
          complement: null,
          voie: null,
          ville: null,
          pays: null,
          region: null,
          cp: null,
          nomContact: null,
          email: null,
          telephone: null,
          fax: null,
        },
        site_Web: null,
        devise: null,
        siret: null,
        litige: false,
        echeance: null,
        mode_Paiement: null,
        cc_Num: null,
        compte_Tier: null,
        code_Tarif: null,
        etat: null,
        tarif_Negocie: false,
        date_Create: null,
        date_Modif: null,
        langue: null,
        facture: null,
        tva: null,
        cBmarq: null,
        li_No: null,
        secteur_Client: null,
        usage: null,
        domaine: null,
        client_abonne: false,
        est_Super_Client: false,
        super_Client: null,
        longitude: null,
        lattitude: null,
        referent_Geotechnique: null,
        email_Relance_Impaye: null,
        remise: null,
        actif: true,
      },
    };

    this.tvaFrance = null;
    this.codeTarifFrance = null;
    this.factureFrance = null;
    this.ALaCommande = null;
    this.langueFr = null;
    this.tvaExport = null;
    this.codeTarifExport = null;

    this.handleStateChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleCopyAdress = this.handleCopyAdress.bind(this);
    this.handleBlurNom = this.handleBlurNom.bind(this);
    this.handleBlurPays = this.handleBlurPays.bind(this);
    this.getListOfSimilarClients = this.getListOfSimilarClients.bind(this);

    this.handleClickOnCreationButton =
      this.handleClickOnCreationButton.bind(this);
    this.handleActionValidation = this.handleActionValidation.bind(this);
    this.handleCloseValidation = this.handleCloseValidation.bind(this);
  }

  async componentDidMount() {
    const [
      devise,
      etat,
      tvaFrance,
      codeTarifFrance,
      factureFrance,
      tvaExport,
      codeTarifExport,
      echeance,
      ALaCommande,
      modePaiement,
      langueFr,
    ] = await Promise.all([
      DeviseService.getByValue("Euro"),
      EtatClientService.getByValue("Suspect"),
      TVAService.getByValue(20),
      CodeTarifService.getByValue("Tarif France"),
      FactureService.getByValue("France"),
      TVAService.getByValue(0),
      CodeTarifService.getByValue("Tarif Export"),
      EcheanceService.getByValue("30 jours fin de mois"),
      EcheanceService.getByValue("A la commande"),
      ModePaiementService.getByValue("Virement"),
      LangueService.getByValue("Français"),
    ]).catch((e) => {
      console.log(e);
      return [null, null, null, null, null, null, null, null, null, null, null];
    });

    // let updatedclient = { ...this.state.client };
    // updatedclient["etat"] = etat.data;
    // updatedclient["devise"] = devise.data;
    // this.setState({ client: updatedclient, loaded: true });

    this.setState(
      produce((prevState) => {
        prevState.client.etat = etat.data;
        prevState.client.devise = devise.data;
        prevState.client.echeance = echeance.data;
        prevState.client.mode_Paiement = modePaiement.data;
        prevState.loaded = true;
      })
    );

    this.tvaFrance = tvaFrance.data;
    this.codeTarifFrance = codeTarifFrance.data;
    this.factureFrance = factureFrance.data;
    this.ALaCommande = ALaCommande.data;
    this.tvaExport = tvaExport.data;
    this.codeTarifExport = codeTarifExport.data;
    this.langueFr = langueFr.data;
  }

  handleChange(accessor, value, afterUpdateFunction = null) {
    if (accessor.includes(".")) {
      var accessorSplit = accessor.split(".");

      // Nested property (separated by a '.')
      this.setState(
        produce((prevState) => {
          prevState.client[accessorSplit[0]][accessorSplit[1]] = value;
        }),
        afterUpdateFunction
      );
    } else {
      // Shallow property
      this.setState(
        produce((prevState) => {
          prevState.client[accessor] = value;
        }),
        afterUpdateFunction
      );
    }
  }

  handleSubmit(event) {
    event.preventDefault();
    this.setState(
      produce((state) => {
        state.isSubmitting = true;
        state.client.date_Create = new Date().toISOString();
        state.client.date_Modif = new Date().toISOString();
      }),
      () =>
        this.props.location?.state?.origin == "freeTrial"
          ? ClientService.post(this.state.client).then((returned) =>
              ContactService.link(
                this.props.location.state.contact,
                returned.data.id
              )
                .then(() =>
                  DevisService.postDevisLicence(
                    { client: returned.data },
                    this.props.location.state.licencesToCreate
                  )
                )
                .then((res) => {
                  this.props.history.push({
                    pathname: "/devis/" + res.data.id,
                  });
                })
                .catch((error) => {
                  console.log(error);
                  this.setState({
                    error: true,
                    isSubmitting: false,
                    errorText: error.message,
                  });
                })
            )
          : ClientService.post(this.state.client)
              .then((returned) => {
                this.props.history.push("/clients/" + returned.data.id);
              })
              .catch((error) => {
                console.log(error);
                this.setState({
                  error: true,
                  isSubmitting: false,
                  errorText: error.message,
                });
              })
    );
  }

  handleClickOnCreationButton() {
    if (this.state.similarClients?.length != 0)
      this.setState({ alertOpened: true });
  }

  handleClose() {
    this.setState({ error: false });
  }

  handleActionValidation() {
    this.setState({ alertOpened: false });
  }

  handleCloseValidation() {
    this.setState({ alertOpened: false });
  }

  handleCopyAdress() {
    this.setState(
      produce((prevState) => {
        prevState.client.adresse_Facturation =
          prevState.client.adresse_Livraison_Principale;
        this.handleBlurPays(
          null,
          prevState.client.adresse_Livraison_Principale.pays
        );
      })
    );
  }

  handleBlurNom(acc, value) {
    if (!this.state.client.adresse_Livraison_Principale.intitule) {
      this.setState(
        produce((prevState) => {
          prevState.client.adresse_Livraison_Principale.intitule = value;
        })
      );
    }
  }

  handleBlurPays(acc, value) {
    if (value) {
      if (value.nom_Fr === "France") {
        this.setState(
          produce((prevState) => {
            prevState.client.tva = this.tvaFrance;
            prevState.client.code_Tarif = this.codeTarifFrance;
            prevState.client.facture = this.factureFrance;
            prevState.client.langue = this.langueFr;
          })
        );
      } else {
        this.setState(
          produce((prevState) => {
            prevState.client.tva = this.tvaExport;
            prevState.client.code_Tarif = this.codeTarifExport;
            prevState.client.echeance = this.ALaCommande;
            prevState.client.facture = null;
          })
        );
      }
    }
  }

  getListOfSimilarClients() {
    ClientService.getAll({
      size: 100,
      nom_Client: this.state.client.nom_Client,
      ville_Liv: this.state.client.adresse_Livraison_Principale?.ville,
      cp_Liv: this.state.client.adresse_Livraison_Principale?.cp,
    }).then((res) => {
      let data = res.data && res.data.datas ? res.data.datas : res.data;
      let clientData = data.map((client) => ({
        id: client.id,
        etat: client.etat?.qualification,
        nom_Client: client.nom_Client,
        compte_Tier: client.compte_Tier,
        type_Client: client.type_Client?.type,
        categorie_Client: client.type_Client?.categorie?.type,
        ville: client.adresse_Livraison_Principale?.ville,
        cp: client.adresse_Livraison_Principale?.cp,
        pays: client.adresse_Livraison_Principale?.pays?.nom_Fr,
      }));
      this.setState((prevState) => ({
        ...prevState,
        similarClients: clientData,
      }));
    });
  }

  shouldComponentUpdate(prevProps, prevStates) {
    return !(isEqual(prevProps, this.props) && isEqual(prevStates, this.state));
  }

  form() {
    const informationsPersonnelles = [
      {
        label: "état",
        value: this.state.client.etat,
        accessor: "etat",
        type: "selectSearch",
        service: EtatClientService.getAll,
        optionFieldToDisplay: "qualification",
        valueFieldToDisplay: "qualification",
        required: true,
        invalidText: "Vous devez sélectionner un état.",
      },
      {
        label: "Nom",
        value: this.state.client.nom_Client,
        accessor: "nom_Client",
        required: true,
        handleBlur: this.handleBlurNom,
        invalidText: "Vous devez entrer un nom.",
        afterUpdateFunction: this.getListOfSimilarClients,
      },
      {
        label: "Siret",
        value: this.state.client.siret,
        accessor: "siret",
      },
      {
        label: "Site Web",
        value: this.state.client.site_web,
        accessor: "site_web",
      },
      {
        label: "Origine",
        value: this.state.client.origine,
        type: "selectSearch",
        accessor: "origine",
        service: OrigineService.getAll,
        optionFieldToDisplay: "designation",
        valueFieldToDisplay: "designation",
      },
    ];
    const remiseOptions = [
      {
        id: 1,
        value: 0,
        display: "0%",
      },
      {
        id: 2,
        value: 5,
        display: "5%",
      },
      {
        id: 3,
        value: 10,
        display: "10%",
      },
      {
        id: 4,
        value: 15,
        display: "15%",
      },
      {
        id: 5,
        value: 20,
        display: "20%",
      },
      {
        id: 6,
        value: 25,
        display: "25%",
      },
      {
        id: 7,
        value: 30,
        display: "30%",
      },
      {
        id: 8,
        value: 35,
        display: "35%",
      },
      {
        id: 9,
        value: 40,
        display: "40%",
      },
      {
        id: 10,
        value: 45,
        display: "45%",
      },
      {
        id: 11,
        value: 50,
        display: "50%",
      },
      {
        id: 12,
        value: 55,
        display: "55%",
      },
      {
        id: 13,
        value: 60,
        display: "60%",
      },
      {
        id: 14,
        value: 65,
        display: "65%",
      },
      {
        id: 15,
        value: 70,
        display: "70%",
      },
      {
        id: 16,
        value: 75,
        display: "75%",
      },
      {
        id: 17,
        value: 80,
        display: "80%",
      },
      {
        id: 18,
        value: 85,
        display: "85%",
      },
      {
        id: 19,
        value: 90,
        display: "90%",
      },
      {
        id: 20,
        value: 95,
        display: "95%",
      },
      {
        id: 21,
        value: 100,
        display: "100%",
      },
    ];
    const conditionsCommerciales = [
      {
        label: "Tarif négocié",
        value: this.state.client.tarif_Negocie,
        accessor: "tarif_Negocie",
        type: "checkbox",
      },
      this.state.client.tarif_Negocie
        ? {
            value: this.state.client.remise,
            label: "Pourcentage de remise",
            accessor: "remise",
            type: "selectSearch",
            optionFieldToReturn: "value",
            optionFieldToDisplay: "display",
            valueFieldToDisplay: "display",
            options: remiseOptions,
            value:
              remiseOptions.find(
                (el) => el.value === this.props.client?.remise
              ) ?? null,
            functionAppliedToValue: (el) => {
              return (el ?? "0") + "%";
            },
            colSize: 6,
          }
        : null,
      this.state.client.tarif_Negocie
        ? {
            label: "Commentaire tarif",
            accessor: "commentaireCommercial",
            type: "textArea",
            colSize: 12,
          }
        : null,
    ];
    const informationsGroupe = [
      {
        label: "Groupe",
        value: this.state.client.groupe,
        accessor: "groupe",
      },
      {
        value: this.state.client.taille_Entreprise,
        label: "Taille entreprise (nombre employés)",
        accessor: "taille_Entreprise",
        type: "selectSearch",
        service: TailleEntrepriseService.getAll,
        optionFieldToDisplay: "libelle",
        valueFieldToDisplay: "libelle",
        functionAppliedToValue: (value) => {
          if (value) {
            return value.libelle;
          }
          return null;
        },
        colSize: 6,
      },
      {
        label: "Produits internes",
        value: this.state.client.produitInternes,
        accessor: "produitInternes",
        type: "multipleSelect",
        required: true,
        service: ProduitInterneService.getAll,
        optionFieldToDisplay: "designation",
        invalidText:
          "Vous devez sélectionner au minimum un type de produit interne.",
      },
      {
        label: "Type",
        value: this.state.client.type_Client,
        accessor: "type_Client",
        type: "selectSearch",
        required: true,
        service: TypeClientService.getAll,
        optionFieldToDisplay: "type",
        valueFieldToDisplay: "type",
      },
    ];
    const informationsAdresseLiv = [
      {
        label: "Intitulé d'adresse",
        value: this.state.client.adresse_Livraison_Principale.intitule,
        accessor: "adresse_Livraison_Principale.intitule",
        required: true,
        invalidText:
          "Vous devez entrer un intitulé pour l'adresse de livraison.",
      },
      {
        label: "Voie",
        value: this.state.client.adresse_Livraison_Principale.voie,
        accessor: "adresse_Livraison_Principale.voie",
        invalidText:
          "Vous devez entrer une adresse pour l'adresse de livraison.",
      },
      {
        label: "Complément d'adresse",
        value: this.state.client.adresse_Livraison_Principale.complement,
        accessor: "adresse_Livraison_Principale.complement",
      },
      {
        label: "Ville",
        value: this.state.client.adresse_Livraison_Principale.ville,
        accessor: "adresse_Livraison_Principale.ville",
        type: "text",
        required:
          this.state.client.adresse_Livraison_Principale?.pays?.nom_Fr ===
          "France",
        invalidText: "Vous devez entrer une ville pour l'adresse de livraison.",
        afterUpdateFunction: this.getListOfSimilarClients,
      },
      {
        label: "Code Postal",
        value: this.state.client.adresse_Livraison_Principale.cp,
        accessor: "adresse_Livraison_Principale.cp",
        required:
          this.state.client.adresse_Livraison_Principale?.pays?.nom_Fr ===
          "France",
        invalidText:
          "Vous devez entrer un code postal pour l'adresse de livraison.",
        afterUpdateFunction: this.getListOfSimilarClients,
      },
      {
        label: "Pays",
        value: this.state.client.adresse_Livraison_Principale.pays,
        accessor: "adresse_Livraison_Principale.pays",
        type: "selectSearch",
        service: PaysService.getAll,
        optionFieldToDisplay: "nom_Fr",
        valueFieldToDisplay: "nom_Fr",
        required: true,
        invalidText: "Vous devez sélectionner un pays de livraison.",
      },
      {
        label: "Email",
        accessor: "adresse_Livraison_Principale.email",
        type: "email",
        colSize: 6,
      },
      {
        label: "Téléphone",
        accessor: "adresse_Livraison_Principale.telephone",
        type: "text",
        colSize: 6,
      },
      {
        label: "Fax",
        accessor: "adresse_Livraison_Principale.fax",
        type: "text",
        colSize: 6,
      },
    ];
    const informationsAdresseFact = [
      {
        label: "Intitulé d'adresse",
        value: this.state.client.adresse_Facturation.intitule,
        accessor: "adresse_Facturation.intitule",
        invalidText:
          "Vous devez entrer un intitulé pour l'adresse de facturation.",
      },
      {
        label: "Voie",
        value: this.state.client.adresse_Facturation.voie,
        accessor: "adresse_Facturation.voie",
        invalidText:
          "Vous devez entrer une adresse pour l'adresse de facturation.",
      },
      {
        label: "Complément d'adresse",
        value: this.state.client.adresse_Facturation.complement,
        accessor: "adresse_Facturation.complement",
      },
      {
        label: "Ville",
        value: this.state.client.adresse_Facturation.ville,
        accessor: "adresse_Facturation.ville",
        type: "text",
        invalidText:
          "Vous devez entrer une ville pour l'adresse de facturation.",
      },
      {
        label: "Code Postal",
        value: this.state.client.adresse_Facturation.cp,
        accessor: "adresse_Facturation.cp",
        invalidText:
          "Vous devez entrer un code postal pour l'adresse de facturation.",
      },
      {
        label: "Pays",
        value: this.state.client.adresse_Facturation.pays,
        accessor: "adresse_Facturation.pays",
        handleBlur: this.handleBlurPays,
        type: "selectSearch",
        service: PaysService.getAll,
        optionFieldToDisplay: "nom_Fr",
        valueFieldToDisplay: "nom_Fr",
        invalidText: "Vous devez sélectionner un pays de facturation.",
      },

      {
        value: this.state.client.adresse_Facturation.email,
        label: "Email",
        accessor: "adresse_Facturation.email",
        type: "email",
        colSize: 6,
      },
      {
        value: this.state.client.adresse_Facturation.telephone,
        label: "Téléphone",
        accessor: "adresse_Facturation.telephone",
        type: "text",
        colSize: 6,
      },
      {
        value: this.state.client.adresse_Facturation.fax,
        label: "Fax",
        accessor: "adresse_Facturation.fax",
        type: "text",
        colSize: 6,
      },
      {
        label: "Site plateforme",
        value: this.state.client.site_Plateforme,
        accessor: "site_Plateforme",
      },
    ];
    const informationsComptables = [
      {
        label: "échéance",
        value: this.state.client.echeance,
        accessor: "echeance",
        type: "selectSearch",
        service: EcheanceService.getAll,
        optionFieldToDisplay: "designation",
        valueFieldToDisplay: "designation",
      },
      {
        label: "Mode de paiement",
        value: this.state.client.mode_Paiement,
        accessor: "mode_Paiement",
        type: "selectSearch",
        service: ModePaiementService.getAll,
        optionFieldToDisplay: "mode",
        valueFieldToDisplay: "mode",
      },
      {
        label: "TVA",
        value: this.state.client.tva,
        accessor: "tva",
        type: "selectSearch",
        service: TVAService.getAll,
        optionFieldToDisplay: "valeur",
        valueFieldToDisplay: "valeur",
      },
      {
        label: "Code Tarif",
        value: this.state.client.code_Tarif,
        accessor: "code_Tarif",
        type: "selectSearch",
        service: CodeTarifService.getAll,
        optionFieldToDisplay: "designation",
        valueFieldToDisplay: "designation",
      },
      {
        label: "Devise",
        value: this.state.client.devise,
        accessor: "devise",
        type: "selectSearch",
        service: DeviseService.getAll,
        optionFieldToDisplay: "designation",
        valueFieldToDisplay: "designation",
      },
      {
        label: "Facture",
        value: this.state.client.facture,
        accessor: "facture",
        type: "selectSearch",
        service: FactureService.getAll,
        optionFieldToDisplay: "designation",
        valueFieldToDisplay: "designation",
      },
      {
        label: "International",
        value: this.state.client.international,

        accessor: "international",
        functionAppliedToValue: (value) => {
          return value ? "Oui" : "Non";
        },
        type: "checkbox",
        colSize: 4,
      },
    ];

    const informationsAutres = [
      {
        label: "Compétence géotechnique",
        value: this.state.client.referent_Geotechnique,
        accessor: "referent_Geotechnique",
        type: "selectSearch",
        service: ReferentGeotechniqueService.getAll,
        optionFieldToDisplay: "designation",
        valueFieldToDisplay: "designation",
      },
      {
        label: "Type Ligne",
        value: this.state.client.type_Ligne,
        accessor: "type_Ligne",
        type: "selectSearch",
        service: TypeLigneService.getAll,
        optionFieldToDisplay: "type",
        valueFieldToDisplay: "type",
      },
      {
        value: this.state.client.langue,
        label: "Langue",
        accessor: "langue",
        type: "selectSearch",
        service: LangueService.getAll,
        optionFieldToDisplay: "langage",
        valueFieldToDisplay: "langage",
        required: true,
      },
    ];

    return this.state.loaded ? (
      <>
        <FormWithValidation onSubmit={this.handleSubmit}>
          <div className="mb-4">
            <Box
              header={
                <>
                  <div className="col-12 align-self-center py-2">
                    <div>Informations Personnelles</div>
                  </div>
                </>
              }
              body={
                <>
                  {informationsPersonnelles.map((field, fieldsIndex) => {
                    return field ? (
                      <div className="col-lg-4 pt-2" key={fieldsIndex}>
                        <Input
                          label={field.label} // Titre du champ
                          value={field.value} // Valeur du champ
                          accessor={field.accessor} // Accesseur
                          placeholder={field.placeholder}
                          debounce={field.debounce}
                          service={field.service}
                          handleChange={(acc, val) =>
                            this.handleChange(
                              acc,
                              val,
                              field.afterUpdateFunction
                            )
                          } // Fonction pour mettre à jour la valeur dans l'object via son accesseur
                          handleBlur={field.handleBlur} // Fonction pour mettre à jour la valeur dans l'object via son accesseur
                          type={field.type} // Type de l'input a utiliser
                          options={field.options} // options de l'input
                          optionFieldToDisplay={field.optionFieldToDisplay} // champ de l'objet a afficher
                          optionFieldToReturn={field.optionFieldToReturn} // champd de l'objet a utiliser en tant que valeur
                          valueFieldToDisplay={field.valueFieldToDisplay}
                          required={field.required}
                          disabled={field.disabled}
                          validText={field.validText}
                          invalidText={field.invalidText}
                        />
                      </div>
                    ) : null;
                  })}
                </>
              }
            />
          </div>

          <div className="mb-4">
            <Box
              header={
                <>
                  <div className="col-12 align-self-center py-2">
                    <div>Adresse de livraison</div>
                  </div>
                </>
              }
              body={
                <>
                  {informationsAdresseLiv.map((field, fieldsIndex) => {
                    return field ? (
                      <div className="col-lg-4 pt-2" key={fieldsIndex}>
                        <Input
                          label={field.label} // Titre du champ
                          value={field.value} // Valeur du champ
                          accessor={field.accessor} // Accesseur
                          placeholder={field.placeholder}
                          debounce={field.debounce}
                          service={field.service}
                          handleChange={(acc, val) =>
                            this.handleChange(
                              acc,
                              val,
                              field.afterUpdateFunction
                            )
                          } // Fonction pour mettre à jour la valeur dans l'object via son accesseur
                          handleBlur={field.handleBlur} // Fonction pour mettre à jour la valeur dans l'object via son accesseur
                          type={field.type} // Type de l'input a utiliser
                          options={field.options} // options de l'input
                          optionFieldToDisplay={field.optionFieldToDisplay} // champ de l'objet a afficher
                          optionFieldToReturn={field.optionFieldToReturn} // champd de l'objet a utiliser en tant que valeur
                          valueFieldToDisplay={field.valueFieldToDisplay}
                          required={field.required}
                          disabled={field.disabled}
                          validText={field.validText}
                          invalidText={field.invalidText}
                        />
                      </div>
                    ) : null;
                  })}
                </>
              }
            />
          </div>

          <div>
            <Box
              header={
                <div className="col-12 align-self-center py-2">
                  <div>Clients similaires</div>
                </div>
              }
              body={
                <table className="mt-1 text-uppercase table table-bordered table-striped table-sm">
                  <thead>
                    <tr>
                      <th>Qualification</th>
                      <th>Nom Client</th>
                      <th></th>
                      <th>Compte tiers</th>
                      <th>Type</th>
                      <th>Categorie</th>
                      <th>Ville</th>
                      <th>Code Postal</th>
                      <th>Pays</th>
                    </tr>
                  </thead>
                  <tbody className="text-muted">
                    {this.state.similarClients.map((client) => (
                      <tr key={client.id}>
                        <td>{client.etat}</td>
                        <td>{client.nom_Client}</td>
                        <td>
                          <ButtonIcon
                            icon={faExternalLinkAlt}
                            className="btn btn-secondary btn-sm text-light ms-1"
                            onClick={() =>
                              window.open("/clients/" + client.id, "_blank")
                            }
                          ></ButtonIcon>
                        </td>
                        <td>{client.compte_Tier}</td>
                        <td>{client.type_Client}</td>
                        <td>{client.categorie_Client}</td>
                        <td>{client.ville}</td>
                        <td>{client.cp}</td>
                        <td>{client.pays}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              }
            />
          </div>

          <div className="mb-4">
            <Box
              header={
                <>
                  <div className="col-12 align-self-center py-2">
                    <div>
                      {"Adresse de facturation "}
                      <ButtonIcon
                        icon={faArrowCircleDown}
                        className="btn btn-info btn-sm text-light ms-1"
                        onClick={this.handleCopyAdress}
                      />
                    </div>
                  </div>
                </>
              }
              body={
                <>
                  {informationsAdresseFact.map((field, fieldsIndex) => {
                    return field ? (
                      <div className="col-lg-4 pt-2" key={fieldsIndex}>
                        <Input
                          label={field.label} // Titre du champ
                          value={field.value} // Valeur du champ
                          accessor={field.accessor} // Accesseur
                          placeholder={field.placeholder}
                          debounce={field.debounce}
                          service={field.service}
                          handleChange={(acc, val) =>
                            this.handleChange(
                              acc,
                              val,
                              field.afterUpdateFunction
                            )
                          } // Fonction pour mettre à jour la valeur dans l'object via son accesseur
                          handleBlur={field.handleBlur} // Fonction pour mettre à jour la valeur dans l'object via son accesseur
                          type={field.type} // Type de l'input a utiliser
                          options={field.options} // options de l'input
                          optionFieldToDisplay={field.optionFieldToDisplay} // champ de l'objet a afficher
                          optionFieldToReturn={field.optionFieldToReturn} // champd de l'objet a utiliser en tant que valeur
                          valueFieldToDisplay={field.valueFieldToDisplay}
                          required={field.required}
                          disabled={field.disabled}
                          validText={field.validText}
                          invalidText={field.invalidText}
                        />
                      </div>
                    ) : null;
                  })}
                </>
              }
            />
          </div>
          <div className="mb-4">
            <Box
              header={
                <>
                  <div className="col-12 align-self-center py-2">
                    <div>Groupe</div>
                  </div>
                </>
              }
              body={
                <>
                  {informationsGroupe.map((field, fieldsIndex) => {
                    return field ? (
                      <div className="col-lg-4 pt-2" key={fieldsIndex}>
                        <Input
                          label={field.label} // Titre du champ
                          value={field.value} // Valeur du champ
                          accessor={field.accessor} // Accesseur
                          placeholder={field.placeholder}
                          debounce={field.debounce}
                          service={field.service}
                          handleChange={(acc, val) =>
                            this.handleChange(
                              acc,
                              val,
                              field.afterUpdateFunction
                            )
                          } // Fonction pour mettre à jour la valeur dans l'object via son accesseur
                          handleBlur={field.handleBlur} // Fonction pour mettre à jour la valeur dans l'object via son accesseur
                          type={field.type} // Type de l'input a utiliser
                          options={field.options} // options de l'input
                          optionFieldToDisplay={field.optionFieldToDisplay} // champ de l'objet a afficher
                          optionFieldToReturn={field.optionFieldToReturn} // champd de l'objet a utiliser en tant que valeur
                          valueFieldToDisplay={field.valueFieldToDisplay}
                          required={field.required}
                          disabled={field.disabled}
                          validText={field.validText}
                          invalidText={field.invalidText}
                        />
                      </div>
                    ) : null;
                  })}
                </>
              }
            />
          </div>

          <div className="mb-4">
            <Box
              header={
                <>
                  <div className="col-12 align-self-center py-2">
                    <div>Comptabilité</div>
                  </div>
                </>
              }
              body={
                <>
                  {informationsComptables.map((field, fieldsIndex) => {
                    return field ? (
                      <div className="col-lg-4 pt-2" key={fieldsIndex}>
                        <Input
                          label={field.label} // Titre du champ
                          value={field.value} // Valeur du champ
                          accessor={field.accessor} // Accesseur
                          placeholder={field.placeholder}
                          debounce={field.debounce}
                          service={field.service}
                          handleChange={(acc, val) =>
                            this.handleChange(
                              acc,
                              val,
                              field.afterUpdateFunction
                            )
                          } // Fonction pour mettre à jour la valeur dans l'object via son accesseur
                          handleBlur={field.handleBlur} // Fonction pour mettre à jour la valeur dans l'object via son accesseur
                          type={field.type} // Type de l'input a utiliser
                          options={field.options} // options de l'input
                          optionFieldToDisplay={field.optionFieldToDisplay} // champ de l'objet a afficher
                          optionFieldToReturn={field.optionFieldToReturn} // champd de l'objet a utiliser en tant que valeur
                          valueFieldToDisplay={field.valueFieldToDisplay}
                          required={field.required}
                          disabled={field.disabled}
                          validText={field.validText}
                          invalidText={field.invalidText}
                        />
                      </div>
                    ) : null;
                  })}
                </>
              }
            />
          </div>

          <div className="mb-4">
            <Box
              header={
                <>
                  <div className="col-12 align-self-center py-2">
                    <div>Conditions commerciales</div>
                  </div>
                </>
              }
              body={
                <>
                  {conditionsCommerciales.map((field, fieldsIndex) => {
                    return field ? (
                      <div className="col-lg-4 pt-2" key={fieldsIndex}>
                        <Input
                          label={field.label} // Titre du champ
                          value={field.value} // Valeur du champ
                          accessor={field.accessor} // Accesseur
                          placeholder={field.placeholder}
                          debounce={field.debounce}
                          service={field.service}
                          handleChange={(acc, val) =>
                            this.handleChange(
                              acc,
                              val,
                              field.afterUpdateFunction
                            )
                          } // Fonction pour mettre à jour la valeur dans l'object via son accesseur
                          handleBlur={field.handleBlur} // Fonction pour mettre à jour la valeur dans l'object via son accesseur
                          type={field.type} // Type de l'input a utiliser
                          options={field.options} // options de l'input
                          optionFieldToDisplay={field.optionFieldToDisplay} // champ de l'objet a afficher
                          optionFieldToReturn={field.optionFieldToReturn} // champd de l'objet a utiliser en tant que valeur
                          valueFieldToDisplay={field.valueFieldToDisplay}
                          required={field.required}
                          disabled={field.disabled}
                          validText={field.validText}
                          invalidText={field.invalidText}
                        />
                      </div>
                    ) : null;
                  })}
                </>
              }
            />
          </div>

          <div className="mb-4">
            <Box
              header={
                <>
                  <div className="col-12 align-self-center py-2">
                    <div>Divers</div>
                  </div>
                </>
              }
              body={
                <>
                  {informationsAutres.map((field, fieldsIndex) => {
                    return field ? (
                      <div className="col-lg-4 pt-2" key={fieldsIndex}>
                        <Input
                          label={field.label} // Titre du champ
                          value={field.value} // Valeur du champ
                          accessor={field.accessor} // Accesseur
                          placeholder={field.placeholder}
                          debounce={field.debounce}
                          service={field.service}
                          handleChange={(acc, val) =>
                            this.handleChange(
                              acc,
                              val,
                              field.afterUpdateFunction
                            )
                          } // Fonction pour mettre à jour la valeur dans l'object via son accesseur
                          handleBlur={field.handleBlur} // Fonction pour mettre à jour la valeur dans l'object via son accesseur
                          type={field.type} // Type de l'input a utiliser
                          options={field.options} // options de l'input
                          optionFieldToDisplay={field.optionFieldToDisplay} // champ de l'objet a afficher
                          optionFieldToReturn={field.optionFieldToReturn} // champd de l'objet a utiliser en tant que valeur
                          valueFieldToDisplay={field.valueFieldToDisplay}
                          required={field.required}
                          disabled={field.disabled}
                          validText={field.validText}
                          invalidText={field.invalidText}
                        />
                      </div>
                    ) : null;
                  })}
                </>
              }
            />
          </div>

          <div className="text-center">
            {this.state.similarClients?.length == 0 ? (
              <ButtonIcon
                className="btn btn-success mt-4 w-25"
                type="submit"
                disabled={this.state.isSubmitting}
                textOption="Créer le client"
              />
            ) : (
              <ButtonIcon
                className="btn btn-success mt-4 w-25"
                type="button"
                textOption="Créer le client"
                onClick={this.handleClickOnCreationButton}
              />
            )}
            {this.state.similarClients?.length != 0 &&
            this.state.alertOpened ? (
              <div>
                <div>
                  Un ou des clients similaires existent, êtes-vous sûr de
                  vouloir créer ce client ?
                </div>
                <ButtonIcon
                  className="btn btn-success mt-4 w-25"
                  textOption="Confirmer"
                  type="submit"
                  disabled={this.state.isSubmitting}
                />
              </div>
            ) : null}
          </div>
        </FormWithValidation>
      </>
    ) : (
      <FontAwesomeIcon
        icon={faSpinner}
        size="lg"
        className="fa-spin flex-auto"
      />
    );
  }

  render() {
    return (
      <>
        <Helmet>
          <meta charset="utf-8" />
          <title>Création d'un client</title>
        </Helmet>
        <AlertDialog
          title="Une erreur a été rencontrée"
          body={
            <>
              <div>La création du client a échouée.</div>
              <div>{this.state.errorText}</div>
            </>
          }
          valider="Ok"
          open={this.state.error}
          handleClose={this.handleClose}
        />
        <div className=" p-3 m-4" id="ajoutClient">
          <div className="d-flex overflow-auto justify-content-between py-1">
            <h2 className="nowrap pr-100 solwayFont">
              Création d'un client
              {this.props.location?.state?.contact != null
                ? " pour " +
                  this.props.location.state.contact.prenom_Contact +
                  " " +
                  this.props.location.state.contact.nom_Contact
                : null}
            </h2>
            <div>
              {this.props.history.length > 1 && (
                <button
                  className="btn btn-primary nowrap mx-1"
                  onClick={() => {
                    this.props.history.goBack();
                  }}
                >
                  Retour
                </button>
              )}
            </div>
          </div>
          <div className="row mt-4">{this.form()}</div>
        </div>
      </>
    );
  }
}

AjoutClient.propTypes = {
  history: PropTypes.object,
};

export { AjoutClient };
