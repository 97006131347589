import React from "react";

import { Input } from "_components/Input";

import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { ButtonIcon } from "_components";

function LigneFormationPersonnel(props) {
  return (
    <tr className="LigneFormationPersonnel">
      <td className="col1 background-color">
        <Input
          accessor={"formationPersonnel." + props.formation.id + ".date"}
          value={props.formation.date}
          type="date"
          placeholder=""
          handleChange={props.handleChange}
          showValidator={false}
          showClearButton={false}
          disabled={!props.editing}
        />
      </td>
      <td className="col2 background-color">
        <Input
          accessor={"formationPersonnel." + props.formation.id + ".intitule"}
          value={props.formation.intitule}
          type="text"
          placeholder=""
          handleChange={props.handleChange}
          showValidator={false}
          showClearButton={false}
          disabled={!props.editing}
        />
      </td>
      <td className="col3 background-color" style={{ width: "55px" }}>
        <ButtonIcon
          smallText=""
          icon={faTrash}
          iconSize="sm"
          onClick={() => {
            props?.deleteLineFormationPersonnel(props.formation.id);
          }}
          className="btn btn-danger deleteLineFormationPersonnel"
          style={!props.editing ? { display: "none" } : {}}
        />
      </td>
    </tr>
  );
}

export { LigneFormationPersonnel };
