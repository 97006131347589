import http from "http-common";

class ProduitInterneService {
  static cache = null;
  static loading = true;

  static getAll(params, refresh) {
    //Gestion de la valeur par défaut de la propriété "isActive" des paramètres
    let reqParams;
    if (!params) {
      // null
      reqParams = { isActive: true };
    } else if (typeof params === "object" && params.isActive === undefined) {
      // Objet ne contenant pas isActive
      reqParams = { ...params, isActive: true };
    } else if (typeof params === "object" && params.isActive !== undefined) {
      // Objet contenant isActive (null/true/false)
      reqParams = params;
    } else {
      reqParams = { isActive: true };
    }

    //On a des params (autres que isActive) ou isActive !=true => On ne veut pas les données stockées en cache.
    if (!(Object.keys(reqParams).length == 1) || reqParams.isActive !== true)
      return ProduitInterneService.get(reqParams);

    if (refresh == true || ProduitInterneService.loading) {
      ProduitInterneService.cache = ProduitInterneService.get({ isActive: true });
      ProduitInterneService.loading = false;
    }
    return ProduitInterneService.cache;
  }

  /**
   * Fonction permettant de récuperer tous les produitinternes avec une possibilité de filtre via params
   * @returns la liste de tous les produitinternes
   */
  static get(params) {
    return http.get("/produitinternes", { params });
  }

  /**
   * Fonction permettant de récuperer tous les produitinternes avec une possibilité de filtre via params
   * @returns la liste de tous les produitinternes
   */
  static getAllMat() {
    return http.get("/produitinternesMAT");
  }
}

export { ProduitInterneService };
