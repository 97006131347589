import React from "react";

import { Input } from "_components/Input";

import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { ButtonIcon } from "_components";

function LigneEnfantPersonnel(props) {
  return (
    <tr className="ligneEnfantPersonnel">
      <td className="col1 background-color">
        <Input
          accessor={"enfantPersonnel." + props.enfant.id + ".nom"}
          value={props.enfant.nom}
          type="text"
          placeholder=""
          handleChange={props.handleChange}
          showValidator={false}
          showClearButton={false}
          disabled={!props.editing}
        />
      </td>
      <td className="col2 background-color">
        <Input
          accessor={"enfantPersonnel." + props.enfant.id + ".prenom"}
          value={props.enfant.prenom}
          type="text"
          placeholder=""
          handleChange={props.handleChange}
          showValidator={false}
          showClearButton={false}
          disabled={!props.editing}
        />
      </td>
      <td className="col3 background-color">
        <Input
          accessor={"enfantPersonnel." + props.enfant.id + ".dateDeNaissance"}
          value={props.enfant.dateDeNaissance}
          type="date"
          placeholder=""
          handleChange={props.handleChange}
          showValidator={false}
          showClearButton={false}
          disabled={!props.editing}
        />
      </td>
      <td className="col4 background-color" style={{ width: "55px" }}>
        <ButtonIcon
          smallText=""
          icon={faTrash}
          iconSize="sm"
          onClick={() => {
            props?.deleteLineEnfantPersonnel(props.enfant.id);
          }}
          className="btn btn-danger deleteLineEnfantPersonnel"
          style={!props.editing ? { display: "none" } : {}}
        />
      </td>
    </tr>
  );
}

export { LigneEnfantPersonnel };
