import Fuse from "fuse.js";

export function containFilter(options) {
  return (value) => {
    if (!value.length) {
      return options;
    }
    console.log(value);
    return options?.filter((el) =>
      el.name?.toUpperCase().includes(value?.toUpperCase())
    );
  };
}

export function noFilter(options) {
  return () => options;
}

export function customFuseSearch(options, params) {
  const fuse = new Fuse(options, {
    keys: ["name", "groupName"],
    ...params,
  });

  return (value) => {
    if (!value.length) {
      // cas sans recherche
      return options;
    }
    let resultFuse = fuse.search(value);
    return resultFuse.map((e) => e.item);
  };
}

export function alphabeticSortFn(a, b) {
  return a.item.name.localeCompare(b.item.name);
}
