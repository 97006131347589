import http from "http-common";

const FiltrePersonnaliseService = class {
  

  /**
   * Fonction permettant de récuperer un Fonctions via son id
   * @param {*} id, id de du Fonctions à récupérer
   * @returns le Fonctions souhaité
   */
  static getByPageAndOid(page) {
    return http.get("/FiltrePersonnalise/"+page);
  }

  static post(filtre){
    return http.post("/FiltrePersonnalise",filtre);
  }
};

export { FiltrePersonnaliseService };
