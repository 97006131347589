import http from "http-common";

const DroitsService = class {
  static cache = null;
  static loading = true;
  /**
   * Fonction permettant de récuperer tous les droits
   * @returns la liste de tous les droits
   */
  static getAll(params, refresh) {
    if (refresh == true || DroitsService.loading) {
      DroitsService.cache = http.get("/Droits");
      DroitsService.loading = false;
    }
    return DroitsService.cache;
  }

  static update(droits) {
    return http.put("/droits", droits);
  }
};

export { DroitsService };