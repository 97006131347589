import http from "http-common";

const TacheService = class {
  /**
   * @returns les 4 listes de tâches : en cours, disponibles, non assignées, et terminées.
   */
  static getTaskBoardViaPost(params) {
    return http.post("/taches/board", params);
  }

  static postTache(tache) {
    return http.post("/taches", tache);
  }

  static putTache(tache) {
    return http.put("/taches", tache);
  }

  /**
   * @returns un fichier
   */
  static print(payload) {
    return http({
      url: "/taches/rapport",
      method: "PUT",
      responseType: "blob",
      data: payload,
    });
  }
};

export { TacheService };
