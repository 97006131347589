import React from "react";

import logoError from "_assets/pandaHorsConnexion.png";
import { Helmet } from "react-helmet";

class NotConnectedPage extends React.PureComponent {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <>
        <Helmet>
          <title>Non connecté</title>
        </Helmet>

        <div
          className="d-flex"
          style={{
            height: "fit-content",
            backgroundColor: "#fcfcfc",
            justifyContent: "center",
          }}
        >
          <img
            src={logoError}
            className="img-fluid d-block "
            style={{ width: "92.5vh", height: "92.5vh" }}
          ></img>
        </div>
      </>
    );
  }
}

export { NotConnectedPage };
