import http from "http-common";

// URL de l'API Nominatim
const baseURL = "https://nominatim.openstreetmap.org/search";

/**
 * Récupère les données sur des lieux en fonction de la recherche utilisée via l'API Nominatim
 * @param {string} query, chaine de caractères correspondant à la recherche du ou des lieux souhaités
 * @returns une liste des lieux correspondant à la recherche
 */
const getDataFromQuery = (query) => {
  return http.get(baseURL + "?format=json&q=" + query);
};

/**
 * Récupère les données sur des lieux en fonction de la recherche utilisée via l'API Nominatim
 * @param {string} street, numéro et rue recherchés
 * @param {string} city, ville de la position recherchée
 * @param {string} postalcode, code postal de la position recherchée
 * @param {string} country, pays de la position recherchée
 * @returns
 */
const getDataFromAddress = (
  street = "",
  city = "",
  postalcode = "",
  country = "France"
) => {
  return http.get(
    baseURL +
      "?format=json&street=" +
      street +
      "&city=" +
      city +
      "&country=" +
      country +
      "&postalcode=" +
      postalcode
  );
};

const NominatimService = {
  getDataFromQuery,
  getDataFromAddress,
};

export { NominatimService };
