import React from "react";
import { PropTypes } from "prop-types";
import { isEqual } from "lodash";

/**
 * Génère le composant Checkbox
 * @param {string} label, le label de la checkbox
 * @param {*} value, la valeur de la checkbox
 * @param {string} accessor, l'accesseur de l'objet lié à cette checkbox
 * @param {int} colSize, la taille de cet élément en colonne
 * @param {*} index, son index si besoin dans un .map
 * @param {function} handleChange, la fonction à appeler lors d'un changement
 * @returns le composant Checkbox
 */
class CheckBox extends React.Component {
  constructor(props) {
    super(props);
  }

  shouldComponentUpdate(prevProps, prevStates) {
    return !(isEqual(prevProps, this.props) && isEqual(prevStates, this.state));
  }

  render() {
    return (
      <>
        <div className="text-uppercase text-muted solwayFont">
          {this.props.label}
        </div>
        <input
          className="form-check-input"
          type="checkbox"
          name={this.props.accessor}
          value={this.props.value}
          checked={this.props.value}
          onChange={() => {
            this.props.handleChange(this.props.accessor, !this.props.value);
          }}
          disabled={this.props.disabled}
        ></input>
      </>
    );
  }
}

CheckBox.propTypes = {
  label: PropTypes.string,
  value: PropTypes.any,
  accessor: PropTypes.string,
  handleChange: PropTypes.func,
};

export { CheckBox };
