import React, { useState } from "react";
import Toast from "react-bootstrap/Toast";
import Col from "react-bootstrap/Col";
import "./Toast.css";

const Toasted = (props) => {
  const [show, setShow] = useState(true);

  let bg = "info";
  if (props.type == "success") {
    bg = "success";
  } else if (props.type == "failure") {
    bg = "danger";
  }
  return (
    <Col xs={80}>
      <Toast
        onClose={() => setShow(false)}
        show={show}
        delay={props.delay}
        autohide
      >
        <Toast.Header>
          <div
            className={"bg-" + bg + " rounded me-2"}
            style={{ height: "20px", width: "20px", marginRight: "10px" }}
          ></div>
          <strong className="me-auto">{props.header}</strong>
        </Toast.Header>
        <Toast.Body>{props.body}</Toast.Body>
      </Toast>
    </Col>
  );
};
export default Toasted;
