import http from "http-common";

const CodeTarifService = class {
  /**
   * Fonction permettant de récuperer tous les code tarifs avec une possibilité de filtre via params
   * @returns la liste de tous les code tarifs
   */
  static getAll() {
    return http.get("/codeTarifs");
  }

  /**
   * Fonction permettant de récuperer un code tarif via son id
   * @param {*} id, id de du code tarif à récupérer
   * @returns le code tarif souhaité
   */
  static getById(id) {
    return http.get("/codeTarifs/" + id);
  }

  static getByValue(value) {
    return http.get("/codeTarifs/get/" + value);
  }
};

export { CodeTarifService };
