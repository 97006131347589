// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.pageClient {
  display: flex;
  flex-flow: column;
  flex: auto;
}


.pageClient thead{
  border-bottom-color: #ddd !important;
}

.fill-parent {
  flex-grow: 1;
}

.scrollbar {
  position: relative;
  height: 200px;
  overflow: auto;
}

.form-select-page {
  width: auto;
  display: inline-block;
}

.noflex {
  flex: none;
}

.page {
  inline-size: -webkit-max-content;
  inline-size: max-content;
}

.nowrap {
  white-space: nowrap;
}

.orderby {
  float: right;
  cursor: pointer;
}

.pr-100 {
  padding-right: 100px;
}

.overflow-x-auto {
  overflow-x: scroll;
}

.mw-100 {
  min-width: 200px;
}

h2 {
  margin-bottom: 0px;
}

.hovereffect:hover {
  background-color: lightgray;
}

#bg {
  --bs-table-accent-bg: none;
  background-color: white;
}

.hide-border-right {
  border-right-style: hidden;
}

.rechercheGlobale {
  min-width: 200px;
}

.flex-auto {
  flex: auto;
}

#rechercheGlobale {
  width: 300px;
}

.hoverPointer:hover {
  cursor: pointer;
  font-weight: bold;
}

.fitContent {
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
}

.exportToDetails {
  width: 25px;
  height: 25px;
}

`, "",{"version":3,"sources":["webpack://./src/_components/ReactTable/ReactTableServerSide.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,iBAAiB;EACjB,UAAU;AACZ;;;AAGA;EACE,oCAAoC;AACtC;;AAEA;EACE,YAAY;AACd;;AAEA;EACE,kBAAkB;EAClB,aAAa;EACb,cAAc;AAChB;;AAEA;EACE,WAAW;EACX,qBAAqB;AACvB;;AAEA;EACE,UAAU;AACZ;;AAEA;EACE,gCAAwB;EAAxB,wBAAwB;AAC1B;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE,YAAY;EACZ,eAAe;AACjB;;AAEA;EACE,oBAAoB;AACtB;;AAEA;EACE,kBAAkB;AACpB;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,kBAAkB;AACpB;;AAEA;EACE,2BAA2B;AAC7B;;AAEA;EACE,0BAA0B;EAC1B,uBAAuB;AACzB;;AAEA;EACE,0BAA0B;AAC5B;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,UAAU;AACZ;;AAEA;EACE,YAAY;AACd;;AAEA;EACE,eAAe;EACf,iBAAiB;AACnB;;AAEA;EACE,2BAAmB;EAAnB,wBAAmB;EAAnB,mBAAmB;AACrB;;AAEA;EACE,WAAW;EACX,YAAY;AACd","sourcesContent":[".pageClient {\n  display: flex;\n  flex-flow: column;\n  flex: auto;\n}\n\n\n.pageClient thead{\n  border-bottom-color: #ddd !important;\n}\n\n.fill-parent {\n  flex-grow: 1;\n}\n\n.scrollbar {\n  position: relative;\n  height: 200px;\n  overflow: auto;\n}\n\n.form-select-page {\n  width: auto;\n  display: inline-block;\n}\n\n.noflex {\n  flex: none;\n}\n\n.page {\n  inline-size: max-content;\n}\n\n.nowrap {\n  white-space: nowrap;\n}\n\n.orderby {\n  float: right;\n  cursor: pointer;\n}\n\n.pr-100 {\n  padding-right: 100px;\n}\n\n.overflow-x-auto {\n  overflow-x: scroll;\n}\n\n.mw-100 {\n  min-width: 200px;\n}\n\nh2 {\n  margin-bottom: 0px;\n}\n\n.hovereffect:hover {\n  background-color: lightgray;\n}\n\n#bg {\n  --bs-table-accent-bg: none;\n  background-color: white;\n}\n\n.hide-border-right {\n  border-right-style: hidden;\n}\n\n.rechercheGlobale {\n  min-width: 200px;\n}\n\n.flex-auto {\n  flex: auto;\n}\n\n#rechercheGlobale {\n  width: 300px;\n}\n\n.hoverPointer:hover {\n  cursor: pointer;\n  font-weight: bold;\n}\n\n.fitContent {\n  height: fit-content;\n}\n\n.exportToDetails {\n  width: 25px;\n  height: 25px;\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
