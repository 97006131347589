import React from "react";
import { makeEmailClickable } from "_utils";
import { PropTypes } from "prop-types";

/**
 * Génère un champ d'information en ligne contenant :
 * @param {string} label, le label de la valeur
 * @param {*} value, la valeur
 * @param {int} colSize, la taille prise par la cet élément en colonne
 * @returns un champ d'information avec un label et une valeur
 */
function DataFieldRow(props) {
  return (
    <div className={"row col-" + props.colSize + " pt-2"}>
      <div className="col-3 text-uppercase text-muted">{props.label}</div>
      <div className="col-9" style={{ overflowWrap: "break-word" }}>
        {props.label.toLowerCase() === "email"
          ? makeEmailClickable(props.value)
          : props.value}
      </div>
    </div>
  );
}

DataFieldRow.propTypes = {
  label: PropTypes.string,
  value: PropTypes.any,
  colSize: PropTypes.any,
};

export { DataFieldRow };
