import React from "react";
import { App } from "App";

import { Box, WarningBar } from "_components";
import { ToLocaleDateString } from "_utils";
class DocumentsFDS extends React.PureComponent {
  constructor(props) {
    super(props);
  }

  render() {
    const right = App.RightsGuard?.current?.hasRight(
      "Fiche de suivi",
      "Documents"
    );
    if (right == "RW" || right == "RO") {
      var documentFDS = this.props.ficheDeSuivi.documentAssocies;
      // var devisArr = document.filter((e) => e.refDocParent == null);
      // var bcArr = document.filter((e) => devisArr.find(s.aR_Ref == e.refDocParent));
      // var blArr = document.filter((e) => bc.find(s.aR_Ref == e.refDocParent));

      return (
        <>
          <Box
            noPaddingBottom={true}
            header={
              <div className="col-12 align-self-center py-2">
                <div>Devis/Commandes/Bon de Livraison</div>
              </div>
            }
            body={
              <table className="text-uppercase table table-sm table-striped table-bordered">
                <thead
                  className={
                    "sticky-top" +
                    (documentFDS?.length == 0 ? " theadBorderRadius" : "")
                  }
                >
                  <tr>
                    <th>Numéro</th>
                    <th>Nom</th>
                    <th>Date de création</th>
                    <th>Statut</th>
                  </tr>
                </thead>
                <tbody>
                  {documentFDS.map((d) => {
                    return (
                      <tr key={d.id}>
                        <td>
                          {d.fiche != null ? (
                            <a
                              href={"/" + d.fiche + "/" + d.id}
                              target="_blank"
                            >
                              {d.reference}
                            </a>
                          ) : (
                            d.reference
                          )}
                        </td>
                        <td>{d.name}</td>
                        <td>
                          {d.dateCreation
                            ? ToLocaleDateString(d.dateCreation)
                            : null}
                        </td>
                        <td>{d.statut}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            }
          />
        </>
      );
    } else {
      return (
        <>
          <WarningBar
            active={false}
            content={"Vous n'avez pas le droit de voir cette page"}
          />
        </>
      );
    }
  }
}

export { DocumentsFDS };
