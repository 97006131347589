import React from "react";
import { ToLocaleDateString } from "_utils";

class LigneArticleStock extends React.PureComponent {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <>
        <tr>
          <td>{this.props.articleStock.depot.dE_Intitule}</td>
          <td>{this.props.articleStock.depot.dE_Pays}</td>
          <td>{this.props.articleStock.aS_QteSto}</td>
          <td>{this.props.articleStock.aS_QteMaxi}</td>
          <td>{this.props.articleStock.aS_QteMini}</td>
          <td>{ToLocaleDateString(this.props.articleStock.cbModification)}</td>
        </tr>
      </>
    );
  }
}

export { LigneArticleStock };
