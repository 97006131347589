import React from "react";
import {
  DataFieldColumn,
  ButtonIcon,
  Box,
  AlertDialog,
  FormWithValidation,
} from "_components";
import { Input } from "_components/Input";
import { PropTypes } from "prop-types";
import {
  faCheck,
  faTimes,
  faPen,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import { App } from "App";

/**
 * Génére un composant boite avec des champs et une possibilité de les éditer via des inputs
 */
class BoxInformationsInline extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      edition: false,
      error: false,
      errorText: "",
      saveState: {},
    };

    this.handleClickEdition = this.handleClickEdition.bind(this);
    this.handleUpdate = this.handleUpdate.bind(this);
    this.handleUndo = this.handleUndo.bind(this);
    this.handleError = this.handleError.bind(this);
    this.getValue = this.getValue.bind(this);
  }

  componentDidMount() {
    let x = this.props.setForceEdition;
    if (x)
      this.setState((prevState) => ({
        ...prevState,
        edition: this.props.setForceEdition,
        saveState: { ...this.props.state },
      }));
  }

  handleError() {
    this.setState({ error: false });
  }

  handleClickEdition() {
    this.setState({ saveState: { ...this.props.state } });
    this.setState({ edition: true });
    this.props.handleEditing(true);
  }

  handleUpdate(event) {
    event.preventDefault();
    if (this.props.handleUpdate) {
      this.props
        .handleUpdate()
        .then(() => {
          this.setState({ edition: false });
          this.props.handleEditing(false);
          if (this.props?.state?.date_Modif) {
            this.props.handleChange("date_Modif", new Date().toISOString());
          }

          App.Toaster?.current?.createToast({
            body: this.props.bodyToast ?? "Mis à jour",
            header: (this.props.headerToast ?? " ") + " Succès",
            type: "success",
          });
        })
        .catch((error) => {
          console.log(error.response);

          // let errors = error.response.data.errors;
          App.Toaster?.current?.createToast({
            body: this.props.bodyToast ?? "Mis à jour",
            header: (this.props.headerToast ?? "") + " Echec",
            type: "failure",
          });
          this.setState({
            error: true,
            errorText: (
              <>
                <br />
                <div>L&apos;enregistrement a échoué.</div>
                <br />
                <div>Code : {error?.response?.data?.code ?? "UNKOWN"}</div>
                <div>Cause: {error?.response?.data?.cause ?? "UNKOWN"}</div>
              </>
            ),
          });
        });
    } else {
      this.props.service
        .put(this.props.state)
        .then(() => {
          this.setState({ edition: false });
          this.props.handleEditing(false);
          if (this.props?.state?.date_Modif) {
            this.props.handleChange("date_Modif", new Date().toISOString());
          }

          App.Toaster?.current?.createToast({
            body: this.props.bodyToast ?? "Mis à jour",
            header: (this.props.headerToast ?? " ") + " Succès",
            type: "success",
          });
        })
        .catch((error) => {
          console.log(
            error?.response?.data?.code,
            error?.response?.data?.cause
          );

          // let errors = error.response.data.errors;
          App.Toaster?.current?.createToast({
            body: this.props.bodyToast ?? "Mis à jour",
            header: (this.props.headerToast ?? "") + " Echec",
            type: "failure",
          });
          this.setState({
            error: true,
            errorText: (
              <>
                <br />
                <div>L&apos;enregistrement a échoué.</div>
                <br />
                <div>Code : {error?.response?.data?.code ?? "UNKOWN"}</div>
                <div>Cause: {error?.response?.data?.cause ?? "UNKOWN"}</div>
              </>
            ),
          });
        });
    }
  }

  handleUndo() {
    this.props.handleChange(
      this.props.stateFieldNameToUpdate,
      this.state.saveState
    );
    this.setState({ edition: false });
    this.props.handleEditing(false);
  }

  getValue(accessor) {
    if (accessor.includes(".")) {
      var accessorSplit = accessor.split(".");
      // Nested property (separated by a '.')
      return this.props.state[accessorSplit[0]]?.[accessorSplit[1]];
    } else {
      // Shallow property
      return this.props.state[accessor];
    }
  }

  render() {
    return (
      <>
        <AlertDialog
          title="Erreur"
          body={
            <>
              <div>Une erreur a été rencontrée lors de l&apos;opération.</div>
              <div>{this.state.errorText}</div>
            </>
          }
          valider="Ok"
          open={this.state.error}
          handleClose={this.handleError}
        />
        <FormWithValidation
          className="d-flex flex-grow-1"
          onSubmit={this.handleUpdate}
        >
          <Box
            header={
              <>
                <div className="col-8 align-self-center py-2">
                  <div>{this.props.title}</div>
                </div>
                {!this.props.NoEdition ? (
                  <div className="col-4 text-end align-self-center">
                    <div role="group" className=" me-1">
                      {this.props.boutonSupplementaire != null
                        ? this.props.boutonSupplementaire
                        : null}
                      <ButtonIcon
                        icon={faCheck}
                        className="btn btn-success btn-sm text-light ms-1"
                        style={
                          !this.state.edition
                            ? { display: "none" }
                            : { width: "32px" }
                        }
                        type="submit"
                        tooltip="Valider les modifications"
                      ></ButtonIcon>
                      <ButtonIcon
                        icon={faTimes}
                        className="btn btn-danger btn-sm text-light ms-1"
                        onClick={this.handleUndo}
                        style={
                          !this.state.edition
                            ? { display: "none" }
                            : { width: "32px" }
                        }
                        tooltip="Annuler toutes modifications"
                      ></ButtonIcon>
                      <ButtonIcon
                        icon={faPen}
                        className="btn btn-secondary btn-sm text-light ms-1"
                        onClick={this.handleClickEdition}
                        style={
                          this.state.edition || this.props.editing
                            ? { display: "none" }
                            : null
                        }
                        tooltip="Editer"
                      ></ButtonIcon>
                      <ButtonIcon
                        icon={faTrash}
                        className="btn btn-danger btn-sm text-light ms-1"
                        onClick={this.props.handleDelete}
                        style={
                          this.props.handleDelete && this.state.edition
                            ? null
                            : { display: "none" }
                        }
                        tooltip="Supprimer"
                      ></ButtonIcon>
                    </div>
                  </div>
                ) : null}
              </>
            }
            body={
              <>
                {this.props.datas.map((data, index) => {
                  return this.state.edition && data.type ? (
                    // Si on passe en mode edition
                    <div
                      className={
                        "col-lg-" + data.colSize + " pt-2 align-self-end"
                      }
                      key={index}
                    >
                      <Input
                        label={data.label} // Titre du champ
                        value={
                          data.value ??
                          (data.accessor
                            ? this.props.state[data.accessor]
                            : null)
                        } // Valeur du/des champ
                        accessor={data.accessor} // Accesseur
                        type={data.type} // Type de l'input par défaut -> text
                        placeholder={data.placeholder}
                        service={data.service}
                        debounce={data.debounce}
                        options={data.options}
                        valueFieldToDisplay={data.valueFieldToDisplay}
                        optionFieldToDisplay={data.optionFieldToDisplay}
                        optionFieldToReturn={data.optionFieldToReturn}
                        handleChange={(acc, val) =>
                          this.props.handleChange(
                            acc,
                            val,
                            data.afterUpdateFunction
                          )
                        } // Fonction pour mettre à jour la valeur dans l'object via son accesseur
                        handleBlur={data.handleBlur}
                        required={data.required}
                        requiredIntitule={data.requiredIntitule}
                        disabled={data.disabled}
                        showValidator={data.showValidator}
                        showClearButton={data.showClearButton}
                        setForceUpdateFunction={data.setForceUpdateFunction}
                        customFirstOption={data.customFirstOption}
                        customFilter={data.customFilter}
                        colSizeInnerFields={data.colSizeInnerFields}
                        numberOfDecimals={data.numberOfDecimals}
                        intituleLabel={data.intituleLabel}
                        pattern={data.pattern}
                        tooltip={data.tooltip}
                        afficherIntitule={data.afficherIntitule}
                      />
                    </div>
                  ) : (
                    // Si l'on est pas en mode edition
                    <div
                      key={index}
                      className={"col-lg-" + data.colSize + " pt-2"}
                    >
                      <DataFieldColumn
                        label={data.label}
                        type={data.type}
                        value={this.getValue(data.accessor)}
                        functionAppliedToValue={data.functionAppliedToValue}
                      />
                    </div>
                  );
                })}
              </>
            }
            boxSize={this.props.boxSize}
          />
        </FormWithValidation>
      </>
    );
  }
}

BoxInformationsInline.propTypes = {
  title: PropTypes.any,
  datas: PropTypes.array,
  state: PropTypes.any,
  stateFieldNameToUpdate: PropTypes.string,
  service: PropTypes.any,
  handleChange: PropTypes.func,
  handleBlur: PropTypes.func,
  handleDelete: PropTypes.func,
  handleEditing: PropTypes.func,
  colSize: PropTypes.string,
  boxSize: PropTypes.string,
  NoEdition: PropTypes.bool,
  editing: PropTypes.bool,
  headerToast: PropTypes.string,
};

export { BoxInformationsInline };
