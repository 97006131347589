import { LogLevel } from "@azure/msal-browser";

/**
 * Configuration object to be passed to MSAL instance on creation.
 */
export const msalConfig = {
  auth: {
    clientId: process.env.REACT_APP_AZURE_CLIENT_ID,
    authority:
      "https://login.microsoftonline.com/6006c8a1-2d7a-4dec-a5b2-ecf6333c182c",
    redirectUri: "/",
    postLogoutRedirectUri: "/",
    navigateToLoginRequestUrl: false,
  },
  cache: {
    cacheLocation: "localStorage",
    storeAuthStateInCookie: false,
  },
  system: {
    loggerOptions: {
      loggerCallback: (level, message, containsPii) => {
        if (containsPii) {
          return;
        }
        switch (level) {
          case LogLevel.Error:
            console.error(message);
            return;
          case LogLevel.Info:
            //console.info(message);
            return;
          case LogLevel.Verbose:
            //console.debug(message);
            return;
          case LogLevel.Warning:
            console.warn(message);
            return;
        }
      },
    },
  },
};

/**
 *   scope à accepter à la première connexion
 */
export const loginRequest = {
  scopes: ["User.ReadBasic.All", "Group.Read.All"],
};

export const protectedResources = {
  apiBamboo: {
    endpoint: process.env.REACT_APP_AZURE_ENDPOINT_API,
    scopes: [
      "api://b18e429e-bdb5-4e39-bc28-da6c9d9ffc5b/access_as_user",
      "offline_access",
    ], // e.g. api://xxxxxx/access_as_user
  },
};

export const graphConfig = {
  graphMeEndpoint: "https://graph.microsoft.com/v1.0/users/",
};
