import React from "react";
import { AlertDialog, Box, ButtonIcon, FormWithValidation } from "_components";
import { Input } from "_components/Input";
import { PropTypes } from "prop-types";
import { App } from "App";

import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { WarningBar } from "_components";

import {
  DevisService,
  ClientService,
  ContactService,
  PersonnelService,
  ProduitInterneService,
  MissionService,
  TypeOuvrageService,
  ReussiteService,
  FamilleAffaireService,
} from "_services";
import { Helmet } from "react-helmet";
import produce from "immer";

class AjoutDevis extends React.PureComponent {
  constructor(props) {
    super(props);
    this.isClientLoaded =
      this.props.history.location.state &&
      this.props.history.location.state.client;

    this.state = {
      loaded: false,
      errorText: null,
      error: false,
      isSubmitting: false,
      callbackForceUpdateContactMaitreDOeuvre: null,
      callbackForceUpdateContactMaitreDOuvrage: null,
      callbackForceUpdateContactEntreprise: null,
      devis: {
        id: 0,
        nom_Devis: null,
        consultation: null,
        descriptif: null,
        client: null,
        entreprise: null,
        charge_Affaire: null,
        produit_Interne: null,
        type_Mission: null,
        type_Ouvrage: null,
        international: false,
        lld: false,
        maitre_Oeuvre: null,
        maitre_Ouvrage: null,
        contact_Maitre_Oeuvre: null,
        contact_Maitre_Ouvrage: null,
        contact_Entreprise: null,
        contact_Client: null,
        date_Envoie_Devis: null,
        date_Commande_Previsionnelle: null,
        date_Commande_Previsionnelle2: null,
        delaiRealisation: null,
        reussite: null,
        adresseChantier: {
          intitule: null,
          complement: null,
          voie: null,
          ville: null,
          pays: null,
        },
        dateVisiteChantier: null,
        superficie: null,
        niveau: null,
        sousSol: false,
        siteFacileDacces: false,
        descenteCharge: false,
        dateDICT: null,
        dict: false,
        isParticulier: false,
        nomParticulier: null,
        mailParticulier: null,
        telephoneParticulier: null,
        adresseParticulier: {
          intitule: null,
          complement: null,
          voie: null,
          ville: null,
          pays: null,
        },
        ficheDeSuivi: { id: null },
        user_Create: null,
        date_Create: null,
        user_Modif: null,
        date_Modif: null,
      },
    };

    this.handleStateChange = this.handleStateChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.form = this.form.bind(this);

    this.setForceUpdateContactMaitreDOeuvre =
      this.setForceUpdateContactMaitreDOeuvre.bind(this);
    this.setForceUpdateContactMaitreDOuvrage =
      this.setForceUpdateContactMaitreDOuvrage.bind(this);
    this.setForceUpdateContactEntreprise =
      this.setForceUpdateContactEntreprise.bind(this);
    this.setForceUpdateContactClient =
      this.setForceUpdateContactClient.bind(this);
  }

  setForceUpdateContactMaitreDOeuvre(callbackFunction) {
    this.setState({
      callbackForceUpdateContactMaitreDOeuvre: callbackFunction,
    });
  }

  setForceUpdateContactMaitreDOuvrage(callbackFunction) {
    this.setState({
      callbackForceUpdateContactMaitreDOuvrage: callbackFunction,
    });
  }

  setForceUpdateContactEntreprise(callbackFunction) {
    this.setState({ callbackForceUpdateContactEntreprise: callbackFunction });
  }

  setForceUpdateContactClient(callbackFunction) {
    this.setState({ callbackForceUpdateContactClient: callbackFunction });
  }

  async componentDidMount() {
    var geranT = App.RightsGuard?.current?.getPersonnelConnecte();

    this.setState((state) => ({
      loaded: true,
      devis: {
        ...state.devis,
        client: this.props.history.location.state
          ? this.props.history.location.state.devis.client
          : null,
        ficheDeSuivi: this.props.history.location.state
          ? this.props.history.location.state.devis.ficheDeSuivi
          : null,
        contact_Client: this.props.history.location.state
          ? this.props.history.location.state.devis.contact_Client
          : null,
        charge_Affaire: this.props.history.location.state
          ? this.props.history.location.state.devis.charge_Affaire
          : geranT,
      },
    }));
  }

  handleStateChange(
    accessor,
    value = "__ACCES_DIRECT__",
    afterUpdateFunction = null
  ) {
    if (value != "__ACCES_DIRECT__") {
      //Update
      if (accessor.includes(".")) {
        // Nested property (separated by a '.')
        var accessorSplit = accessor.split(".");

        this.setState(
          (prevState) => ({
            devis: {
              ...prevState.devis,
              [accessorSplit[0]]: {
                ...prevState.devis[accessorSplit[0]],
                [accessorSplit[1]]: value,
              },
            },
          }),
          afterUpdateFunction
        );
      } else {
        // Shallow property
        this.setState(
          (prevState) => ({
            devis: {
              ...prevState.devis,
              [accessor]: value,
            },
          }),
          afterUpdateFunction
        );
      }
    } else {
      this.setState(
        { devis: { ...this.state.devis, ...accessor } },
        afterUpdateFunction
      );
    }
  }

  handleSubmit(event) {
    event.preventDefault();
    this.setState({ isSubmitting: true });

    DevisService.post(this.state.devis)
      .then((retour) => {
        this.props.history.push("/devis/" + retour.data.id);
      })
      .catch((error) => {
        console.log(error);
        this.setState({
          error: true,
          isSubmitting: false,
          errorText: error.message,
        });
      });
  }

  functionAppliedToValueClient(value) {
    return (
      <>
        <div className="d-flex justify-content-start align-items-center">
          {value != null ? value.nom_Client : "-"}
          <ButtonIcon
            icon={faExternalLinkAlt}
            className="btn btn-secondary btn-sm text-light ms-1"
            onClick={() => window.open("/clients/" + value.id, "_blank")}
            style={value != null ? { width: "32px" } : { display: "none" }}
          ></ButtonIcon>
        </div>
      </>
    );
  }

  form() {
    const informationsGeneral = [
      {
        value: this.state.devis.nom_Devis,
        label: "Nom du devis",
        accessor: "nom_Devis",
        type: "text",
        colSize: 12,
        required: true,
      },
      {
        value: this.state.devis.descriptif,
        label: "Descriptif",
        accessor: "descriptif",
        type: "textArea",
        colSize: 12,
      },
    ];

    const informationsClient = [
      {
        value: this.state.devis.client,
        label: "Client",
        accessor: "client",
        type: "selectSearch",
        service: ClientService.getForSelectSearch,
        optionFieldToDisplay: "nom_Client",
        valueFieldToDisplay: "nom_Client",
        required: true,
        functionAppliedToValue: (value) => (
          <>
            <div className="d-flex justify-content-start align-items-center">
              {value && value.nom_Client ? value.nom_Client : "-"}
              <ButtonIcon
                icon={faExternalLinkAlt}
                className="btn btn-secondary btn-sm text-light ms-1"
                onClick={() => window.open("/clients/" + value.id, "_blank")}
                style={{ width: "32px" }}
              ></ButtonIcon>
            </div>
          </>
        ),
        afterUpdateFunction: () => {
          this.setState(
            produce((prevState) => {
              prevState.devis.contact_Client = null;
            }),
            this.state.callbackForceUpdateContactClient
          );
        },
        colSize: 6,
      },
      {
        value: this.state.devis.contact_Client,
        label: "Contact Client",
        accessor: "contact_Client",
        type: "selectSearch",
        service: () =>
          ContactService.getByIdClient(this.state.devis.client?.id),
        optionFieldToDisplay: ["nom_Contact", "prenom_Contact", "email"],
        valueFieldToDisplay: ["nom_Contact", "prenom_Contact", "email"],
        setForceUpdateFunction: this.setForceUpdateContactClient,
        colSize: 6,
      },
      {
        value: this.state.devis.entreprise,
        label: "Entreprise",
        accessor: "entreprise",
        type: "selectSearch",
        service: ClientService.getForSelectSearch,
        optionFieldToDisplay: "nom_Client",
        valueFieldToDisplay: "nom_Client",
        functionAppliedToValue: (value) => (
          <>
            <div className="d-flex justify-content-start align-items-center">
              {value && value.nom_Client ? value.nom_Client : "-"}
              <ButtonIcon
                icon={faExternalLinkAlt}
                className="btn btn-secondary btn-sm text-light ms-1"
                onClick={() => window.open("/clients/" + value.id, "_blank")}
                style={{ width: "32px" }}
              ></ButtonIcon>
            </div>
          </>
        ),
        afterUpdateFunction: () => {
          this.setState(
            produce((prevState) => {
              prevState.devis.contact_Entreprise = null;
            }),
            this.state.callbackForceUpdateContactEntreprise
          );
        },
        colSize: 6,
      },
      {
        value: this.state.devis.contact_Entreprise,
        label: "Contact Entreprise",
        accessor: "contact_Entreprise",
        type: "selectSearch",
        service: () =>
          ContactService.getByIdClient(this.state.devis.entreprise?.id),
        optionFieldToDisplay: ["nom_Contact", "prenom_Contact", "email"],
        valueFieldToDisplay: ["nom_Contact", "prenom_Contact", "email"],
        setForceUpdateFunction: this.setForceUpdateContactEntreprise,
        colSize: 6,
      },
      {
        value: this.state.devis.charge_Affaire,
        label: "Chargé d'affaire",
        accessor: "charge_Affaire",
        functionAppliedToValue: (value) => {
          return value ? value.nom + " " + value.prenom : "-";
        },
        type: "selectSearch",
        service: PersonnelService.getForSelectSearch,
        optionFieldToDisplay: ["nom", "prenom"],
        valueFieldToDisplay: ["nom", "prenom"],
        colSize: 6,
      },
      {
        value: this.state.devis.famille_Affaire,
        label: "Famille d'affaires",
        accessor: "famille_Affaire",
        required: this.state.devis.produit_Interne?.isPresta ?? false,
        type: "selectSearch",
        service: FamilleAffaireService.getAll,
        optionFieldToDisplay: "designation",
        valueFieldToDisplay: "designation",
        colSize: 6,
      },
      {
        value: this.state.devis.produit_Interne,
        label: "Produit interne",
        accessor: "produit_Interne",
        required: true,
        type: "selectSearch",
        service: ProduitInterneService.getAll,
        optionFieldToDisplay: "designation",
        valueFieldToDisplay: "designation",
        colSize: 6,
      },
      {
        value: this.state.devis.type_Mission,
        label: "Type mission",
        accessor: "type_Mission",
        functionAppliedToValue: (value) => {
          return value ? value.designation : null;
        },
        type: "selectSearch",
        service: MissionService.getAll,
        optionFieldToDisplay: "designation",
        valueFieldToDisplay: "designation",
        colSize: 6,
        required: this.state.devis.produit_Interne?.designation == "ETU",
      },
      {
        value: this.state.devis.type_Ouvrage,
        label: "Type d'ouvrage",
        accessor: "type_ouvrage",
        type: "selectSearch",
        service: TypeOuvrageService.getAll,
        functionAppliedToValue: (value) => {
          return value ? value.signe + " " + value.type : null;
        },
        optionFieldToDisplay: ["signe", "type"],
        valueFieldToDisplay: ["signe", "type"],
        colSize: 6,
      },
      // {
      //   value: this.state.devis.international,
      //   label: "International",
      //   accessor: "international",
      //   type: "checkbox",
      //   functionAppliedToValue: (value) => {
      //     return value ? "Oui" : "Non";
      //   },
      //   colSize: 6,
      // },
    ];
    const informationsCommande = [
      {
        value: this.state.devis.date_Envoie_Devis,
        label: "Date envoi devis",
        accessor: "date_Envoie_Devis",
        type: "date",
        colSize: 6,
      },
      {
        value: this.state.devis.date_Commande_Previsionnelle,
        label: "Date cde prévisionnelle",
        accessor: "date_Commande_Previsionnelle",
        type: "date",
        colSize: 6,
        required: true,
      },
      {
        value: this.state.devis.date_Commande_Previsionnelle2,
        label: "Date cde prévisionnelle 2",
        accessor: "date_Commande_Previsionnelle2",
        type: "date",
        colSize: 6,
      },
      {
        value: this.state.devis.delaiRealisation,
        label: "Délai de réalisation (en semaine)",
        accessor: "delaiRealisation",
        type: "decimal",
        colSize: 6,
        handleBlur: this.handleStateChange,
      },
      {
        value: this.state.devis.reussite,
        label: "% réussite",
        accessor: "reussite",
        type: "selectSearch",
        optionFieldToDisplay: "valeur",
        valueFieldToDisplay: "valeur",
        service: ReussiteService.getAll,
        functionAppliedToValue: (value) => {
          return value ? value.valeur : null;
        },
        colSize: 6,
        required: true,
      },
    ];

    const informationsChantier = [
      {
        value: this.state.devis.maitre_Oeuvre,
        label: "Maitre d'Oeuvre",
        accessor: "maitre_Oeuvre",
        type: "selectSearch",
        service: ClientService.getForSelectSearch,
        optionFieldToDisplay: "nom_Client",
        valueFieldToDisplay: "nom_Client",
        specialRenderValue: this.specialRenderValueClient,
        afterUpdateFunction: () => {
          this.setState(
            produce((prevState) => {
              prevState.devis.contact_Maitre_Oeuvre = null;
            }),
            this.state.callbackForceUpdateContactMaitreDOeuvre
          );
        },
      },
      {
        value: this.state.devis.contact_Maitre_Oeuvre,
        label: "Contact Maitre d'Oeuvre",
        accessor: "contact_Maitre_Oeuvre",
        type: "selectSearch",
        service: () =>
          ContactService.getByIdClient(this.state.devis.maitre_Oeuvre?.id),
        optionFieldToDisplay: ["nom_Contact", "prenom_Contact", "email"],
        valueFieldToDisplay: ["nom_Contact", "prenom_Contact", "email"],
        setForceUpdateFunction: this.setForceUpdateContactMaitreDOeuvre,
      },
      {
        value: this.state.devis.maitre_Ouvrage,
        label: "Maitre d'Ouvrage",
        accessor: "maitre_Ouvrage",
        type: "selectSearch",
        service: ClientService.getForSelectSearch,
        optionFieldToDisplay: "nom_Client",
        valueFieldToDisplay: "nom_Client",
        specialRenderValue: this.specialRenderValueClient,
        afterUpdateFunction: () => {
          this.setState(
            produce((prevState) => {
              prevState.devis.contact_Maitre_Ouvrage = null;
            }),
            this.state.callbackForceUpdateContactMaitreDOuvrage
          );
        },
      },
      {
        value: this.state.devis.contact_Maitre_Ouvrage,
        label: "Contact Maitre d'Ouvrage",
        accessor: "contact_Maitre_Ouvrage",
        type: "selectSearch",
        service: () =>
          ContactService.getByIdClient(this.state.devis.maitre_Ouvrage?.id),
        optionFieldToDisplay: ["nom_Contact", "prenom_Contact", "email"],
        valueFieldToDisplay: ["nom_Contact", "prenom_Contact", "email"],
        setForceUpdateFunction: this.setForceUpdateContactMaitreDOuvrage,
      },
      {
        value: this.state.devis.dateVisiteChantier,
        label: "Date de visite du site",
        accessor: "dateVisiteChantier",
        type: "date",
        colSize: 4,
      },
      {
        value: this.state.devis.superficie,
        label: "Superficie",
        accessor: "superficie",
        type: "text",
        colSize: 4,
      },
      {
        value: this.state.devis.niveau,
        label: "Niveau",
        accessor: "niveau",
        type: "text",
        colSize: 4,
      },
      {
        value: this.state.devis.sousSol,
        label: "Sous-sol",
        accessor: "sousSol",
        type: "checkbox",
        functionAppliedToValue: (value) => {
          return value ? "Oui" : "Non";
        },
        colSize: 4,
      },
      {
        value: this.state.devis.siteFacileDAcces,
        label: "Site facile d'accès",
        accessor: "siteFacileDAcces",
        type: "checkbox",
        functionAppliedToValue: (value) => {
          return value ? "Oui" : "Non";
        },
        colSize: 4,
      },
      {
        value: this.state.devis.descenteCharge,
        label: "Descente charge",
        accessor: "descenteCharge",
        type: "checkbox",
        functionAppliedToValue: (value) => {
          return value ? "Oui" : "Non";
        },
        colSize: 4,
      },
      {
        value: this.state.devis.dict,
        label: "DICT",
        accessor: "dict",
        type: "checkbox",
        functionAppliedToValue: (value) => {
          return value ? "Oui" : "Non";
        },
        colSize: 4,
      },
      {
        value: this.state.devis.sousTraitance,

        label: "Sous-traitance",
        accessor: "sousTraitance",
        type: "checkbox",
        functionAppliedToValue: (value) => {
          return value ? "Oui" : "Non";
        },
        colSize: 4,
      },
      {
        value: this.state.devis.adresseChantier,
        label: "Adresse Chantier",
        functionAppliedToValue: () => {
          return (
            <>
              {this.state.devis.adresseChantier.intitule ?? ""}
              <br />
              {this.state.devis.adresseChantier.voie ?? ""}
              <br />
              {this.state.devis.adresseChantier.complement ?? ""}
              <br />
              {(this.state.devis.adresseChantier.cp ?? "") +
                " " +
                (this.state.devis.adresseChantier.ville ?? "")}
            </>
          );
        },
        accessor: "adresseChantier",
        colSize: 12,
        type: "address",
        optionFieldToDisplay: "nom_Fr",
      },
    ];

    const checkboxParticulier = [
      {
        value: this.state.devis.isParticulier,
        label: "Particulier ?",
        accessor: "isParticulier",
        type: "checkbox",
        functionAppliedToValue: (value) => {
          return value ? "Oui" : "Non";
        },
      },
    ];

    const informationsParticulier = [
      {
        value: this.state.devis.nomParticulier,
        label: "Nom",
        accessor: "nomParticulier",
        type: "text",
      },
      {
        value: this.state.devis.mailParticulier,
        label: "Mail",
        accessor: "adresseParticulier.email",
        type: "email",
      },
      {
        value: this.state.devis.telephoneParticulier,
        label: "Téléphone",
        accessor: "adresseParticulier.telephone",
        type: "text",
      },
      {
        value: this.state.devis.adresseParticulier,
        label: "Adresse Particulier",
        functionAppliedToValue: () => {
          return (
            <>
              {this.state.devis.adresseParticulier.intitule ?? ""}
              <br />
              {this.state.devis.adresseParticulier.voie ?? ""}
              <br />
              {this.state.devis.adresseParticulier.complement ?? ""}
              <br />
              {(this.state.devis.adresseParticulier.cp ?? "") +
                " " +
                (this.state.devis.adresseParticulier.ville ?? "")}
            </>
          );
        },
        accessor: "adresseParticulier",
        colSize: 12,
        type: "address",
        optionFieldToDisplay: "nom_Fr",
      },
    ];

    const infos = [
      { info: informationsGeneral, titre: "Informations générales" },
      { info: informationsClient, titre: "Informations sur le client" },
      { info: informationsCommande, titre: "Informations sur la commande" },
      { info: informationsChantier, titre: "Informations sur le chantier" },
      {
        info: this.state.devis.isParticulier
          ? checkboxParticulier.concat(informationsParticulier)
          : checkboxParticulier,
        titre: "Informations sur le particulier",
      },
    ];
    return this.state.loaded ? (
      <>
        <FormWithValidation onSubmit={this.handleSubmit}>
          {infos.map((infoItem, index) => {
            return (
              <div className="mb-4" key={index}>
                <Box
                  header={
                    <>
                      <div className="col-12 align-self-center py-2">
                        <div>{infoItem.titre}</div>
                      </div>
                    </>
                  }
                  body={
                    <>
                      {infoItem.info.map((field, fieldsIndex) => {
                        return field ? (
                          <div
                            className={"col-lg-" + field.colSize + " pt-2"}
                            key={fieldsIndex}
                          >
                            <Input
                              label={field.label} // Titre du champ
                              value={field.value} // Valeur du champ
                              accessor={field.accessor} // Accesseur
                              placeholder={field.placeholder}
                              debounce={field.debounce}
                              service={field.service}
                              handleChange={(acc, val) =>
                                this.handleStateChange(
                                  acc,
                                  val,
                                  field.afterUpdateFunction
                                )
                              } // Fonction pour mettre à jour la valeur dans l'object via son accesseur
                              handleBlur={field.handleBlur} // Fonction pour mettre à jour la valeur dans l'object via son accesseur
                              type={field.type} // Type de l'input a utiliser
                              options={field.options} // options de l'input
                              optionFieldToDisplay={field.optionFieldToDisplay} // champ de l'objet a afficher
                              optionFieldToReturn={field.optionFieldToReturn} // champd de l'objet a utiliser en tant que valeur
                              valueFieldToDisplay={field.valueFieldToDisplay}
                              required={field.required}
                              disabled={field.disabled}
                              validText={field.validText}
                              invalidText={field.invalidText}
                              specialRenderValue={field.specialRenderValue}
                              setForceUpdateFunction={
                                field.setForceUpdateFunction
                              }
                            />
                          </div>
                        ) : null;
                      })}
                    </>
                  }
                />
              </div>
            );
          })}

          <div className="text-center">
            <ButtonIcon
              className="btn btn-success mt-4 w-25"
              type="submit"
              disabled={this.state.isSubmitting}
              textOption="Créer le devis"
            ></ButtonIcon>
          </div>
        </FormWithValidation>
      </>
    ) : (
      <FontAwesomeIcon
        icon={faSpinner}
        size="lg"
        className="fa-spin flex-auto"
      />
    );
  }

  render() {
    return (
      <>
        <Helmet>
          <title>Création d'un devis</title>
        </Helmet>
        <AlertDialog
          title="Une erreur a été rencontrée"
          body={
            <>
              <div>La création du devis a échouée.</div>
              <div>{this.state.errorText}</div>
            </>
          }
          valider="Ok"
          open={this.state.error}
          handleClose={() => this.setState({ error: false })}
        />
        <div className="p-3 m-4" id="ajoutDevis">
          <div className="d-flex overflow-auto justify-content-between py-1">
            <h2 className="nowrap pr-100 solwayFont">
              Création d&apos;un devis
              {this.state.devis.client
                ? " pour " + this.state.devis.client.nom_Client
                : null}
            </h2>
            <div>
              {this.props.history.length > 1 && (
                <button
                  className="btn btn-primary nowrap mx-1"
                  onClick={() => {
                    this.props.history.goBack();
                  }}
                >
                  Retour
                </button>
              )}
            </div>
          </div>
          <WarningBar
            active={!this.state.devis.client?.litige}
            content={"Client en litige"}
            background={"bg-danger"}
          />
          <WarningBar
            active={!this.state.devis.client?.tarif_Negocie}
            content={"Tarifs négociés"}
          ></WarningBar>
          <div className="row mt-4">{this.form()}</div>
        </div>
      </>
    );
  }
}

AjoutDevis.propTypes = {
  history: PropTypes.object,
};

export { AjoutDevis };
