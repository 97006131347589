import React from "react";
import { ReactTableServerSide, CellWithRedirection } from "_components";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationTriangle } from "@fortawesome/free-solid-svg-icons";

import { RedirectTo, ToLocaleDateString } from "_utils";
import {
  ClientService,
  ContratCategorieService,
  ContratFinanceurService,
  ContratPeriodiciteService,
  ContratService,
  ContratSortieService,
  PacksService,
} from "_services/";
import { Helmet } from "react-helmet";
import { App } from "App";

class BaseContrat extends React.PureComponent {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <>
        <Helmet>
          <title>Base Contrat</title>
        </Helmet>
        <ReactTableServerSide
          title="Base Contrat"
          titreFiltre="Base Contrat"
          service={ContratService.getAll}
          displayExtractButton={
            App.RightsGuard.current?.hasRight("Contrat", "Extraction") == "RW"
          }
          serviceExtract={ContratService.Extract}
          module="Contrat"
          redirectToCreate={() => {
            RedirectTo(this.props.history, "/contrats/add");
          }}
          orderByDefault="id"
          localStorageKeyColumns="BaseContratColumns"
          columns={[
            {
              Header: "Num",
              accessor: "numEtude",
              search: "numEtude",
              hideable: true,
              sortable: true,
              exportable: true,
              minWidth: "180px",
              type: "text",
              Cell: (row) => {
                return (
                  <CellWithRedirection
                    history={this.props.history}
                    redirection="/contrats/"
                    id={row.cell.row.original.id}
                    value={row.cell.value}
                  />
                );
              },
            },
            {
              Header: "Client",
              accessor: "client",
              hideable: true,
              exportable: true,
              minWidth: "150px",
              type: "selectSearch",
              valueFieldToDisplay: "nom_Client",
              optionFieldToReturn: "nom_Client",
              optionFieldToDisplay: "nom_Client",
              service: ClientService.getForSelectSearch,
              search: "nom_client",
              Cell: (row) => {
                if (row.cell.value)
                  return (
                    <CellWithRedirection
                      history={this.props.history}
                      redirection="/clients/"
                      id={row.cell.value.id}
                      value={row.cell.value ? row.cell.value.nom_Client : null}
                    />
                  );
                else return null;
              },
            },
            {
              Header: "Pack",
              accessor: "pack",
              search: "packId",
              hideable: true,
              sortable: true,
              exportable: true,
              minWidth: "130px",
              type: "selectSearch",
              optionFieldToReturn: "id",
              optionFieldToDisplay: "designation",
              valueFieldToDisplay: "designation",
              service: PacksService.getAll,

              Cell: (row) => {
                if (row.cell.value) {
                  return <div>{row.cell.value.designation}</div>;
                } else {
                  return null;
                }
              },
            },
            {
              Header: "Catégorie",
              accessor: "categorie",
              search: "categorieId",
              hideable: true,
              sortable: true,
              exportable: true,
              minWidth: "170px",
              type: "selectSearch",
              optionFieldToDisplay: "designation",
              valueFieldToDisplay: "designation",
              optionFieldToReturn: "id",
              service: ContratCategorieService.getAll,
              Cell: (row) => {
                return row.cell.value ? (
                  <span>{row.cell.value.designation}</span>
                ) : null;
              },
            },
            {
              Header: "Financeur",
              accessor: "financeur",
              search: "financeurId",
              hideable: true,
              sortable: true,
              exportable: true,
              minWidth: "170px",
              type: "selectSearch",
              optionFieldToDisplay: "designation",
              valueFieldToDisplay: "designation",
              optionFieldToReturn: "id",
              service: ContratFinanceurService.getAll,
              Cell: (row) => {
                return row.cell.value ? (
                  <span>{row.cell.value.designation}</span>
                ) : null;
              },
            },
            {
              Header: "Sortie",
              accessor: "sortie",
              search: "sortieId",
              hideable: true,
              sortable: true,
              exportable: true,
              minWidth: "170px",
              type: "selectSearch",
              optionFieldToDisplay: "designation",
              valueFieldToDisplay: "designation",
              optionFieldToReturn: "id",
              service: ContratSortieService.getAll,
              Cell: (row) => {
                return row.cell.value ? (
                  <span>{row.cell.value.designation}</span>
                ) : null;
              },
            },
            {
              Header: "Début abonnement",
              accessor: "debutAbonnement",
              hideable: true,
              sortable: true,
              exportable: true,
              minWidth: "180px",
              type: "period",
              Cell: (row) => {
                return row.cell.value ? (
                  <span>{ToLocaleDateString(row.cell.value)}</span>
                ) : null;
              },
            },
            {
              Header: "Fin abonnement",
              accessor: "finAbonnement",
              hideable: true,
              sortable: true,
              exportable: true,
              minWidth: "180px",
              type: "period",
              Cell: (row) => {
                return row.cell.value ? (
                  <span>{ToLocaleDateString(row.cell.value)}</span>
                ) : null;
              },
            },
            {
              Header: "Périodicité",
              accessor: "periodicite",
              search: "periodiciteId",
              hideable: true,
              sortable: true,
              exportable: true,
              minWidth: "170px",
              type: "selectSearch",
              optionFieldToDisplay: "designation",
              valueFieldToDisplay: "designation",
              optionFieldToReturn: "id",
              service: ContratPeriodiciteService.getAll,
              Cell: (row) => {
                return row.cell.value ? (
                  <span>{row.cell.value.designation}</span>
                ) : null;
              },
            },
            {
              Header: "Montant HT périod.",
              accessor: "montantAbonnementPeriodiqueHT",
              hideable: true,
              sortable: true,
              exportable: true,
              minWidth: "150px",
              type: "intervalDecimal",
              Cell: (row) => {
                return row.cell.value != null ? (
                  <span>{row.cell.value}</span>
                ) : null;
              },
            },
            {
              Header: "Actif",
              accessor: "actif",
              defaultValue: {
                id: 0,
                display: "Oui",
                value: true,
              },
              hideable: true,
              sortable: true,
              exportable: true,
              search: "actif",
              minWidth: "130px",
              type: "selectSearch",
              optionFieldToReturn: "value",
              optionFieldToDisplay: "display",
              valueFieldToDisplay: "display",
              options: [
                {
                  id: 0,
                  display: "Oui",
                  value: true,
                },
                {
                  id: 1,
                  display: "Non",
                  value: false,
                },
              ],
              Cell: (row) => {
                if (row.cell.value == true) {
                  return <div>Oui</div>;
                } else {
                  return (
                    <div>
                      Non
                      <FontAwesomeIcon
                        className="text-danger"
                        icon={faExclamationTriangle}
                      />
                    </div>
                  );
                }
              },
            },
          ]}
        ></ReactTableServerSide>
      </>
    );
  }
}

export { BaseContrat };
