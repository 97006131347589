import React from "react";
import { PropTypes } from "prop-types";
import { App } from "App";

import "./General.css";
import { BoxInformationsInline, WarningBar } from "_components";

class General extends React.PureComponent {
  constructor(props) {
    super(props);
  }

  render() {
    const right = App.RightsGuard?.current?.hasRight("Article", "General");
    if (right == "RW" || right == "RO") {
      const informationsGeneral = [
        {
          label: "Numéro de l'article",
          accessor: "aR_Ref",
          colSize: 3,
        },
        {
          label: "Nom de l'article",
          accessor: "description",
          colSize: 6,
        },
      ];

      const informationsTarif = [
        {
          label: "Prix d'achat",
          accessor: "prixAchat",
          functionAppliedToValue: (value) => {
            return value ? "".concat(value, " €") : null;
          },
          colSize: 4,
        },
        {
          label: "Tarif France",
          accessor: "prixVenteFrance",
          functionAppliedToValue: (value) => {
            return value ? "".concat(value, " €") : null;
          },
          colSize: 4,
        },
        {
          label: "Tarif Export",
          accessor: "prixVenteExport",
          functionAppliedToValue: (value) => {
            return value ? "".concat(value, " €") : null;
          },
          colSize: 4,
        },
      ];
      const informationsArticle = [
        {
          label: "Code Famille Article",
          accessor: "fA_CodeFamille",
          colSize: 4,
        },
        {
          label: "Suivi stock",
          accessor: "suiviStock",
          colSize: 4,
        },
        {
          label: "Actif",
          accessor: "actif",
          colSize: 4,
          type: "checkbox",
          functionAppliedToValue: (value) => {
            return value ? "Oui" : "Non";
          },
        },
      ];
      const informationsFiche = [
        {
          label: "Dernière modification",
          accessor: "ar_DateModif",
          type: "date",
          colSize: 12,
        },
      ];
      return (
        <>
          <div className="row ficheArticle">
            <div className="col-12 boxInformationsGeneral">
              <BoxInformationsInline
                title="Général"
                datas={informationsGeneral}
                state={this.props.article}
                stateFieldNameToUpdate={this.props.stateFieldNameToUpdate}
                service={this.props.service}
                colSize="4"
                NoEdition
              />
            </div>
          </div>
          <div className="row ficheArticle">
            <div className="col-12 boxTarifs">
              <BoxInformationsInline
                title="Tarifs"
                datas={informationsTarif}
                state={this.props.article}
                stateFieldNameToUpdate={this.props.stateFieldNameToUpdate}
                service={this.props.service}
                colSize="6"
                NoEdition
              />
            </div>
          </div>
          <div className="row ficheArticle">
            <div className="col-12 boxInformationsArticle">
              <BoxInformationsInline
                title="Informations"
                datas={informationsArticle}
                state={this.props.article}
                stateFieldNameToUpdate={this.props.stateFieldNameToUpdate}
                service={this.props.service}
                colSize="6"
                NoEdition
              />
            </div>
          </div>
          <div className="row justify-content-end">
            <div className="col-lg-6 col-md-12 boxInformationsFiche">
              <BoxInformationsInline
                title="Informations sur la fiche"
                datas={informationsFiche}
                state={this.props.article}
                stateFieldNameToUpdate={this.props.stateFieldNameToUpdate}
                service={this.props.service}
                colSize="3"
                boxSize="small"
                NoEdition
              />
            </div>
          </div>
        </>
      );
    } else {
      return (
        <>
          <WarningBar
            active={false}
            content={"Vous n'avez pas le droit de voir cette page"}
          />
        </>
      );
    }
  }
}

General.propTypes = {
  article: PropTypes.object,
  stateFieldNameToUpdate: PropTypes.string,
  history: PropTypes.object,
  service: PropTypes.func,
};

export { General };
