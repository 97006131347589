import React from "react";

import { Helmet } from "react-helmet";
import { MesChantierContainer } from "./MesChantierContainer";

class MesChantiers extends React.PureComponent {
  constructor(props) {
    super(props);
  }

  componentDidMount() {}

  render() {
    return (
      <>
        <Helmet>
          <title>Mes chantiers</title>
        </Helmet>

        <div className="shadow p-3 m-4 bg-body rounded bg-light">
          <h1 className="text-center">Mes chantiers</h1>
          <MesChantierContainer history={this.props.history} />
        </div>
      </>
    );
  }
}

export { MesChantiers };
