// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#PopupAffaire .select-search__select{
    position: static !important;
}`, "",{"version":3,"sources":["webpack://./src/FicheCommande/FicheCommande.css"],"names":[],"mappings":"AAAA;IACI,2BAA2B;AAC/B","sourcesContent":["#PopupAffaire .select-search__select{\n    position: static !important;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
