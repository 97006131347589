import React from "react";
import { App } from "App";

import { Box } from "_components";

import { ButtonIcon, WarningBar } from "_components";
import {
  faFileMedicalAlt,
  faFileSignature,
} from "@fortawesome/free-solid-svg-icons";

import { ToLocaleDateString } from "_utils";

class Materiel extends React.PureComponent {
  constructor(props) {
    super(props);
  }

  render() {
    const right = App.RightsGuard?.current?.hasRight("Client", "BDD Materiel");
    if (right == "RW" || right == "RO") {
      return (
        <>
          <Box
            noPaddingBottom={true}
            header={
              <div className="col-12 align-self-center py-2">
                <div>Materiels Propriétaire</div>
              </div>
            }
            body={
              <table className="table table-striped table-bordered table-sm">
                <thead
                  className={
                    "sticky-top" +
                    (this.props.client?.materielsProprietaire?.length == 0
                      ? " theadBorderRadius"
                      : "")
                  }
                >
                  <tr>
                    <th>Num Série</th>
                    <th>Ref Article</th>
                    <th>Descriptif article</th>
                    <th>Date Fabrication</th>
                    <th>Au rebus</th>
                    <th>Utilisateur</th>
                  </tr>
                </thead>
                <tbody>
                  {this.props.client.materielsProprietaire?.map((materiel) => (
                    <tr key={materiel.id}>
                      <td className="d-flex">
                        <span style={{ width: "100px" }}>
                          <a
                            href={"/materiels/" + materiel.id}
                            target="_blank"
                            data-text={materiel.num_Serie}
                            className="fixWidthHover"
                          >
                            {materiel.num_Serie}
                          </a>
                        </span>

                        <ButtonIcon
                          icon={faFileMedicalAlt}
                          className="btn btn-secondary btn-sm text-light ms-1"
                          onClick={() => {
                            this.props.handleCreateFdS(
                              materiel.utilisateur,
                              materiel
                            );
                          }}
                          style={{ width: "32px" }}
                        />
                        {materiel.contrat ? (
                          <ButtonIcon
                            icon={faFileSignature}
                            className="btn btn-secondary btn-sm text-light ms-1"
                            onClick={() => {
                              window.open("/contrats/" + materiel.contrat.id);
                            }}
                            style={{ width: "32px" }}
                          />
                        ) : null}
                      </td>
                      <td>{materiel.aR_Ref ?? "-"}</td>
                      <td>{materiel.type_Materiel?.designation ?? "-"}</td>
                      <td>{ToLocaleDateString(materiel.date_Fabrication)}</td>
                      <td>{materiel.au_Rebus ? "Oui" : "Non"}</td>
                      <td>{materiel.utilisateur?.nom_Client}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            }
          />
          <Box
            noPaddingBottom={true}
            header={
              <div className="col-12 align-self-center py-2">
                <div>Materiels Utilisateur</div>
              </div>
            }
            body={
              <table className="table table-striped table-bordered table-sm">
                <thead
                  className={
                    "sticky-top" +
                    (this.props.client?.materielsUtilisateur?.length == 0
                      ? " theadBorderRadius"
                      : "")
                  }
                >
                  <tr>
                    <th>Num Série</th>
                    <th>Ref Article</th>
                    <th>Descriptif article</th>
                    <th>Date Fabrication</th>
                    <th>Au rebus</th>
                    <th>Proprietaire</th>
                  </tr>
                </thead>
                <tbody>
                  {this.props.client.materielsUtilisateur?.map((materiel) => (
                    <tr key={materiel.id}>
                      <td className="d-flex">
                        <span style={{ width: "100px" }}>
                          <a
                            href={"/materiels/" + materiel.id}
                            target="_blank"
                            data-text={materiel.num_Serie}
                            className="fixWidthHover"
                          >
                            {materiel.num_Serie}
                          </a>
                        </span>

                        <ButtonIcon
                          icon={faFileMedicalAlt}
                          className="btn btn-secondary btn-sm text-light ms-1"
                          onClick={() => {
                            this.props.handleCreateFdS(
                              materiel.utilisateur,
                              materiel
                            );
                          }}
                          style={{ width: "32px" }}
                        />
                      </td>
                      <td>{materiel.aR_Ref ?? "-"}</td>
                      <td>{materiel.type_Materiel?.designation ?? "-"}</td>
                      <td>{ToLocaleDateString(materiel.date_Fabrication)}</td>
                      <td>{materiel.au_Rebus ? "Oui" : "Non"}</td>
                      <td>{materiel.proprietaire?.nom_Client}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            }
          />
        </>
      );
    } else
      return (
        <>
          <WarningBar
            active={false}
            content={"Vous n'avez pas le droit de voir cette page"}
          />
        </>
      );
  }
}

export { Materiel };
