import React, { Fragment } from "react";

import { ButtonIcon } from "_components";
import { faMinusSquare, faPlusSquare } from "@fortawesome/free-solid-svg-icons";

import { ToLocaleDateString } from "_utils";

import { PropTypes } from "prop-types";

class AccordionParAnneeMOE extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isAccordionOpen: props.isInitiallyOpen,
    };

    this.renderAccordionFirstLine = this.renderAccordionFirstLine.bind(this);
  }

  renderAccordionFirstLine() {
    return (
      <Fragment key={this.props.annee}>
        <tr>
          <td>
            <ButtonIcon
              icon={this.state.isAccordionOpen ? faMinusSquare : faPlusSquare}
              className="btn"
              onClick={() =>
                this.setState((prevState) => ({
                  isAccordionOpen: !prevState.isAccordionOpen,
                }))
              }
              style={{ width: "32px" }}
            ></ButtonIcon>
            {this.props.annee}
          </td>
          <td></td>
          <td></td>
          <td></td>
        </tr>
      </Fragment>
    );
  }

  render() {
    if (!this.state.isAccordionOpen) {
      return this.renderAccordionFirstLine();
    } else {
      return (
        <>
          {this.renderAccordionFirstLine()}
          {this.props.devis.map((devis) => {
            return (
              <Fragment key={devis.id}>
                <tr key={devis.id}>
                  <td></td>
                  <td className="tableIndent0">
                    Devis
                    {"(" + devis.resultat?.valeur + ") : "}
                    <a
                      href={"/devis/" + devis.id}
                      target="_blank"
                      data-text={devis.reference}
                      className="fixWidthHover"
                    >
                      {devis.reference}
                    </a>
                    {" " + devis.nom_Devis}
                  </td>
                  <td className="tableIndent0">
                    {ToLocaleDateString(devis.date_Create)}
                  </td>
                  <td className="tableIndent0">{devis.prixVenteTotal}</td>
                </tr>
                {devis.commandes.map((commande) => {
                  return (
                    <Fragment key={commande.id}>
                      <tr key={commande.id}>
                        <td></td>
                        <td className="tableIndent1">
                          {"Commande : "}
                          <a
                            href={"/commandes/" + commande.id}
                            target="_blank"
                            data-text={commande.reference}
                            className="fixWidthHover"
                          >
                            {commande.reference}
                          </a>
                          {" " + commande.nom_Commande}
                        </td>
                        <td className="tableIndent1">
                          {ToLocaleDateString(commande.date_Create)}
                        </td>
                        <td className="tableIndent1">
                          {commande.prixVenteTotal}
                        </td>
                      </tr>
                    </Fragment>
                  );
                })}
              </Fragment>
            );
          })}

          {this.props.commandes.map((commande) => {
            return (
              <Fragment key={commande.id}>
                <tr key={commande.id}>
                  <td></td>
                  <td>
                    {"Commande : "}
                    <a
                      href={"/commandes/" + commande.id}
                      target="_blank"
                      data-text={commande.reference}
                      className="fixWidthHover"
                    >
                      {commande.reference}
                    </a>
                    {" " + commande.nom_Commande}
                  </td>
                  <td>{ToLocaleDateString(commande.date_Create)}</td>
                  <td>{commande.prixVenteTotal}</td>
                </tr>
              </Fragment>
            );
          })}
        </>
      );
    }
  }
}

AccordionParAnneeMOE.propTypes = {
  index: PropTypes.number,
  annee: PropTypes.string,
  isInitiallyOpen: PropTypes.bool,
  devis: PropTypes.any,
  commandes: PropTypes.any,
  factures: PropTypes.any,
};

export { AccordionParAnneeMOE };
