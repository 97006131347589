import React from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { PropTypes } from "prop-types";

/**
 * Génère un composant Dialog de Material-UI avec son bouton associé
 * @param {*} props
 * @returns un composant Dialog de Material-UI avec son bouton associé
 */
function AlertDialogWithButton(props) {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <button className="btn btn-primary" onClick={handleClickOpen}>
        {props.button}
      </button>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{props.title}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {props.body}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <button onClick={handleClose} className="btn btn-primary">
            Fermer
          </button>
          <button
            onClick={() => {
              if (props.onClick) {
                props.onClick();
              }
              handleClose();
            }}
            className="btn btn-primary"
            autoFocus
          >
            {props.valider}
          </button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

AlertDialogWithButton.propTypes = {
  button: PropTypes.string,
  title: PropTypes.string,
  body: PropTypes.string,
  valider: PropTypes.string,
  onClick: PropTypes.func,
};

/**
 * Génère un composant Dialog de Material-UI
 * @param {*} props
 * @returns composant Dialog de Material-UI
 */
function AlertDialog(props) {
  return (
    <Dialog
      open={props.open}
      onClose={props.handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{props.title}</DialogTitle>
      <DialogContent>{props.body}</DialogContent>
      <DialogActions>
        {props.onClick ? (
          <>
            <button onClick={props.handleClose} className="btn btn-secondary">
              Annuler
            </button>
            <button onClick={props.onClick} className="btn btn-danger">
              {props.valider}
            </button>
          </>
        ) : (
          <button onClick={props.handleClose} className="btn btn-primary">
            {props.valider}
          </button>
        )}
      </DialogActions>
    </Dialog>
  );
}

AlertDialog.propTypes = {
  title: PropTypes.any,
  body: PropTypes.any,
  valider: PropTypes.string,
  open: PropTypes.bool,
  handleClose: PropTypes.func,
  onClick: PropTypes.func,
};

export { AlertDialogWithButton, AlertDialog };
