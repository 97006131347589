import React from "react";

// Fontawesome
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPaintRoller } from "@fortawesome/free-solid-svg-icons";

// Validation des props
import { PropTypes } from "prop-types";

/**
 * Génère un composant bouton permettant de changer le theme de la page
 */
class ThemeButton extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      themeIndex: 0,
      themes: [
        "Défaut",
        "Journal",
        "United",
        "Yeti",
        "Pulse",
        "Quartz",
        //"Darkly",
        //"SolSol",
      ],
    };
    this.nextTheme = this.nextTheme.bind(this);
    this.initTheme = this.initTheme.bind(this);
  }

  componentDidMount() {
    this.initTheme();
  }

  componentDidUpdate() {
    localStorage.setItem("theme", this.state.themeIndex);
  }

  /**
   * Initialise le theme de la page lors du chargement du composant. Dans le cas où la clé "theme" n'existe pas en stockage local
   * on l'initialise à 0, ce qui correspond à l'index 0 du tableau themes de cette classe. Dans d'autres cas on récupère la valeur
   * et on l'applique à l'index courant.
   */
  initTheme() {
    if (localStorage.getItem("theme") == null) {
      // if (
      //   window.matchMedia &&
      //   window.matchMedia("(prefers-color-scheme: dark)").matches
      // ) {
      //   // Theme Darkly (theme sombre)
      //   localStorage.setItem("theme", 6);
      // } else {
      localStorage.setItem("theme", 0);
      //}
    } else {
      this.setState({ themeIndex: localStorage.getItem("theme") });
    }
  }

  /**
   * Permet de changer de theme, on parcourt l'intégralité du tableau des themes et si l'on arrive à la fin on repart du début.
   */
  nextTheme() {
    if (this.state.themeIndex >= this.state.themes.length - 1) {
      this.setState({ themeIndex: 0 });
    } else {
      this.setState({ themeIndex: parseInt(this.state.themeIndex) + 1 });
    }
  }

  render() {
    return (
      <>
        {this.state.themeIndex > 0 && (
          <link
            rel="stylesheet"
            type="text/css"
            href={
              // eslint-disable-next-line no-undef
              process.env.PUBLIC_URL +
              "/themes/" +
              this.state.themes[this.state.themeIndex] +
              ".css"
            }
          />
        )}
        <div className={this.props.className} onClick={this.nextTheme}>
          <FontAwesomeIcon size="lg" icon={faPaintRoller} />
        </div>
      </>
    );
  }
}

/**
 * Validation des props :
 * className : classes à appliquer au bouton
 */
ThemeButton.propTypes = {
  className: PropTypes.string,
};

export { ThemeButton };
