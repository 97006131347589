import http from "http-common";

const RubriqueCarteBancaireService = class {
    /**
     * Fonction permettant de récuperer tous les EtatAvancement avec une possibilité de filtre via params
     * @returns la liste de tous les EtatAvancement
     */
    static getAll() {
      return http.get("/RubriqueCarteBancaire");
    }
    
};

export {RubriqueCarteBancaireService};