import http from "http-common";

class ContratFinanceurService {
  static cache = null;
  static loading = true;

  static getAll(params, refresh) {
    //Gestion de la valeur par défaut de la propriété "isActive" des paramètres
    let reqParams;
    if (!params) {
      // null
      reqParams = { isActive: true };
    } else if (typeof params === "object" && params.isActive === undefined) {
      // Objet ne contenant pas isActive
      reqParams = { ...params, isActive: true };
    } else if (typeof params === "object" && params.isActive !== undefined) {
      // Objet contenant isActive (null/true/false)
      reqParams = params;
    } else {
      reqParams = { isActive: true };
    }

    //On a des params (autres que isActive) ou isActive !=true => On ne veut pas les données stockées en cache.
    if (!(Object.keys(reqParams).length == 1) || reqParams.isActive !== true)
      return ContratFinanceurService.get(reqParams);

    //Si besoin, on (re)charge le cache avant de le renvoyer
    if (refresh == true || ContratFinanceurService.loading) {
      ContratFinanceurService.cache = ContratFinanceurService.get({
        isActive: true,
      });
      ContratFinanceurService.loading = false;
    }
    return ContratFinanceurService.cache;
  }

  static get(params) {
    return http.get("/ContratFinanceur", { params });
  }

  static getByValue(value) {
    return http.get("/ContratFinanceur/get/" + value);
  }
}

export { ContratFinanceurService };
