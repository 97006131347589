import React from "react";
import { PropTypes } from "prop-types";
import { ButtonIcon } from "_components";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
/**
 * Génère un input en ligne
 * @param {string} label, le label de l'input
 * @param {*} value, la valeur de l'input
 * @param {string} accessor, l'accesseur de l'élément lié à cet input
 * @param {int} colSize, la taille de cet élément en colonne
 * @param {*} index, son index si besoin dans un .map
 * @param {function} handleChange, la fonction à appeler lors d'un changement
 * @returns un input en ligne
 */
function DataInputRow(props) {
  return (
    <div className={"row col-" + props.colSize + " pt-2"}>
      <div className="col-3 text-uppercase text-muted align-self-center">
        {props.label}
      </div>
      <div className="col-9">
        <div className="input-group ">
          <input
            className="form-control"
            name={props.accessor}
            value={props.value ? props.value : ""}
            onChange={props.handleChange}
          />
          <ButtonIcon
            smallText=""
            icon={faTimes}
            iconSize="sm"
            onClick={() => {
              props.handleChange({
                target: { value: null, type: "text", name: props.accessor },
              });
            }}
            className="btn btn-danger"
          ></ButtonIcon>
        </div>
      </div>
    </div>
  );
}

DataInputRow.propTypes = {
  label: PropTypes.string,
  value: PropTypes.any,
  accessor: PropTypes.string,
  colSize: PropTypes.any,
  handleChange: PropTypes.func,
};

export { DataInputRow };
