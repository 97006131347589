import http from "http-common";

const ModePaiementService = class {
  /**
   * Fonction permettant de récuperer tous les mode de paiements avec une possibilité de filtre via params
   * @returns la liste de tous les mode de paiements
   */
  static getAll() {
    return http.get("/modePaiements");
  }

  /**
   * Fonction permettant de récuperer un mode de paiement via son id
   * @param {*} id, id de du mode de paiement à récupérer
   * @returns le mode de paiement souhaité
   */
  static getById(id) {
    return http.get("/modePaiements/" + id);
  }

  static getByValue(value) {
    return http.get("/modePaiements/get/" + value);
  }
};

export { ModePaiementService };
