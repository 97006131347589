import http from "http-common";

const PaysService = class {
  /**
   * Fonction permettant de récuperer tous les pays avec une possibilité de filtre via params
   * @returns la liste de tous les pays
   */
  static getAll(params) {
    //Gestion de la valeur par défaut de la propriété "isActive" des paramètres
    if (!params)
      return http.get("/pays", {params: {isActive: true}});
    else if (typeof params === "object" && params.isActive === undefined)
        return http.get("/pays", {params: {...params, isActive: true}});
    else if (typeof params === "object" && params.isActive !== undefined)
      return http.get("/pays", {params});
    else
      return http.get("/pays", {params: {isActive: true}});
  }

  /**
   * Fonction permettant de récuperer un pays via son id
   * @param {*} id, id du pays à récupérer
   * @returns le pays souhaité
   */
  static getById(id) {
    return http.get("/pays/" + id);
  }

  /**
   * Fonction permettant de récuperer un pays via son nom
   * @param {*} name, nom du pays à récupérer
   * @returns le pays souhaité
   */
  static getByName(name) {
    return http.get("/pays/get/" + name);
  }
};

export { PaysService };
