import React from "react";
import { App } from "App";
import { ToLocaleDateString } from "_utils";
import { Box, ButtonIcon, WarningBar } from "_components";
import { faFileUpload } from "@fortawesome/free-solid-svg-icons";

class NoteDeFrais extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      file: null,
    };
  }

  render() {
    const right = App.RightsGuard?.current?.hasRight(
      "Affaire",
      "Notes de frais"
    );
    const rightImport = App.RightsGuard?.current?.hasRight(
      "Affaire",
      "Import excel"
    );

    if (right == "RW" || right == "RO") {
      var ndf = this.props.NdF?.slice();

      if (!ndf) ndf = [];

      ndf = ndf
        .slice()
        .sort((a, b) => new Date(b.dateFrais) - new Date(a.dateFrais));
      return (
        <>
          {rightImport == "RW" ? (
            <ButtonIcon
              icon={faFileUpload}
              onClick={() => {
                this.props.history.push("/notesDeFrais/");
              }}
              className="btn btn-primary text-light mt-1"
              textOption=" Import notes de frais"
            ></ButtonIcon>
          ) : null}

          <Box
            noPaddingBottom={true}
            header={
              <div className="col-12 align-self-center py-2">
                <div>Notes De Frais</div>
              </div>
            }
            body={
              <table className="table table-sm table-striped table-bordered">
                <thead
                  className={
                    "sticky-top" +
                    (ndf?.length == 0 ? " theadBorderRadius" : "")
                  }
                >
                  <tr>
                    <th>Libellé</th>
                    <th>Montant TTC</th>
                    <th>Montant TVA</th>
                    <th>Personnel</th>
                    <th>Carte Bancaire</th>
                    <th>Rubrique</th>
                    <th>Nombre</th>
                    <th>Date de Frais</th>
                  </tr>
                </thead>
                <tbody>
                  {ndf.map((d) => {
                    return (
                      <tr key={d.id}>
                        <td className="tableIndent1">{d.libelle}</td>
                        <td className="tableIndent1">{d.montant_TTC}</td>
                        <td className="tableIndent1">{d.montant_TVA}</td>
                        <td className="tableIndent1">
                          {d.personnel?.initiales}
                        </td>
                        <td className="tableIndent1">{d.cb?.name}</td>
                        <td className="tableIndent1">
                          {d.typeNoteDeFrais?.code_Secteur}
                        </td>
                        <td className="tableIndent1">{d.nombre}</td>
                        <td className="tableIndent1">
                          {d.dateFrais != null
                            ? ToLocaleDateString(d.dateFrais)
                            : null}
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            }
          />
        </>
      );
    } else {
      return (
        <>
          <WarningBar
            active={false}
            content={"Vous n'avez pas le droit de voir cette page"}
          />
        </>
      );
    }
  }
}

export { NoteDeFrais };
