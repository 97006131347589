import React from "react";
import {
  ReactTableServerSide,
  IndeterminateCheckbox,
  CellWithRedirection,
} from "_components";

import {
  FicheDeSuiviService,
  FicheDeSuiviTypePanneService,
  FicheDeSuiviEtatAvancementService,
  FamilleArticleService,
  PersonnelService,
  PacksService,
  FicheDeSuiviFournisseurService,
  FicheDeSuiviProvenanceService,
  FicheDeSuiviTypeService,
  ProduitInterneService,
} from "_services";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationTriangle } from "@fortawesome/free-solid-svg-icons";

import { IfNullToText, RedirectTo, ToLocaleDateString } from "_utils";
import { PropTypes } from "prop-types";
import { Helmet } from "react-helmet";
import { App } from "App";

class BaseFicheDeSuivi extends React.PureComponent {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <>
        <Helmet>
          <title>Base fiche de suivi</title>
        </Helmet>
        <ReactTableServerSide
          title="Base Fiches de suivi"
          titreFiltre="Base Fiches de suivi"
          service={FicheDeSuiviService.getAll}
          displayExtractButton={
            App.RightsGuard.current?.hasRight("Fiche de suivi", "Extraction") ==
            "RW"
          }
          serviceExtract={FicheDeSuiviService.Extract}
          module="Fiche de suivi"
          redirectToCreate={() => {
            RedirectTo(this.props.history, "/fichesDeSuivi/add");
          }}
          orderByDefault="reference"
          localStorageKeyColumns="BaseFicheDeSuiviColumns"
          objectFieldToExport={{
            client: "nom_Client",
            contact: "nom_Contact",
            type_Materiel: "designation",
            etat_Avancement: "designation",
          }}
          columns={[
            {
              id: "selection",
              Header: ({ getToggleAllRowsSelectedProps }) => (
                <div className="text-center align-middle" id="selectAll">
                  <IndeterminateCheckbox
                    className="align-middle"
                    {...getToggleAllRowsSelectedProps()}
                  />
                </div>
              ),
              Cell: ({ row }) => (
                <div className="text-center align-middle" id="selectOne">
                  <IndeterminateCheckbox
                    className="align-middle"
                    {...row.getToggleRowSelectedProps()}
                  />
                </div>
              ),
            },
            {
              Header: "Actif",
              accessor: "actif",
              hideable: true,
              sortable: true,
              exportable: true,
              defaultValue: {
                id: 1,
                display: "Oui",
                value: true,
              },
              minWidth: "130px",
              type: "selectSearch",
              optionFieldToReturn: "value",
              optionFieldToDisplay: "display",
              valueFieldToDisplay: "display",
              options: [
                {
                  id: 1,
                  display: "Oui",
                  value: true,
                },
                {
                  id: 2,
                  display: "Non",
                  value: false,
                },
              ],
              Cell: (row) => {
                if (row.cell.value == true) {
                  return <div>Oui</div>;
                } else {
                  return (
                    <div>
                      Non{" "}
                      <FontAwesomeIcon
                        className="text-danger"
                        icon={faExclamationTriangle}
                      />
                    </div>
                  );
                }
              },
            },
            {
              Header: "N° Fiche de suivi",
              accessor: "reference",
              hideable: true,
              sortable: true,
              exportable: true,
              minWidth: "150px",
              type: "text",
              Cell: (row) => {
                return (
                  <CellWithRedirection
                    history={this.props.history}
                    redirection="/fichesDeSuivi/"
                    id={row.cell.row.original.id}
                    value={row.cell.value}
                  />
                );
              },
            },
            {
              Header: "Statut",
              accessor: "statut",
              search: "statut",
              hideable: true,
              exportable: true,
              minWidth: "120px",
              type: "selectSearch",
              options: [
                {
                  id: 1,
                  designation: "Soldée",
                  display: "Soldée",
                },
                {
                  id: 2,
                  designation: "En cours",
                  display: "En cours",
                },
              ],
              optionFieldToReturn: "designation",
              optionFieldToDisplay: "display",
              valueFieldToDisplay: "display",
              Cell: (row) => {
                return row.cell.value ? (
                  <span>{row.cell.value?.designation}</span>
                ) : null;
              },
            },
            {
              Header: "Utilisateur",
              accessor: "utilisateur",
              search: "nom_Client",
              hideable: true,
              exportable: true,
              minWidth: "300px",
              type: "text",
              Cell: (row) => {
                return (
                  <CellWithRedirection
                    history={this.props.history}
                    redirection="/clients/"
                    id={
                      row.cell.row.original.utilisateur
                        ? row.cell.row.original.utilisateur.id
                        : row.cell.row.original.id
                    }
                    value={row.cell.value ? row.cell.value.nom_Client : null}
                  />
                );
              },
            },
            {
              Header: "Contact utilisateur",
              accessor: "contactUtilisateur",
              search: "nom_Contact",
              hideable: true,
              exportable: true,
              minWidth: "300px",
              type: "text",
              Cell: (row) => {
                if (
                  row.cell.value &&
                  (row.cell.value.nom_Contact || row.cell.value.prenom_Contact)
                ) {
                  return (
                    <CellWithRedirection
                      history={this.props.history}
                      redirection="/contacts/"
                      id={row.cell.row.original.contactUtilisateur.id}
                      value={
                        IfNullToText(row.cell.value.nom_Contact, "") +
                        " " +
                        IfNullToText(row.cell.value.prenom_Contact, "")
                      }
                    />
                  );
                }
                return null;
              },
            },
            {
              Header: "Propriétaire",
              accessor: "materiel.proprietaire",
              search: "nomProprietaire",
              hideable: true,
              exportable: true,
              minWidth: "300px",
              type: "text",
              Cell: (row) => {
                if (row.cell.value)
                  return (
                    <CellWithRedirection
                      history={this.props.history}
                      redirection="/clients/"
                      id={
                        row.cell.row.original.materiel.proprietaire
                          ? row.cell.row.original.materiel.proprietaire.id
                          : row.cell.row.original.id
                      }
                      value={row.cell.value ? row.cell.value.nom_Client : null}
                    />
                  );
                else return null;
              },
            },
            {
              Header: "Gérant",
              accessor: "gerant",
              hideable: true,
              exportable: true,
              minWidth: "150px",
              type: "selectSearch",
              valueFieldToDisplay: ["nom", "prenom"],
              optionFieldToReturn: "id",
              optionFieldToDisplay: ["nom", "prenom"],
              service: PersonnelService.getForSelectSearch,
              search: "numGerant",
              Cell: (row) => {
                return row.cell.value ? (
                  <span>
                    {row.cell.value.nom} {row.cell.value.prenom}
                  </span>
                ) : null;
              },
            },
            {
              Header: "Type matériel",
              accessor: "typeMaterielDesignation",
              search: "type_Materiel",
              hideable: true,
              sortable: true,
              exportable: true,
              minWidth: "180px",
              type: "selectSearch",
              optionFieldToDisplay: "designation",
              optionFieldToReturn: "designation",
              valueFieldToDisplay: "designation",
              service: FamilleArticleService.getAll,
              Cell: (row) => {
                return row.cell.value ? <span>{row.cell.value}</span> : null;
              },
            },
            {
              Header: "Etat d'avancement",
              accessor: "etat_Avancement",
              hideable: true,
              sortable: true,
              exportable: true,
              minWidth: "250px",
              type: "selectSearch",
              service: FicheDeSuiviEtatAvancementService.getAll,
              optionFieldToDisplay: "designation",
              optionFieldToReturn: "designation",
              valueFieldToDisplay: "designation",
              Cell: (row) => {
                return row.cell.value ? (
                  <span>{row.cell.value.designation}</span>
                ) : null;
              },
            },
            {
              Header: "Emplacement",
              accessor: "emplacement",
              hideable: true,
              sortable: true,
              exportable: true,
              minWidth: "250px",
              type: "selectSearch",
              service: FicheDeSuiviFournisseurService.getAll,
              optionFieldToDisplay: "designation",
              optionFieldToReturn: "id",
              valueFieldToDisplay: "designation",
              Cell: (row) => {
                return row.cell.value ? (
                  <span>{row.cell.value.designation}</span>
                ) : null;
              },
            },
            {
              Header: "Type de panne",
              accessor: "type_Panne",
              hideable: true,
              sortable: true,
              exportable: true,
              minWidth: "180px",
              type: "selectSearch",
              optionFieldToDisplay: "panne",
              optionFieldToReturn: "panne",
              valueFieldToDisplay: "panne",
              service: FicheDeSuiviTypePanneService.getAll,
              Cell: (row) => {
                return row.cell.value ? (
                  <span>{row.cell.value.panne}</span>
                ) : null;
              },
            },
            {
              Header: "Numéro de série",
              accessor: "materiel.num_Serie",
              search: "num_Serie",
              hideable: true,
              sortable: true,
              exportable: true,
              minWidth: "140px",
              type: "text",
            },
            {
              Header: "Date Création",
              accessor: "date_Create",
              hideable: true,
              sortable: true,
              exportable: true,
              minWidth: "150px",
              type: "period",
              Cell: (row) => {
                return row.cell.value ? (
                  <span>{ToLocaleDateString(row.cell.value)}</span>
                ) : null;
              },
            },
            {
              Header: "Date réception",
              accessor: "date_Reception",
              hideable: true,
              sortable: true,
              exportable: true,
              minWidth: "150px",
              type: "period",
              Cell: (row) => {
                return row.cell.value ? (
                  <span>{ToLocaleDateString(row.cell.value)}</span>
                ) : null;
              },
            },
            {
              Header: "LLD",
              accessor: "materiel.pack",
              search: "lLD",
              hideable: true,
              sortable: true,
              exportable: true,
              minWidth: "130px",
              type: "selectSearch",
              optionFieldToReturn: "id",
              optionFieldToDisplay: "designation",
              valueFieldToDisplay: "designation",
              service: PacksService.getAll,

              Cell: (row) => {
                if (row.cell.value) {
                  return <div>{row.cell.value.designation}</div>;
                } else {
                  return null;
                }
              },
            },
            {
              Header: "Resultat test",
              accessor: "resultat_Test",
              hideable: true,
              sortable: true,
              exportable: true,
              minWidth: "130px",
              type: "selectSearch",
              optionFieldToReturn: "value",
              optionFieldToDisplay: "display",
              valueFieldToDisplay: "display",
              options: [
                {
                  id: 1,
                  display: "OK",
                  value: true,
                },
                {
                  id: 2,
                  display: "Non OK",
                  value: false,
                },
              ],
              Cell: (row) => {
                if (row.cell.value == true) {
                  return <span>OK</span>;
                } else if (row.cell.value == false) {
                  return (
                    <div>
                      <span>Non OK </span>
                      <FontAwesomeIcon
                        className="text-danger"
                        icon={faExclamationTriangle}
                      />
                    </div>
                  );
                } else {
                  return null;
                }
              },
            },
            {
              Header: "Source",
              accessor: "source",
              search: "source",
              hideable: true,
              sortable: true,
              exportable: true,
              minWidth: "130px",
              type: "selectSearch",
              optionFieldToReturn: "id",
              optionFieldToDisplay: "designation",
              valueFieldToDisplay: "designation",
              service: FicheDeSuiviProvenanceService.getAll,

              Cell: (row) => {
                if (row.cell.value) {
                  return <div>{row.cell.value.designation}</div>;
                } else {
                  return null;
                }
              },
            },
            {
              Header: "Sujet",
              accessor: "sujet",
              search: "sujet",
              hideable: true,
              sortable: true,
              exportable: true,
              minWidth: "130px",
              type: "selectSearch",
              optionFieldToReturn: "id",
              optionFieldToDisplay: "designation",
              valueFieldToDisplay: "designation",
              service: FicheDeSuiviTypeService.getAll,

              Cell: (row) => {
                if (row.cell.value) {
                  return <div>{row.cell.value.designation}</div>;
                } else {
                  return null;
                }
              },
            },
            {
              Header: "Produit interne",
              accessor: "produitInterne",
              search: "produitInterne",
              hideable: true,
              sortable: true,
              exportable: true,
              minWidth: "130px",
              type: "selectSearch",
              optionFieldToReturn: "id",
              optionFieldToDisplay: "designation",
              valueFieldToDisplay: "designation",
              service: ProduitInterneService.getAll,

              Cell: (row) => {
                if (row.cell.value) {
                  return <div>{row.cell.value.designation}</div>;
                } else {
                  return null;
                }
              },
            },
            {
              Header: "Réclamation",
              accessor: "reclamation",
              hideable: true,
              sortable: true,
              exportable: true,
              defaultValue: null,
              minWidth: "130px",
              type: "selectSearch",
              optionFieldToReturn: "value",
              optionFieldToDisplay: "display",
              valueFieldToDisplay: "display",
              options: [
                {
                  id: 1,
                  display: "Oui",
                  value: true,
                },
                {
                  id: 2,
                  display: "Non",
                  value: false,
                },
              ],
              Cell: (row) => {
                if (row.cell.value == true) {
                  return (
                    <div>
                      Oui
                      <FontAwesomeIcon
                        className="text-danger"
                        icon={faExclamationTriangle}
                      />
                    </div>
                  );
                } else {
                  return <div>Non</div>;
                }
              },
            },
          ]}
        ></ReactTableServerSide>
      </>
    );
  }
}

BaseFicheDeSuivi.propTypes = {
  history: PropTypes.object,
};

export { BaseFicheDeSuivi };
