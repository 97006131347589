import React from "react";
import { App } from "App";

import { Box, ButtonIcon } from "_components";

import { WarningBar } from "_components";
import { faPlus } from "@fortawesome/free-solid-svg-icons";

import { LigneTacheClient } from "./LigneTacheClient";

class TacheClient extends React.PureComponent {
  constructor(props) {
    super(props);
  }

  render() {
    const right = App.RightsGuard?.current?.hasRight("Client", "Actions");
    if (right == "RW" || right == "RO") {
      return (
        <>
          <Box
            header={
              <div className="row text-align-center">
                <div className="col-6 py-2">Tâches Client</div>
                <div className="col-6 text-end py-2">
                  <ButtonIcon
                    smallText=""
                    icon={faPlus}
                    iconSize="sm"
                    onClick={() => {
                      this.props.openDialogCreationTacheFunc();
                    }}
                    className="btn btn-success"
                  ></ButtonIcon>
                </div>
              </div>
            }
            body={
              <table
                id="tableTacheClient"
                className={
                  "table table-sm" +
                  (this.props.client.taches?.length > 0
                    ? ""
                    : " table-bordered")
                }
              >
                <thead
                  className={
                    "sticky-top" +
                    (this.props.client?.taches?.length == 0
                      ? " theadBorderRadius"
                      : "")
                  }
                >
                  <tr className="py-1">
                    <th></th>
                    <th>Statut</th>
                    <th>Date de création</th>
                    <th>Contact</th>
                    <th>Action</th>
                    <th>Doc.</th>
                    <th>Produit Interne</th>
                    <th>Priorité</th>
                    <th>Assignée à</th>
                  </tr>
                </thead>
                <tbody>
                  {this.props.client.taches
                    ?.slice()
                    .sort((a, b) =>
                      (b.joursRetard ?? 0) === (a.joursRetard ?? 0)
                        ? new Date(b.dateCreation) - new Date(a.dateCreation)
                        : (b.joursRetard ?? 0) - (a.joursRetard ?? 0)
                    )
                    .map((tache, index) => (
                      <LigneTacheClient
                        key={tache.id}
                        tache={tache}
                        index={index}
                        handleChangeTache={this.props.handleChangeTache}
                        handleClickEditionTache={
                          this.props.handleClickEditionTache
                        }
                      />
                    ))}
                </tbody>
              </table>
            }
            noPaddingBottom={true}
          />
        </>
      );
    } else
      return (
        <>
          <WarningBar
            active={false}
            content={"Vous n'avez pas le droit de voir cette page"}
          />
        </>
      );
  }
}

export { TacheClient };
