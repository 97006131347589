// function AddCSSToHead(fileName) {
//   let head = document.head;
//   let link = document.createElement("link");

//   // On enleve le theme précedent
//   const theme = document.getElementById("theme");
//   if (theme) {
//     theme.remove();
//   }

//   link.type = "text/css";
//   link.rel = "stylesheet";
//   link.href = fileName;
//   link.id = "theme";

//   head.appendChild(link);
// }

// export { AddCSSToHead };
