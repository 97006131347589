import http from "http-common";


const CarteBancaireService = class {
    /**
     * Fonction permettant de récuperer tous les articles avec une possibilité de filtre via params
     * @param {*} params, paramètres à utiliser pour la requête (page, nombre d'éléments par page et filtres sur les champs)
     * @returns une liste des articles respectant les critères de params et le nombre de page pour ces critères
     */
    static getAll(params) {
      return http.get("/cartebancaire/", { params });
    }

    static getById(id) {
        return http.get("/cartebancaire/" + id);
    }

    static post(params) {
      return http.post("/cartebancaire/", params);
    }

    static put(params) {
      return http.put("/cartebancaire/", params);
    }

    static delete(params) {
      return http.delete("/cartebancaire/"+params);
    }

    static numeroter(numero,params) {
      return http.put("/cartebancaire/numeroter/"+numero, params);
    }
};


export { CarteBancaireService };
