// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `

@media (max-width: 992px) {
  .text-center-md {
    text-align: center;
  }
}

.borderIconTypeClient {
  border: 1px solid var(--bs-light);
  border-radius: 500px;
}
`, "",{"version":3,"sources":["webpack://./src/FicheClient/General/General.css"],"names":[],"mappings":";;AAEA;EACE;IACE,kBAAkB;EACpB;AACF;;AAEA;EACE,iCAAiC;EACjC,oBAAoB;AACtB","sourcesContent":["\n\n@media (max-width: 992px) {\n  .text-center-md {\n    text-align: center;\n  }\n}\n\n.borderIconTypeClient {\n  border: 1px solid var(--bs-light);\n  border-radius: 500px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
