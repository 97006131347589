// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#module .select-search__select {
    position:fixed;
  } `, "",{"version":3,"sources":["webpack://./src/PageAdmin/ReferentielDroit/ReferentielDroit.css"],"names":[],"mappings":"AAAA;IACI,cAAc;EAChB","sourcesContent":["#module .select-search__select {\n    position:fixed;\n  } "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
