import React from "react";
import { PropTypes } from "prop-types";
import { App } from "App";

import { LigneArticleStock } from "./LigneArticleStock";
import { Box, WarningBar } from "_components";

import "./Stock.css";

class Stock extends React.PureComponent {
  constructor(props) {
    super(props);
  }

  render() {
    const right = App.RightsGuard?.current?.hasRight("Article", "Stock");
    if (right == "RW" || right == "RO") {
      return (
        <>
          <Box
            noPaddingBottom={true}
            header={
              <div className="col-12 align-self-center py-2">
                <div>Stock</div>
              </div>
            }
            body={
              <table className="table table-sm table-striped table-bordered">
                <thead className="sticky-top">
                  <tr>
                    <th>Dépot</th>
                    <th>Pays Dépot</th>
                    <th>Stock</th>
                    <th>Stock Critique Maxi</th>
                    <th>Stock Critique Mini</th>
                    <th>Dernière modification</th>
                  </tr>
                </thead>
                <tbody>
                  {this.props.article.articles_Stock
                    .sort((a, b) => a.depot.dE_No - b.depot.dE_No)
                    .map((articleStock, index) => (
                      <LigneArticleStock
                        articleStock={articleStock}
                        key={index}
                      />
                    ))}
                </tbody>
              </table>
            }
          />
        </>
      );
    } else {
      return (
        <>
          <WarningBar
            active={false}
            content={"Vous n'avez pas le droit de voir cette page"}
          />
        </>
      );
    }
  }
}

Stock.propTypes = {
  article: PropTypes.object,
  stateFieldNameToUpdate: PropTypes.string,
  history: PropTypes.object,
  service: PropTypes.func,
};

export { Stock };
