// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.toast {
    z-index: 2147483647;
    background-color: rgba(255,255,255,255);
    position: relative;
}

`, "",{"version":3,"sources":["webpack://./src/_components/Toaster/Toast.css"],"names":[],"mappings":"AAAA;IACI,mBAAmB;IACnB,uCAAuC;IACvC,kBAAkB;AACtB","sourcesContent":[".toast {\n    z-index: 2147483647;\n    background-color: rgba(255,255,255,255);\n    position: relative;\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
