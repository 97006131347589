import React from "react";
import { IndeterminateCheckbox, ReactTableServerSide } from "_components";

import {
  ClientService,
  DevisService,
  PersonnelService,
  ProduitInterneService,
  ResultatService,
} from "_services";
import { RedirectTo, ToLocaleDateString } from "_utils";
import { PropTypes } from "prop-types";
import { CellWithRedirection } from "_components/ReactTable/ReactTableServerSide";
import { Helmet } from "react-helmet";
import { App } from "App";

class BaseDevis extends React.PureComponent {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <>
        <Helmet>
          <title>Base devis</title>
        </Helmet>
        <ReactTableServerSide
          title="Base devis"
          titreFiltre="Base devis"
          service={DevisService.getAll}
          displayExtractButton={
            App.RightsGuard.current?.hasRight("Devis", "Extraction") == "RW"
          }
          serviceExtract={DevisService.Extract}
          module="Devis"
          redirectToCreate={() => {
            RedirectTo(this.props.history, "/devis/" + "add");
          }}
          orderByDefault="reference"
          localStorageKeyColumns="BaseDevisColumns"
          objectFieldToExport={{
            client: "nom_Client", // TODO
          }}
          columns={[
            {
              id: "selection",
              Header: ({ getToggleAllRowsSelectedProps }) => (
                <div className="text-center align-middle" id="selectAll">
                  <IndeterminateCheckbox
                    className="align-middle"
                    {...getToggleAllRowsSelectedProps()}
                  />
                </div>
              ),
              Cell: ({ row }) => (
                <div className="text-center align-middle" id="selectOne">
                  <IndeterminateCheckbox
                    className="align-middle"
                    {...row.getToggleRowSelectedProps()}
                  />
                </div>
              ),
            },
            {
              Header: "N° Devis",
              accessor: "reference",
              hideable: true,
              sortable: true,
              exportable: true,
              minWidth: "150px",
              type: "number",
              Cell: (row) => {
                return (
                  <CellWithRedirection
                    history={this.props.history}
                    redirection="/devis/"
                    id={row.cell.row.original.id}
                    value={row.cell.value}
                  />
                );
              },
            },
            {
              Header: "Nom",
              accessor: "nom_Devis",
              hideable: true,
              sortable: true,
              exportable: true,
              minWidth: "150px",
              type: "text",
            },
            {
              Header: "Chargé d'Affaires",
              accessor: "charge_Affaire",
              hideable: true,
              exportable: true,
              minWidth: "150px",
              type: "selectSearch",
              valueFieldToDisplay: ["nom", "prenom"],
              optionFieldToReturn: "id",
              optionFieldToDisplay: ["nom", "prenom"],
              service: PersonnelService.getForSelectSearch,
              search: "num_Charge_Affaire",
              Cell: (row) => {
                return row.cell.value ? (
                  <span>
                    {row.cell.value.nom} {row.cell.value.prenom}
                  </span>
                ) : null;
              },
            },
            {
              Header: "Client",
              accessor: "client",
              hideable: true,
              exportable: true,
              minWidth: "150px",
              type: "selectSearch",
              valueFieldToDisplay: "nom_Client",
              optionFieldToReturn: "nom_Client",
              optionFieldToDisplay: "nom_Client",
              service: ClientService.getForSelectSearch,
              search: "nom_client",
              Cell: (row) => {
                if (row.cell.value)
                  return (
                    <CellWithRedirection
                      history={this.props.history}
                      redirection="/clients/"
                      id={row.cell.value.id}
                      value={row.cell.value ? row.cell.value.nom_Client : null}
                    />
                  );
                else return null;
              },
            },
            {
              Header: "Produit Interne",
              accessor: "produit_Interne",
              hideable: true,
              // sortable: true,
              exportable: true,
              minWidth: "150px",
              type: "selectSearch",
              optionFieldToReturn: "id",
              optionFieldToDisplay: "designation",
              valueFieldToDisplay: "designation",
              service: ProduitInterneService.getAll,
              search: "idProduitInterne",
              Cell: (row) => {
                return row.cell.value ? (
                  <span>{row.cell.value.designation}</span>
                ) : null;
              },
            },
            {
              Header: "International",
              accessor: "international",
              hideable: true,
              sortable: true,
              exportable: true,
              minWidth: "130px",
              type: "selectSearch",
              optionFieldToReturn: "value",
              optionFieldToDisplay: "display",
              valueFieldToDisplay: "display",
              options: [
                {
                  id: 0,
                  display: "Oui",
                  value: true,
                },
                {
                  id: 1,
                  display: "Non",
                  value: false,
                },
              ],
              Cell: (row) => {
                if (row.cell.value == true) {
                  return <div>Oui</div>;
                } else {
                  return <div>Non</div>;
                }
              },
            },
            {
              Header: "Résultat",
              accessor: "resultat",
              hideable: true,
              sortable: true,
              exportable: true,
              minWidth: "150px",
              type: "selectSearch",
              optionFieldToReturn: "valeur",
              optionFieldToDisplay: "valeur",
              valueFieldToDisplay: "valeur",
              service: ResultatService.getAll,
              Cell: (row) => {
                return row.cell.value ? (
                  <span>{row.cell.value.valeur}</span>
                ) : null;
              },
            },
            {
              Header: "Date Création",
              accessor: "date_Create",
              hideable: true,
              sortable: true,
              exportable: true,
              minWidth: "150px",
              type: "period",
              Cell: (row) => {
                return row.cell.value ? (
                  <span>{ToLocaleDateString(row.cell.value)}</span>
                ) : null;
              },
            },
            {
              Header: "Date commande prévi",
              accessor: "date_Commande_Previsionnelle",
              hideable: true,
              sortable: true,
              exportable: true,
              minWidth: "150px",
              type: "period",
              Cell: (row) => {
                return row.cell.value ? (
                  <span>{ToLocaleDateString(row.cell.value)}</span>
                ) : null;
              },
            },
            {
              Header: "Montant HT",
              accessor: "prixVenteTotal",
              hideable: true,
              sortable: true,
              exportable: true,
              minWidth: "150px",
              type: "intervalDecimal",
              search: "prixVenteTotal",
              Cell: (row) => {
                return row.cell.value != null ? (
                  <span>{row.cell.value}</span>
                ) : null;
              },
            },
            {
              Header: "Montant TTC",
              accessor: "prixVenteTTCTotal",
              hideable: true,
              sortable: true,
              exportable: true,
              minWidth: "150px",
              type: "intervalDecimal",
              search: "prixVenteTTCTotal",
              Cell: (row) => {
                return row.cell.value != null ? (
                  <span>{row.cell.value}</span>
                ) : null;
              },
            },
          ]}
        ></ReactTableServerSide>
      </>
    );
  }
}

BaseDevis.propTypes = {
  history: PropTypes.object,
};

export { BaseDevis };
