import React from "react";

import { Box } from "_components";

import { ButtonIcon } from "_components";
import { faPlus } from "@fortawesome/free-solid-svg-icons";

import { ToLocaleDateString } from "_utils";

class HistoriqueAffaire extends React.PureComponent {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <>
        <Box
          noPaddingBottom={true}
          header={
            <div className="row text-align-center">
              <div className="col-6 py-2">Historique Affaire</div>
              <div className="col-6 text-end py-2">
                <ButtonIcon
                  smallText=""
                  icon={faPlus}
                  iconSize="sm"
                  onClick={() => {
                    this.props.openDialogCreationHistoFunc();
                  }}
                  className="btn btn-success"
                ></ButtonIcon>
              </div>
            </div>
          }
          body={
            <table className="table table-striped table-bordered table-sm">
              <thead
                className={
                  "sticky-top" +
                  (this.props.affaire.historiques?.length == 0
                    ? " theadBorderRadius"
                    : "")
                }
              >
                <tr>
                  <th className="text-nowrap ps-2" style={{ width: "150px" }}>
                    Date
                  </th>
                  <th className="text-nowrap ps-2" style={{ width: "150px" }}>
                    Process
                  </th>
                  <th className="text-nowrap ps-2" style={{ width: "300px" }}>
                    Action
                  </th>
                  <th
                    className="text-nowrap ps-2"
                    style={{ minWidth: "500px" }}
                  >
                    Description
                  </th>
                </tr>
              </thead>
              <tbody>
                {this.props.affaire?.historiques
                  ?.slice()
                  ?.sort(
                    (a, b) =>
                      new Date(b.dateCreation) - new Date(a.dateCreation)
                  )
                  .map((historique) => {
                    return (
                      <tr key={historique.id}>
                        <td className="text-nowrap ps-2">
                          {ToLocaleDateString(historique.dateCreation)}
                        </td>
                        <td className="text-nowrap ps-2">
                          {historique.process?.designation ?? "-"}
                        </td>
                        <td className="ps-2" style={{ minWidth: "200px" }}>
                          {historique.action?.designation ?? "-"}
                        </td>
                        <td className="ps-2 keepWhiteSpace">
                          {historique.description}
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>
          }
        />
      </>
    );
  }
}

export { HistoriqueAffaire };
