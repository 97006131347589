import { sanitizeValue, isAValue } from "_utils/DecimalFunctions";

export function computeCommandeDetail(
  accessor,
  commandeDetail,
  tauxTVA,
  cours
) {
  switch (accessor) {
    case "article":
    case "prixAchatUnitaire":
    case "coefficient":
      computePrixVenteUnitaire(commandeDetail);
    // No break, we continue calculating the following values.
    case "prixVenteUnitaire":
    case "quantite":
    case "remise":
      computePrixVenteTotal(commandeDetail, cours);
    // No break, we continue calculating the following values.
    case "prixVenteTotal":
      computeMontantTVA(commandeDetail, tauxTVA, cours);
      computePrixVenteTotalTTC(commandeDetail, cours);
      break; // Break, we stop.
    case "montantTVA":
      computePrixVenteTotalTTC(commandeDetail);
      break;
  }
}

export function computePrixVenteUnitaire(commandeDetail) {
  var prixAchatUnitaire = sanitizeValue(commandeDetail.prixAchatUnitaire);
  var coefficient = sanitizeValue(commandeDetail.coefficient);
  if (!isAValue(coefficient) || coefficient == 0) {
    coefficient = 1;
  }
  if (isAValue(prixAchatUnitaire) && parseFloat(prixAchatUnitaire) != 0) {
    commandeDetail.prixVenteUnitaire = coefficient * prixAchatUnitaire;
  }
}

export function computePrixVenteTotal(commandeDetail, cours) {
  var remise = sanitizeValue(commandeDetail.remise);
  var quantite = sanitizeValue(commandeDetail.quantite);
  var prixVenteUnitaire = sanitizeValue(commandeDetail.prixVenteUnitaire);

  if (isAValue(prixVenteUnitaire)) {
    commandeDetail.prixVenteTotal =
      quantite * prixVenteUnitaire * (1 - remise / 100);
    commandeDetail.prixVenteTotalDevise = commandeDetail.prixVenteTotal * cours;
  }
}

export function computeMontantTVA(commandeDetail, tauxTVA, cours) {
  var prixVenteTotal = sanitizeValue(commandeDetail.prixVenteTotal);
  var tva = 0;
  if (isAValue(tauxTVA)) {
    tva = sanitizeValue(tauxTVA);
  }
  commandeDetail.montantTVA = prixVenteTotal * (tva / 100);
  commandeDetail.montantTVADevise = commandeDetail.montantTVA * cours;
}

export function computePrixVenteTotalTTC(commandeDetail, cours) {
  var prixVenteTotal = sanitizeValue(commandeDetail.prixVenteTotal);
  var montantTVA = sanitizeValue(commandeDetail.montantTVA);
  commandeDetail.prixVenteTotalTTC = prixVenteTotal + montantTVA;
  commandeDetail.prixVenteTotalTTCDevise =
    commandeDetail.prixVenteTotalTTC * cours;
}

export function computeTotals(commande) {
  var totalPrixVenteTotal = 0;
  var totalMontantTVA = 0;
  var totalPrixVenteTotalTTC = 0;

  var totalPrixVenteTotalDevise = 0;
  var totalMontantTVADevise = 0;
  var totalPrixVenteTotalTTCDevise = 0;
  for (let i in commande.commandeDetails) {
    if (commande.commandeDetails[i].type == 0) {
      totalPrixVenteTotal += sanitizeValue(
        commande.commandeDetails[i].prixVenteTotal
      );
      totalMontantTVA += sanitizeValue(commande.commandeDetails[i].montantTVA);
      totalPrixVenteTotalTTC += sanitizeValue(
        commande.commandeDetails[i].prixVenteTotalTTC
      );

      totalPrixVenteTotalDevise += sanitizeValue(
        commande.commandeDetails[i].prixVenteTotalDevise
      );
      totalMontantTVADevise += sanitizeValue(
        commande.commandeDetails[i].montantTVADevise
      );
      totalPrixVenteTotalTTCDevise += sanitizeValue(
        commande.commandeDetails[i].prixVenteTotalTTCDevise
      );
    }
  }

  commande.prixVenteTotal = totalPrixVenteTotal;
  commande.montantTVATotal = totalMontantTVA;
  commande.prixVenteTTCTotal = totalPrixVenteTotalTTC;

  commande.prixVenteTotalDevise = totalPrixVenteTotalDevise;
  commande.montantTVATotalDevise = totalMontantTVADevise;
  commande.prixVenteTTCTotalDevise = totalPrixVenteTotalTTCDevise;
}

export function computeSubTotals(commandeDetails) {
  let prixVenteTotal = 0;
  let montantTVA = 0;
  let prixVenteTotalTTC = 0;

  let prixVenteTotalDevise = 0;
  let montantTVADevise = 0;
  let prixVenteTotalTTCDevise = 0;
  for (let i = 0; i < commandeDetails.length; i++) {
    // Partant de la première ligne des commandeDetails, on descend les lignes en sommant, jusqu'a tomber sur une ligne de sous-total (type = 2), où on place la somme.
    if (commandeDetails[i].type === 2) {
      commandeDetails[i].prixVenteTotal = prixVenteTotal;
      prixVenteTotal = 0;
      commandeDetails[i].montantTVA = montantTVA;
      montantTVA = 0;
      commandeDetails[i].prixVenteTotalTTC = prixVenteTotalTTC;
      prixVenteTotalTTC = 0;

      commandeDetails[i].prixVenteTotalDevise = prixVenteTotalDevise;
      prixVenteTotalDevise = 0;
      commandeDetails[i].montantTVADevise = montantTVADevise;
      montantTVADevise = 0;
      commandeDetails[i].prixVenteTotalTTCDevise = prixVenteTotalTTCDevise;
      prixVenteTotalTTCDevise = 0;
    } else if (commandeDetails[i].type == 0) {
      prixVenteTotal += commandeDetails[i].prixVenteTotal;
      montantTVA += commandeDetails[i].montantTVA;
      prixVenteTotalTTC += commandeDetails[i].prixVenteTotalTTC;

      prixVenteTotalDevise += commandeDetails[i].prixVenteTotalDevise;
      montantTVADevise += commandeDetails[i].montantTVADevise;
      prixVenteTotalTTCDevise += commandeDetails[i].prixVenteTotalTTCDevise;
    }
  }
}
