import http from "http-common";

const JoursFeriesService = class {
  static getAll() {
    let url = "/JoursFeries";

    return http.get(url);
  }

  static getAllByPeriod(params) {
    let url = "/JoursFeriesByPeriod";
    return http.get(url, { params });
  }
};

export { JoursFeriesService };
