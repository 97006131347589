import React from "react";
import { PropTypes } from "prop-types";
import { Link } from "react-router-dom";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { App } from "App";

import { General } from ".";
import { WarningBar } from "_components";

import { ContratService } from "_services";
import { ButtonIconWithValidation } from "_components";
import { faTrash } from "@fortawesome/free-solid-svg-icons";

import { Helmet } from "react-helmet";
import produce from "immer";

import moment from "moment";
import TabSelector from "_components/FicheComponents/TabSelector";

class FicheContrat extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      tabs: ["Général"],
      active: "Général",
      editing: false,
      contrat: {},
    };

    this.renderActive = this.renderActive.bind(this);
    this.generateTabs = this.generateTabs.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleEditing = this.handleEditing.bind(this);
    this.handleDelete = this.handleDelete.bind(this);

    this.handleUpdate = this.handleUpdate.bind(this);

    this.deleteMateriel = this.deleteMateriel.bind(this);
    this.computeMontantperiodique = this.computeMontantperiodique.bind(this);
  }

  computeMontantperiodique(prevState) {
    if (
      prevState.contrat.duree &&
      prevState.contrat.montantAbonnementHT &&
      prevState.contrat.periodicite
    ) {
      return (
        (prevState.contrat.montantAbonnementHT /
          prevState.contrat.duree.nbMois) *
        prevState.contrat.periodicite.valeur
      );
    }
  }

  componentDidMount() {
    if (sessionStorage.getItem("ContratOnglet") == null) {
      sessionStorage.setItem("ContratOnglet", this.state.active);
    } else {
      this.setState({ active: sessionStorage.getItem("ContratOnglet") });
    }

    let { id } = this.props.match.params;
    ContratService.getById(id).then((res) => {
      this.setState({ contrat: res.data, loading: false });
    });
  }

  deleteMateriel(idMat) {
    this.setState(
      produce((prevState) => {
        prevState.contrat.materiels.splice(
          prevState.contrat.materiels.findIndex((el) => el.id === idMat),
          1
        );
      })
    );
  }

  handleChange(accessor, value, afterUpdateFunction) {
    if (accessor.includes(".")) {
      var accessorSplit = accessor.split(".");

      // Nested property (separated by a '.')

      this.setState(
        produce((prevState) => {
          prevState.contrat[accessorSplit[0]][accessorSplit[1]] = value;
        }),
        afterUpdateFunction
      );
    } else {
      // Shallow property
      if (accessor == "financeur") {
        this.setState(
          produce((prevState) => {
            prevState.contrat.financeur = value;
            prevState.contrat.numEtude =
              value?.designation == "Sol Solution"
                ? this.state.modeleNumEtude
                : "";
          })
        );
      } else if (accessor == "materiels") {
        this.setState(
          produce((prevState) => {
            prevState.contrat.materiels.splice(0, 0, value);
          })
        );
      } else if (accessor == "duree") {
        if (value != null) {
          this.setState(
            produce((prevState) => {
              prevState.contrat.duree = value;
              if (prevState.contrat.debutAbonnement)
                prevState.contrat.finAbonnement = moment(
                  prevState.contrat.debutAbonnement
                )
                  .add(prevState.contrat.duree.nbMois, "months")
                  .toISOString();

              prevState.contrat.montantAbonnementPeriodiqueHT =
                this.computeMontantperiodique(prevState);
            })
          );
        }
      } else if (accessor == "debutAbonnement") {
        if (value != null) {
          this.setState(
            produce((prevState) => {
              prevState.contrat.debutAbonnement = value;
              if (prevState.contrat.duree)
                prevState.contrat.finAbonnement = moment(
                  prevState.contrat.debutAbonnement
                )
                  .add(prevState.contrat.duree.nbMois, "months")
                  .toISOString();
            })
          );
        }
      } else if (accessor == "montantAbonnementHT") {
        if (value != null) {
          this.setState(
            produce((prevState) => {
              prevState.contrat.montantAbonnementHT = value;

              prevState.contrat.montantAbonnementPeriodiqueHT =
                this.computeMontantperiodique(prevState);
            })
          );
        }
      } else if (accessor == "periodicite") {
        if (value != null) {
          this.setState(
            produce((prevState) => {
              prevState.contrat.periodicite = value;

              prevState.contrat.montantAbonnementPeriodiqueHT =
                this.computeMontantperiodique(prevState);
            })
          );
        }
      } else {
        this.setState(
          produce((prevState) => {
            prevState.contrat[accessor] = value;
          }),
          afterUpdateFunction
        );
      }
    }
  }

  handleUpdate() {
    return ContratService.put(this.state.contrat).then((res) => {
      this.setState({ contrat: res.data });
    });
  }

  handleDelete() {
    return ContratService.delete(this.state.contrat.id).then((res) => {
      this.setState({ contrat: res.data });
    });
  }

  handleEditing(editing) {
    this.setState((prevState) => ({
      ...prevState,
      editing: editing,
    }));
  }

  /**
   * Génère les onglets de la fiche
   * @param {*} tabs
   * @returns
   */
  generateTabs(tabs) {
    return (
      <div>
        <TabSelector
          tabs={tabs}
          activeTab={this.state.active}
          onSelect={(tab) => {
            this.setState({ active: tab });
            sessionStorage.setItem("ContratOnglet", tab);
          }}
          notAllowed={this.state.editing}
        ></TabSelector>
      </div>
    );
  }

  renderActive() {
    switch (this.state.active) {
      case "Général":
        return (
          <General
            contrat={this.state.contrat}
            stateFieldNameToUpdate="contrat"
            handleChange={this.handleChange}
            handleUpdate={this.handleUpdate}
            service={ContratService}
            history={this.props.history}
            editing={this.state.editing}
            handleEditing={this.handleEditing}
            deleteMateriel={this.deleteMateriel}
            handleRedirectionMat={this.handleRedirectionMat}
          />
        );

      default:
        return (
          <h4 className="mt-5">Désolé la page n&apos;a pas réussi à charger</h4>
        );
    }
  }

  render() {
    const isContactEmpty = !Object.entries(this.state.contrat).length;
    return (
      <>
        <Helmet>
          <title>{"Contrat : ".concat(this.state.contrat.numEtude)}</title>
        </Helmet>
        {this.state.loading ? (
          <div
            style={{
              flexGrow: 1,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <FontAwesomeIcon icon={faSpinner} size="lg" className="fa-spin" />
          </div>
        ) : (
          <div className="p-3 m-4" id="ficheClient">
            <div className="d-flex overflow-auto justify-content-between py-1">
              <h4 className="nowrap pr-100">
                {"Contrat: ".concat(this.state.contrat.numEtude)}{" "}
              </h4>
              <div className="d-flex">
                <Link to="/contrats" className="btn btn-primary nowrap ms-1">
                  Base Contrat
                </Link>
                {this.props.history.length > 1 && (
                  <button
                    className="btn btn-primary nowrap ms-1"
                    onClick={() => {
                      this.props.history.goBack();
                    }}
                  >
                    Retour
                  </button>
                )}
                {App.RightsGuard.current?.hasRight("Contrat", "Suppression") ==
                "RW" ? (
                  <ButtonIconWithValidation
                    icon={faTrash}
                    onClick={this.handleDelete}
                    className="form-control btn btn-danger text-light ms-1"
                    alertTitle=" Suppression"
                    alertBody={
                      <div>
                        Souhaitez-vous réellement désactiver ce contrat ? <br />
                      </div>
                    }
                    alertButtonValidationText="Oui, je veux supprimer."
                  ></ButtonIconWithValidation>
                ) : null}
              </div>
            </div>
            {this.generateTabs(this.state.tabs)}

            <WarningBar
              active={this.state.contrat.actif}
              content={"Ce contrat n'est pas actif"}
            />
            {!isContactEmpty && this.renderActive()}
          </div>
        )}
      </>
    );
  }
}

/**
 * Validation des props :
 */
FicheContrat.propTypes = {
  history: PropTypes.object,
  match: PropTypes.object,
};

export { FicheContrat };
