import React from "react";
import "./Calculette.css";
import { App } from "App";

import { Input } from "_components/Input";

import { ButtonIcon, WarningBar } from "_components";
import { faMinusSquare, faPlusSquare } from "@fortawesome/free-solid-svg-icons";

class Calculette extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      savedDevis: null,
      persoPlie: true,
      primePlie: true,
      deplaPlie: true,
      matosPlie: true,
      autrePlie: true,
    };

    this.input = this.input.bind(this);
    this.emptyCell = this.emptyCell.bind(this);
    this.renderCalculette = this.renderCalculette.bind(this);
    this.handleClickEdition = this.handleClickEdition.bind(this);
    this.handleClickUndo = this.handleClickUndo.bind(this);
    this.handleClickValidate = this.handleClickValidate.bind(this);
    this.renderAutre = this.renderAutre.bind(this);
    this.renderDepla = this.renderDepla.bind(this);
    this.renderHeadCalculette = this.renderHeadCalculette.bind(this);
    this.renderMat = this.renderMat.bind(this);
    this.renderPersonnel = this.renderPersonnel.bind(this);
    this.renderSousTotal = this.renderSousTotal.bind(this);
    this.renderPrime = this.renderPrime.bind(this);
    this.getColor = this.getColor.bind(this);
    this.getRedOrGreen = this.getRedOrGreen.bind(this);
    this.showMarge = this.showMarge.bind(this);
  }

  input({ accessor, disabled = false, numberOfDecimals }) {
    return (
      <td className="input">
        <Input
          value={this.props.affaire.calculette[accessor]}
          ignoredValues={[0]}
          numberOfDecimals={numberOfDecimals}
          handleBlur={this.props.handleChange}
          type="decimal"
          accessor={"calculette." + accessor}
          showClearButton={false}
          showValidator={false}
          disabled={disabled || !this.props.editing}
        ></Input>
      </td>
    );
  }

  emptyCell() {
    return <td className="empty"></td>;
  }

  handleClickEdition() {
    this.setState({
      savedAffaire: { ...this.props.affaire },
    });
    this.props.handleEditing(true);
  }

  handleClickUndo() {
    this.props.handleChange(
      this.props.stateFieldNameToUpdate,
      this.state.savedAffaire
    );
    this.props.handleEditing(false);
  }

  handleClickValidate() {
    this.props.handleUpdate();
    this.props.handleEditing(false);
  }

  getColor(value) {
    let cal = this.props.affaire.calculette;
    let couleur = cal.couleur;
    if (value > couleur?.borne5) {
      return couleur?.borne5Couleur;
    } else if (value > couleur?.borne4) {
      return couleur?.borne4Couleur;
    } else if (value > couleur?.borne3) {
      return couleur?.borne3Couleur;
    } else if (value > couleur?.borne2) {
      return couleur?.borne2Couleur;
    } else if (value > couleur?.borne1) {
      return couleur?.borne1Couleur;
    } else {
      return "red";
    }
  }

  getRedOrGreen(value) {
    if (value > 0) return "green";
    else {
      return "red";
    }
  }

  showMarge() {
    let calculette = this.props.affaire?.calculette;

    let value =
      calculette.personnel_ResponsableService_Marge +
      calculette.personnel_ResponsablePole_Marge +
      calculette.personnel_ChargeDAffaire_Marge +
      calculette.personnel_Techniciens_Marge +
      calculette.personnel_Assistante_Marge +
      calculette.prime_MajorationHeuresChantierNuit_Marge +
      calculette.prime_PrimeDeNuit_Marge +
      calculette.prime_PrimeDeDeplacement_Marge +
      calculette.prime_PrimeDeDepla4j_Marge +
      calculette.prime_PrimeDeDepla5j_Marge +
      calculette.prime_RecupHeureDeNuit_Marge +
      calculette.prime_MajorationHeuresSupp_Marge +
      calculette.prime_MajorationHeuresDimanche_Marge +
      calculette.prime_Weekend_Marge +
      calculette.prime_MajorationHeuresFeriee_Marge +
      calculette.deplacements_ForfaitNuit_Marge +
      calculette.deplacements_ForfaitRestaurant_Marge +
      calculette.deplacements_Essence_Marge +
      calculette.deplacements_Peages_Marge +
      calculette.deplacements_VehiculeFraisFixesParJour_Marge +
      calculette.deplacements_TrainAvion_Marge +
      calculette.materiels_SystemeDInformations_Marge +
      calculette.materiels_MaterielIndustriel_A_Marge +
      calculette.materiels_SousTraitanceOuLocation_Marge +
      calculette.materiels_Consommables_Marge +
      calculette.autres_A_Marge +
      calculette.autres_B_Marge +
      calculette.autres_C_Marge +
      calculette.autres_D_Marge +
      calculette.autres_E_Marge;

    return value != 0;
  }

  renderHeadCalculette() {
    let showmarge = this.showMarge();
    return (
      <>
        <tr>
          <th colSpan="1"></th>
          <th colSpan={showmarge ? "4" : "3"}>Prévisionnel</th>
          <th colSpan="7">Réalisé</th>
        </tr>
        <tr>
          <th></th>

          <th>Quantité</th>
          <th>Total coûts</th>
          <th>% coûts</th>
          {showmarge ? <th>€ marge</th> : null}
          <th></th>
          <th>Quantité</th>
          <th>Total coûts</th>
          <th>% coûts</th>
          <th>Ecart €</th>
          <th>Ecart %</th>
        </tr>
      </>
    );
  }

  renderPersonnel() {
    let showmarge = this.showMarge();

    return (
      <>
        <tr>
          <th className="Titlelabel">
            <ButtonIcon
              icon={this.state.persoPlie ? faPlusSquare : faMinusSquare}
              className="btn"
              onClick={() =>
                this.setState((prevState) => ({
                  persoPlie: !prevState.persoPlie,
                }))
              }
              style={{ width: "32px" }}
            ></ButtonIcon>
            Personnel
          </th>
          {this.input({ accessor: "personnel_Total", disabled: true })}
          {this.input({
            accessor: "personnel_TotalCouts",
            disabled: true,
          })}
          {this.input({
            accessor: "personnel_PartCouts",
            disabled: true,
          })}
          {showmarge
            ? this.input({
                accessor: "personnel_Marge",
                disabled: true,
              })
            : null}
          <th colSpan="1"></th>

          {this.input({
            accessor: "personnel_TotalRealise",
            disabled: true,
          })}
          {this.input({
            accessor: "personnel_TotalCoutsRealise",
            disabled: true,
          })}
          {this.input({
            accessor: "personnel_PartCoutsRealise",
            disabled: true,
          })}
          {
            <td className="input" style={{ height: "50px" }}>
              <div
                className="d-flex"
                style={{
                  height: "100%",
                }}
              >
                <div
                  style={{
                    height: "100%",
                    width: "15px",
                    backgroundColor: this.getRedOrGreen(
                      this.props.affaire.calculette.personnel_Ecart
                    ),
                  }}
                ></div>
                <Input
                  value={this.props.affaire.calculette.personnel_Ecart}
                  ignoredValues={[0]}
                  handleBlur={this.props.handleChange}
                  type="decimal"
                  accessor={"calculette.personnel_Ecart"}
                  showClearButton={false}
                  showValidator={false}
                  disabled={true}
                  style={{
                    color: this.getRedOrGreen(
                      this.props.affaire.calculette.personnel_Ecart
                    ),
                  }}
                ></Input>
              </div>
            </td>
          }
          {
            <td className="input" style={{ height: "50px" }}>
              <div
                className="d-flex"
                style={{
                  height: "100%",
                }}
              >
                <div
                  style={{
                    height: "100%",
                    width: "15px",
                    backgroundColor: this.getRedOrGreen(
                      -1 * this.props.affaire.calculette.personnel_TauxEcart
                    ),
                  }}
                ></div>
                <Input
                  value={this.props.affaire.calculette.personnel_TauxEcart}
                  ignoredValues={[0]}
                  handleBlur={this.props.handleChange}
                  type="decimal"
                  accessor={"calculette.personnel_TauxEcart"}
                  showClearButton={false}
                  showValidator={false}
                  disabled={true}
                  style={{
                    color: this.getRedOrGreen(
                      -1 * this.props.affaire.calculette.personnel_TauxEcart
                    ),
                  }}
                ></Input>
              </div>
            </td>
          }
        </tr>
        {this.state.persoPlie ? null : (
          <>
            <tr>
              <td className="label">Responsable Service</td>
              {this.input({
                accessor: "personnel_ResponsableService_Total",
                disabled: true,
              })}
              {this.input({
                accessor: "personnel_ResponsableService_TotalCouts",
                disabled: true,
              })}
              <td></td>
              {showmarge
                ? this.input({
                    accessor: "personnel_ResponsableService_Marge",
                    disabled: true,
                  })
                : null}
              <td rowSpan="5"></td>
              {this.input({
                accessor: "personnel_ResponsableService_TotalRealise",
                disabled: true,
              })}
              {this.input({
                accessor: "personnel_ResponsableService_Realise",
                disabled: true,
              })}
            </tr>
            <tr>
              <td className="label">Responsable Pôle</td>
              {this.input({
                accessor: "personnel_ResponsablePole_Total",
                disabled: true,
              })}
              {this.input({
                accessor: "personnel_ResponsablePole_TotalCouts",
                disabled: true,
              })}
              <td></td>
              {showmarge
                ? this.input({
                    accessor: "personnel_ResponsablePole_Marge",
                    disabled: true,
                  })
                : null}

              {this.input({
                accessor: "personnel_ResponsablePole_TotalRealise",
                disabled: true,
              })}
              {this.input({
                accessor: "personnel_ResponsablePole_Realise",
                disabled: true,
              })}
            </tr>

            <tr>
              <td className="label">Chargé d'affaire</td>
              {this.input({
                accessor: "personnel_ChargeDAffaire_Total",
                disabled: true,
              })}
              {this.input({
                accessor: "personnel_ChargeDAffaire_TotalCouts",
                disabled: true,
              })}
              <td></td>
              {showmarge
                ? this.input({
                    accessor: "personnel_ChargeDAffaire_Marge",
                    disabled: true,
                  })
                : null}

              {this.input({
                accessor: "personnel_ChargeDAffaire_TotalRealise",
                disabled: true,
              })}
              {this.input({
                accessor: "personnel_ChargeDAffaire_Realise",
                disabled: true,
              })}
            </tr>
            <tr>
              <td className="label">Techniciens</td>
              {this.input({
                accessor: "personnel_Techniciens_Total",
                disabled: true,
              })}
              {this.input({
                accessor: "personnel_Techniciens_TotalCouts",
                disabled: true,
              })}
              <td></td>
              {showmarge
                ? this.input({
                    accessor: "personnel_Techniciens_Marge",
                    disabled: true,
                  })
                : null}

              {this.input({
                accessor: "personnel_Techniciens_TotalRealise",
                disabled: true,
              })}
              {this.input({
                accessor: "personnel_Techniciens_Realise",
                disabled: true,
              })}
            </tr>
            <tr>
              <td className="label">Assistante</td>
              {this.input({
                accessor: "personnel_Assistante_Total",
                disabled: true,
              })}
              {this.input({
                accessor: "personnel_Assistante_TotalCouts",
                disabled: true,
              })}
              <td></td>
              {showmarge
                ? this.input({
                    accessor: "personnel_Assistante_Marge",
                    disabled: true,
                  })
                : null}

              {this.input({
                accessor: "personnel_Assistante_TotalRealise",
                disabled: true,
              })}
              {this.input({
                accessor: "personnel_Assistante_Realise",
                disabled: true,
              })}
            </tr>
            {/* <tr>
              <td className="label paddingLeft">
                dont heures de chantier de jour
              </td>
              {this.emptyCell()}
              {this.input({
                accessor: "personnel_HeuresDeChantierDeJour_TotalCouts",
                disabled: true,
              })}
              <td></td>
              {this.input({
                accessor: "personnel_HeuresDeChantierDeJour_Marge",
                disabled: true,
              })}
              {this.input({
                accessor: "personnel_HeuresDeChantierDeJour_Realise",
                disabled: true,
              })}
            </tr>
            <tr>
              <td className="label paddingLeft">
                dont heures de chantier de nuit
              </td>
              {this.emptyCell()}
              {this.input({
                accessor: "personnel_HeuresDeChantierDeNuit_TotalCouts",
                disabled: true,
              })}
              <td></td>
              {this.input({
                accessor: "personnel_HeuresDeChantierDeNuit_Marge",
                disabled: true,
              })}
              {this.input({
                accessor: "personnel_HeuresDeChantierDeNuit_Realise",
                disabled: true,
              })}
            </tr>
            <tr>
              <td className="label paddingLeft">dont heure de dimanche</td>
              {this.emptyCell()}
              {this.input({
                accessor: "personnel_HeuresDimanche_TotalCouts",
                disabled: true,
              })}
              <td></td>
              {this.input({
                accessor: "personnel_HeuresDimanche_Marge",
                disabled: true,
              })}
              {this.input({
                accessor: "personnel_HeuresDimanche_Realise",
                disabled: true,
              })}
            </tr>
            <tr>
              <td className="label paddingLeft">dont heures supp</td>
              {this.emptyCell()}
              {this.input({
                accessor: "personnel_HeuresSupp_TotalCouts",
                disabled: true,
              })}
              <td></td>
              {this.input({
                accessor: "personnel_HeuresSupp_Marge",
                disabled: true,
              })}
              {this.input({
                accessor: "personnel_HeuresSupp_Realise",
                disabled: true,
              })}
            </tr>
            <tr>
              <td className="label paddingLeft">dont heures férié</td>
              {this.emptyCell()}
              {this.input({
                accessor: "personnel_HeuresFeriees_TotalCouts",
                disabled: true,
              })}
              <td></td>
              {this.input({
                accessor: "personnel_HeuresFeriees_Marge",
                disabled: true,
              })}
              {this.input({
                accessor: "personnel_HeuresFeriees_Realise",
                disabled: true,
              })}
            </tr> */}
          </>
        )}
      </>
    );
  }

  renderPrime() {
    let showmarge = this.showMarge();

    return (
      <>
        <tr>
          <th className="Titlelabel">
            <ButtonIcon
              icon={this.state.primePlie ? faPlusSquare : faMinusSquare}
              className="btn"
              onClick={() =>
                this.setState((prevState) => ({
                  primePlie: !prevState.primePlie,
                }))
              }
              style={{ width: "32px" }}
            ></ButtonIcon>
            Prime
          </th>
          {this.input({ accessor: "prime_Total", disabled: true })}
          {this.input({
            accessor: "prime_TotalCouts",
            disabled: true,
          })}
          {this.input({
            accessor: "prime_PartCouts",
            disabled: true,
          })}
          {showmarge
            ? this.input({
                accessor: "prime_Marge",
                disabled: true,
              })
            : null}
          <th colSpan="2"></th>
          {this.input({
            accessor: "prime_TotalCoutsRealise",
            disabled: true,
          })}
          {this.input({
            accessor: "prime_PartCoutsRealise",
            disabled: true,
          })}
          {
            <td className="input" style={{ height: "50px" }}>
              <div
                className="d-flex"
                style={{
                  height: "100%",
                }}
              >
                <div
                  style={{
                    height: "100%",
                    width: "15px",
                    backgroundColor: this.getRedOrGreen(
                      this.props.affaire.calculette.prime_Ecart
                    ),
                  }}
                ></div>
                <Input
                  value={this.props.affaire.calculette.prime_Ecart}
                  ignoredValues={[0]}
                  handleBlur={this.props.handleChange}
                  type="decimal"
                  accessor={"calculette.prime_Ecart"}
                  showClearButton={false}
                  showValidator={false}
                  disabled={true}
                  style={{
                    color: this.getRedOrGreen(
                      this.props.affaire.calculette.prime_Ecart
                    ),
                  }}
                ></Input>
              </div>
            </td>
          }
          {
            <td className="input" style={{ height: "50px" }}>
              <div
                className="d-flex"
                style={{
                  height: "100%",
                }}
              >
                <div
                  style={{
                    height: "100%",
                    width: "15px",
                    backgroundColor: this.getRedOrGreen(
                      -1 * this.props.affaire.calculette.prime_TauxEcart
                    ),
                  }}
                ></div>
                <Input
                  value={this.props.affaire.calculette.prime_TauxEcart}
                  ignoredValues={[0]}
                  handleBlur={this.props.handleChange}
                  type="decimal"
                  accessor={"calculette.prime_TauxEcart"}
                  showClearButton={false}
                  showValidator={false}
                  disabled={true}
                  style={{
                    color: this.getRedOrGreen(
                      -1 * this.props.affaire.calculette.prime_TauxEcart
                    ),
                  }}
                ></Input>
              </div>
            </td>
          }
        </tr>
        {this.state.primePlie ? null : (
          <>
            <tr>
              <td className="label">Majoration heures chantier de nuit</td>
              {this.emptyCell()}
              {this.input({
                accessor: "prime_MajorationHeuresChantierNuit_TotalCouts",
                disabled: true,
              })}
              <td></td>
              {showmarge
                ? this.input({
                    accessor: "prime_MajorationHeuresChantierNuit_Marge",
                    disabled: true,
                  })
                : null}
              <td rowSpan="10"></td>
              {this.emptyCell()}
              {this.input({
                accessor: "prime_MajorationHeuresChantierNuit_Realise",
                disabled: true,
              })}
            </tr>
            <tr>
              <td className="label">Prime de nuit</td>

              {this.emptyCell()}
              {this.input({
                accessor: "prime_PrimeDeNuit_TotalCouts",
                disabled: true,
              })}
              <td></td>
              {showmarge
                ? this.input({
                    accessor: "prime_PrimeDeNuit_Marge",
                    disabled: true,
                  })
                : null}
              {this.emptyCell()}
              {this.input({
                accessor: "prime_PrimeDeNuit_Realise",
                disabled: true,
              })}
            </tr>
            <tr>
              <td className="label">Prime déplacement</td>
              {this.emptyCell()}
              {this.input({
                accessor: "prime_PrimeDeDeplacement_TotalCouts",
                disabled: true,
              })}
              <td></td>
              {showmarge
                ? this.input({
                    accessor: "prime_PrimeDeDeplacement_Marge",
                    disabled: true,
                  })
                : null}
              {this.emptyCell()}
              {this.input({
                accessor: "prime_PrimeDeDeplacement_Realise",
                disabled: true,
              })}
            </tr>
            <tr>
              <td className="label">Prime déplacement 4j</td>
              {this.emptyCell()}
              {this.input({
                accessor: "prime_PrimeDeDepla4j_TotalCouts",
                disabled: true,
              })}
              <td></td>
              {showmarge
                ? this.input({
                    accessor: "prime_PrimeDeDepla4j_Marge",
                    disabled: true,
                  })
                : null}
              {this.emptyCell()}
              {this.input({
                accessor: "prime_PrimeDeDepla4j_Realise",
                disabled: true,
              })}
            </tr>
            <tr>
              <td className="label">Prime déplacement 5j</td>
              {this.emptyCell()}
              {this.input({
                accessor: "prime_PrimeDeDepla5j_TotalCouts",
                disabled: true,
              })}
              <td></td>
              {showmarge
                ? this.input({
                    accessor: "prime_PrimeDeDepla5j_Marge",
                    disabled: true,
                  })
                : null}
              {this.emptyCell()}
              {this.input({
                accessor: "prime_PrimeDeDepla5j_Realise",
                disabled: true,
              })}
            </tr>
            <tr>
              <td className="label">Récupération heure de nuit</td>
              {this.emptyCell()}
              {this.input({
                accessor: "prime_RecupHeureDeNuit_TotalCouts",
                disabled: true,
              })}
              <td></td>
              {showmarge
                ? this.input({
                    accessor: "prime_RecupHeureDeNuit_Marge",
                    disabled: true,
                  })
                : null}
              {this.emptyCell()}
              {this.input({
                accessor: "prime_RecupHeureDeNuit_Realise",
                disabled: true,
              })}
            </tr>
            <tr>
              <td className="label">Majoration heure supp</td>
              {this.emptyCell()}
              {this.input({
                accessor: "prime_MajorationHeuresSupp_TotalCouts",
                disabled: true,
              })}
              <td></td>
              {showmarge
                ? this.input({
                    accessor: "prime_MajorationHeuresSupp_Marge",
                    disabled: true,
                  })
                : null}
              {this.emptyCell()}
              {this.input({
                accessor: "prime_MajorationHeuresSupp_Realise",
                disabled: true,
              })}
            </tr>
            <tr>
              <td className="label">Majoration heure dimanche</td>
              {this.emptyCell()}
              {this.input({
                accessor: "prime_MajorationHeuresDimanche_TotalCouts",
                disabled: true,
              })}
              <td></td>
              {showmarge
                ? this.input({
                    accessor: "prime_MajorationHeuresDimanche_Marge",
                    disabled: true,
                  })
                : null}
              {this.emptyCell()}
              {this.input({
                accessor: "prime_MajorationHeuresDimanche_Realise",
                disabled: true,
              })}
            </tr>
            <tr>
              <td className="label">Prime week-end</td>
              {this.emptyCell()}
              {this.input({
                accessor: "prime_Weekend_TotalCouts",
                disabled: true,
              })}
              <td></td>
              {showmarge
                ? this.input({
                    accessor: "prime_Weekend_Marge",
                    disabled: true,
                  })
                : null}
              {this.emptyCell()}
              {this.input({
                accessor: "prime_Weekend_Realise",
                disabled: true,
              })}
            </tr>
            <tr>
              <td className="label">Majoration heures jour férié</td>
              {this.emptyCell()}
              {this.input({
                accessor: "prime_MajorationHeuresFeriee_TotalCouts",
                disabled: true,
              })}
              <td></td>
              {showmarge
                ? this.input({
                    accessor: "prime_MajorationHeuresFeriee_Marge",
                    disabled: true,
                  })
                : null}
              {this.emptyCell()}
              {this.input({
                accessor: "prime_MajorationHeuresFeriee_Realise",
                disabled: true,
              })}
            </tr>
          </>
        )}
      </>
    );
  }

  renderDepla() {
    let showmarge = this.showMarge();

    return (
      <>
        <tr>
          <th className="Titlelabel">
            <ButtonIcon
              icon={this.state.deplaPlie ? faPlusSquare : faMinusSquare}
              className="btn"
              onClick={() =>
                this.setState((prevState) => ({
                  deplaPlie: !prevState.deplaPlie,
                }))
              }
              style={{ width: "32px" }}
            ></ButtonIcon>
            Déplacements
          </th>
          <th colSpan="1"></th>
          {this.input({
            accessor: "deplacements_TotalCouts",
            disabled: true,
          })}
          {this.input({
            accessor: "deplacements_PartCouts",
            disabled: true,
          })}
          {showmarge
            ? this.input({
                accessor: "deplacements_Marge",
                disabled: true,
              })
            : null}
          <th colSpan="2"></th>
          {this.input({
            accessor: "deplacements_TotalCoutsRealise",
            disabled: true,
          })}
          {this.input({
            accessor: "deplacements_PartCoutsRealise",
            disabled: true,
          })}
          {
            <td className="input" style={{ height: "50px" }}>
              <div
                className="d-flex"
                style={{
                  height: "100%",
                }}
              >
                <div
                  style={{
                    height: "100%",
                    width: "15px",
                    backgroundColor: this.getRedOrGreen(
                      this.props.affaire.calculette.deplacements_Ecart
                    ),
                  }}
                ></div>
                <Input
                  value={this.props.affaire.calculette.deplacements_Ecart}
                  ignoredValues={[0]}
                  handleBlur={this.props.handleChange}
                  type="decimal"
                  accessor={"calculette.deplacements_Ecart"}
                  showClearButton={false}
                  showValidator={false}
                  disabled={true}
                  style={{
                    color: this.getRedOrGreen(
                      this.props.affaire.calculette.deplacements_Ecart
                    ),
                  }}
                ></Input>
              </div>
            </td>
          }
          {
            <td className="input" style={{ height: "50px" }}>
              <div
                className="d-flex"
                style={{
                  height: "100%",
                }}
              >
                <div
                  style={{
                    height: "100%",
                    width: "15px",
                    backgroundColor: this.getRedOrGreen(
                      -1 * this.props.affaire.calculette.deplacement_TauxEcart
                    ),
                  }}
                ></div>
                <Input
                  value={this.props.affaire.calculette.deplacement_TauxEcart}
                  ignoredValues={[0]}
                  handleBlur={this.props.handleChange}
                  type="decimal"
                  accessor={"calculette.deplacement_TauxEcart"}
                  showClearButton={false}
                  showValidator={false}
                  disabled={true}
                  style={{
                    color: this.getRedOrGreen(
                      -1 * this.props.affaire.calculette.deplacement_TauxEcart
                    ),
                  }}
                ></Input>
              </div>
            </td>
          }
        </tr>
        {this.state.deplaPlie ? null : (
          <>
            <tr>
              <td className="label">Forfait nuit</td>
              {this.input({
                accessor: "deplacements_ForfaitNuit_AmeneRepli",
                disabled: true,
              })}
              {this.input({
                accessor: "deplacements_ForfaitNuit_TotalCouts",
                disabled: true,
              })}
              <td></td>
              {showmarge
                ? this.input({
                    accessor: "deplacements_ForfaitNuit_Marge",
                    disabled: true,
                  })
                : null}
              <td rowSpan="6"></td>
              {this.emptyCell()}
              {this.input({
                accessor: "deplacements_ForfaitNuit_Realise",
                disabled: true,
              })}
            </tr>
            <tr>
              <td className="label">Forfait restaurant</td>
              {this.input({
                accessor: "deplacements_ForfaitRestaurant_Total",
                disabled: true,
              })}
              {this.input({
                accessor: "deplacements_ForfaitRestaurant_TotalCouts",
                disabled: true,
              })}
              <td></td>
              {showmarge
                ? this.input({
                    accessor: "deplacements_ForfaitRestaurant_Marge",
                    disabled: true,
                  })
                : null}
              {this.emptyCell()}
              {this.input({
                accessor: "deplacements_ForfaitRestaurant_Realise",
                disabled: true,
              })}
            </tr>
            <tr>
              <td className="label">Essence</td>
              {this.emptyCell()}
              {this.input({
                accessor: "deplacements_Essence_TotalCouts",
                disabled: true,
              })}
              <td></td>
              {showmarge
                ? this.input({
                    accessor: "deplacements_Essence_Marge",
                    disabled: true,
                  })
                : null}
              {this.emptyCell()}
              {this.input({
                accessor: "deplacements_Essence_Realise",
                disabled: true,
              })}
            </tr>
            <tr>
              <td className="label">Péages</td>
              {this.emptyCell()}
              {this.input({
                accessor: "deplacements_Peages_TotalCouts",
                disabled: true,
              })}
              <td></td>
              {showmarge
                ? this.input({
                    accessor: "deplacements_Peages_Marge",
                    disabled: true,
                  })
                : null}
              {this.emptyCell()}
              {this.input({
                accessor: "deplacements_Peages_Realise",
                disabled: true,
              })}
            </tr>
            <tr>
              <td className="label">Véhicule</td>
              {this.input({
                accessor: "deplacements_VehiculeFraisFixesParJour_Total",
                disabled: true,
              })}
              {this.input({
                accessor: "deplacements_VehiculeFraisFixesParJour_TotalCouts",
                disabled: true,
              })}
              <td></td>
              {showmarge
                ? this.input({
                    accessor: "deplacements_VehiculeFraisFixesParJour_Marge",
                    disabled: true,
                  })
                : null}
              {this.emptyCell()}
              {this.input({
                accessor: "deplacements_VehiculeFraisFixesParJour_Realise",
                disabled: true,
              })}
            </tr>
            <tr>
              <td className="label">Train / Avion</td>
              {this.emptyCell()}
              {this.input({
                accessor: "deplacements_TrainAvion_TotalCouts",
                disabled: true,
              })}
              <td></td>
              {showmarge
                ? this.input({
                    accessor: "deplacements_TrainAvion_Marge",
                    disabled: true,
                  })
                : null}
              {this.emptyCell()}
              {this.input({
                accessor: "deplacements_TrainAvion_Realise",
                disabled: true,
              })}
            </tr>
          </>
        )}
      </>
    );
  }

  renderMat() {
    let showmarge = this.showMarge();

    return (
      <>
        <tr>
          <th className="Titlelabel">
            <ButtonIcon
              icon={this.state.matosPlie ? faPlusSquare : faMinusSquare}
              className="btn"
              onClick={() =>
                this.setState((prevState) => ({
                  matosPlie: !prevState.matosPlie,
                }))
              }
              style={{ width: "32px" }}
            ></ButtonIcon>
            Matériels
          </th>
          <th colSpan="1"></th>
          {this.input({
            accessor: "materiels_TotalCouts",
            disabled: true,
          })}
          {this.input({
            accessor: "materiels_PartCouts",
            disabled: true,
          })}
          {showmarge
            ? this.input({
                accessor: "materiels_Marge",
                disabled: true,
              })
            : null}
          <th colSpan="2"></th>
          {this.input({
            accessor: "materiels_TotalCoutsRealise",
            disabled: true,
          })}
          {this.input({
            accessor: "materiels_PartCoutsRealise",
            disabled: true,
          })}
          {
            <td className="input" style={{ height: "50px" }}>
              <div
                className="d-flex"
                style={{
                  height: "100%",
                }}
              >
                <div
                  style={{
                    height: "100%",
                    width: "15px",
                    backgroundColor: this.getRedOrGreen(
                      this.props.affaire.calculette.materiels_Ecart
                    ),
                  }}
                ></div>
                <Input
                  value={this.props.affaire.calculette.materiels_Ecart}
                  ignoredValues={[0]}
                  handleBlur={this.props.handleChange}
                  type="decimal"
                  accessor={"calculette.materiels_Ecart"}
                  showClearButton={false}
                  showValidator={false}
                  disabled={true}
                  style={{
                    color: this.getRedOrGreen(
                      this.props.affaire.calculette.materiels_Ecart
                    ),
                  }}
                ></Input>
              </div>
            </td>
          }
          {
            <td className="input" style={{ height: "50px" }}>
              <div
                className="d-flex"
                style={{
                  height: "100%",
                }}
              >
                <div
                  style={{
                    height: "100%",
                    width: "15px",
                    backgroundColor: this.getRedOrGreen(
                      -1 * this.props.affaire.calculette.materiels_TauxEcart
                    ),
                  }}
                ></div>
                <Input
                  value={this.props.affaire.calculette.materiels_TauxEcart}
                  ignoredValues={[0]}
                  handleBlur={this.props.handleChange}
                  type="decimal"
                  accessor={"calculette.materiel_TauxEcart"}
                  showClearButton={false}
                  showValidator={false}
                  disabled={true}
                  style={{
                    color: this.getRedOrGreen(
                      -1 * this.props.affaire.calculette.materiels_TauxEcart
                    ),
                  }}
                ></Input>
              </div>
            </td>
          }
        </tr>
        {this.state.matosPlie ? null : (
          <>
            <tr>
              <td className="label">Système d'informations</td>
              {this.input({
                accessor: "materiels_SystemeDInformations_A_Total",
                disabled: true,
              })}
              {this.input({
                accessor: "materiels_SystemeDInformations_TotalCouts",
                disabled: true,
              })}
              <td></td>
              {showmarge
                ? this.input({
                    accessor: "materiels_SystemeDInformations_Marge",
                    disabled: true,
                  })
                : null}
              <td rowSpan="4"></td>
              {this.emptyCell()}
              {this.input({
                accessor: "materiels_SystemeDInformations_Realise",
                disabled: true,
              })}
            </tr>

            <tr>
              <td className="input">
                <Input
                  value={
                    this.props.affaire.calculette
                      .materiels_MaterielIndustriel_A_Label
                  }
                  handleBlur={this.props.handleChange}
                  type="text"
                  accessor={"calculette.materiels_MaterielIndustriel_A_Label"}
                  showClearButton={false}
                  showValidator={false}
                  disabled={!this.props.editing}
                ></Input>
              </td>

              {this.input({
                accessor: "materiels_MaterielIndustriel_A_Total",
                disabled: true,
              })}
              {this.input({
                accessor: "materiels_MaterielIndustriel_A_TotalCouts",
                disabled: true,
              })}
              <td></td>
              {showmarge
                ? this.input({
                    accessor: "materiels_MaterielIndustriel_A_Marge",
                    disabled: true,
                  })
                : null}
              {this.emptyCell()}
              {this.input({
                accessor: "materiels_MaterielIndustriel_A_Realise",
                disabled: true,
              })}
            </tr>

            <tr>
              <td className="label">Sous-traitance ou location</td>

              {this.input({
                accessor: "materiels_SousTraitanceOuLocation_Total",
                disabled: true,
              })}
              {this.input({
                accessor: "materiels_SousTraitanceOuLocation_TotalCouts",
                disabled: true,
              })}
              <td></td>
              {showmarge
                ? this.input({
                    accessor: "materiels_SousTraitanceOuLocation_Marge",
                    disabled: true,
                  })
                : null}
              {this.emptyCell()}
              {this.input({
                accessor: "materiels_SousTraitanceOuLocation_Realise",
                disabled: true,
              })}
            </tr>
            <tr>
              <td className="label">Consommables</td>

              {this.input({
                accessor: "materiels_Consommables_Total",
                disabled: true,
              })}
              {this.input({
                accessor: "materiels_Consommables_TotalCouts",
                disabled: true,
              })}
              <td></td>

              {showmarge
                ? this.input({
                    accessor: "materiels_Consommables_Marge",
                    disabled: true,
                  })
                : null}
              {this.emptyCell()}
              {this.input({
                accessor: "materiels_Consommables_Realise",
                disabled: true,
              })}
            </tr>
          </>
        )}
      </>
    );
  }

  renderAutre() {
    let showmarge = this.showMarge();

    let calculette = this.props.affaire?.calculette;
    return (
      <>
        <tr>
          <th className="Titlelabel">
            <ButtonIcon
              icon={this.state.autrePlie ? faPlusSquare : faMinusSquare}
              className="btn"
              onClick={() =>
                this.setState((prevState) => ({
                  autrePlie: !prevState.autrePlie,
                }))
              }
              style={{ width: "32px" }}
            ></ButtonIcon>
            Autres
          </th>
          <th colSpan="1"></th>

          {this.input({
            accessor: "autres_TotalCouts",
            disabled: true,
          })}
          {this.input({
            accessor: "autres_PartCouts",
            disabled: true,
          })}
          <th colSpan={showmarge ? "3" : "2"}></th>
          {this.input({
            accessor: "autres_TotalCoutsRealise",
            disabled: true,
          })}
          {this.input({
            accessor: "autres_PartCoutsRealise",
            disabled: true,
          })}
          {
            <td className="input" style={{ height: "50px" }}>
              <div
                className="d-flex"
                style={{
                  height: "100%",
                }}
              >
                <div
                  style={{
                    height: "100%",
                    width: "15px",
                    backgroundColor: this.getRedOrGreen(
                      this.props.affaire.calculette.autres_Ecart
                    ),
                  }}
                ></div>
                <Input
                  value={this.props.affaire.calculette.autres_Ecart}
                  ignoredValues={[0]}
                  handleBlur={this.props.handleChange}
                  type="decimal"
                  accessor={"calculette.autres_Ecart"}
                  showClearButton={false}
                  showValidator={false}
                  disabled={true}
                  style={{
                    color: this.getRedOrGreen(
                      this.props.affaire.calculette.autres_Ecart
                    ),
                  }}
                ></Input>
              </div>
            </td>
          }
          {
            <td className="input" style={{ height: "50px" }}>
              <div
                className="d-flex"
                style={{
                  height: "100%",
                }}
              >
                <div
                  style={{
                    height: "100%",
                    width: "15px",
                    backgroundColor: this.getRedOrGreen(
                      this.props.affaire.calculette.autres_TauxEcart
                    ),
                  }}
                ></div>
                <Input
                  value={this.props.affaire.calculette.autres_TauxEcart}
                  ignoredValues={[0]}
                  handleBlur={this.props.handleChange}
                  type="decimal"
                  accessor={"calculette.autres_TauxEcart"}
                  showClearButton={false}
                  showValidator={false}
                  disabled={true}
                  style={{
                    color: this.getRedOrGreen(
                      -1 * this.props.affaire.calculette.autres_TauxEcart
                    ),
                  }}
                ></Input>
              </div>
            </td>
          }
        </tr>
        {this.state.autrePlie ? null : (
          <>
            <tr>
              <td className="input">
                <Input
                  value={calculette?.autres_A_Label}
                  handleChange={this.props.handleChange}
                  type="text"
                  accessor={"calculette.autres_A_Label"}
                  showClearButton={false}
                  showValidator={false}
                  disabled={!this.props.editing}
                ></Input>
              </td>

              {this.emptyCell()}
              {this.input({
                accessor: "autres_A_TotalCouts",
                disabled: true,
              })}
              <td></td>
              {showmarge
                ? this.input({
                    accessor: "autres_A_Marge",
                    disabled: true,
                  })
                : null}
              <td></td>
              {this.emptyCell()}

              {this.input({
                accessor: "autres_A_Realise",
                disabled: true,
              })}
            </tr>
            <tr>
              <td className="input">
                <Input
                  value={calculette?.autres_B_Label}
                  handleChange={this.props.handleChange}
                  type="text"
                  accessor={"calculette.autres_B_Label"}
                  showClearButton={false}
                  showValidator={false}
                  disabled={!this.props.editing}
                ></Input>
              </td>

              {this.emptyCell()}
              {this.input({
                accessor: "autres_B_TotalCouts",
                disabled: true,
              })}
              <td></td>

              {showmarge
                ? this.input({
                    accessor: "autres_B_Marge",
                    disabled: true,
                  })
                : null}
            </tr>
            <tr>
              <td className="input">
                <Input
                  value={calculette?.autres_C_Label}
                  handleChange={this.props.handleChange}
                  type="text"
                  accessor={"calculette.autres_C_Label"}
                  showClearButton={false}
                  showValidator={false}
                  disabled={!this.props.editing}
                ></Input>
              </td>

              {this.emptyCell()}
              {this.input({
                accessor: "autres_C_TotalCouts",
                disabled: true,
              })}
              <td></td>
              {showmarge
                ? this.input({
                    accessor: "autres_C_Marge",
                    disabled: true,
                  })
                : null}
            </tr>
            <tr>
              <td className="input">
                <Input
                  value={calculette?.autres_D_Label}
                  handleChange={this.props.handleChange}
                  type="text"
                  accessor={"calculette.autres_D_Label"}
                  showClearButton={false}
                  showValidator={false}
                  disabled={!this.props.editing}
                ></Input>
              </td>

              {this.emptyCell()}
              {this.input({
                accessor: "autres_D_TotalCouts",
                disabled: true,
              })}
              <td></td>
              {showmarge
                ? this.input({
                    accessor: "autres_D_Marge",
                    disabled: true,
                  })
                : null}
            </tr>
            <tr>
              <td className="input">
                <Input
                  value={calculette?.autres_E_Label}
                  handleChange={this.props.handleChange}
                  type="text"
                  accessor={"calculette.autres_E_Label"}
                  showClearButton={false}
                  showValidator={false}
                  disabled={!this.props.editing}
                ></Input>
              </td>

              {this.emptyCell()}
              {this.input({
                accessor: "autres_E_TotalCouts",
                disabled: true,
              })}
              <td></td>
              {showmarge
                ? this.input({
                    accessor: "autres_E_Marge",
                    disabled: true,
                  })
                : null}
            </tr>
          </>
        )}
      </>
    );
  }

  renderSousTotal() {
    //let cal = this.props.affaire.calculette;
    let showmarge = this.showMarge();
    return (
      <>
        <tr>
          <th className="Titlelabel">Total/mission</th>

          {this.emptyCell()}

          {this.input({
            accessor: "totalCouts",
            disabled: true,
          })}
          {this.emptyCell()}
          {showmarge
            ? this.input({
                accessor: "total_Marge",
                disabled: true,
              })
            : null}
          {this.emptyCell()}

          {this.input({
            accessor: "totalRealise",
            disabled: true,
          })}
        </tr>
        <tr>
          <td className="label">Marge total</td>

          {this.emptyCell()}

          {this.input({
            accessor: "total_Total_Marge",
            disabled: true,
          })}
          {this.emptyCell()}
          {this.emptyCell()}
          {this.emptyCell()}

          {showmarge ? this.emptyCell() : null}
        </tr>
        <tr>
          <th className="Titlelabel">Sous-total exploitation</th>
          <th colSpan="1"></th>

          {this.input({
            accessor: "exploitation_TotalCouts",
            disabled: true,
          })}
          {this.input({
            accessor: "exploitation_PartCouts",
            disabled: true,
          })}
        </tr>

        <tr>
          <td className="label bold">Assurance</td>

          {this.emptyCell()}
          {this.emptyCell()}
          {showmarge ? this.emptyCell() : null}
          {this.input({
            accessor: "exploitation_Assurance_TotalCouts",
            disabled: true,
          })}
          <td colSpan="2"></td>

          {this.input({
            accessor: "exploitation_Assurance_Realise",
            disabled: true,
          })}
          <td colSpan="1"></td>
          {
            <td className="input" style={{ height: "50px" }}>
              <div
                className="d-flex"
                style={{
                  height: "100%",
                }}
              >
                <div
                  style={{
                    height: "100%",
                    width: "15px",
                    backgroundColor: this.getRedOrGreen(
                      this.props.affaire.calculette.exploitation_Assurance_Ecart
                    ),
                  }}
                ></div>
                <Input
                  value={
                    this.props.affaire.calculette.exploitation_Assurance_Ecart
                  }
                  ignoredValues={[0]}
                  handleBlur={this.props.handleChange}
                  type="decimal"
                  accessor={"calculette.exploitation_Assurance_Ecart"}
                  showClearButton={false}
                  showValidator={false}
                  disabled={true}
                  style={{
                    color: this.getRedOrGreen(
                      this.props.affaire.calculette.exploitation_Assurance_Ecart
                    ),
                  }}
                ></Input>
              </div>
            </td>
          }
          {
            <td className="input" style={{ height: "50px" }}>
              <div
                className="d-flex"
                style={{
                  height: "100%",
                }}
              >
                <div
                  style={{
                    height: "100%",
                    width: "15px",
                    backgroundColor: this.getRedOrGreen(
                      this.props.affaire.calculette
                        .exploitation_Assurance_TauxEcart
                    ),
                  }}
                ></div>
                <Input
                  value={
                    this.props.affaire.calculette
                      .exploitation_Assurance_TauxEcart
                  }
                  ignoredValues={[0]}
                  handleBlur={this.props.handleChange}
                  type="decimal"
                  accessor={"calculette.exploitation_Assurance_TauxEcart"}
                  showClearButton={false}
                  showValidator={false}
                  disabled={true}
                  style={{
                    color: this.getRedOrGreen(
                      this.props.affaire.calculette.margeTauxEcart
                    ),
                  }}
                ></Input>
              </div>
            </td>
          }
        </tr>
        <tr>
          <td className="label bold">Aléa</td>

          {this.emptyCell()}
          {this.emptyCell()}
          {showmarge ? this.emptyCell() : null}
          {this.input({
            accessor: "exploitation_Alea_TotalCouts",
            disabled: true,
          })}
          <td></td>
        </tr>

        <tr>
          <td className="label bold">TOTAL PRIX REVIENT HT</td>

          {this.emptyCell()}
          {this.emptyCell()}
          {showmarge ? this.emptyCell() : null}
          {this.input({
            accessor: "totalCouts",
            disabled: true,
          })}
          <td colSpan="2"></td>
          {this.input({
            accessor: "totalRealise",
            disabled: true,
          })}
          <td colSpan="1"></td>
          {
            <td className="input" style={{ height: "50px" }}>
              <div
                className="d-flex"
                style={{
                  height: "100%",
                }}
              >
                <div
                  style={{
                    height: "100%",
                    width: "15px",
                    backgroundColor: this.getRedOrGreen(
                      this.props.affaire.calculette.totalEcart
                    ),
                  }}
                ></div>
                <Input
                  value={this.props.affaire.calculette.totalEcart}
                  ignoredValues={[0]}
                  handleBlur={this.props.handleChange}
                  type="decimal"
                  accessor={"calculette.totalEcart"}
                  showClearButton={false}
                  showValidator={false}
                  disabled={true}
                  style={{
                    color: this.getRedOrGreen(
                      this.props.affaire.calculette.totalEcart
                    ),
                  }}
                ></Input>
              </div>
            </td>
          }
          {
            <td className="input" style={{ height: "50px" }}>
              <div
                className="d-flex"
                style={{
                  height: "100%",
                }}
              >
                <div
                  style={{
                    height: "100%",
                    width: "15px",
                    backgroundColor: this.getRedOrGreen(
                      this.props.affaire.calculette.tauxTotalEcart
                    ),
                  }}
                ></div>
                <Input
                  value={this.props.affaire.calculette.tauxTotalEcart}
                  ignoredValues={[0]}
                  handleBlur={this.props.handleChange}
                  type="decimal"
                  accessor={"calculette.tauxTotalEcart"}
                  showClearButton={false}
                  showValidator={false}
                  disabled={true}
                  style={{
                    color: this.getRedOrGreen(
                      this.props.affaire.calculette.margeTauxEcart
                    ),
                  }}
                ></Input>
              </div>
            </td>
          }
        </tr>
        <tr>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
        </tr>
        <tr>
          <td className="label bold">PV HT</td>
          {this.emptyCell()}
          {this.emptyCell()}
          {showmarge ? this.emptyCell() : null}
          {this.input({
            accessor: "pV_HT",
            disabled: true,
          })}
          <td colSpan="2"></td>
          {this.input({
            accessor: "pV_HT_Realise",
            disabled: true,
          })}
          <td colSpan="1"></td>
          {
            <td className="input" style={{ height: "50px" }}>
              <div
                className="d-flex"
                style={{
                  height: "100%",
                }}
              >
                <div
                  style={{
                    height: "100%",
                    width: "15px",
                    backgroundColor: this.getRedOrGreen(
                      this.props.affaire.calculette.pV_HT_Ecart
                    ),
                  }}
                ></div>
                <Input
                  value={this.props.affaire.calculette.pV_HT_Ecart}
                  ignoredValues={[0]}
                  handleBlur={this.props.handleChange}
                  type="decimal"
                  accessor={"calculette.pV_HT_Ecart"}
                  showClearButton={false}
                  showValidator={false}
                  disabled={true}
                  style={{
                    color: this.getRedOrGreen(
                      this.props.affaire.calculette.pV_HT_Ecart
                    ),
                  }}
                ></Input>
              </div>
            </td>
          }
          {
            <td className="input" style={{ height: "50px" }}>
              <div
                className="d-flex"
                style={{
                  height: "100%",
                }}
              >
                <div
                  style={{
                    height: "100%",
                    width: "15px",
                    backgroundColor: this.getRedOrGreen(
                      this.props.affaire.calculette.pV_HT_TauxEcart
                    ),
                  }}
                ></div>
                <Input
                  value={this.props.affaire.calculette.pV_HT_TauxEcart}
                  ignoredValues={[0]}
                  handleBlur={this.props.handleChange}
                  type="decimal"
                  accessor={"calculette.pV_HT_TauxEcart"}
                  showClearButton={false}
                  showValidator={false}
                  disabled={true}
                  style={{
                    color: this.getRedOrGreen(
                      this.props.affaire.calculette.pV_HT_TauxEcart
                    ),
                  }}
                ></Input>
              </div>
            </td>
          }
        </tr>
        <tr>
          <td className="label bold">Marge</td>

          {this.emptyCell()}
          {this.emptyCell()}
          {showmarge ? this.emptyCell() : null}
          {this.input({
            accessor: "marge",
            disabled: true,
          })}
          {this.emptyCell()}

          {this.input({
            accessor: "margeRealise",
            disabled: true,
          })}
          {
            <td className="input" style={{ height: "50px" }}>
              <div
                className="d-flex"
                style={{
                  height: "100%",
                }}
              >
                <div
                  style={{
                    height: "100%",
                    width: "15px",
                    backgroundColor: this.getColor(
                      this.props.affaire.calculette.partMargeRealise
                    ),
                  }}
                ></div>
                <Input
                  value={this.props.affaire.calculette.partMargeRealise}
                  ignoredValues={[0]}
                  handleBlur={this.props.handleChange}
                  type="decimal"
                  accessor={"calculette.partMargeRealise"}
                  showClearButton={false}
                  showValidator={false}
                  disabled={true}
                  style={{
                    color: this.getColor(
                      this.props.affaire.calculette.partMargeRealise
                    ),
                  }}
                ></Input>
              </div>
            </td>
          }
          <td colSpan="1"></td>
          {
            <td className="input " style={{ height: "50px" }}>
              <div
                className="d-flex"
                style={{
                  height: "100%",
                }}
              >
                <div
                  style={{
                    height: "100%",
                    width: "15px",
                    backgroundColor: this.getRedOrGreen(
                      this.props.affaire.calculette.margeEcart
                    ),
                  }}
                ></div>
                <Input
                  value={this.props.affaire.calculette.margeEcart}
                  ignoredValues={[0]}
                  handleBlur={this.props.handleChange}
                  type="decimal"
                  accessor={"calculette.margeEcart"}
                  showClearButton={false}
                  showValidator={false}
                  disabled={true}
                  style={{
                    color: this.getRedOrGreen(
                      this.props.affaire.calculette.margeEcart
                    ),
                  }}
                ></Input>
              </div>
            </td>
          }
          {
            <td className="input" style={{ height: "50px" }}>
              <div
                className="d-flex"
                style={{
                  height: "100%",
                }}
              >
                <div
                  style={{
                    height: "100%",
                    width: "15px",
                    backgroundColor: this.getRedOrGreen(
                      this.props.affaire.calculette.margeTauxEcart
                    ),
                  }}
                ></div>
                <Input
                  value={this.props.affaire.calculette.margeTauxEcart}
                  ignoredValues={[0]}
                  handleBlur={this.props.handleChange}
                  type="decimal"
                  accessor={"calculette.margeTauxEcart"}
                  showClearButton={false}
                  showValidator={false}
                  disabled={true}
                  style={{
                    color: this.getRedOrGreen(
                      this.props.affaire.calculette.margeTauxEcart
                    ),
                  }}
                ></Input>
              </div>
            </td>
          }
        </tr>
      </>
    );
  }

  renderCalculette() {
    return (
      <div className="shadow p-3 bg-body rounded bg-light pageClient">
        <table className="Calculette">
          <thead>
            {this.renderHeadCalculette()}
            {this.renderPersonnel()}
            {this.renderPrime()}
            {this.renderDepla()}
            {this.renderMat()}
            {this.renderAutre()}
            {this.renderSousTotal()}
          </thead>
        </table>
      </div>
    );
  }

  render() {
    const right = App.RightsGuard?.current?.hasRight("Devis", "Calculette");
    if (right == "RW" || right == "RO") {
      if (this.props.affaire.calculette) return this.renderCalculette();
      else return <></>;
    } else {
      return (
        <>
          <WarningBar
            active={false}
            content={"Vous n'avez pas le droit de voir cette page"}
          />
        </>
      );
    }
  }
}

export { Calculette };
