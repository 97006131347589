import React from "react";
import { LigneDevisDetail } from "./LigneDevisDetail";
import { App } from "App";

import {
  DevisModeleService,
  ProduitInterneService,
  LangueService,
} from "_services";

import { ButtonIcon, ButtonIconWithValidation, WarningBar } from "_components";

import { containFilter } from "_components/Input/InputTypes/SelectSearchFunctions.js";

import {
  faPlus,
  faCheck,
  faTimes,
  faPen,
  faFileImport,
  faFileExport,
  faCommentDots,
} from "@fortawesome/free-solid-svg-icons";

import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";

import { formatValueDecimal } from "_utils/DecimalFunctions";

import "./Details.css";
import { Input } from "_components/Input";

class Details extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      savedDevis: null,
      modelToImport: null,
      modeleNameToExport: null,
      modelePoleToExport: this.props.devis.produit_Interne,
      modeleLangueToExport: this.props.devis.client?.langue,
    };

    this.handleClickEdition = this.handleClickEdition.bind(this);
    this.handleClickUndo = this.handleClickUndo.bind(this);
    this.handleClickValidate = this.handleClickValidate.bind(this);
    this.renderLigneDevisDetail = this.renderLigneDevisDetail.bind(this);
    this.handleImportModele = this.handleImportModele.bind(this);
    this.handleExportModele = this.handleExportModele.bind(this);
  }

  handleClickEdition() {
    this.setState({
      savedDevis: { ...this.props.devis },
    });
    this.props.handleEditing(true);
  }

  handleClickUndo() {
    this.props.handleChange(
      this.props.stateFieldNameToUpdate,
      this.state.savedDevis
    );
    this.props.handleEditing(false);
  }

  handleClickValidate() {
    this.props.handleUpdate();
    this.props.handleEditing(false);
  }

  handleImportModele() {
    DevisModeleService.getById(this.state.modelToImport.id).then(
      (modelToImport) => {
        this.props.importDevisModele(modelToImport.data);

        this.setState((prevState) => ({
          ...prevState,
          modelToImport: null,
        }));
      }
    );
  }

  handleExportModele() {
    let devisModele = {
      ...this.props.devis,
      devisDetails: this.props.devis.devisDetails,
      nom_Devis_Modele: this.state.modeleNameToExport
        ? this.state.modeleNameToExport
        : "",
      produit_Interne: this.state.modelePoleToExport,
      langue: this.state.modeleLangueToExport,
    };
    DevisModeleService.post(devisModele);

    this.setState((prevState) => ({
      ...prevState,
      modeleNameToExport: null,
      modelePoleToExport: this.props.devis.produit_Interne,
      modeleLangueToExport: this.props.devis.client.langue,
    }));
  }

  renderLigneDevisDetail(devisDetail, index) {
    return (
      <LigneDevisDetail
        key={devisDetail.id}
        index={index}
        pourcentageTVA={
          this.props.devis.client && this.props.devis.client.tva
            ? this.props.devis.client.tva.valeur
            : 0
        }
        langueClient={
          this.props.devis?.client?.langue?.langage == "Français"
            ? "fr"
            : this.props.devis?.client?.langue?.langage == "Anglais"
            ? "en"
            : this.props.devis?.client?.langue?.langage == "Espagnol"
            ? "es"
            : this.props.devis?.client?.langue?.langage == "Allemand"
            ? "de"
            : null
        }
        devisDetail={devisDetail}
        handleChange={this.props.handleChange}
        moveLigneDevisDetail={this.props.changeLinePosition}
        addLineDevisDetail={this.props.addLineDevisDetail}
        deleteLineDevisDetail={this.props.deleteLineDevisDetail}
        getSousTotal={this.props.getSousTotal}
        editing={this.props.editing}
      />
    );
  }

  render() {
    const right = App.RightsGuard?.current?.hasRight("Devis", "Details");
    if (right == "RW" || right == "RO") {
      return (
        <>
          <div className="DevisDetails shadow p-3 m-4 bg-body rounded bg-light pageClient">
            <div
              className="d-flex justify-content-between"
              id="DevisDetailsHeader"
            >
              <div>
                <span
                  className="badge rounded-pill bg-info"
                  style={{ fontSize: "1em", margin: "6px" }}
                >
                  TVA Client : {this.props.devis.client?.tva?.valeur} %
                </span>
                <span
                  className="badge rounded-pill bg-info"
                  style={{ fontSize: "1em", margin: "6px" }}
                >
                  Devise affichée : €
                </span>
                {this.props.devis?.devise?.designation != "Euro" ? (
                  <>
                    <span
                      className="badge rounded-pill bg-info"
                      style={{ fontSize: "1em", margin: "6px" }}
                    >
                      Devise calculée : {this.props.devis?.devise?.sigle}
                    </span>
                    <span
                      className="badge rounded-pill bg-info"
                      style={{ fontSize: "1em", margin: "6px" }}
                    >
                      Cours utilisé : {this.props.devis?.coursDevise}
                    </span>
                  </>
                ) : null}
              </div>
              <div>
                <ButtonIconWithValidation
                  icon={faFileImport}
                  className="btn btn-secondary text-light ms-1"
                  textOption="Importer depuis modèle"
                  alertTitle="Importer depuis modèle"
                  alertButtonValidationText="Importer"
                  style={!this.props.editing ? { display: "none" } : {}}
                  onClick={this.handleImportModele}
                  onClose={() =>
                    this.setState((prevState) => ({
                      ...prevState,
                      modelToImport: null,
                    }))
                  }
                  alertBody={
                    <div id="AlerteDevisDetails">
                      <Input
                        id="ListeImport"
                        label="Modèle à importer"
                        type="selectSearch"
                        value={null}
                        service={(query) =>
                          DevisModeleService.getAllGrouped({
                            produitInterne:
                              this.props.devis.produit_Interne?.designation,
                            langue: this.props.devis.client.langue?.langage,
                            nomDevisModele: query,
                          })
                        }
                        optionFieldToDisplay="nom_Devis_Modele"
                        valueFieldToDisplay="nom_Devis_Modele"
                        handleChange={(accessor, value) =>
                          this.setState((prevState) => ({
                            ...prevState,
                            modelToImport: value,
                          }))
                        }
                        functionAppliedToGroupByName={(option) =>
                          "".concat(
                            option.produit_Interne?.designation,
                            "  |  ",
                            option.langue?.langage
                          )
                        }
                      ></Input>
                    </div>
                  }
                ></ButtonIconWithValidation>
                <ButtonIconWithValidation
                  icon={faFileExport}
                  className="btn btn-secondary text-light ms-1"
                  textOption="Exporter vers modèle"
                  alertTitle="Exporter vers modèle"
                  alertButtonValidationText="Exporter"
                  style={!this.props.editing ? { display: "none" } : {}}
                  onClick={this.handleExportModele}
                  onClose={() =>
                    this.setState((prevState) => ({
                      ...prevState,
                      modeleNameToExport: null,
                      modelePoleToExport: this.props.devis.produit_Interne,
                    }))
                  }
                  alertBody={
                    <div id="AlerteDevisDetails">
                      <Input
                        label="Nom du modèle"
                        type="selectSearch"
                        value={this.state.modeleNameToExport}
                        service={(search) =>
                          DevisModeleService.getAll({ global: search })
                        }
                        optionFieldToDisplay="nom_Devis_Modele"
                        valueFieldToDisplay="nom_Devis_Modele"
                        optionFieldToReturn="nom_Devis_Modele"
                        handleChange={(accessor, value) =>
                          this.setState((prevState) => ({
                            ...prevState,
                            modeleNameToExport: value,
                          }))
                        }
                        customFirstOption={(query) => ({
                          name: "* Ajouter le modèle : ".concat(query),
                          option: { nom_Devis_Modele: query },
                        })}
                        customFilter={containFilter}
                      ></Input>
                      <Input
                        label="Pôle du modèle"
                        type="selectSearch"
                        value={this.props.devis.produit_Interne}
                        service={ProduitInterneService.getAll}
                        optionFieldToDisplay="designation"
                        valueFieldToDisplay="designation"
                        handleChange={(accessor, value) =>
                          this.setState((prevState) => ({
                            ...prevState,
                            modelePoleToExport: value,
                          }))
                        }
                      ></Input>
                      <Input
                        label="Langue du modèle"
                        type="selectSearch"
                        value={this.props.devis.client?.langue}
                        service={LangueService.getAll}
                        optionFieldToDisplay="langage"
                        valueFieldToDisplay="langage"
                        handleChange={(accessor, value) =>
                          this.setState((prevState) => ({
                            ...prevState,
                            modeleLangueToExport: value,
                          }))
                        }
                      ></Input>
                    </div>
                  }
                ></ButtonIconWithValidation>
              </div>
              <div>
                <ButtonIcon
                  icon={faCheck}
                  className="btn btn-success text-light ms-1"
                  style={
                    !this.props.editing
                      ? { display: "none" }
                      : { width: "42px" }
                  }
                  onClick={this.handleClickValidate}
                ></ButtonIcon>
                <ButtonIcon
                  icon={faTimes}
                  className="btn btn-danger text-light ms-1"
                  onClick={this.handleClickUndo}
                  style={
                    !this.props.editing
                      ? { display: "none" }
                      : { width: "42px" }
                  }
                ></ButtonIcon>
                <ButtonIcon
                  icon={faPen}
                  className="btn btn-secondary text-light ms-1"
                  onClick={this.handleClickEdition}
                  style={
                    this.props.editing || right != "RW"
                      ? { display: "none" }
                      : { width: "42px" }
                  }
                ></ButtonIcon>
              </div>
            </div>
            <div id="DevisDetailsTable">
              <DndProvider backend={HTML5Backend}>
                <table>
                  <thead>
                    <tr>
                      <th className=" noPadding"></th>
                      <th className=" noPadding borderStyle">
                        <span>Ref Article</span>
                      </th>
                      <th className=" noPadding borderStyle">
                        <span>Description</span>
                      </th>
                      <th className=" noPadding borderStyle">
                        <span>Prix Vente Unitaire</span>
                      </th>
                      <th className=" noPadding borderStyle">
                        <span>Quantité</span>
                      </th>
                      <th className=" noPadding borderStyle">
                        <span>Unité</span>
                      </th>
                      <th className=" noPadding borderStyle">
                        <span>Remise (%)</span>
                      </th>
                      <th className=" noPadding borderStyle">
                        <span>Prix Vente Total</span>
                      </th>
                      <th className=" noPadding borderStyle">
                        <span>Montant TVA</span>
                      </th>
                      <th className=" noPadding borderStyle">
                        <span>Prix Vente Total TTC</span>
                      </th>
                      <th className="col11 noPadding  ">
                        <ButtonIcon
                          icon={faPlus}
                          iconSize="sm"
                          onClick={() => this.props?.addLineDevisDetail(0)}
                          className="btn btn-success addLineDevisDetail"
                          style={!this.props.editing ? { display: "none" } : {}}
                          tooltip="Ajouter article"
                        ></ButtonIcon>
                      </th>
                      <th className="col12 noPadding  ">
                        <ButtonIcon
                          icon={faCommentDots}
                          iconSize="sm"
                          onClick={() => this.props?.addLineDevisDetail(1)}
                          className="btn btn-success addLineDevisDetail"
                          style={!this.props.editing ? { display: "none" } : {}}
                          tooltip="Ajouter commentaire"
                        ></ButtonIcon>
                      </th>
                      <th className="col13 noPadding">
                        <ButtonIcon
                          textOption={"\u03A3"}
                          onClick={() => this.props?.addLineDevisDetail(2)}
                          className="btn btn-success addLineDevisDetail"
                          style={!this.props.editing ? { display: "none" } : {}}
                          tooltip="Ajout sous-total"
                        />
                      </th>
                      <th className="col14 noPadding"></th>
                    </tr>
                  </thead>

                  <tbody>
                    {this.props.devis?.devisDetails?.map((devisDetail, index) =>
                      this.renderLigneDevisDetail(devisDetail, index)
                    )}
                  </tbody>

                  <tfoot>
                    <tr>
                      <td
                        colSpan="6"
                        style={{ backgroundClip: "padding-box" }}
                      ></td>
                      <td className="borderStyle noPadding">
                        <span>Total</span>
                      </td>
                      <td className="borderStyle noPadding">
                        <span>
                          {formatValueDecimal(this.props.devis.prixVenteTotal)}
                        </span>
                      </td>
                      <td className="borderStyle noPadding">
                        <span>
                          {formatValueDecimal(this.props.devis.montantTVATotal)}
                        </span>
                      </td>
                      <td className="borderStyle noPadding">
                        <span>
                          {formatValueDecimal(
                            this.props.devis.prixVenteTTCTotal
                          )}
                        </span>
                      </td>
                    </tr>
                  </tfoot>
                </table>
              </DndProvider>
            </div>
          </div>
        </>
      );
    } else {
      return (
        <>
          <WarningBar
            active={false}
            content={"Vous n'avez pas le droit de voir cette page"}
          />
        </>
      );
    }
  }
}

export { Details };
