import http from "http-common";

const ConsultationService = class {
  /**
   * Fonction permettant de récuperer tous les consultations avec une possibilité de filtre via params
   * @param {*} params, paramètres à utiliser pour la requête (page, nombre d'éléments par page et filtres sur les champs)
   * @returns une liste des consultations respectant les critères de params et le nombre de page pour ces critères
   */
  static getAll(params) {
    return http.get("/consultations/", { params });
  }

  static getForSS(params) {
    return http.get("/consultations/", {
      params: {
        orderBy: "nom_Consultation",
        order: "ASC",
        nom_Client: params,
        size: 20,
        actif: true,
      },
    });
  }

  /**
   * Fonction permettant de créer un nouveau consultation pour un consultation donné
   * @param {*} consultation, le consultation à ajouter
   * @returns le consultation crée avec son id
   */
  static post(consultation) {
    return http.post("/consultations", consultation);
  }

  /**
   * Fonction permettant de mettre à jour un consultation
   * @param {*} consultation, le consultation à mettre à jour
   * @returns
   */
  static put(consultation) {
    return http.put("/consultations/" + consultation.id, consultation);
  }

  /**
   * Fonction permettant de créer une nouvelle consultation à partir d'une consultation existante
   * @param {*} consultationid, l'id de la consultation d'origine à dupliquer
   * @returns la consultation créée avec son id
   */
  static duplicate(consultationid) {
    return http.post("/consultations/duplicate/" + consultationid, null);
  }

  /**
   * Fonction permettant de récuperer un consultation via son id
   * @param {*} id, id de du consultation à récupérer
   * @returns le consultation souhaité
   */
  static getById(id) {
    return http.get("/consultations/" + id);
  }

  /**
   * Fonction permettant de supprimer un consultation
   * @param {*} consultation, le consultation à supprimer
   * @returns
   */
  static delete(consultation) {
    return http.delete("/consultations/" + consultation.id);
  }

  /**
   * @returns un fichier
   */
     static print(modele,id) {
      return http({
       url: "/consultations/impression/"+modele+"/"+id,
       method: "GET",
       responseType: "blob",
     });
   }

  // static Extract(params) {
  //   return http.get("/consultations/extraction", { params });
  // }

  static Extract(payload) {
    const params = new URLSearchParams(payload);
    return http({
      url: "/consultations/extraction?"+params,
      method: "GET",
      responseType: "blob",
    });
  }

  static ExtractByID(id) {
    return http({
      url: "/consultations/extraction/"+id,
      method: "GET",
      responseType: "blob",
    });
  }
};

export { ConsultationService };
