import http from "http-common";

const StatutsConsultationService = class {
  /**
   * Fonction permettant de récuperer toutes les statuts personnels
   * @returns la liste de toutes les statuts personnels
   */
  static getAll(params) {
    let url = "/StatutsConsultation";
    
    //Gestion de la valeur par défaut de la propriété "isActive" des paramètres
    if (!params)
      return http.get(url, {params: {isActive: true}});
    else if (typeof params === "object" && params.isActive === undefined)
        return http.get(url, {params: {...params, isActive: true}});
    else if (typeof params === "object" && params.isActive !== undefined)
      return http.get(url, {params});
    else
      return http.get(url, {params: {isActive: true}});
  }

  /**
   * Fonction permettant de récupérer une statut personnel via son id
   * @param {*} id, id de la statut personnel à récupérer
   * @returns la statut personnel souhaitée
   */
//   static getById(id) {
//     return http.get("/StatutsCommande/" + id);
//   }
};

export { StatutsConsultationService };
