import React from "react";
import { PropTypes } from "prop-types";
import { App } from "App";

// import { FonctionService } from "_services";
import { BoxContact, ButtonIcon, WarningBar } from "_components";
import { Input } from "_components/Input";
import { faPlus, faTimes } from "@fortawesome/free-solid-svg-icons";
import { ContactService } from "_services";

class ContactsClient extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      recherche: "",
    };

    this.IsSearchInDatas = this.IsSearchInDatas.bind(this);
    this.handleAddContact = this.handleAddContact.bind(this);
    this.handleSelectContact = this.handleSelectContact.bind(this);
  }

  handleAddContact() {
    this.props.history.push({
      pathname: "/contacts/add",
      state: { client: this.props.client },
    });
  }

  IsSearchInDatas(recherche, datas) {
    let isInRecherche = false;
    datas.map((string) => {
      if (
        string != null &&
        string
          .normalize("NFD")
          .replace(/\p{Diacritic}/gu, "")
          .toLowerCase()
          .indexOf(recherche.toLowerCase()) !== -1
      ) {
        isInRecherche = true;
      }
    });

    return isInRecherche;
  }

  LayoutContacts() {
    const contacts = this.props.client.contacts;
    const contactsDistri = this.props.client.distributorFinalContacts;
    const recherche = this.state.recherche;

    let contactsToDisplay = contacts?.filter((contact) =>
      this.IsSearchInDatas(recherche, [
        contact.nom_Contact,
        contact.prenom_Contact,
        contact.fonction ? contact.fonction.designation : null,
        contact.commentaire,
      ])
    );

    let contactsDistriToDisplay = contactsDistri?.filter((contact) =>
      this.IsSearchInDatas(recherche, [
        contact.nom_Contact,
        contact.prenom_Contact,
        contact.fonction ? contact.fonction.designation : null,
        contact.commentaire,
      ])
    );

    return (
      <>
        {contactsToDisplay.length && contactsDistriToDisplay.length ? (
          <>
            <p className="bg-success text-center fw-bold text-white rounded solwayFont">
              Contacts du client
            </p>
            {this.CartesContact(contactsToDisplay, true)}
            <hr />
            <p className="bg-info text-center fw-bold text-white rounded solwayFont">
              Contacts des clients finaux du distributeur
            </p>
            {this.CartesContact(contactsDistriToDisplay, false)}
          </>
        ) : contactsToDisplay.length ? (
          <>
            <p className="bg-success text-center fw-bold text-white rounded solwayFont">
              Contacts du client
            </p>
            {this.CartesContact(contactsToDisplay, true)}
          </>
        ) : contactsDistriToDisplay.length ? (
          <>
            <p className="bg-info text-center fw-bold text-white rounded solwayFont">
              Contacts des clients finaux du distributeur
            </p>
            {this.CartesContact(contactsDistriToDisplay, false)}
          </>
        ) : (
          <h4 className="text-center">Pas de contact</h4>
        )}
      </>
    );
  }

  CartesContact(contacts, actionsAvailable) {
    const right = App.RightsGuard?.current?.hasRight("Client", "Contacts");

    let contactsToDisplay = contacts?.map((contact) => (
      <div className="col-lg-4 col-md-12" key={contact.id} id={contact.id}>
        <BoxContact
          contact={contact}
          clientId={this.props.client.id}
          updateFunction={this.props.updateContact}
          redirectToLicence={() => this.props.changeTab("Logiciels")}
          history={this.props.history}
          NoEdition={right == "RO" || !actionsAvailable}
          licence={this.props.licences.find(
            (licence) => licence.user?.id == contact.id
          )}
          adresseAColler={this.props.adresseAColler}
        />
      </div>
    ));

    return contactsToDisplay;
  }

  handleSelectContact(acc, selectOption) {
    if (selectOption)
      ContactService.link(selectOption, this.props.client.id)
        .then(() => {
          this.props.updateContact(selectOption, "link");
        })
        .catch((error) => {
          console.error(error);
        });
  }

  render() {
    const right = App.RightsGuard?.current?.hasRight("Client", "Contacts");
    const creationContact = App.RightsGuard?.current?.hasRight(
      "Contact",
      "Creation"
    );

    if (right == "RW" || right == "RO") {
      return (
        <>
          <div
            className="d-flex mt-2 justify-content-between"
            style={{ marginBottom: "10px" }}
          >
            <div
              className="input-group"
              style={{ width: "500px", flexShrink: "0" }}
            >
              <input
                name="rechercheGlobale"
                placeholder="Rechercher par nom, prénom, fonction ou commentaire..."
                className="form-control"
                onChange={(e) => {
                  this.setState({ recherche: e.target.value });
                }}
                value={this.state.recherche}
                style={{ maxWidth: "450px" }}
              />
              <ButtonIcon
                smallText=""
                icon={faTimes}
                iconSize="1x"
                onClick={() => {
                  this.setState({ recherche: "" });
                }}
                className="btn btn-danger"
              ></ButtonIcon>
            </div>
            {right == "RO" ? (
              <></>
            ) : (
              <>
                <div
                  className="d-flex flex-row align-items-center"
                  style={{
                    width: "600px",
                  }}
                >
                  <div
                    style={{
                      width: "230px",
                      flexShrink: "0",
                    }}
                  >
                    Ajouter un contact existant :
                  </div>
                  <Input
                    type="selectSearch"
                    service={(p) =>
                      ContactService.getAll({ global: p, size: 100 })
                    }
                    valueFieldToDisplay={[
                      "nom_Contact",
                      "prenom_Contact",
                      "email",
                    ]}
                    optionFieldToDisplay={[
                      "nom_Contact",
                      "prenom_Contact",
                      "email",
                    ]}
                    handleChange={this.handleSelectContact}
                  ></Input>
                </div>{" "}
                {creationContact == "RW" ? (
                  <div
                    className="ms-1 d-flex flex-row align-items-center"
                    role="group"
                  >
                    <div
                      style={{
                        width: "230px",
                        flexShrink: "0",
                      }}
                    >
                      Créer un nouveau contact :
                    </div>
                    <ButtonIcon
                      icon={faPlus}
                      iconSize="lg"
                      className="btn btn-success text-light"
                      onClick={this.handleAddContact}
                    ></ButtonIcon>
                  </div>
                ) : null}
              </>
            )}
          </div>
          <hr />
          <div className="row">{this.LayoutContacts()}</div>
        </>
      );
    } else
      return (
        <>
          <WarningBar
            active={false}
            content={"Vous n'avez pas le droit de voir cette page"}
          />
        </>
      );
  }
}

ContactsClient.propTypes = {
  history: PropTypes.object,
  client: PropTypes.object,
  updateContact: PropTypes.func,
};

export { ContactsClient };
