import React from "react";
import { PropTypes } from "prop-types";
import { App } from "App";

import { ClientService } from "_services";
import { Box, WarningBar } from "_components";
import { Input } from "_components/Input";

class Logistique extends React.PureComponent {
  constructor(props) {
    super(props);
  }

  render() {
    const right = App.RightsGuard?.current?.hasRight("Commande", "Logistique");
    if (right == "RW" || right == "RO") {
      return (
        <>
          <Box
            header={
              <div className="row">
                <div className="col-12 py-2">Général</div>
              </div>
            }
            body={
              <>
                <div>
                  <Input
                    label="Selectionner l'adresse de livraison du client"
                    accessor="adresse_Livraison"
                    type={right == "RO" ? null : "selectSearch"}
                    functionAppliedToValue={() => {
                      return "";
                    }}
                    required={false}
                    service={() =>
                      ClientService.getAllAdressesById(
                        this.props.commande.client?.id
                      )
                    }
                    optionFieldToDisplay="intitule"
                    valueFieldToDisplay="intitule"
                    colSize={12}
                    handleChange={(accessor, value) =>
                      this.props.handleChange(
                        accessor,
                        value,
                        this.props.handleUpdate
                      )
                    }
                  ></Input>
                </div>

                <div style={{ marginTop: "20px" }}>
                  <div className="text-uppercase text-muted">
                    Adresse sélectionnée
                  </div>
                  {this.props.commande.adresse_Livraison?.intitule ?? ""}
                  <br />
                  {this.props.commande.adresse_Livraison?.voie ?? ""}
                  <br />
                  {this.props.commande.adresse_Livraison?.complement ?? ""}
                  <br />
                  {(this.props.commande.adresse_Livraison?.cp ?? "") +
                    " " +
                    (this.props.commande.adresse_Livraison?.ville ?? "")}
                  <br />
                  {this.props.commande.adresse_Livraison?.pays?.nom_Fr ?? ""}
                  <br />

                  <br />
                  {this.props.commande.adresse_Livraison?.nomContact ?? ""}

                  <br />
                  {this.props.commande.adresse_Livraison?.telephone ?? ""}

                  <br />
                  {this.props.commande.adresse_Livraison?.email ?? ""}
                </div>
              </>
            }
          />
        </>
      );
    } else {
      return (
        <>
          <WarningBar
            active={false}
            content={"Vous n'avez pas le droit de voir cette page"}
          />
        </>
      );
    }
  }
}

Logistique.propTypes = {
  commande: PropTypes.object,
  stateFieldNameToUpdate: PropTypes.string,
  handleChange: PropTypes.func,
  history: PropTypes.object,
  service: PropTypes.func,
};

export { Logistique };
