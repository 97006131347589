import { faSave } from "@fortawesome/free-solid-svg-icons";
import { ButtonIcon } from "_components/Buttons";
import { DialogForm } from "_components/Dialogs";
import { Input } from "_components/Input";
import { FiltrePersonnaliseService } from "_services/FiltrePersonnalise";
import React, { useState } from "react";

const FiltrePersonnalise = (props) => {
  const [openDialogFiltreFunc, setOpenDialogFiltreFunc] = useState();
  const [nomFiltre, setNomFiltre] = useState("");
  const [forceUpdateSelect, setForceUpdateSelect] = useState();

  const postFiltreServiceProxy = () => {
    FiltrePersonnaliseService.post({
      nomFiltre: nomFiltre,
      page: props.page,
      filtreJSONstringified: JSON.stringify({
        ...props.getFiltre(),
      }),
    }).then(() => forceUpdateSelect());
  };

  /**
   *
   * Recupère les filtres liés  à la grille
   */
  const getFiltreServiceProxy = () => {
    return FiltrePersonnaliseService.getByPageAndOid(props.page);
  };

  return (
    <div className="d-flex align-self-center nowrap">
      <div id="filtre">
        <div>
          <DialogForm
            tooltip="Sauvegarde filtre"
            classNameButton="btn btn-success"
            dialogTitle="Sauvegarde filtre"
            labelValidateButton="Valider"
            setOpenDialog={(openDialogFunc) =>
              setOpenDialogFiltreFunc(() => openDialogFunc)
            }
            onValidate={() => {
              postFiltreServiceProxy();
            }}
            onClose={() => {}}
            body={
              <div id="PopupFiltre">
                <div>
                  Veuillez choisir un nom pour ce filtre <br />
                </div>

                <Input
                  label="Nom du filtre"
                  accessor="nomFiltre"
                  type="text"
                  value={nomFiltre ?? ""}
                  handleChange={(acc, value) => setNomFiltre(value)}
                  required={true}
                />
              </div>
            }
          />
        </div>
        <Input
          accessor="filtre" // Accesseur
          handleChange={(acc, value) => {
            if (value != null)
              return props.handleLoadFiltre(
                JSON.parse(value.filtreJSONstringified)
              );
            return props.handleLoadFiltre(null);
          }} // Fonction pour mettre à jour la valeur dans l'object via son accesseur
          type="selectSearch" // Type de l'input a utiliser
          service={getFiltreServiceProxy}
          showClearButton={false}
          value={nomFiltre ?? ""}
          optionFieldToDisplay={"nomFiltre"}
          valueFieldToDisplay={"nomFiltre"}
          setForceUpdateFunction={(func) => setForceUpdateSelect(() => func)}
        />
      </div>
      <div className="ms-1">
        <ButtonIcon
          {...(props.buttonParameter != null
            ? props.buttonParameter
            : {
                iconSize: "lg",
                className: "form-control btn btn-success mx-1",
              })}
          icon={faSave}
          id="saveFiltre"
          onClick={() => openDialogFiltreFunc()}
        ></ButtonIcon>
      </div>
    </div>
  );
};
export { FiltrePersonnalise };
