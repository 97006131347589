// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `

.ligneHeurePersonnel .form-control {
  background-color: transparent;
}

.ligneHeurePersonnel .form-control:disabled {
  background-color: transparent;
}

.ligneHeurePersonnel .form-select {
  background-color: transparent;
}

.ligneHeurePersonnel .form-select:disabled {
  background-color: transparent;
}

.ligneHeurePersonnel.bgColorBlue {
  background-color: #0dcaf0;
}

.ligneHeurePersonnel.bgColorYellow {
  background-color: #f4d147;
}

.ligneHeurePersonnel.bgColorGreen {
  background-color: #20c997;
}

.ligneHeurePersonnel.bgColorRed {
  background-color: #ba5252;
}

.maxWidth75{
  max-width: 75px;
}

.maxWidth130{
  max-width: 130px;
}

.maxWidth150{
  max-width: 150px;
}`, "",{"version":3,"sources":["webpack://./src/FichePersonnel/Heures/Heures.css"],"names":[],"mappings":";;AAEA;EACE,6BAA6B;AAC/B;;AAEA;EACE,6BAA6B;AAC/B;;AAEA;EACE,6BAA6B;AAC/B;;AAEA;EACE,6BAA6B;AAC/B;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE,eAAe;AACjB;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,gBAAgB;AAClB","sourcesContent":["\n\n.ligneHeurePersonnel .form-control {\n  background-color: transparent;\n}\n\n.ligneHeurePersonnel .form-control:disabled {\n  background-color: transparent;\n}\n\n.ligneHeurePersonnel .form-select {\n  background-color: transparent;\n}\n\n.ligneHeurePersonnel .form-select:disabled {\n  background-color: transparent;\n}\n\n.ligneHeurePersonnel.bgColorBlue {\n  background-color: #0dcaf0;\n}\n\n.ligneHeurePersonnel.bgColorYellow {\n  background-color: #f4d147;\n}\n\n.ligneHeurePersonnel.bgColorGreen {\n  background-color: #20c997;\n}\n\n.ligneHeurePersonnel.bgColorRed {\n  background-color: #ba5252;\n}\n\n.maxWidth75{\n  max-width: 75px;\n}\n\n.maxWidth130{\n  max-width: 130px;\n}\n\n.maxWidth150{\n  max-width: 150px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
