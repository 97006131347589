import { Box } from "_components";

const VersionNumber = () => {
  return (
    <Box
      header={<div className="col-6 align-self-center py-2">About</div>}
      body={
        <div>
          <div>Version Number: {process.env.REACT_APP_VERSION_NUMBER}</div>
        </div>
      }
    />
  );
};

export default VersionNumber;
