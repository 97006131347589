import React from "react";
import { App } from "App";

import { Input } from "_components/Input";

import { ButtonIcon, WarningBar } from "_components";

import { faCheck, faTimes, faPen } from "@fortawesome/free-solid-svg-icons";

import { CalculetteAdminService } from "_services";

class CalculetteAdmin extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      calAdmin: null,
      savedCal: null,
    };

    this.input = this.input.bind(this);
    this.emptyCell = this.emptyCell.bind(this);
    this.renderCalculette = this.renderCalculette.bind(this);
    this.handleClickEdition = this.handleClickEdition.bind(this);
    this.handleClickUndo = this.handleClickUndo.bind(this);
    this.handleClickValidate = this.handleClickValidate.bind(this);
    this.renderEntete = this.renderEntete.bind(this);
    this.renderAutre = this.renderAutre.bind(this);
    this.renderDepla = this.renderDepla.bind(this);
    this.renderHeadCalculette = this.renderHeadCalculette.bind(this);
    this.renderHypotheses = this.renderHypotheses.bind(this);
    this.renderMat = this.renderMat.bind(this);
    this.renderPersonnel = this.renderPersonnel.bind(this);
    this.renderSousTotal = this.renderSousTotal.bind(this);
    this.renderPrime = this.renderPrime.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleUpdate = this.handleUpdate.bind(this);
  }

  componentDidMount() {}

  input({ accessor, disabled = false, numberOfDecimals }) {
    return (
      <td className="input">
        <Input
          value={this.state.calAdmin[accessor]}
          ignoredValues={[0]}
          numberOfDecimals={numberOfDecimals}
          handleBlur={this.handleChange}
          type="decimal"
          accessor={accessor}
          showClearButton={false}
          showValidator={false}
          disabled={disabled || !this.props.editing}
        ></Input>
      </td>
    );
  }

  inputText(accessor, disabled = false) {
    return (
      <td className="input">
        <Input
          value={this.state.calAdmin[accessor]}
          handleBlur={this.handleChange}
          type="text"
          accessor={accessor}
          showClearButton={false}
          showValidator={false}
          disabled={disabled || !this.props.editing}
        ></Input>
      </td>
    );
  }
  emptyCell() {
    return <td className="empty"></td>;
  }

  handleChange(accessor, value) {
    var newCalculette = {
      ...this.state.calAdmin,
      [accessor]: value,
    };
    this.setState({ calAdmin: newCalculette });
  }

  handleClickEdition() {
    this.setState({
      savedCal: { ...this.state.calAdmin },
    });
    this.props.handleEditing(true);
  }

  handleClickUndo() {
    this.setState({ calAdmin: this.state.savedCal });
    this.props.handleEditing(false);
  }

  handleUpdate() {
    CalculetteAdminService.put(this.state.calAdmin).then((res) =>
      this.setState({ calAdmin: res.data })
    );
  }
  handleClickValidate() {
    this.handleUpdate();
    this.props.handleEditing(false);
  }

  renderEntete() {
    const right = App.RightsGuard?.current?.hasRight("Devis", "Calculette");

    return (
      <div className="d-flex justify-content-end" style={{ marginTop: "30px" }}>
        <ButtonIcon
          icon={faCheck}
          className="btn btn-success text-light ms-1"
          style={!this.props.editing ? { display: "none" } : { width: "42px" }}
          onClick={this.handleClickValidate}
        ></ButtonIcon>
        <ButtonIcon
          icon={faTimes}
          className="btn btn-danger text-light ms-1"
          onClick={this.handleClickUndo}
          style={!this.props.editing ? { display: "none" } : { width: "42px" }}
        ></ButtonIcon>
        <ButtonIcon
          icon={faPen}
          className="btn btn-secondary text-light ms-1"
          onClick={this.handleClickEdition}
          style={
            this.props.editing || right != "RW"
              ? { display: "none" }
              : { width: "42px" }
          }
        ></ButtonIcon>
      </div>
    );
  }

  renderHypotheses() {
    return (
      <>
        <div className="d-flex" style={{ gap: "20px", marginBottom: "50px" }}>
          <div style={{ width: "20%" }}>
            <Input
              value={{
                id: -1,
                designation: this.state.calAdmin?.modeCalcul,
              }}
              type="selectSearch"
              options={[
                {
                  id: 0,
                  designation: "Logique 1 : Accord cadre",
                },
                {
                  id: 1,
                  designation: "Logique 2 : Prix de revient",
                },
                {
                  id: 2,
                  designation: "Logique 3 : Prix de vente",
                },
              ]}
              optionFieldToReturn="designation"
              optionFieldToDisplay="designation"
              valueFieldToDisplay="designation"
              label="Logique de calcul"
              accessor="modeCalcul"
              handleChange={this.handleChange}
              disabled={!this.props.editing}
            />
          </div>
          <div style={{ width: "20%" }}>
            {this.state.calAdmin?.modeCalcul == "Logique 1 : Accord cadre" ? (
              <Input
                type="selectSearch"
                options={[
                  {
                    id: 0,
                    designation: "Jour / Nuit",
                  },
                  {
                    id: 1,
                    designation: "Km",
                  },
                ]}
                optionFieldToReturn="designation"
                optionFieldToDisplay="designation"
                valueFieldToDisplay="designation"
                label="Calcul du PV"
                accessor="financier_ModeCalcul"
                handleChange={this.handleChange}
                value={{
                  id: -1,
                  designation: this.state.calAdmin?.financier_ModeCalcul,
                }}
                disabled={!this.props.editing}
              />
            ) : null}
          </div>
        </div>
        <div className="d-flex " style={{ gap: "10px", marginBottom: "60px" }}>
          <div>
            <table className="Calculette General Financier">
              <thead>
                <tr>
                  <td className="label">Prix Jour</td>
                  {this.input({
                    accessor: "financier_PrixJour",
                  })}
                </tr>
                <tr>
                  <td className="label">Prix Nuit</td>
                  {this.input({
                    accessor: "financier_PrixNuit",
                  })}
                </tr>
                <tr>
                  <td className="label">Prix Kilomètre</td>
                  {this.input({
                    accessor: "financier_PrixMetre",
                  })}
                </tr>
                <tr>
                  <td className="label">Temps Rapport / nb km</td>
                  {this.input({
                    accessor: "financier_TempsRapport_Ou_NbKmTraiterBureau",
                  })}
                </tr>
              </thead>
            </table>
          </div>
        </div>
      </>
    );
  }

  renderHeadCalculette() {
    return (
      <>
        <tr>
          <th>Label</th>
          <th>Coût unitaire</th>
          <th style={{ width: "400px" }}>Unité d'oeuvre</th>
        </tr>
      </>
    );
  }

  renderPersonnel() {
    return (
      <>
        <tr>
          <th>Personnel</th>
          <th colSpan="2"></th>
        </tr>
        <tr>
          <td className="label">Responsable Service</td>
          {this.input({
            accessor: "personnel_ResponsableService_CoutUnitaire",
          })}
          {this.inputText("personnel_ResponsableService_Unite")}
        </tr>
        <tr>
          <td className="label">Responsable Pôle</td>
          {this.input({
            accessor: "personnel_ResponsablePole_CoutUnitaire",
          })}
          {this.inputText("personnel_ResponsablePole_Unite")}
        </tr>

        <tr>
          <td className="label">Chargé d'affaire</td>
          {this.input({
            accessor: "personnel_ChargeDAffaire_CoutUnitaire",
          })}
          {this.inputText("personnel_ChargeDAffaire_Unite")}
        </tr>
        <tr>
          <td className="label">Techniciens</td>
          {this.input({
            accessor: "personnel_Techniciens_CoutUnitaire",
          })}
          {this.inputText("personnel_Techniciens_Unite")}
        </tr>
        <tr>
          <td className="label">Assistante</td>
          {this.input({
            accessor: "personnel_Assistante_CoutUnitaire",
          })}
          {this.inputText("personnel_Assistante_Unite")}
        </tr>
        <tr>
          <td className="label paddingLeft">dont Heures de chantier de jour</td>
          {this.input({
            accessor: "personnel_HeuresDeChantierDeJour_CoutUnitaire",
            disabled: true,
          })}
          {this.inputText("personnel_HeuresDeChantierDeJour_Unite")}
        </tr>
        <tr>
          <td className="label paddingLeft">dont Heures de chantier de nuit</td>
          {this.input({
            accessor: "personnel_HeuresDeChantierDeNuit_CoutUnitaire",
            disabled: true,
          })}
          {this.inputText("personnel_HeuresDeChantierDeNuit_Unite")}
        </tr>
        <tr>
          <td className="label paddingLeft">dont heure de dimanche</td>
          {this.input({
            accessor: "personnel_HeuresDimanche_CoutUnitaire",
            disabled: true,
          })}
          {this.inputText("personnel_HeuresDimanche_Unite")}
        </tr>
        <tr>
          <td className="label paddingLeft">dont heures supp</td>
          {this.input({
            accessor: "personnel_HeuresSupp_CoutUnitaire",
            disabled: true,
          })}
          {this.inputText("personnel_HeuresSupp_Unite")}
        </tr>
        <tr>
          <td className="label paddingLeft">dont heures férié</td>
          {this.input({
            accessor: "personnel_HeuresFeriees_CoutUnitaire",
            disabled: true,
          })}
          {this.inputText("personnel_HeuresFeriees_Unite")}
        </tr>
      </>
    );
  }

  renderPrime() {
    return (
      <>
        <tr>
          <th>Prime</th>
          <th colSpan="2"></th>
        </tr>
        <tr>
          <td className="label">Majoration Heures chantier de nuit</td>
          {this.input({
            accessor: "prime_MajorationHeuresChantierNuit_CoutUnitaire",
          })}
          {this.inputText("prime_MajorationHeuresChantierNuit_Unite")}
        </tr>
        <tr>
          <td className="label">Prime de nuit</td>
          {this.input({
            accessor: "prime_PrimeDeNuit_CoutUnitaire",
          })}
          {this.inputText("prime_PrimeDeNuit_Unite")}
        </tr>
        <tr>
          <td className="label">Prime dépla</td>
          {this.input({
            accessor: "prime_PrimeDeDeplacement_CoutUnitaire",
          })}
          {this.inputText("prime_PrimeDeDeplacement_Unite")}
        </tr>
        <tr>
          <td className="label">Prime dépla4</td>
          {this.input({
            accessor: "prime_PrimeDeDepla4j_CoutUnitaire",
          })}
          {this.inputText("prime_PrimeDeDepla4j_Unite")}
        </tr>
        <tr>
          <td className="label">Prime dépla5</td>
          {this.input({
            accessor: "prime_PrimeDeDepla5j_CoutUnitaire",
          })}
          {this.inputText("prime_PrimeDeDepla5j_Unite")}
        </tr>
        <tr>
          <td className="label">Recup heure de nuit</td>
          {this.input({
            accessor: "prime_RecupHeureDeNuit_CoutUnitaire",
          })}
          {this.inputText("prime_RecupHeureDeNuit_Unite")}
        </tr>
        <tr>
          <td className="label">Majoration heure supp</td>
          {this.input({
            accessor: "prime_MajorationHeuresSupp_CoutUnitaire",
          })}
          {this.inputText("prime_MajorationHeuresSupp_Unite")}
        </tr>
        <tr>
          <td className="label">majoration heure dimanche</td>
          {this.input({
            accessor: "prime_MajorationHeuresDimanche_CoutUnitaire",
          })}
          {this.inputText("prime_MajorationHeuresDimanche_Unite")}
        </tr>
        <tr>
          <td className="label">prime WE</td>
          {this.input({
            accessor: "prime_Weekend_CoutUnitaire",
          })}
          {this.inputText("prime_Weekend_Unite")}
        </tr>
        <tr>
          <td className="label">Majoration heure férié</td>
          {this.input({
            accessor: "prime_MajorationHeuresFeriee_CoutUnitaire",
          })}
          {this.inputText("prime_MajorationHeuresFeriee_Unite")}
        </tr>
      </>
    );
  }

  renderDepla() {
    return (
      <>
        <tr>
          <th className="label">Déplacements</th>
          <th colSpan="2"></th>
        </tr>
        <tr>
          <td className="label">Forfait nuit</td>
          {this.input({
            accessor: "deplacements_ForfaitNuit_CoutUnitaire",
          })}
          {this.inputText("deplacements_ForfaitNuit_Unite")}
        </tr>
        <tr>
          <td className="label">Forfait restaurant</td>
          {this.input({
            accessor: "deplacements_ForfaitRestaurant_CoutUnitaire",
          })}
          {this.inputText("deplacements_ForfaitRestaurant_Unite")}
        </tr>
        <tr>
          <td className="label">Essence</td>
          {this.input({
            accessor: "deplacements_Essence_CoutUnitaire",
            numberOfDecimals: 4,
          })}
          {this.inputText("deplacements_Essence_Unite")}
        </tr>
        <tr>
          <td className="label">Péages</td>
          {this.input({
            accessor: "deplacements_Peages_CoutUnitaire",
          })}
          {this.inputText("deplacements_Peages_Unite")}
        </tr>
        <tr>
          <td className="label">Véhicule : frais fixes par jour</td>
          {this.input({
            accessor: "deplacements_VehiculeFraisFixesParJour_CoutUnitaire",
          })}
          {this.inputText("deplacements_VehiculeFraisFixesParJour_Unite")}
        </tr>
        <tr>
          <td className="label">Train / Avion</td>
          {this.input({
            accessor: "deplacements_TrainAvion_CoutUnitaire",
          })}
          {this.inputText("deplacements_TrainAvion_Unite")}
        </tr>
      </>
    );
  }

  renderMat() {
    return (
      <>
        <tr>
          <th className="label">Matériels</th>
          <th colSpan="2"></th>
        </tr>
        <tr>
          <td className="label">Système d'informations</td>
          {this.input({
            accessor: "materiels_SystemeDInformations_CoutUnitaire",
          })}
          {this.inputText("materiels_SystemeDInformations_Unite")}
        </tr>

        <tr>
          {this.inputText("materiels_MaterielIndustriel_A_Label")}
          {this.input({
            accessor: "materiels_MaterielIndustriel_A_CoutUnitaire",
          })}
          {this.inputText("materiels_MaterielIndustriel_A_Unite")}
        </tr>

        <tr>
          <td className="label">Sous-traitance ou location</td>
          {this.input({
            accessor: "materiels_SousTraitanceOuLocation_CoutUnitaire",
          })}
          {this.inputText("materiels_SousTraitanceOuLocation_Unite")}
        </tr>
        <tr>
          <td className="label">Consommables</td>
          {this.input({
            accessor: "materiels_Consommables_CoutUnitaire",
          })}
          {this.inputText("materiels_Consommables_Unite")}
        </tr>
      </>
    );
  }

  renderAutre() {
    return (
      <>
        <tr>
          <th className="label">Autres</th>
          <th colSpan="2"></th>
        </tr>
        <tr>
          {this.inputText("autres_A_Label")}
          {this.input({ accessor: "autres_A_CoutUnitaire" })}
          {this.inputText("autres_A_Unite")}
        </tr>
        <tr>
          {this.inputText("autres_B_Label")}
          {this.input({ accessor: "autres_B_CoutUnitaire" })}
          {this.inputText("autres_B_Unite")}
        </tr>
        <tr>
          {this.inputText("autres_C_Label")}
          {this.input({ accessor: "autres_C_CoutUnitaire" })}
          {this.inputText("autres_C_Unite")}
        </tr>
        <tr>
          {this.inputText("autres_D_Label")}
          {this.input({ accessor: "autres_D_CoutUnitaire" })}
          {this.inputText("autres_D_Unite")}
        </tr>
        <tr>
          {this.inputText("autres_E_Label")}
          {this.input({ accessor: "autres_E_CoutUnitaire" })}
          {this.inputText("autres_E_Unite")}
        </tr>
      </>
    );
  }

  renderSousTotal() {
    return (
      <>
        <tr>
          <th className="label">Sous-total exploitation</th>
          <th colSpan="2"></th>
        </tr>

        <tr>
          <td className="label bold">Assurance</td>
          {this.input({
            accessor: "exploitation_Assurance_CoutUnitaire",
          })}
          {this.inputText("exploitation_Assurance_Unite")}
        </tr>
        {this.state.calAdmin?.type == "ETU" ? (
          <>
            <tr>
              <td className="label bold">Assurance G1-G4</td>
              {this.input({
                accessor: "exploitation_Assurance_G1_CoutUnitaire",
              })}
            </tr>
            <tr>
              <td className="label bold">Assurance G5</td>
              {this.input({
                accessor: "exploitation_Assurance_G5_CoutUnitaire",
              })}
            </tr>
            <tr>
              <td className="label bold">Assurance DIG</td>
              {this.input({
                accessor: "exploitation_Assurance_DIG_CoutUnitaire",
              })}
            </tr>
          </>
        ) : null}
        <tr>
          <td className="label bold">Aléa</td>
          {this.input({
            accessor: "exploitation_Alea_CoutUnitaire",
          })}
          {this.inputText("exploitation_Alea_Unite")}
        </tr>
      </>
    );
  }

  renderCalculette() {
    return (
      <div className="shadow p-3 m-4 bg-body rounded bg-light pageClient">
        <div className="col-3 align-self-center py-2">
          <div
            id="module"
            style={{
              width: "80px",
              flexShrink: "0",
            }}
          >
            Module :
          </div>
          <Input
            accessor="calAdmin"
            type="selectSearch"
            valueFieldToDisplay={"type"}
            optionFieldToDisplay={"type"}
            optionFieldToReturn={"id"}
            handleChange={(acc, val) => {
              if (val != null) {
                CalculetteAdminService.getById(val).then((res) =>
                  this.setState({ calAdmin: res.data })
                );
              } else {
                this.setState({ calAdmin: null });
              }
            }}
            service={CalculetteAdminService.getAll}
          />
        </div>
        {this.state.calAdmin ? (
          <div>
            {this.renderEntete()}
            {this.renderHypotheses()}
            <table className="Calculette">
              <thead>
                {this.renderHeadCalculette()}
                {this.renderPersonnel()}
                {this.renderPrime()}
                {this.renderDepla()}
                {this.renderMat()}
                {this.renderAutre()}
                {this.renderSousTotal()}
              </thead>
            </table>
          </div>
        ) : null}
      </div>
    );
  }

  render() {
    const right = App.RightsGuard?.current?.hasRight("Devis", "Calculette");
    if (right == "RW" || right == "RO") {
      return this.renderCalculette();
    } else {
      return (
        <>
          <WarningBar
            active={false}
            content={"Vous n'avez pas le droit de voir cette page"}
          />
        </>
      );
    }
  }
}

export { CalculetteAdmin };
