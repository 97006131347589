import http from "http-common";

const ContactService = class {
  /**
   * Fonction permettant de récuperer tous les contactss avec une possibilité de filtre via params
   * @param {*} params, paramètres à utiliser pour la requête (page, nombre d'éléments par page et filtres sur les champs)
   * @returns une liste des contactss respectant les critères de params et le nombre de page pour ces critères
   */
  static getAll(params) {
    return http.get("/contacts", { params });
  }

  /**
   * Fonction permettant de récuperer un contacts via son id
   * @param {*} id, id de du contacts à récupérer
   * @returns le contacts souhaité
   */
  static getById(id) {
    return http.get("/contacts/" + id);
  }

  /**
   * Fonction permettant de récuperer des contacts via l'id d'un client
   * @param {*} idClient, id du client
   * @returns les contacts souhaités
   */
  static getByIdClient(idClient) {
    if (idClient) {
      return http.get("/contacts/client/" + idClient);
    } else {
      return new Promise(() => {});
    }
  }

  /**
   * Fonction permettant de créer un nouveau contact pour un client donné
   * @param {*} contact, le contact à ajouter
   * @param {*} idClient, l'id du client à qui ajouter le contact
   * @returns le contact crée avec son id
   */
  static post(contact) {
    return http.post("/contacts", contact);
  }

  /**
   * Fonction permettant de créer un nouveau contact pour un client donné
   * @param {*} contact, le contact à ajouter
   * @param {*} idClient, l'id du client à qui ajouter le contact
   * @returns le contact crée avec son id
   */
  static link(contact, idClient) {
    return http.post("/contacts/" + contact.id + "/clients/" + idClient);
  }

  /**
   * Fonction permettant de mettre à jour un contact
   * @param {*} contact, le contact à mettre à jour
   * @returns
   */
  static put(contact) {
    // contact.date_Modif = new Date().toISOString();
    return http.put("/contacts/" + contact.id, contact);
  }

  /**
   * Fonction permettant de supprimer un contact
   * @param {*} contact, le contact à supprimer
   * @returns
   */
  static delete(contact, idClient) {
    if (idClient) {
      return http.delete("/contacts/" + contact.id + "/" + idClient);
    } else {
      return http.delete("/contacts/" + contact.id);
    }
  }

  static Extract(payload) {
    const params = new URLSearchParams(payload);
    return http({
      url: "/contacts/extraction?"+params,
      method: "GET",
      responseType: "blob",
    });
  }
};

export { ContactService };
