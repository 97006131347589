import http from "http-common";

const DevisService = class {
  /**
   * Fonction permettant de récuperer tous les devis avec une possibilité de filtre via params
   * @param {*} params, paramètres à utiliser pour la requête (page, nombre d'éléments par page et filtres sur les champs)
   * @returns une liste des devis respectant les critères de params et le nombre de page pour ces critères
   */
  static getAll(params) {
    return http.get("/devis/", { params });
  }

  static getForSS(params) {
    return http.get("/devis/", {
      params: {
        orderBy: "nom_Devis",
        order: "ASC",
        nom_Client: params,
        size: 20,
        actif: true,
      },
    });
  }

  /**
   * Fonction permettant de créer un nouveau devis
   * @param {*} devis, le devis à ajouter
   * @returns le devis créé avec son id
   */
  static post(devis) {
    return http.post("/devis", devis);
  }

  /**
   * Fonction permettant de créer un nouveau devis à partir d'une consultation
   * @param {*} idConsult, la consultation à partir de laquelle créer le devis
   * @param {*} idReussite, l'identifiant du taux de réussite
   * @param {*} date, la date de création du devis
   * @returns le devis créé avec son id
   */
  static postFromConsult(idConsult, idReussite, date) {
    return http.post(
      "/devis/fromConsultation/" + idConsult + "/" + idReussite + "/" + date
    );
  }

  /**
   * Fonction permettant de créer un nouveau devis d'ajout de modules à une licence
   * @param {*} licenceId, l'identitfiant de la licence à l'origine du devis
   * @param {*} devis, les informations sur le devis à ajouter
   * @param {*} modules, la liste des modules à ajouter
   * @returns le devis créé avec son id
   */
  static postLicenceAddModules(licenceId, devis, modules) {
    var refModules = modules.map(module => module.aR_Ref);

    return http.post("/devis/modulesLicence/" + licenceId, {devis: devis, refModules: refModules});
  }

  /**
   * Fonction permettant de créer un nouveau devis de licence sans matériel
   * @param {*} devis, les informations sur le devis à ajouter
   * @param {*} licences, les licences à ajouter
   * @returns le devis créé avec son id
   */
    static postDevisLicence(devis, licences, utilisateur) {  
      return http.post("/devis/licences/", { devis: devis, detailsLicences: licences, contact: utilisateur });
    }

  /**
   * Fonction permettant de créer un nouveau devis à partir d'un devis existant
   * @param {*} devisid, l'id du devis d'origine à dupliquer
   * @param {*} annulerDevis, booléen, si true, on annule le devis d'origine.
   * @returns le devis créé avec son id
   */
  static duplicate(devisid, annulerDevis) {
    return http.post("/devis/duplicate/" + devisid, null, {
      params: {
        annulerDevis,
      },
    });
  }

  /**
   * Fonction permettant de mettre à jour un devis
   * @param {*} devis, le devis à mettre à jour
   * @returns
   */
  static put(devis) {
    return http.put("/devis/" + devis.id, devis);
  }

  /**
   * Fonction permettant de récuperer un devis via son id
   * @param {*} id, id du devis à récupérer
   * @returns le devis souhaité
   */
  static getById(id) {
    return http.get("/devis/" + id);
  }

  /**
   * Fonction permettant de supprimer un devis
   * @param {*} devis, le devis à supprimer
   * @returns
   */
  static delete(devis) {
    return http.delete("/devis/" + devis.id);
  }

  /**
   * @returns un fichier
   */
  static print(idDevis) {
    return http({
      url: "/devis/impression/" + idDevis,
      method: "GET",
      responseType: "blob",
    });
  }

  /**
   * @returns un fichier.
   */
  static printPack(impression, idDevis) {
    return http({
      url: "/devis/impressionPack/" + impression + "/" + idDevis,
      method: "GET",
      responseType: "blob",
    });
  }

  /**
   * @returns un fichier.
   */
  static printAssistance(impression, idDevis) {
    return http({
      url: "/devis/impressionAssistance/" + impression + "/" + idDevis,
      method: "GET",
      responseType: "blob",
    });
  }

  /**
   * @returns un fichier.
   */
  static printContrat(impression, idDevis) {
    return http({
      url: "/devis/impressionContrat/" + impression + "/" + idDevis,
      method: "GET",
      responseType: "blob",
    });
  }

  static Extract(payload) {
    const params = new URLSearchParams(payload);
    return http({
      url: "/devis/extraction?" + params,
      method: "GET",
      responseType: "blob",
    });
  }
};

export { DevisService };
