import http from "http-common";

const PersonnelService = class {
  static cache = null;
  static cacheSS = null;
  static loading = true;
  static loadingSS = true;
  static cacheOid = null;
  static loadingOid = true;

  /**
   * Fonction permettant de récuperer tous les personnels avec une possibilité de filtre via params
   * @param {*} params, paramètres à utiliser pour la requête (page, nombre d'éléments par page et filtres sur les champs)
   * @returns une liste des personnels respectant les critères de params et le nombre de page pour ces critères
   */
  static getAll(params) {
    if (params) {
      return PersonnelService.get(params);
    } else {
      if (PersonnelService.loading) {
        PersonnelService.cache = PersonnelService.get();
        PersonnelService.loading = false;
      }
      return PersonnelService.cache;
    }
  }

  static get(params) {
    return http.get("/personnels/get", { params });
  }

  static getTechniciens(params) {
    return http.get("/personnels/gettechniciens", { params });
  }

  /**
   * Fonction permettant de créer un nouveau personnel pour un personnel donné
   * @param {*} personnel, le personnel à ajouter
   * @returns le personnel crée avec son id
   */
  static post(personnel) {
    personnel.date_Create = new Date().toISOString();
    personnel.date_Modif = new Date().toISOString();
    return http.post("/personnels", personnel);
  }

  /**
   * Fonction permettant de mettre à jour un personnel
   * @param {*} personnel, le personnel à mettre à jour
   * @returns
   */
  static put(personnel) {
    return http.put("/personnels/", personnel);
  }

  /**
   * Fonction permettant de récuperer un personnel via son id
   * @param {*} id, id de du personnel à récupérer
   * @returns le personnel souhaité
   */
  static getById(id) {
    return http.get("/personnels/get/" + id);
  }

  /**
   * Fonction permettant de récuperer un personnel via son oid
   * @param {*} oid, id de du personnel à récupérer
   * @returns le personnel souhaité
   */
  static getByOid(oid) {
    if (PersonnelService.loadingOid) {
      PersonnelService.cacheOid = http.get("/personnels/get/oid/" + oid);
      PersonnelService.loadingOid = false;
    }
    return PersonnelService.cacheOid;
  }

  /**
   * Fonction permettant de récuperer toutes les initiales des personnels
   * @returns les initiales des tous les personnels de la base
   */
  static getAllInitiales() {
    return http.get("/personnels/initiales");
  }

  /**
   * Fonction permettant de récuperer tous les clients avec une possibilité de filtre via params
   * @param {*} param, paramètres à utiliser pour la requête (page, nombre d'éléments par page et filtres sur les champs)
   * @returns une liste des clients respectant les critères de params et le nombre de page pour ces critères
   */
  static getForSelectSearch(params) {
    if (params) {
      return PersonnelService.getForSS(params);
    } else {
      if (PersonnelService.loadingSS) {
        PersonnelService.cacheSS = PersonnelService.getForSS();
        PersonnelService.loadingSS = false;
      }
      return PersonnelService.cacheSS;
    }
  }

  static getForSS(params) {
    return http.get("/personnels/get", {
      params: {
        orderBy: "nom",
        order: "ASC",
        nom: params,
        size: 25,
      },
    });
  }

  /**
   * Fonction permettant de supprimer un personnel
   * @param {*} personnel, le personnel à supprimer
   * @returns
   */
  static delete(personnel) {
    return http.delete("/personnels/" + personnel.id);
  }

  static searchHeuresExtractions(nameFilter) {
    return http.get("/personnels/heuresextraction/search/", { nameFilter });
  }

  static getHeuresExtraction(id) {
    return http({
      url: "/personnels/heuresextraction/get/" + id,
      method: "GET",
      responseType: "blob",
    });
  }

  static extractHeuresExtraction() {
    return http({
      url: "/personnels/heuresextraction/extract",
      method: "GET",
      responseType: "blob",
    });
  }

  static Extract(payload) {
    const params = new URLSearchParams(payload);
    return http({
      url: "/personnels/extraction?" + params,
      method: "GET",
      responseType: "blob",
    });
  }
};

export { PersonnelService };
