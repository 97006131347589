import React from "react";

import { ButtonIcon } from "_components";
import { faMinusSquare, faPlusSquare } from "@fortawesome/free-solid-svg-icons";

import { ToLocaleDateString } from "_utils";

import { PropTypes } from "prop-types";

class AccordionFacture extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isAccordionOpen: false,
    };
  }

  render() {
    return (
      <>
        <tr
          key={this.props.index * 1000}
          className={this.props.index % 2 ? "shadowLine" : ""}
        >
          <td className="tableIndent1">
            <ButtonIcon
              icon={this.state.isAccordionOpen ? faMinusSquare : faPlusSquare}
              className="btn"
              onClick={() =>
                this.setState((prevState) => ({
                  isAccordionOpen: !prevState.isAccordionOpen,
                }))
              }
              style={{ width: "32px" }}
            ></ButtonIcon>
            {this.props.facture.num_Facture}
          </td>
          <td className="tableIndent1 pt-2">
            {ToLocaleDateString(this.props.facture.dateCreation)}
          </td>
          <td className="tableIndent1"></td>
          <td className="tableIndent1"></td>
          <td className="tableIndent1"></td>
          <td className="tableIndent1"></td>
          <td className="tableIndent1 pt-2">
            {this.props.facture.prixTotalHT}
          </td>
          <td className="tableIndent1 pt-2">
            {this.props.facture.prixTotalTTC}
          </td>
          <td className="tableIndent1 pt-2">
            {this.props.facture.estReglee ? "Oui" : "Non"}
          </td>
          <td className="tableIndent1 pt-2">
            {this.props.facture.montantRegle}
          </td>
        </tr>

        {this.state.isAccordionOpen
          ? this.props.facture.details.map((detail, index) => {
              return (
                <tr
                  key={this.props.index * 1000 + index}
                  className={
                    "text-muted " + (this.props.index % 2 ? "shadowLine" : "")
                  }
                >
                  <td className="tableIndent1"></td>
                  <td className="tableIndent1"></td>
                  <td className="tableIndent1">{detail.refArticle}</td>
                  <td className="tableIndent1">{detail.description}</td>
                  <td className="tableIndent1">{detail.quantite}</td>
                  <td className="tableIndent1">{detail.prixVenteUnitaire}</td>
                  <td className="tableIndent1">{detail.prixVenteTotal}</td>
                  <td className="tableIndent1">{detail.prixVenteTotalTTC}</td>
                  <td className="tableIndent1"></td>
                  <td className="tableIndent1"></td>
                </tr>
              );
            })
          : null}
      </>
    );
  }
}

AccordionFacture.propTypes = {
  index: PropTypes.number,
  facture: PropTypes.any,
};

export { AccordionFacture };
