import React from "react";
import { PropTypes } from "prop-types";
import { isEqual } from "lodash";
import { produce } from "immer";

class SelectDays extends React.Component {
  constructor(props) {
    super(props);
    this.lettresjours = ["L", "M", "M", "J", "V"];
    this.state = {
      joursTravailles: this.props.value,
      accessor: this.props.accessor,
    };
    this.handleOnChange = this.handleOnChange.bind(this);
  }

  handleOnChange(index) {
    this.setState(
      produce((prevState) => {
        let valeurJourTravailleAtIndex = prevState.joursTravailles?.[index];
        if (valeurJourTravailleAtIndex === "1") {
          valeurJourTravailleAtIndex = "0";
        } else {
          valeurJourTravailleAtIndex = "1";
        }
        prevState.joursTravailles =
          prevState.joursTravailles.substring(0, index) +
          valeurJourTravailleAtIndex +
          prevState.joursTravailles.substring(index + 1);
      }),
      () => this.props.handleBlur?.(this.state.joursTravailles)
    );
  }

  shouldComponentUpdate(nextStates, nextProps) {
    return !isEqual(nextProps, this.state);
  }

  componentDidUpdate() {
    this.props.handleChange(this.props.accessor, this.state.joursTravailles);
  }

  render() {
    return (
      <div className="d-flex">
        {this.lettresjours.map((jour, index) => {
          return (
            <div className="ms-2 flex-grow-1" key={index}>
              <div className="text-center" style={{ width: "fit-content" }}>
                <div>{jour}</div>
                <input
                  className="form-check-input"
                  type="checkbox"
                  checked={this.state.joursTravailles[index * 2] === "1"}
                  value={this.state.joursTravailles[index * 2] === "1"}
                  name={this.state.accessor}
                  onChange={() => {
                    this.handleOnChange(index * 2);
                  }}
                ></input>
                <input
                  className="form-check-input"
                  type="checkbox"
                  checked={this.state.joursTravailles[index * 2 + 1] === "1"}
                  value={this.state.joursTravailles[index * 2 + 1] === "1"}
                  name={this.state.accessor}
                  onChange={() => {
                    this.handleOnChange(index * 2 + 1);
                  }}
                ></input>
              </div>
            </div>
          );
        })}
      </div>
    );
  }
}

SelectDays.propTypes = {
  value: PropTypes.any,
  accessor: PropTypes.string,
  handleChange: PropTypes.func,
  handleBlur: PropTypes.func,
};

export { SelectDays };
