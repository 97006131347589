// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#tableTacheClient td, #tableTacheClient th{
    padding-left: 0.25rem !important;
    padding-top: 0.25rem;
}

#tableTacheClient .shadowLine{
  box-shadow: inset 0 0 0 9999px rgba(0,0,0,0.05) !important
}

#tableTacheClient th {
  text-transform: uppercase;
  white-space: nowrap;
}`, "",{"version":3,"sources":["webpack://./src/FicheClient/Taches/Taches.css"],"names":[],"mappings":"AAAA;IACI,gCAAgC;IAChC,oBAAoB;AACxB;;AAEA;EACE;AACF;;AAEA;EACE,yBAAyB;EACzB,mBAAmB;AACrB","sourcesContent":["#tableTacheClient td, #tableTacheClient th{\n    padding-left: 0.25rem !important;\n    padding-top: 0.25rem;\n}\n\n#tableTacheClient .shadowLine{\n  box-shadow: inset 0 0 0 9999px rgba(0,0,0,0.05) !important\n}\n\n#tableTacheClient th {\n  text-transform: uppercase;\n  white-space: nowrap;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
