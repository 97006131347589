import { Box, ButtonIcon } from "_components";
import { Input } from "_components/Input";
import { CommandeService } from "_services";
import { useState } from "react";
import { Helmet } from "react-helmet";
import { useHistory, useLocation } from "react-router-dom";

import "./AjoutLicence.css";

const AjoutLicences = () => {
  //Props deconstruction

  //Hooks and variables

  let history = useHistory();
  let location = useLocation();
  let [isSubmiting, setIsSubmiting] = useState(false);

  ///modelesLicences liste d'objets sous la forme {
  ///  quantite: int
  ///  modele: {
  ///    aR_Ref: int
  ///    classification: int
  ///    description: string
  ///  }
  ///}
  const orderedModelesLicences = location.state?.modelesLicences
    ?.sort((a, b) => a.modele.classification - b.modele.classification)
    .map((modele) => ({ ...modele, quantite: Math.ceil(modele.quantite) }));
  let [licences, setLicences] = useState([
    Array(orderedModelesLicences.length).fill(false),
  ]);

  //Local functions

  function isCheckboxDisabled(i, j) {
    return (
      licences[i][j] == false &&
      //Contrainte sur le nombre de modules déjà affectés
      (licences.reduce(
        (acc, licence) => acc + (licence[j] == true ? 1 : 0),
        0
      ) >= orderedModelesLicences[j].quantite ||
        //Contrainte sur les classifications de licences
        licences[i].some(
          (hasModule, index) =>
            hasModule == true &&
            orderedModelesLicences[index].modele.classification !=
              orderedModelesLicences[j].modele.classification
        ))
    );
  }

  function isRepartitionValid() {
    return !(
      licences.some(
        (licence) => licence.reduce((acc, val) => acc || val, false) == false
      ) ||
      orderedModelesLicences.some(
        (modele, index) =>
          licences.reduce(
            (acc, licence) => acc + (licence[index] == true ? 1 : 0),
            0
          ) != modele.quantite
      )
    );
  }

  //Local components

  const BoxClassesModules = () => (
    <Box
      header={
        <>
          <div className="col-12 align-self-center py-2">
            <div>Modules à répartir</div>
          </div>
        </>
      }
      body={
        <>
          <div className="py-2 text-start">
            {orderedModelesLicences?.map((modeleLicence) => (
              <div key={modeleLicence.modele.aR_Ref} className="row">
                <div className="col-1">{"x" + modeleLicence.quantite}</div>
                <div className="col-2">
                  {modeleLicence.modele.aR_Ref + " : "}
                </div>
                <div className="col">{modeleLicence.modele.description}</div>
              </div>
            ))}
          </div>
        </>
      }
    />
  );

  const RowModulesPerLicence = (props) => (
    <tr>
      {props.licence.map((hasModule, j) => (
        <td key={j} className="text-center">
          <Input
            type={"checkbox"}
            value={hasModule}
            handleChange={(accessor, value) => {
              let array = [...licences];
              array[props.index][j] = value;
              setLicences(array);
            }}
            disabled={isCheckboxDisabled(props.index, j)}
          />
        </td>
      ))}
      <td className="text-center">
        <ButtonIcon
          className="btn btn-danger w-5"
          textOption="Supprimer la licence"
          tooltip="Supprimer la licence"
          disabled={props.licence.some((hasModule) => hasModule == true)}
          onClick={() => {
            let array = [...licences];
            array.splice(props.index, 1);
            setLicences(array);
          }}
        />
      </td>
    </tr>
  );

  const TableModulesPerLicence = () => (
    <div className="p-3 bg-body shadow rounded pageClient">
      <div className="py-2 text-center text-warning">
        Chaque ligne représente une licence.
        <br />
        Tous les articles doivent être attribués.
        <br />
        Toutes les lignes doivent contenir au moins un article.
      </div>
      <table className="mb-3 TableModules">
        <thead>
          <tr>
            {orderedModelesLicences?.map((modeleLicence, index) => (
              <th key={modeleLicence.modele.aR_Ref} className="text-center">
                {modeleLicence.modele.aR_Ref +
                  " " +
                  licences.reduce(
                    (acc, licence) => acc + (licence[index] == true ? 1 : 0),
                    0
                  ) +
                  "/" +
                  modeleLicence.quantite}
              </th>
            ))}
            <th></th>
          </tr>
        </thead>
        <tbody>
          {licences.map((licence, index) => (
            <RowModulesPerLicence key={index} index={index} licence={licence} />
          ))}
        </tbody>
      </table>
      <div className="text-center">
        <ButtonIcon
          className="btn btn-success w-5"
          textOption="Ajouter une licence"
          tooltip="Ajouter une ligne licence"
          onClick={() => {
            setLicences([
              ...licences,
              Array(orderedModelesLicences?.length).fill(false),
            ]);
          }}
        />
        <ButtonIcon
          className="btn btn-success w-5"
          textOption=" Valider"
          tooltip="Valider la répartition"
          disabled={isSubmiting || !isRepartitionValid()}
          onClick={() => {
            setIsSubmiting(true);

            let listLicences = licences.reduce((acc, licence) => {
              const listModele = licence.reduce((acc2, hasModele, index) => {
                if (hasModele)
                  acc2.push(orderedModelesLicences[index].modele.aR_Ref);

                return acc2;
              }, []);

              acc.push([...listModele]);

              return acc;
            }, []);

            if (location.state.contratId != null) {
              CommandeService.postFromDevisWithContrat(
                location.state.devisId,
                location.state.nouvelleAffaireId,
                location.state.contratId,
                location.state.matLicences,
                listLicences
              )
                .then((res) => {
                  history.push("/commandes/" + res.data.id);
                })
                .finally(() => {
                  setIsSubmiting(false);
                });
            } else {
              CommandeService.postFromDevis(
                location.state.devisId,
                location.state.nouvelleAffaireId,
                location.state.matLicences,
                listLicences
              )
                .then((res) => history.push("/commandes/" + res.data.id))
                .finally(() => {
                  setIsSubmiting(false);
                });
            }
          }}
        />
      </div>
    </div>
  );

  //ComponentView

  return (
    <>
      <Helmet>
        <meta charset="utf-8" />
        <title>Création de licences</title>
      </Helmet>
      <div className=" p-3 m-4" id="ajoutLicences">
        {/* Header de la page */}
        <div className="py-1 overflow-auto d-flex justify-content-between">
          <h2 className="nowrap pr-100 solwayFont">
            Création de licences pour
            {location.state?.client?.nom_Client
              ? " " + location.state?.client.nom_Client
              : " ERROR_CLIENT_NAME"}
          </h2>
          <div>
            {history.length > 1 ? (
              <button
                className="btn btn-primary nowrap mx-1"
                onClick={() => {
                  history.goBack();
                }}
              >
                Retour
              </button>
            ) : null}
          </div>
        </div>

        {/* Contenu de la page */}
        <BoxClassesModules />
        <TableModulesPerLicence />
      </div>
    </>
  );
};

export { AjoutLicences };
