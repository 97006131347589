import React from "react";

// Fontawesome
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

// Validation des props
import { PropTypes } from "prop-types";

import { AlertDialog } from "_components";

/**
 * Génère un composant bouton avec une icône et un texte optionnel à l'interieur et une validation
 */
class ButtonIconWithValidation extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      error: false,
      errorText: "",
      opened: false,
    };

    this.handleClick = this.handleClick.bind(this);
    this.handleActionValidation = this.handleActionValidation.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleCloseValidation = this.handleCloseValidation.bind(this);
  }

  handleClick() {
    this.setState({ opened: true });
  }

  handleActionValidation() {
    let returnRes = this.props.onClick();
    if (returnRes) {
      returnRes.catch((error) => {
        this.setState({ error: true, errorText: error.message });
      });
    }
    this.setState({ opened: false });
  }

  handleClose() {
    this.setState({ error: false });
  }

  handleCloseValidation() {
    this.props.onClose?.();
    this.setState({ opened: false });
  }

  render() {
    return (
      <>
        <AlertDialog
          title={this.props.alertTitle}
          body={this.props.alertBody}
          valider={this.props.alertButtonValidationText}
          open={this.state.opened}
          onClick={this.handleActionValidation}
          handleClose={this.handleCloseValidation}
        />
        <AlertDialog
          title="Une erreur a été rencontrée"
          body={
            <>
              <div>
                Une erreur est survenue lors de la mise à jour des données :
              </div>
              <div>{this.state.errorText}</div>
            </>
          }
          valider="Ok"
          open={this.state.error}
          handleClose={this.handleClose}
        />
        <button
          onClick={this.handleClick}
          className={this.props.className}
          style={this.props.style}
          type={this.props.type}
          data-bs-toggle="tooltip"
          data-bs-placement="top"
          title={this.props.tooltip}
          id={this.props.id}
        >
          <FontAwesomeIcon size={this.props.iconSize} icon={this.props.icon} />
          {this.props.textOption ? " " + this.props.textOption : null}
        </button>
      </>
    );
  }
}

/**
 * Validation des props :
 * icon : icone de fontawesome
 * iconSize : taille gérées par fontawesome [xs, sm, lg, 2x, 3x, ...]
 * onClick : fonction appliquée lors du click sur le bouton
 * className : classes à appliquer au bouton
 * textOption : texte à ajouter à côté de l'icône
 * tooltip : contenu du tooltip
 * id : id du bouton
 */
ButtonIconWithValidation.propTypes = {
  icon: PropTypes.any,
  iconSize: PropTypes.string,
  onClick: PropTypes.func,
  className: PropTypes.string,
  textOption: PropTypes.any,
  alertTitle: PropTypes.any,
  alertBody: PropTypes.any,
  alertButtonValidationText: PropTypes.string,
  type: PropTypes.string,
  style: PropTypes.object,
  tooltip: PropTypes.any,
  id: PropTypes.string,
};

ButtonIconWithValidation.defaultProps = {
  type: "button",
};

export { ButtonIconWithValidation };
