import http from "http-common";

const SageDocumentService = class {
  /**
   * Fonction permettant de récuperer les comptes tiers avec un filtre optionnel.
   * @param {*} filtre, filtre sur le comptes tiers dont le nom commence par filtre.
   * @returns une liste des comptes tiers respectant les critères de params et le nombre de page pour ces critères.
   */
  static getCompteTiers(filtre) {
    if (filtre.trim().length > 0) {
      return http.get("/sageDocuments/comptesTiers/" + filtre);
    } else {
      return new Promise(() => {});
    }
  }

  /**
   * Fonction permettant de créer ou relier un client a un compte tier Sage.
   * @param {*} client, le client à relier, contenant les informations financières utilisées lors de la création du compte tier.
   * @returns Une promise retournant void
   */
  static setCompteTiers(client) {
    return http.put("/sageDocuments/comptesTiers/", client);
  }

  /**
   * Fonction permettant de récuperer un article via son id
   * @param {*} id, id de du article à récupérer
   * @returns le article souhaité
   */
  static getFacturesImpayeesByCompteTiers(ct) {
    return http.get("sageDocuments/facturesImpayees/" + ct);
  }

  static getBonsAchats(compteAffaire) {
    return http.get("sageDocuments/bonsAchats/" + compteAffaire);
  }
};

export { SageDocumentService };
