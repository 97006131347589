import React from "react";
import { PropTypes } from "prop-types";
import { Link } from "react-router-dom";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import "./FicheArticle.css";

import { General, Stock } from ".";
import { WarningBar } from "_components";

import { ArticleService } from "_services";
import { Helmet } from "react-helmet";
import TabSelector from "_components/FicheComponents/TabSelector";

class FicheArticle extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      tabs: ["Général", "Stock"],
      active: "Général",
      article: {},
    };

    this.renderActive = this.renderActive.bind(this);
    this.generateTabs = this.generateTabs.bind(this);
  }

  componentDidMount() {
    if (sessionStorage.getItem("ArticleOnglet") == null) {
      sessionStorage.setItem("ArticleOnglet", this.state.active);
    } else {
      this.setState({ active: sessionStorage.getItem("ArticleOnglet") });
    }

    let { id } = this.props.match.params;
    ArticleService.getById(id).then((res) => {
      this.setState({ article: res.data, loading: false });
    });
  }

  /**
   * Génère les onglets de la fiche
   * @param {*} tabs
   * @returns
   */
  generateTabs(tabs) {
    return (
      <div>
        <TabSelector
          tabs={tabs}
          activeTab={this.state.active}
          onSelect={(tab) => {
            this.setState({ active: tab });
            sessionStorage.setItem("ArticleOnglet", tab);
          }}
          notAllowed={this.state.editing}
        ></TabSelector>
      </div>
    );
  }

  renderActive() {
    switch (this.state.active) {
      case "Général":
        return (
          <General
            article={this.state.article}
            stateFieldNameToUpdate="article"
            service={ArticleService}
            history={this.props.history}
          ></General>
        );
      case "Stock":
        return (
          <Stock
            article={this.state.article}
            stateFieldNameToUpdate="article"
            service={ArticleService}
            history={this.props.history}
          ></Stock>
        );
      default:
        return (
          <h4 className="mt-5">Désolé la page n&apos;a pas réussi à charger</h4>
        );
    }
  }

  render() {
    const isArticleEmpty = !Object.entries(this.state.article).length;
    return (
      <>
        <Helmet>
          <title>
            {"Article : "
              .concat(
                this.state.article.aR_Ref ? this.state.article.aR_Ref : ""
              )
              .concat(
                this.state.article.arT_LIB
                  ? " | ".concat(this.state.article.arT_LIB)
                  : ""
              )}
          </title>
        </Helmet>
        {this.state.loading ? (
          <div
            style={{
              flexGrow: 1,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <FontAwesomeIcon icon={faSpinner} size="lg" className="fa-spin" />
          </div>
        ) : (
          <div className="p-3 m-4" id="ficheClient">
            <div className="d-flex overflow-auto justify-content-between py-1">
              <h4 className="nowrap pr-100">
                {"Article : "
                  .concat(
                    this.state.article.aR_Ref ? this.state.article.aR_Ref : ""
                  )
                  .concat(
                    this.state.article.arT_LIB
                      ? " | ".concat(this.state.article.arT_LIB)
                      : ""
                  )}
              </h4>
              <div className="d-flex">
                <Link to="/articles" className="btn btn-primary nowrap ms-1">
                  Base articles
                </Link>
                {this.props.history.length > 1 && (
                  <button
                    className="btn btn-primary nowrap ms-1"
                    onClick={() => {
                      this.props.history.goBack();
                    }}
                  >
                    Retour
                  </button>
                )}
              </div>
            </div>
            {this.generateTabs(this.state.tabs)}
            <WarningBar
              active={this.state.article.actif}
              content={"Cet article n'est pas actif"}
            >
              {" "}
            </WarningBar>
            {!isArticleEmpty && this.renderActive()}
          </div>
        )}
      </>
    );
  }
}

/**
 * Validation des props :
 */
FicheArticle.propTypes = {
  history: PropTypes.object,
  match: PropTypes.object,
};

export { FicheArticle };
