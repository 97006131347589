import http from "http-common";

const NationaliteService = class {
  /**
   * Fonction permettant de récuperer toutes les nationalités avec une possibilité de filtre via params
   * @returns la liste de toutes les nationalités
   */
  static getAll() {
    return http.get("/nationalites");
  }


};

export { NationaliteService };
