import L from "leaflet";

import markerShadow from "_assets/MarkersIcons/marker-shadow.png";
import blue from "_assets/MarkersIcons/marker-icon-2x-blue.png";
import gold from "_assets/MarkersIcons/marker-icon-2x-gold.png";
import red from "_assets/MarkersIcons/marker-icon-2x-red.png";
import green from "_assets/MarkersIcons/marker-icon-2x-green.png";
import orange from "_assets/MarkersIcons/marker-icon-2x-orange.png";
import yellow from "_assets/MarkersIcons/marker-icon-2x-yellow.png";
import violet from "_assets/MarkersIcons/marker-icon-2x-violet.png";
import grey from "_assets/MarkersIcons/marker-icon-2x-grey.png";
import black from "_assets/MarkersIcons/marker-icon-2x-black.png";

let blueIcon = new L.Icon({
  iconUrl: blue,
  shadowUrl: markerShadow,
  iconSize: [25, 41],
  iconAnchor: [12, 41],
  popupAnchor: [1, -34],
  shadowSize: [41, 41],
});

let goldIcon = new L.Icon({
  iconUrl: gold,
  shadowUrl: markerShadow,
  iconSize: [25, 41],
  iconAnchor: [12, 41],
  popupAnchor: [1, -34],
  shadowSize: [41, 41],
});

let redIcon = new L.Icon({
  iconUrl: red,
  shadowUrl: markerShadow,
  iconSize: [25, 41],
  iconAnchor: [12, 41],
  popupAnchor: [1, -34],
  shadowSize: [41, 41],
});

let greenIcon = new L.Icon({
  iconUrl: green,
  shadowUrl: markerShadow,
  iconSize: [25, 41],
  iconAnchor: [12, 41],
  popupAnchor: [1, -34],
  shadowSize: [41, 41],
});

let orangeIcon = new L.Icon({
  iconUrl: orange,
  shadowUrl: markerShadow,
  iconSize: [25, 41],
  iconAnchor: [12, 41],
  popupAnchor: [1, -34],
  shadowSize: [41, 41],
});

let yellowIcon = new L.Icon({
  iconUrl: yellow,
  shadowUrl: markerShadow,
  iconSize: [25, 41],
  iconAnchor: [12, 41],
  popupAnchor: [1, -34],
  shadowSize: [41, 41],
});

let violetIcon = new L.Icon({
  iconUrl: violet,
  shadowUrl: markerShadow,
  iconSize: [25, 41],
  iconAnchor: [12, 41],
  popupAnchor: [1, -34],
  shadowSize: [41, 41],
});

let greyIcon = new L.Icon({
  iconUrl: grey,
  shadowUrl: markerShadow,
  iconSize: [25, 41],
  iconAnchor: [12, 41],
  popupAnchor: [1, -34],
  shadowSize: [41, 41],
});

let blackIcon = new L.Icon({
  iconUrl: black,
  shadowUrl: markerShadow,
  iconSize: [25, 41],
  iconAnchor: [12, 41],
  popupAnchor: [1, -34],
  shadowSize: [41, 41],
});

export {
  blueIcon,
  goldIcon,
  redIcon,
  greenIcon,
  orangeIcon,
  yellowIcon,
  violetIcon,
  greyIcon,
  blackIcon,
};
