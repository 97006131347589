import React from "react";
import { AlertDialog, Box, ButtonIcon, FormWithValidation } from "_components";
import { Input } from "_components/Input";
import { PropTypes } from "prop-types";

import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { CarteBancaireService, RubriqueCarteBancaireService } from "_services";
import { Helmet } from "react-helmet";
import produce from "immer";

class AjoutCarteBancaire extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      loaded: false,
      errorText: null,
      error: false,
      isSubmitting: false,
      cartebancaire: {
        id: 0,
        compteTiers: null,
        jO_Num: null,
        nameDetail: null,
        name: null,
        actif: true,
      },
    };

    this.handleStateChange = this.handleStateChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.form = this.form.bind(this);
  }

  componentDidMount() {
    this.setState(() => ({
      loaded: true,
    }));
  }

  handleStateChange(accessor, value, afterUpdateFunction = null) {
    if (accessor.includes(".")) {
      var accessorSplit = accessor.split(".");

      // Nested property (separated by a '.')
      this.setState(
        produce((prevState) => {
          prevState.cartebancaire[accessorSplit[0]][accessorSplit[1]] = value;
        }),
        afterUpdateFunction
      );
    } else {
      // Shallow property
      this.setState(
        produce((prevState) => {
          prevState.cartebancaire[accessor] = value;
        }),
        afterUpdateFunction
      );
    }
  }

  handleSubmit(event) {
    event.preventDefault();
    this.setState({ isSubmitting: true });

    CarteBancaireService.post(this.state.cartebancaire)
      .then((retour) => {
        this.props.history.push("/cartesbancaires/" + retour.data.id);
      })
      .catch((error) => {
        this.setState({
          error: true,
          isSubmitting: false,
          errorText: error.message,
        });
      });
  }

  form() {
    const informationsGeneral = [
      {
        value: this.state.cartebancaire.name,
        label: "Nom de la carte",
        accessor: "name",
        type: "text",
        required: true,
        colSize: 6,
      },
      {
        value: this.state.cartebancaire.nameDetail,
        label: "Nom détaillé",
        accessor: "nameDetail",
        type: "text",
        required: true,
        colSize: 6,
      },
      {
        value: null,

        label: "Compte tiers",
        accessor: "compteTiers",
        type: "text",
        required: true,
        showClearButton: false,
      },
      {
        value: this.state.cartebancaire.jO_Num,
        label: "jO Num",
        accessor: "jO_Num",
        type: "selectSearch",
        required: true,
        optionFieldToDisplay: "rubrique",
        valueFieldToDisplay: "rubrique",
        service: RubriqueCarteBancaireService.getAll,
        colSize: 6,
      },
    ];

    const infos = [
      { info: informationsGeneral, titre: "Informations générales" },
    ];
    return this.state.loaded ? (
      <>
        <FormWithValidation onSubmit={this.handleSubmit}>
          {infos.map((infoItem, index) => {
            return (
              <div className="mb-4" key={index}>
                <Box
                  header={
                    <>
                      <div className="col-12 align-self-center py-2">
                        <div>{infoItem.titre}</div>
                      </div>
                    </>
                  }
                  body={
                    <>
                      {infoItem.info.map((field, fieldsIndex) => {
                        return field ? (
                          <div className="col-lg-4 pt-2" key={fieldsIndex}>
                            <Input
                              label={field.label} // Titre du champ
                              value={field.value} // Valeur du champ
                              accessor={field.accessor} // Accesseur
                              placeholder={field.placeholder}
                              debounce={field.debounce}
                              service={field.service}
                              handleChange={(acc, val) =>
                                this.handleStateChange(
                                  acc,
                                  val,
                                  field.afterUpdateFunction
                                )
                              } // Fonction pour mettre à jour la valeur dans l'object via son accesseur
                              handleBlur={field.handleBlur} // Fonction pour mettre à jour la valeur dans l'object via son accesseur
                              type={field.type} // Type de l'input a utiliser
                              options={field.options} // options de l'input
                              optionFieldToDisplay={field.optionFieldToDisplay} // champ de l'objet a afficher
                              optionFieldToReturn={field.optionFieldToReturn} // champd de l'objet a utiliser en tant que valeur
                              valueFieldToDisplay={field.valueFieldToDisplay}
                              customFirstOption={field.customFirstOption}
                              required={field.required}
                              disabled={field.disabled}
                              validText={field.validText}
                              invalidText={field.invalidText}
                              specialRenderValue={field.specialRenderValue}
                              setForceUpdateFunction={
                                field.setForceUpdateFunction
                              }
                            />
                          </div>
                        ) : null;
                      })}
                    </>
                  }
                />
              </div>
            );
          })}

          <div className="text-center">
            <ButtonIcon
              className="btn btn-success mt-4 w-25"
              type="submit"
              error={this.state.isSubmitting}
              textOption="Créer la carte"
            ></ButtonIcon>
          </div>
        </FormWithValidation>
      </>
    ) : (
      <FontAwesomeIcon
        icon={faSpinner}
        size="lg"
        className="fa-spin flex-auto"
      />
    );
  }

  render() {
    return (
      <>
        <Helmet>
          <title>Création d'une CB</title>
        </Helmet>
        <AlertDialog
          title="Une erreur a été rencontrée"
          body={
            <>
              <div>La création de la carte a échouée.</div>
              <div>{this.state.errorText}</div>
            </>
          }
          valider="Ok"
          open={this.state.error}
          handleClose={() => this.setState({ error: false })}
        />
        <div className="p-3 m-4" id="ajoutDevis">
          <h3 className="nowrap pr-100 solwayFont">
            Création d&apos;une carte bancaire
          </h3>
          <div className="d-flex overflow-auto justify-content-between py-1">
            <div>
              {this.props.history.length > 1 && (
                <button
                  className="btn btn-primary nowrap mx-1"
                  onClick={() => {
                    this.props.history.goBack();
                  }}
                >
                  Retour
                </button>
              )}
            </div>
          </div>
          <div className="row mt-4">{this.form()}</div>
        </div>
      </>
    );
  }
}

AjoutCarteBancaire.propTypes = {
  history: PropTypes.object,
};

export { AjoutCarteBancaire };
