import React from "react";
import { AlertDialog, Box, FormWithValidation } from "_components";

import { Input } from "_components/Input";

import { PropTypes } from "prop-types";

import { ButtonIcon } from "_components";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { isEqual } from "lodash";
import {
  ArticleASuivreDansBFAService,
  ClientService,
  ContactService,
  ProduitInterneService,
  FamilleArticleService,
  PacksService,
  MaterielService,
} from "_services";

import { Helmet } from "react-helmet";

import { faExternalLinkAlt } from "@fortawesome/free-solid-svg-icons";

import produce from "immer";

class AjoutMateriel extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      callbackForceUpdateContactUtilisateur: null,
      loaded: false,
      errorText: null,
      error: false,
      isSubmitting: false,
      materiel: {},
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.setForceUpdateContactUtilisateur =
      this.setForceUpdateContactUtilisateur.bind(this);
  }

  componentDidMount() {
    this.setState(() => ({
      loaded: true,
    }));
  }

  handleChange(accessor, value, afterUpdateFunction = null) {
    if (accessor.includes(".")) {
      var accessorSplit = accessor.split(".");

      // Nested property (separated by a '.')
      this.setState(
        produce((prevState) => {
          prevState.materiel[accessorSplit[0]][accessorSplit[1]] = value;
        }),
        afterUpdateFunction
      );
    } else {
      // Shallow property
      this.setState(
        produce((prevState) => {
          prevState.materiel[accessor] = value;
        }),
        afterUpdateFunction
      );
    }
  }

  handleSubmit(event) {
    event.preventDefault();
    this.setState({ isSubmitting: true });

    this.setState(() =>
      MaterielService.post(this.state.materiel)
        .then((returned) => {
          this.props.history.push("/materiels/" + returned.data.id);
        })
        .catch((error) => {
          console.log(error);
          this.setState({
            error: true,
            isSubmitting: false,
            errorText: error.message,
          });
        })
    );
  }

  shouldComponentUpdate(prevProps, prevStates) {
    return !(isEqual(prevProps, this.props) && isEqual(prevStates, this.state));
  }

  setForceUpdateContactUtilisateur(callbackFunction) {
    this.setState({
      callbackForceUpdateContactUtilisateur: callbackFunction,
    });
  }

  form() {
    const bandeauEnHaut = [
      {
        label: "Numéro de Série",
        accessor: "num_Serie",
        type: "text",
        required: true,
        colSize: 3,
      },
      {
        label: "Référence article",
        accessor: "articleASuivre",
        type: "selectSearch",
        optionFieldToDisplay: "reference",
        valueFieldToDisplay: "reference",
        functionAppliedToValue: (value) => {
          return value?.reference ?? "-";
        },
        service: ArticleASuivreDansBFAService.getAll,
        colSize: 3,
        required: true,
        afterUpdateFunction: () => {
          this.setState(
            produce((prevState) => {
              prevState.materiel.produitInterne =
                prevState.materiel.articleASuivre?.produitInterne;
              prevState.materiel.type_Materiel =
                prevState.materiel.articleASuivre?.familleArticle;
            })
          );
        },
      },
      {
        label: "Produit interne",
        value: this.state.materiel?.produitInterne,
        accessor: "produitInterne",
        colSize: 3,
        functionAppliedToValue: (value) => {
          return value?.designation ?? "-";
        },
        type: "selectSearch",
        optionFieldToDisplay: "designation",
        valueFieldToDisplay: "designation",
        service: ProduitInterneService.getAll,
        required: true,
      },
      {
        label: "Descriptif",
        value: this.state.materiel?.type_Materiel,
        accessor: "type_Materiel",
        type: "selectSearch",
        optionFieldToDisplay: "designation",
        valueFieldToDisplay: "designation",
        functionAppliedToValue: (value) => value?.designation ?? "-",
        service: FamilleArticleService.getAll,
        colSize: 3,
        required: true,
      },
    ];
    const informationsMateriel = [
      {
        label: "Au Rebus",
        accessor: "au_Rebus",
        type: "checkbox",
        functionAppliedToValue: (value) => {
          return value ? "Oui" : "Non";
        },
        colSize: 3,
      },
      {
        label: "Date rebus",
        accessor: "date_Rebus",
        type: "date",
        colSize: 3,
      },
      {
        label: "Date Fabrication",
        accessor: "date_Fabrication",
        type: "date",
        colSize: 3,
      },
      {
        label: "Pack",
        accessor: "pack",
        type: "selectSearch",
        optionFieldToDisplay: "designation",
        valueFieldToDisplay: "designation",
        service: PacksService.getAll,
        colSize: 3,
        functionAppliedToValue: (value) => {
          return value?.designation ?? "-";
        },
      },
      {
        label: "Date Fin Garantie",
        accessor: "date_Fin_Garantie",
        type: "date",
        colSize: 3,
      },
      {
        label: "Num Fabrication",
        accessor: "numero_Fabrication",
        type: "text",
        colSize: 3,
      },
    ];

    if (this.state.materiel.produitInterne?.designation === "GRZ") {
      informationsMateriel.push({
        value: this.state.materiel.energieVariable,
        label: "Energie variable",
        accessor: "energieVariable",
        type: "checkbox",
        colSize: 3,
      });
    }

    const blocCommentaire = [
      {
        label: "Commentaire",
        accessor: "commentaire",
        type: "textArea",
        colSize: 12,
      },
    ];

    const informationsLivraison = [
      {
        label: "Date livraison",
        accessor: "date_livraison",
        type: "date",
        colSize: 3,
      },
      {
        label: "Client propriétaire",
        accessor: "proprietaire",
        colSize: 3,
        functionAppliedToValue: (value) => {
          return (
            <div className="d-flex justify-content-start align-items-center">
              {value ? value.nom_Client : "-"}
              {value ? (
                <ButtonIcon
                  icon={faExternalLinkAlt}
                  className="btn btn-secondary btn-sm text-light ms-1"
                  onClick={() => window.open("/clients/" + value.id, "_blank")}
                  style={{ width: "32px" }}
                ></ButtonIcon>
              ) : null}
            </div>
          );
        },
        type: "selectSearch",
        service: () => ClientService.getAllClientsWithCompteTier(),
        optionFieldToDisplay: "nom_Client",
        valueFieldToDisplay: "nom_Client",
        required: true,
      },
      {
        label: "Client utilisateur",
        accessor: "utilisateur",
        colSize: 3,
        functionAppliedToValue: (value) => {
          return (
            <div className="d-flex justify-content-start align-items-center">
              {value ? value.nom_Client : "-"}
              {value ? (
                <ButtonIcon
                  icon={faExternalLinkAlt}
                  className="btn btn-secondary btn-sm text-light ms-1"
                  onClick={() => window.open("/clients/" + value.id, "_blank")}
                  style={{ width: "32px" }}
                ></ButtonIcon>
              ) : null}
            </div>
          );
        },
        type: "selectSearch",
        service: ClientService.getForSelectSearch,
        optionFieldToDisplay: "nom_Client",
        valueFieldToDisplay: "nom_Client",
        afterUpdateFunction: () => {
          this.setState(
            produce((prevState) => {
              prevState.materiel.contactUtilisateur = null;
            }),
            this.state.callbackForceUpdateContactUtilisateur
          );
        },
      },
      {
        label: "Contact Utilisateur",
        accessor: "contactUtilisateur",
        type: "selectSearch",
        service: () =>
          ContactService.getByIdClient(this.state.materiel.utilisateur?.id),
        optionFieldToDisplay: ["nom_Contact", "prenom_Contact"],
        valueFieldToDisplay: ["nom_Contact", "prenom_Contact"],
        colSize: 3,
        functionAppliedToValue: (value) => {
          return (
            <div className="d-flex justify-content-start align-items-center">
              {value
                ? IfNullToText(value.nom_Contact, null) +
                  " " +
                  IfNullToText(value.prenom_Contact, null)
                : "-"}
              {value ? (
                <ButtonIcon
                  icon={faExternalLinkAlt}
                  className="btn btn-secondary btn-sm text-light ms-1"
                  onClick={() => window.open("/contacts/" + value.id, "_blank")}
                  style={{ width: "32px" }}
                ></ButtonIcon>
              ) : null}
            </div>
          );
        },
        setForceUpdateFunction: this.setForceUpdateContactUtilisateur,
      },
      {
        label: "Num Livraison",
        accessor: "numero_Livraison",
        type: "text",
        colSize: 3,
      },
    ];

    const infos = [
      { info: bandeauEnHaut, titre: "Général" },
      { info: informationsMateriel, titre: "Informations matériel" },
      { info: informationsLivraison, titre: "Livraison" },
      { info: blocCommentaire, titre: "Commentaire" },
    ];

    return this.state.loaded ? (
      <>
        <FormWithValidation onSubmit={this.handleSubmit}>
          {infos.map((infoItem, index) => {
            return (
              <div className="mb-4" key={index}>
                <Box
                  header={
                    <>
                      <div className="col-12 align-self-center py-2">
                        <div>{infoItem.titre}</div>
                      </div>
                    </>
                  }
                  body={
                    <>
                      {infoItem.info.map((field, fieldsIndex) => {
                        return field ? (
                          <div
                            className={"col-lg-" + field.colSize + " pt-2"}
                            key={fieldsIndex}
                          >
                            <Input
                              label={field.label} // Titre du champ
                              value={field.value} // Valeur du champ
                              accessor={field.accessor} // Accesseur
                              placeholder={field.placeholder}
                              debounce={field.debounce}
                              service={field.service}
                              handleChange={(acc, val) =>
                                this.handleChange(
                                  acc,
                                  val,
                                  field.afterUpdateFunction
                                )
                              } // Fonction pour mettre à jour la valeur dans l'object via son accesseur
                              handleBlur={field.handleBlur} // Fonction pour mettre à jour la valeur dans l'object via son accesseur
                              type={field.type} // Type de l'input a utiliser
                              options={field.options} // options de l'input
                              optionFieldToDisplay={field.optionFieldToDisplay} // champ de l'objet a afficher
                              optionFieldToReturn={field.optionFieldToReturn} // champd de l'objet a utiliser en tant que valeur
                              valueFieldToDisplay={field.valueFieldToDisplay}
                              required={field.required}
                              disabled={field.disabled}
                              validText={field.validText}
                              invalidText={field.invalidText}
                              specialRenderValue={field.specialRenderValue}
                              setForceUpdateFunction={
                                field.setForceUpdateFunction
                              }
                            />
                          </div>
                        ) : null;
                      })}
                    </>
                  }
                />
              </div>
            );
          })}

          <div className="text-center">
            <ButtonIcon
              className="btn btn-success mt-4 w-25"
              type="submit"
              disabled={this.state.isSubmitting}
              textOption="Créer le matériel"
            ></ButtonIcon>
          </div>
        </FormWithValidation>
      </>
    ) : (
      <FontAwesomeIcon
        icon={faSpinner}
        size="lg"
        className="fa-spin flex-auto"
      />
    );
  }

  render() {
    return (
      <>
        <Helmet>
          <meta charset="utf-8" />
          <title>Création d'un matériel</title>
        </Helmet>
        <AlertDialog
          title="Une erreur a été rencontrée"
          body={
            <>
              <div>La création du matériel a échouée.</div>
              <div>{this.state.errorText}</div>
            </>
          }
          valider="Ok"
          open={this.state.error}
          handleClose={this.handleClose}
        />
        <div className="p-3 m-4">
          <div className="d-flex overflow-auto justify-content-between py-1">
            <h2 className="nowrap pr-100 solwayFont">
              Création d&apos;un matériel
            </h2>
            <div>
              {this.props.history.length > 1 && (
                <button
                  className="btn btn-primary nowrap mx-1"
                  onClick={() => {
                    this.props.history.goBack();
                  }}
                >
                  Retour
                </button>
              )}
            </div>
          </div>
          <div className="row mt-4">{this.form()}</div>
        </div>
      </>
    );
  }
}

AjoutMateriel.propTypes = {
  history: PropTypes.object,
};

export { AjoutMateriel };
