import http from "http-common";

const TypesLicenceService = class {
    
    /**
     * Fonction permettant de récuperer toutes les classifications de licences
     * @returns la liste de toutes les classifications de licences
     */
    static getAllClassification(params) {
        let url = "/classificationsLicence";
        
        //Gestion de la valeur par défaut de la propriété "isActive" des paramètres
        if (!params)
          return http.get(url, {params: {isActive: true}});
        else if (typeof params === "object" && params.isActive === undefined)
            return http.get(url, {params: {...params, isActive: true}});
        else if (typeof params === "object" && params.isActive !== undefined)
          return http.get(url, {params});
        else
          return http.get(url, {params: {isActive: true}});
    }

    /**
     * Fonction permettant de récuperer toutes les catégories de licences
     * @returns la liste de toutes les catégories de licences
     */
    static getAllCategories(params) {
        let url = "/categoriesLogiciels";
        
        //Gestion de la valeur par défaut de la propriété "isActive" des paramètres
        if (!params)
          return http.get(url, {params: {isActive: true}});
        else if (typeof params === "object" && params.isActive === undefined)
            return http.get(url, {params: {...params, isActive: true}});
        else if (typeof params === "object" && params.isActive !== undefined)
          return http.get(url, {params});
        else
          return http.get(url, {params: {isActive: true}});
    }
    
    /**
     * Fonction permettant de récuperer tous les types de licences
     * @returns la liste de tous les type de licences
     */
    static getAllTypes(params) {
        let url = "/typesLicence";
        
        //Gestion de la valeur par défaut de la propriété "isActive" des paramètres
        if (!params)
          return http.get(url, {params: {isActive: true}});
        else if (typeof params === "object" && params.isActive === undefined)
            return http.get(url, {params: {...params, isActive: true}});
        else if (typeof params === "object" && params.isActive !== undefined)
          return http.get(url, {params});
        else
          return http.get(url, {params: {isActive: true}});
    }

    /**
     * Fonction permettant de récuperer tous les canaux de vente des licences
     * @returns la liste de tous les canaux de vente des licences
     */
    static getAllCanalVente(params) {
        let url = "/canalVente";
        
        //Gestion de la valeur par défaut de la propriété "isActive" des paramètres
        if (!params)
          return http.get(url, {params: {isActive: true}});
        else if (typeof params === "object" && params.isActive === undefined)
            return http.get(url, {params: {...params, isActive: true}});
        else if (typeof params === "object" && params.isActive !== undefined)
          return http.get(url, {params});
        else
          return http.get(url, {params: {isActive: true}});
    }
  };
  
  export { TypesLicenceService };