import React from "react";
import { App } from "App";

import { ButtonIcon } from "_components";
import {
  faMinusSquare,
  faPlusSquare,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";

import { ToLocaleDateString } from "_utils";

import { PropTypes } from "prop-types";

class AccordionCommande extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isAccordionOpen: false,
    };
  }

  render() {
    const right = App.RightsGuard?.current?.hasRight("Affaire", "Commandes");

    return (
      <>
        <tr
          key={this.props.index * 1000}
          className={this.props.index % 2 ? "shadowLine" : ""}
        >
          <td className="tableIndent1">
            <ButtonIcon
              icon={this.state.isAccordionOpen ? faMinusSquare : faPlusSquare}
              className="btn"
              onClick={() =>
                this.setState((prevState) => ({
                  isAccordionOpen: !prevState.isAccordionOpen,
                }))
              }
              style={{ width: "32px" }}
            ></ButtonIcon>
            <a
              className="fixWidthHover"
              href={"/commandes/" + this.props.commande.id}
              target={"_blank"}
              data-text={this.props.commande.reference}
            >
              {" " + this.props.commande.reference}
            </a>{" "}
            {this.props.commande.nom_Commande}
            {right == "RO" ? (
              <></>
            ) : (
              <ButtonIcon
                icon={faTrash}
                className="btn btn-danger btn-sm text-light ms-1"
                onClick={() =>
                  this.props.handleCommandes(this.props.commande, "delete")
                }
                style={{ width: "32px" }}
              ></ButtonIcon>
            )}
          </td>
          <td className="tableIndent1 pt-2">
            {ToLocaleDateString(this.props.commande.date_Create)}
          </td>
          <td className="tableIndent1"></td>
          <td className="tableIndent1"></td>
          <td className="tableIndent1"></td>
          <td className="tableIndent1"></td>
          <td className="tableIndent1 pt-2">
            {this.props.commande.prixVenteTotal}
          </td>
          <td className="tableIndent1 pt-2">
            {this.props.commande.prixVenteTTCTotal}
          </td>
        </tr>

        {this.state.isAccordionOpen
          ? this.props.commande.commandeDetails
              ?.slice()
              .sort((a, b) => a.position - b.position)
              .map((detail, index) => {
                return (
                  <tr
                    key={this.props.index * 1000 + index}
                    className={
                      "text-muted " + (this.props.index % 2 ? "shadowLine" : "")
                    }
                  >
                    <td className="tableIndent1"></td>
                    <td className="tableIndent1"></td>
                    <td className="tableIndent1">{detail.article?.aR_Ref}</td>
                    <td className="tableIndent1">{detail.description}</td>
                    <td className="tableIndent1">{detail.quantite}</td>
                    <td className="tableIndent1">{detail.prixVenteUnitaire}</td>
                    <td className="tableIndent1">{detail.prixVenteTotal}</td>
                    <td className="tableIndent1">{detail.prixVenteTotalTTC}</td>
                  </tr>
                );
              })
          : null}
      </>
    );
  }
}

AccordionCommande.propTypes = {
  index: PropTypes.number,
  commande: PropTypes.any,
  handleCommandes: PropTypes.func,
};

export { AccordionCommande };
