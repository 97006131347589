import http from "http-common";

const LangueService = class {
  /**
   * Fonction permettant de récuperer tous les Langue avec une possibilité de filtre via params
   * @returns la liste de tous les Langue
   */
  static getAll(params) {
    let url = "/langues";
    
    //Gestion de la valeur par défaut de la propriété "isActive" des paramètres
    if (!params)
      return http.get(url, {params: {isActive: true}});
    else if (typeof params === "object" && params.isActive === undefined)
        return http.get(url, {params: {...params, isActive: true}});
    else if (typeof params === "object" && params.isActive !== undefined)
      return http.get(url, {params});
    else
      return http.get(url, {params: {isActive: true}});
  }

  // /**
  //  * Fonction permettant de récuperer un Langue via son id
  //  * @param {*} id, id de du Langue à récupérer
  //  * @returns le Langue souhaité
  //  */
  // static getById(id) {
  //   return http.get("/langues/" + id);
  // }

    /**
   * Fonction permettant de récuperer un Langue via son id
   * @param {*} id, id de du Langue à récupérer
   * @returns le Langue souhaité
   */
     static getByValue(value) {
      return http.get("/langues/get/" + value);
    }
};

export { LangueService };
