import React from "react";
import { Box, DataFieldColumn, WarningBar } from "_components";
import { App } from "App";
import {
  getIconLicence,
  isNullOrWhiteSpaces,
  ToLocaleDateString,
} from "_utils";
import { Input } from "_components/Input";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

class General extends React.PureComponent {
  constructor(props) {
    super(props);
  }

  render() {
    let right = App.RightsGuard?.current?.hasRight("Personnel", "General"); // on obtient les droits généraux
    const licence = this.props.licence;
    if (right == "RW" || right == "RO") {
      return (
        <>
          <div className="row">
            <Box
              header={
                <>
                  {/* Numéro/nom de la licence */}
                  <div className="col-4 align-self-center py-2 d-flex">
                    {licence.classification?.categorie?.id == 1
                      ? getIconLicence("Websprint")
                      : getIconLicence("Geosprint")}
                    <div className="align-self-center ms-2">état actuel</div>
                  </div>
                  {/* Bandeau warnings */}
                  <div className="col-4 align-self-center text-center py-2 text-warning">
                    {licence.impayee ? (
                      <>
                        <FontAwesomeIcon 
                          icon={faExclamationTriangle}
                          className="mx-2"
                        />
                        Licence impayée
                        <br />
                      </>
                    ) : null}
                    {licence.devisModules?.resultat.valeur == "En cours" ||
                    licence.devisModules?.resultat.valeur == "Suspendu" ? (
                      <>
                        <FontAwesomeIcon
                          icon={faFileInvoice}
                          className="mx-2"
                        />
                        Devis en cours
                        <ButtonIcon
                          icon={faExternalLinkAlt}
                          className="btn btn-primary p-0 mx-2 align-self-center exportToDetails ficheClientTab"
                          onClick={() => {
                            RedirectToDetailsNewTab(
                              "/devis/",
                              licence.devisModules?.id
                            );
                          }}
                        />
                      </>
                    ) : null}
                  </div>
                  {/* Boutons d'action selon le contexte */}
                  <div className="col-4 text-end py-2"></div>
                </>
              }
              body={
                <>
                  {/* Partie informations sur la licence */}
                  <div className="col-8">
                    <div className="row pt-2">
                      <div className="col-3 align-self-center">
                        <DataFieldColumn
                          label="Client"
                          value={licence.client?.nom_Client ?? "-"}
                        />
                      </div>
                    </div>
                    <div className="row pt-2">
                      <div className="col-3 align-self-center">
                        <DataFieldColumn
                          label="Catégorie logiciel"
                          value={
                            licence.classification?.categorie?.designation ??
                            "-"
                          }
                        />
                      </div>
                      <div className="col-3 align-self-center">
                        <DataFieldColumn
                          label="type de licence"
                          value={licence.classification?.type?.designation}
                        />
                      </div>
                      <div className="col-3 align-self-center">
                        <DataFieldColumn
                          label="Canal de vente"
                          value={
                            licence.classification?.canalVente?.designation ??
                            "-"
                          }
                        />
                      </div>
                      {licence.classification?.id == 2 ? (
                        <div className="col align-self-center">
                          <DataFieldColumn
                            label="à résilier"
                            value={licence.resiliee ? "oui" : "non"}
                          />
                        </div>
                      ) : null}
                    </div>
                    <div className="row pt-2">
                      <div className="col-3 align-self-center">
                        <DataFieldColumn
                          label="date de début"
                          type="date"
                          value={licence.dateDebut ?? "-"}
                        />
                      </div>
                      <div className="col-3 align-self-center">
                        <DataFieldColumn
                          label="date de fin"
                          type="date"
                          value={licence.dateFin ?? "-"}
                        />
                      </div>
                      <div className="col-3 align-self-center"></div>
                      <div className="col align-self-center">
                        <DataFieldColumn
                          label="gratuit"
                          value={licence.gratuit ? "oui" : "non"}
                        />
                      </div>
                    </div>
                    <div className="row pt-2">
                      <div className="col-3 align-self-start">
                        <DataFieldColumn
                          label="Modules"
                          value={licence.modules?.map((module) => (
                            <div key={module.nom}>
                              {module.nom}
                              {module.dateTest
                                ? " (essai : " +
                                  ToLocaleDateString(module.dateTest) +
                                  ")"
                                : null}
                            </div>
                          ))}
                        />
                      </div>
                      <div className="col">
                        <div className="row">
                          <div className="col">
                            <DataFieldColumn
                              label="Commentaire"
                              value={
                                !isNullOrWhiteSpaces(licence.commentaire)
                                  ? licence.commentaire
                                  : "-"
                              }
                              functionAppliedToValue={(text) => (
                                <div className="keepWhiteSpace">{text}</div>
                              )}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* Partie informations sur l'utilisateur */}
                  <div className="col-4">
                    <div className="align-self-center text-center py-2 mt-2 border border-dark">
                      {licence.user ? (
                        <>
                          <div className="align-self-center pt-2">
                            <DataFieldColumn
                              label="Nom"
                              value={licence.user.nom_Contact}
                            />
                          </div>
                          <div className="align-self-center pt-2">
                            <DataFieldColumn
                              label="Prénom"
                              value={licence.user.prenom_Contact}
                            />
                          </div>
                          <div className="align-self-center pt-2">
                            <DataFieldColumn
                              label="Mail"
                              value={licence.user.email}
                            />
                          </div>
                        </>
                      ) : (
                        "Aucun utilisateur"
                      )}
                    </div>
                  </div>
                </>
              }
            />
          </div>

          <Box
            header={
              <>
                <div className="col-12 align-self-center py-2 d-flex flex-row justify-content-between">
                  Filtres
                </div>
              </>
            }
            body={
              <>
                <div className="col">
                  <Input
                    type="selectSearch"
                    label="Motif"
                    value={this.props.filters.motif ?? ""}
                    valueFieldToDisplay={"display"}
                    optionFieldToDisplay={"display"}
                    optionFieldToReturn={"id"}
                    options={[
                      {
                        id: 0,
                        display: "Impayé",
                      },
                      {
                        id: 1,
                        display: "Payé",
                      },

                      {
                        id: 2,
                        display: "Annulé",
                      },

                      {
                        id: 3,
                        display: "Dé-annulé",
                      },

                      {
                        id: 4,
                        display: "Renouvellé",
                      },

                      {
                        id: 5,
                        display: "Changement module",
                      },

                      {
                        id: 6,
                        display: "Essai gratuit de module",
                      },

                      {
                        id: 7,
                        display: "Retrait d'utilisateur",
                      },
                      {
                        id: 8,
                        display: "Ajout d'utilisateur",
                      },
                      {
                        id: 9,
                        display: "Essai gratuit",
                      },
                      {
                        id: 10,
                        display: "Terminé",
                      },
                      {
                        id: 11,
                        display: "Changement dates",
                      },
                      {
                        id: 12,
                        display: "Detachement distributeur",
                      },
                    ].sort((a, b) =>
                      a.display > b.display ? 1 : b.display > a.display ? -1 : 0
                    )}
                    accessor="motif"
                    handleChange={this.props.handleChange}
                  />
                </div>
              </>
            }
            rowClass="px-2"
          />

          <Box
            noPaddingBottom={true}
            header={<div className="col-6 py-2">états antérieurs</div>}
            body={
              <div
                className="lock-header "
                style={{
                  padding: "0 0 0 0 ",
                }}
              >
                <table className="tableHistorique table table-striped table-bordered table-sm">
                  <thead
                    className={
                      "sticky-top" +
                      (this.props.client?.historiques?.length == 0
                        ? " theadBorderRadius"
                        : "")
                    }
                  >
                    <tr>
                      <th className="text-nowrap ps-2">Date</th>
                      <th className="text-nowrap ps-2">Motif</th>
                      <th className="text-nowrap ps-2">Début</th>
                      <th className="text-nowrap ps-2">Fin</th>
                      <th className="text-nowrap ps-2">Module</th>
                      <th className="text-nowrap ps-2">Email</th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.props?.historiques
                      ?.slice()
                      ?.sort(
                        (a, b) =>
                          new Date(b.date_Creation) - new Date(a.date_Creation)
                      )
                      .map((historique) => {
                        return (
                          <tr key={historique.id}>
                            <td className="ps-2">
                              {ToLocaleDateString(historique.date_Creation)}
                            </td>
                            <td className="ps-2">
                              {historique.motifChangement ?? "-"}
                            </td>
                            <td className="ps-2">
                              {ToLocaleDateString(historique.dateDebut)}
                            </td>
                            <td className="ps-2">
                              {ToLocaleDateString(historique.dateFin)}
                            </td>
                            <td
                              className="ps-2"
                              style={{ minWidth: "120px", maxWidth: "200px" }}
                            >
                              {historique?.modules
                                ?.map((e) => e.nom.substring(0, 4) + ". ")
                                ?.toString()}
                            </td>
                            <td className="ps-2">
                              {historique.emailUtilisateur ?? "-"}
                            </td>
                          </tr>
                        );
                      })}
                  </tbody>
                </table>
              </div>
            }
          />
        </>
      );
    } else {
      return (
        <>
          <WarningBar
            active={false}
            content={"Vous n'avez pas le droit de voir cette page"}
          />
        </>
      );
    }
  }
}

export { General };
