import React from "react";
import { PropTypes } from "prop-types";
import { isEqual } from "lodash";
import { Input } from "_components/Input";

import { PaysService } from "_services";
import { customFuseSearch } from "_components/Input/InputTypes/SelectSearchFunctions";

class Address extends React.Component {
  constructor(props) {
    super(props);
  }

  shouldComponentUpdate(prevProps, prevStates) {
    return !(isEqual(prevProps, this.props) && isEqual(prevStates, this.state));
  }

  render() {
    return (
      <>
        <div className="row">
          {this.props.afficherIntitule ? (
            <div
              className={"col-lg-" + this.props.colSizeInnerFields + " pt-2"}
            >
              <Input
                type="text"
                label={this.props.intituleLabel ?? "intitule"}
                value={this.props.value?.intitule}
                invalidText={this.props.invalidText}
                accessor={this.props.accessor + ".intitule"}
                colSize={this.props.colSizeInnerFields}
                handleChange={this.props.handleChange}
                handleBlur={this.props.handleBlur}
                required={this.props.requiredIntitule}
              />
            </div>
          ) : null}
          <div className={"col-lg-" + this.props.colSizeInnerFields + " pt-2"}>
            <Input
              type="text"
              label="Voie"
              value={this.props.value?.voie}
              invalidText={this.props.invalidText}
              accessor={this.props.accessor + ".voie"}
              colSize={this.props.colSizeInnerFields}
              handleChange={this.props.handleChange}
              handleBlur={this.props.handleBlur}
              required={this.props.requiredVoie}
            />
          </div>
          <div className={"col-lg-" + this.props.colSizeInnerFields + " pt-2"}>
            <Input
              type="text"
              label="Complément"
              value={this.props.value?.complement}
              invalidText={this.props.invalidText}
              accessor={this.props.accessor + ".complement"}
              colSize={this.props.colSizeInnerFields}
              handleChange={this.props.handleChange}
              handleBlur={this.props.handleBlur}
              required={this.props.requiredComplement}
            />
          </div>
          <div className={"col-lg-" + this.props.colSizeInnerFields + " pt-2"}>
            <Input
              type="text"
              label="Ville"
              value={this.props.value?.ville}
              invalidText={this.props.invalidText}
              accessor={this.props.accessor + ".ville"}
              colSize={this.props.colSizeInnerFields}
              handleChange={this.props.handleChange}
              handleBlur={this.props.handleBlur}
              required={this.props.requiredVille}
            />
          </div>
          <div className={"col-lg-" + this.props.colSizeInnerFields + " pt-2"}>
            <Input
              type="text"
              label="Code postal"
              value={this.props.value?.cp}
              invalidText={this.props.invalidText}
              accessor={this.props.accessor + ".cp"}
              colSize={this.props.colSizeInnerFields}
              handleChange={this.props.handleChange}
              handleBlur={this.props.handleBlur}
              required={this.props.requiredCp}
            />
          </div>
          <div className={"col-lg-" + this.props.colSizeInnerFields + " pt-2"}>
            <Input
              type="selectSearch"
              label="Pays"
              value={this.props.value?.pays}
              invalidText={this.props.invalidText}
              accessor={this.props.accessor + ".pays"}
              colSize={this.props.colSizeInnerFields}
              handleChange={this.props.handleChange}
              handleBlur={this.props.handleBlur}
              service={PaysService.getAll}
              optionFieldToDisplay="nom_Fr"
              valueFieldToDisplay="nom_Fr"
              customFilter={(options) =>
                customFuseSearch(options, {
                  threshold: 0.4,
                  ignoreLocation: true,
                })
              }
              required={this.props.requiredPays}
            />
          </div>
        </div>
      </>
    );
  }
}

Address.propTypes = {
  value: PropTypes.any,
  accessor: PropTypes.string,
  colSize: PropTypes.any,
  handleChange: PropTypes.any,
  handleBlur: PropTypes.func,
  requiredIntitule: PropTypes.bool,
  requiredVoie: PropTypes.bool,
  requiredPays: PropTypes.bool,
  requiredComplement: PropTypes.bool,
  requiredVille: PropTypes.bool,
  requiredCp: PropTypes.bool,
  afficherIntitule: PropTypes.bool,
  invalidText: PropTypes.string,
  colSizeInnerFields: PropTypes.number,
};

Address.defaultProps = {
  afficherIntitule: true,
};

export { Address };
