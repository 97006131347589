import React from "react";
import { PropTypes } from "prop-types";
import { App } from "App";
import "./InformationsPersonnelles.css";
import { BoxInformationsInline, Box, ButtonIcon } from "_components";
import {
  CiviliteService,
  PersonnelLienParenteService,
  PersonnelService,
  PersonnelSituationFamilialeService,
} from "_services";
import { WarningBar } from "_components";

import {
  faPlus,
  faCheck,
  faTimes,
  faPen,
} from "@fortawesome/free-solid-svg-icons";

import { LigneEnfantPersonnel } from "./LigneEnfantPersonnel";
import { PersonnelModeDeplacementService } from "_services/PersonnelModeDeplacementService";

class InformationsPersonnelles extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      savedPersonnel: null,
    };

    this.handleClickEditionEnfantsPersonnel =
      this.handleClickEditionEnfantsPersonnel.bind(this);
    this.handleClickUndoEnfantsPersonnel =
      this.handleClickUndoEnfantsPersonnel.bind(this);
    this.handleClickValidateEnfantsPersonnel =
      this.handleClickValidateEnfantsPersonnel.bind(this);
  }

  handleClickEditionEnfantsPersonnel() {
    this.setState({
      savedPersonnel: { ...this.props.personnel },
    });
    this.props.handleEditingEnfantsPersonnel(true);
  }

  handleClickUndoEnfantsPersonnel() {
    this.props.handleChange("personnel", this.state.savedPersonnel);
    this.props.handleEditingEnfantsPersonnel(false);
  }

  handleClickValidateEnfantsPersonnel() {
    this.props.handleUpdate();
    this.props.handleEditingEnfantsPersonnel(false);
  }

  render() {
    let right = App.RightsGuard?.current?.hasRight("Personnel", "Personnel"); // on obtient les droits généraux
    const personneRight = App.RightsGuard?.current?.isPersonne(
      this.props.personnel.oid
    );
    const managerRight = App.RightsGuard?.current?.isManager(
      this.props.personnel.responsable
    );
    if (right != "RW" && managerRight == "RO") right = "RO";
    if (personneRight == "RW") right = "RW"; // on bypass si l'oid de la personnes connecté correspond à l'oid de la fiche personnel

    if (right == "RW" || right == "RO") {
      const informationsGeneral = [
        {
          label: "Civilité",
          accessor: "civilite",
          functionAppliedToValue: (value) => {
            return value ? value.designation : null;
          },
          type: "selectSearch",
          service: CiviliteService.getAll,
          optionFieldToDisplay: "designation",
          valueFieldToDisplay: "designation",
          colSize: 4,
        },
        {
          label: "Nom",
          accessor: "nom",
          type: "text",
          colSize: 4,
        },
        {
          label: "Prénom",
          accessor: "prenom",
          type: "text",
          colSize: 4,
        },
        {
          label: "Initiales",
          accessor: "initiales",
          type: "text",
          invalidText: "Les initiales existent déjà",
          handleBlur: () => {
            // Permet de vérifier si les initiales existent déjà en vérifiant que ce ne sont pas celles déjà utilisées
            PersonnelService.getAllInitiales().then((res) => {
              let inputInitiales = document.getElementsByName("initiales");
              if (
                res.data
                  .filter((val) => val !== this.ancienneInitiales)
                  .find(
                    (element) =>
                      element === this.props.personnel.initiales.toUpperCase()
                  ) === undefined
              ) {
                inputInitiales[0].classList.remove("is-invalid");
              } else {
                inputInitiales[0].classList.add("is-invalid");
              }
            });
          },
          colSize: 4,
        },
        {
          label: "Responsable (initiales)",
          accessor: "responsable",
          type: "selectSearch",
          service: PersonnelService.getAllInitiales,
          colSize: 4,
        },
        {
          label: "Actif",
          accessor: "actif",
          type: "checkbox",
          functionAppliedToValue: (value) => {
            return value ? "Oui" : "Non";
          },
          colSize: 4,
        },
      ];
      const coordonneesPersonnelles = [
        {
          label: "Portable Perso",
          accessor: "portable_Perso",
          type: "text",
          colSize: 6,
        },
        {
          label: "Email Perso",
          accessor: "email_Perso",
          type: "email",
          colSize: 6,
        },
        {
          label: "Adresse",
          functionAppliedToValue: () => (
            <>
              {this.props.personnel.adresse?.voie ?? ""}
              <br />
              {this.props.personnel.adresse?.complement ?? ""}
              <br />
              {(this.props.personnel.adresse?.cp ?? "") +
                " " +
                (this.props.personnel.adresse?.ville ?? "")}
              <br />
              {this.props.personnel.adresse?.pays?.nom_Fr ?? ""}
            </>
          ),
          accessor: "adresse",
          colSize: 12,
          type: "address",
          optionFieldToDisplay: "nom_Fr",
          afficherIntitule: false,
        },
        {
          label: "RIB",
          accessor: "rib",
          type: "text",
          colSize: 12,
        },
        {
          label: "IBAN",
          accessor: "iban",
          type: "text",
          colSize: 8,
        },
        {
          label: "BIC",
          accessor: "bic",
          type: "text",
          colSize: 4,
        },
      ];

      const situationFamiliale = [
        {
          label: "Situation familiale",
          accessor: "situation_Familiale",
          type: "selectSearch",
          functionAppliedToValue: (value) => {
            return value ? value.designation : null;
          },
          service: PersonnelSituationFamilialeService.getAll,
          optionFieldToDisplay: "designation",
          valueFieldToDisplay: "designation",
          colSize: 12,
        },
        {
          label: "Enfant à charge",
          accessor: "enfantACharge",
          type: "checkbox",
          functionAppliedToValue: (value) => {
            return value ? "Oui" : "Non";
          },
          colSize: 12,
        },
      ];

      const contactsEnCasDUrgence = [
        {
          label: "Contact 1 : Lien de parenté",
          accessor: "contactUrgence1_LienParente",
          type: "selectSearch",
          functionAppliedToValue: (value) => {
            return value ? value.designation : null;
          },
          service: PersonnelLienParenteService.getAll,
          optionFieldToDisplay: "designation",
          valueFieldToDisplay: "designation",
          colSize: 12,
        },
        {
          label: "Nom",
          accessor: "contactUrgence1_Nom",
          type: "text",
          colSize: 6,
        },
        {
          label: "Prénom",
          accessor: "contactUrgence1_Prenom",
          type: "text",
          colSize: 6,
        },
        {
          label: "Numéro de téléphone",
          accessor: "contactUrgence1_Telephone",
          type: "text",
          colSize: 12,
        },
        {
          label: "Contact 2 : Lien de parenté",
          accessor: "contactUrgence2_LienParente",
          type: "selectSearch",
          functionAppliedToValue: (value) => {
            return value ? value.designation : null;
          },
          service: PersonnelLienParenteService.getAll,
          optionFieldToDisplay: "designation",
          valueFieldToDisplay: "designation",
          colSize: 12,
        },
        {
          label: "Nom",
          accessor: "contactUrgence2_Nom",
          type: "text",
          colSize: 6,
        },
        {
          label: "Prénom",
          accessor: "contactUrgence2_Prenom",
          type: "text",
          colSize: 6,
        },
        {
          label: "Numéro de téléphone",
          accessor: "contactUrgence2_Telephone",
          type: "text",
          colSize: 12,
        },
      ];

      const sante = [
        {
          label: "Reconnaissance travailleur handicapé",
          accessor: "travailleurHandicape",
          type: "checkbox",
          functionAppliedToValue: (value) => {
            return value ? "Oui" : "Non";
          },
          colSize: 6,
        },
        {
          label: "Date validité RQTH",
          accessor: "dateValiditeRqth",
          type: "date",
          handleChange: this.props.handleChange,
          colSize: 6,
        },
        {
          label: "Allergies",
          accessor: "allergies",
          type: "text",
          colSize: 12,
        },
        {
          label: "Traitements médicaux",
          accessor: "traitementsMedicaux",
          type: "text",
          colSize: 12,
        },
        {
          label:
            "Autres renseignements pertinents à porter à la connaissance de la Direction",
          accessor: "autresRenseignementsMedicaux",
          type: "text",
          colSize: 12,
        },
      ];

      const rse = [
        {
          label: "Mode de déplacement",
          accessor: "modeDeplacement",
          type: "selectSearch",
          functionAppliedToValue: (value) => {
            return value ? value.designation : null;
          },
          service: PersonnelModeDeplacementService.getAll,
          optionFieldToDisplay: "designation",
          valueFieldToDisplay: "designation",
          colSize: 12,
        },
      ];

      return (
        <>
          <div className="row">
            <div className="col-12 d-flex">
              <div className="col-12 col-lg-12 d-flex ">
                <BoxInformationsInline
                  title="général"
                  datas={informationsGeneral}
                  state={this.props.personnel}
                  stateFieldNameToUpdate={this.props.stateFieldNameToUpdate}
                  service={this.props.service}
                  handleChange={this.props.handleChange}
                  colSize="4"
                  editing={this.props.editing}
                  handleEditing={this.props.handleEditing}
                />
              </div>
            </div>
            <div className="col-lg-6 col-md-12">
              <BoxInformationsInline
                title="Coordonnées personnelles"
                datas={coordonneesPersonnelles}
                state={this.props.personnel}
                stateFieldNameToUpdate={this.props.stateFieldNameToUpdate}
                service={this.props.service}
                handleChange={this.props.handleChange}
                colSize="4"
                editing={this.props.editing}
                handleEditing={this.props.handleEditing}
                handleUpdate={this.props.handleUpdate}
              />
              <BoxInformationsInline
                title="RSE"
                datas={rse}
                state={this.props.personnel}
                stateFieldNameToUpdate={this.props.stateFieldNameToUpdate}
                service={this.props.service}
                handleChange={this.props.handleChange}
                colSize="4"
                editing={this.props.editing}
                handleEditing={this.props.handleEditing}
                handleUpdate={this.props.handleUpdate}
              />
              <BoxInformationsInline
                title="Situation familiale"
                datas={situationFamiliale}
                state={this.props.personnel}
                stateFieldNameToUpdate={this.props.stateFieldNameToUpdate}
                service={this.props.service}
                handleChange={this.props.handleChange}
                colSize="4"
                editing={this.props.editing}
                handleEditing={this.props.handleEditing}
                handleUpdate={this.props.handleUpdate}
              />
              <Box
                header={
                  <>
                    <div className="col-6 align-self-center py-2">
                      <div>Enfants</div>
                    </div>
                    <div className="col-6 text-end py-2">
                      <ButtonIcon
                        icon={faPlus}
                        iconSize="sm"
                        onClick={() => this.props.addLineEnfantPersonnel?.()}
                        className="btn btn-success addEnfant"
                        style={
                          !this.props.editingEnfantsPersonnel
                            ? { display: "none" }
                            : {}
                        }
                      ></ButtonIcon>
                      <ButtonIcon
                        icon={faCheck}
                        className="btn btn-success text-light ms-1"
                        style={
                          !this.props.editingEnfantsPersonnel
                            ? { display: "none" }
                            : { width: "42px" }
                        }
                        onClick={this.handleClickValidateEnfantsPersonnel}
                      ></ButtonIcon>
                      <ButtonIcon
                        icon={faTimes}
                        className="btn btn-danger text-light ms-1"
                        onClick={this.handleClickUndoEnfantsPersonnel}
                        style={
                          !this.props.editingEnfantsPersonnel
                            ? { display: "none" }
                            : { width: "42px" }
                        }
                      ></ButtonIcon>
                      <ButtonIcon
                        icon={faPen}
                        className="btn btn-secondary text-light ms-1"
                        onClick={this.handleClickEditionEnfantsPersonnel}
                        style={
                          this.props.editing
                            ? { display: "none" }
                            : { width: "42px" }
                        }
                      ></ButtonIcon>
                    </div>
                  </>
                }
                body={
                  <>
                    <table
                      className={
                        "text-uppercase table table-sm " +
                        (this.props.personnel.enfants?.length > 0
                          ? ""
                          : "table-bordered")
                      }
                    >
                      <thead
                        className={
                          "sticky-top" +
                          (this.props.personnel.enfants?.length == 0
                            ? " theadBorderRadius"
                            : "")
                        }
                      >
                        <tr>
                          <th>Nom</th>
                          <th>Prénom</th>
                          <th>Date de naissance</th>
                          <th></th>
                        </tr>
                      </thead>
                      <tbody>
                        {this.props.personnel.enfants?.slice().map((enfant) => (
                          <LigneEnfantPersonnel
                            key={enfant.id}
                            enfant={enfant}
                            handleChange={this.props.handleChange}
                            editing={this.props.editingEnfantsPersonnel}
                            deleteLineEnfantPersonnel={
                              this.props.deleteLineEnfantPersonnel
                            }
                          />
                        ))}
                      </tbody>
                    </table>
                  </>
                }
              />
            </div>
            <div className="col-lg-6 col-md-12">
              <BoxInformationsInline
                title="Contacts en cas d'urgence"
                datas={contactsEnCasDUrgence}
                state={this.props.personnel}
                stateFieldNameToUpdate={this.props.stateFieldNameToUpdate}
                service={this.props.service}
                handleChange={this.props.handleChange}
                colSize="4"
                editing={this.props.editing}
                handleEditing={this.props.handleEditing}
                handleUpdate={this.props.handleUpdate}
              />
              <BoxInformationsInline
                title="Santé"
                datas={sante}
                state={this.props.personnel}
                stateFieldNameToUpdate={this.props.stateFieldNameToUpdate}
                service={this.props.service}
                handleChange={this.props.handleChange}
                colSize="4"
                editing={this.props.editing}
                handleEditing={this.props.handleEditing}
                handleUpdate={this.props.handleUpdate}
              />
            </div>
          </div>
        </>
      );
    } else {
      return (
        <>
          <WarningBar
            active={false}
            content={"Vous n'avez pas le droit de voir cette page"}
          />
        </>
      );
    }
  }
}

InformationsPersonnelles.propTypes = {
  personnel: PropTypes.object,
  stateFieldNameToUpdate: PropTypes.string,
  handleChange: PropTypes.func,
  history: PropTypes.object,
  service: PropTypes.func,
};

export { InformationsPersonnelles };
