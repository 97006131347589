import React from "react";

// Validation des props
import { PropTypes } from "prop-types";
import { isEqual } from "lodash";

import { Tooltip, OverlayTrigger } from "react-bootstrap";

import "./TooltipEncapsulator.css";

/**
 * Encapsule une div enfante avec une un tooltip qui s'affiche au survol.
 */
class TooltipEncapsulator extends React.Component {
  constructor(props) {
    super(props);
  }

  shouldComponentUpdate(prevProps) {
    return !isEqual(prevProps, this.props);
  }
  componentDidMount() {}

  render() {
    return (
      <OverlayTrigger
        placement="top"
        delay={{ show: 1000, hide: 100 }}
        overlay={
          <Tooltip id="tooltip-encapsulator">{this.props.tooltip}</Tooltip>
        }
      >
        {this.props.children}
      </OverlayTrigger>
    );
  }
}

/**
 * Validation des props :
 * tooltip : contenu du tooltip
 */
TooltipEncapsulator.propTypes = {
  tooltip: PropTypes.any,
};

TooltipEncapsulator.defaultProps = {};

export { TooltipEncapsulator };
