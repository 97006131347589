import http from "http-common";

const CommandeService = class {
  /**
   * Fonction permettant de récuperer toutes les commandes avec une possibilité de filtre via params
   * @param {*} params, paramètres à utiliser pour la requête (page, nombre d'éléments par page et filtres sur les champs)
   * @returns une liste des commandes respectant les critères de params et le nombre de page pour ces critères
   */
  static getAll(params) {
    return http.get("/commandes/", { params });
  }

  static getForSS(params) {
    return http.get("/commandes/", {
      params: {
        orderBy: "nom_Commande",
        order: "ASC",
        num_Client: params,
        size: 20,
        actif: true,
      },
    });
  }


  static postFromDevis(devisId, affaireId, articlesLicences = [], licences = []) {
    return http.post(
      "/commandes/fromdevis/" + devisId + "/affaire/" + affaireId,
      {
        modelesCombinesLicences: licences,
        articlesLicenceFromMat: articlesLicences,
      }
    );
  }

  static postFromDevisWithContrat(devisId, affaireId, contratId, articlesLicences = [], licences = []) {
    return http.post(
      "/commandes/fromdevis/" + devisId + "/affaire/" + affaireId + "/contrat/" + contratId,
      {
        modelesCombinesLicences: licences,
        articlesLicenceFromMat: articlesLicences,
      }
    );
  }
  /**
   * Fonction permettant de mettre à jour une commande
   * @param {*} commande, la commande à mettre à jour
   * @returns
   */
  static put(commande) {
    return http.put("/commandes/" + commande.id, commande);
  }

  /**
   * Fonction permettant de récuperer une commande via son id
   * @param {*} id, id de la commande à récupérer
   * @returns la commande souhaitée
   */
  static getById(id) {
    return http.get("/commandes/" + id);
  }

  static getCommandesByClientButNotInAnyAffaire(params) {
    return http.get("/commandes/byClient", { params });
  }

  /**
   * Fonction permettant de supprimer une commande
   * @param {*} commande, la commande à supprimer
   * @returns
   */
  static delete(commande) {
    return http.delete("/commandes/" + commande.id);
  }

  /**
   * Fonction permettant de supprimer une liaison affaire-commande
   * @param {*} commande, la commande à supprimer
   * @returns
   */
  static unlink(commande) {
    return http.delete("/commandes/unlinkCommande/" + commande.id);
  }

  /**
   * @returns un fichier
   */
  static print(idCom) {
    return http({
     url: "/commandes/impression/"+idCom,
     method: "GET",
     responseType: "blob",
   });
  }

  static Extract(payload) {
    const params = new URLSearchParams(payload);
    return http({
      url: "/commandes/extraction?"+params,
      method: "GET",
      responseType: "blob",
    });
  }
};

export { CommandeService };
