import React from "react";
import Toasted from "./Toast";
import produce from "immer";
import { ToastContainer } from "react-bootstrap";

class Toaster extends React.Component {
  constructor(props) {
    super(props);
    this.state = { toasts: [] };

    this.createToast = this.createToast.bind(this);
    this.deleteToast = this.deleteToast.bind(this);
  }

  createToast(toast) {
    this.setState(
      produce((prevState) => {
        prevState.toasts = prevState.toasts.concat([toast]);
      })
    );

    setTimeout(this.deleteToast, 30000);
  }

  deleteToast() {
    this.setState(
      produce((prevState) => {
        prevState.toasts.pop();
      })
    );
  }

  render() {
    return (
      <ToastContainer
        className="p-3 position-fixed"
        position="bottom-end"
        style={{ zIndex: "10000" }}
      >
        {this.state.toasts.map((e, index) => {
          return (
            <Toasted
              key={index}
              body={e.body}
              header={e.header}
              delay={e.delay ? e.delay : 30000}
              type={e.type}
            />
          );
        })}
      </ToastContainer>
    );
  }
}

export { Toaster };
