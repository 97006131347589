function RedirectTo(history, url) {
  history.push({ pathname: url });
}

function RedirectToDetails(history, baseURL, id) {
  history.push({ pathname: baseURL + id });
}

function RedirectToDetailsNewTab(baseURL, id) {
  window.open(baseURL + id, "_blank");
}

export { RedirectToDetails, RedirectToDetailsNewTab, RedirectTo };
