import React from "react";
import { App } from "App";
import {
  ReactTableServerSide,
  IndeterminateCheckbox,
  CellWithRedirection,
  ButtonIcon,
} from "_components";

import { Input } from "_components/Input";

import { RedirectTo, ToLocaleDateString } from "_utils";
import { PropTypes } from "prop-types";
import {
  PersonnelService,
  PersonnelFonctionService,
  PersonnelServiceService,
  PersonnelTypeContratService,
  PersonnelStatutsService,
  PersonnelPoleService,
  CiviliteService,
  PersonnelModesTeletravailService,
} from "_services/";
import { Helmet } from "react-helmet";

import { faFileExport } from "@fortawesome/free-solid-svg-icons";

import * as FileSaver from "file-saver";

class BasePersonnel extends React.PureComponent {
  constructor(props) {
    super(props);

    this.handleExtraction = this.handleExtraction.bind(this);
    this.handleDownloadExtractionById =
      this.handleDownloadExtractionById.bind(this);
  }

  handleExtraction() {
    PersonnelService.extractHeuresExtraction().then((res) => {
      var blob = new Blob([res?.data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      FileSaver.saveAs(blob, "extractionHeures" + ".xlsx");
    });
  }

  handleDownloadExtractionById(acc, val) {
    if (val) {
      PersonnelService.getHeuresExtraction(val).then((res) => {
        var blob = new Blob([res?.data], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });
        FileSaver.saveAs(blob, "extractionHeures" + ".xlsx");
      });
    }
  }

  render() {
    return (
      <>
        <Helmet>
          <title>Base personnel</title>
        </Helmet>
        <ReactTableServerSide
          title={
            <>
              <div className="d-flex align-self-center nowrap">
                <span>Base Personnel</span>
                {App.RightsGuard?.current?.hasRight(
                  "Personnel",
                  "Extraction heures"
                ) == "RW" ? (
                  <>
                    <ButtonIcon
                      icon={faFileExport}
                      className="btn btn-primary text-light ms-5 me-2"
                      onClick={this.handleExtraction}
                      textOption="Extraction"
                    ></ButtonIcon>
                    <Input
                      accessor="filename"
                      type="selectSearch"
                      placeholder="Extractions Heures"
                      service={PersonnelService.searchHeuresExtractions}
                      optionFieldToDisplay="fileName"
                      valueFieldToDisplay="fileName"
                      optionFieldToReturn="id"
                      handleChange={this.handleDownloadExtractionById}
                      showValidator={false}
                      showClearButton={false}
                    />
                  </>
                ) : null}
              </div>
            </>
          }
          titreFiltre="Base personnel"
          service={PersonnelService.getAll}
          displayExtractButton={
            App.RightsGuard.current?.hasRight("Personnel", "Extraction") == "RW"
          }
          serviceExtract={PersonnelService.Extract}
          module="Personnel"
          redirectToCreate={() => {
            RedirectTo(this.props.history, "/personnels/add");
          }}
          orderByDefault="nom"
          localStorageKeyColumns="BasePersonnelColumns"
          columns={[
            {
              id: "selection",
              Header: ({ getToggleAllRowsSelectedProps }) => (
                <div className="text-center align-middle" id="selectAll">
                  <IndeterminateCheckbox
                    className="align-middle"
                    {...getToggleAllRowsSelectedProps()}
                  />
                </div>
              ),
              Cell: ({ row }) => (
                <div className="text-center align-middle" id="selectOne">
                  <IndeterminateCheckbox
                    className="align-middle"
                    {...row.getToggleRowSelectedProps()}
                  />
                </div>
              ),
            },
            {
              Header: "Actif",
              accessor: "actif",
              hideable: true,
              sortable: true,
              exportable: true,
              defaultValue: {
                id: 1,
                display: "Oui",
                value: true,
              },
              minWidth: "130px",
              type: "selectSearch",
              valueFieldToDisplay: "display",
              optionFieldToDisplay: "display",
              optionFieldToReturn: "value",
              options: [
                {
                  id: 1,
                  display: "Oui",
                  value: true,
                },
                {
                  id: 2,
                  display: "Non",
                  value: false,
                },
              ],
              Cell: (row) => {
                if (row.cell.value == true) {
                  return <div>Oui</div>;
                } else {
                  return <div>Non</div>;
                }
              },
            },
            {
              Header: "Civilité",
              accessor: "civilite",
              search: "civiliteId",
              hideable: true,
              sortable: true,
              exportable: true,
              minWidth: "170px",
              type: "selectSearch",
              optionFieldToDisplay: "designation",
              valueFieldToDisplay: "designation",
              optionFieldToReturn: "id",
              service: CiviliteService.getAll,
              Cell: (row) => {
                return row.cell.value ? (
                  <span>{row.cell.value.designation}</span>
                ) : null;
              },
            },
            {
              Header: "Initiales",
              accessor: "initiales",
              hideable: true,
              sortable: true,
              exportable: true,
              minWidth: "100px",
              type: "text",
              Cell: (row) => {
                return row.cell.value ? <span>{row.cell.value}</span> : null;
              },
            },
            {
              Header: "Nom",
              accessor: "nom",
              hideable: true,
              sortable: true,
              exportable: true,
              minWidth: "150px",
              type: "text",
              Cell: (row) => {
                return (
                  <CellWithRedirection
                    history={this.props.history}
                    redirection="/personnels/"
                    id={row.cell.row.original.id}
                    value={row.cell.value}
                  />
                );
              },
            },
            {
              Header: "Prénom",
              accessor: "prenom",
              hideable: true,
              sortable: true,
              exportable: true,
              minWidth: "150px",
              type: "text",
            },
            {
              Header: "Mobile pro",
              accessor: "mobile_Pro",
              search: "mobilePro",
              hideable: true,
              exportable: true,
              minWidth: "150px",
              type: "text",
            },
            {
              Header: "Type de contrat",
              accessor: "type_Contrat",
              search: "typeContrat",
              hideable: true,
              sortable: true,
              exportable: true,
              minWidth: "150px",
              type: "selectSearch",
              optionFieldToDisplay: "designation",
              valueFieldToDisplay: "designation",
              optionFieldToReturn: "designation",
              service: PersonnelTypeContratService.getAll,
              Cell: (row) => {
                return row.cell.value ? (
                  <span>{row.cell.value.designation}</span>
                ) : null;
              },
            },
            {
              Header: "Responsable",
              accessor: "responsable",
              hideable: true,
              sortable: true,
              exportable: true,
              minWidth: "120px",
              type: "text",
              Cell: (row) => {
                return row.cell.value ? <span>{row.cell.value}</span> : null;
              },
            },
            {
              Header: "Statut",
              accessor: "statut",
              hideable: true,
              sortable: true,
              exportable: true,
              minWidth: "120px",
              type: "selectSearch",
              optionFieldToDisplay: "designation",
              valueFieldToDisplay: "designation",
              optionFieldToReturn: "designation",
              service: PersonnelStatutsService.getAll,
              Cell: (row) => {
                return row.cell.value ? (
                  <span>{row.cell.value.designation}</span>
                ) : null;
              },
            },
            {
              Header: "Service",
              accessor: "service",
              hideable: true,
              sortable: true,
              exportable: true,
              minWidth: "100px",
              type: "selectSearch",
              optionFieldToDisplay: "sigle",
              valueFieldToDisplay: "sigle",
              optionFieldToReturn: "sigle",
              service: PersonnelServiceService.getAll,
              Cell: (row) => {
                return row.cell.value ? (
                  <span>{row.cell.value.sigle}</span>
                ) : null;
              },
            },
            {
              Header: "Pôle",
              accessor: "pole",
              hideable: true,
              sortable: true,
              exportable: true,
              minWidth: "150px",
              type: "selectSearch",
              optionFieldToDisplay: "designation",
              valueFieldToDisplay: "designation",
              optionFieldToReturn: "designation",
              service: PersonnelPoleService.getAll,
              Cell: (row) => {
                return row.cell.value ? (
                  <span>{row.cell.value.designation}</span>
                ) : null;
              },
            },
            {
              Header: "Fonction",
              accessor: "fonction",
              hideable: true,
              sortable: true,
              exportable: true,
              minWidth: "170px",
              type: "selectSearch",
              optionFieldToDisplay: "designation",
              valueFieldToDisplay: "designation",
              optionFieldToReturn: "designation",
              service: PersonnelFonctionService.getAll,
              Cell: (row) => {
                return row.cell.value ? (
                  <span>{row.cell.value.designation}</span>
                ) : null;
              },
            },
            {
              Header: "Temps partiel",
              accessor: "temps_Partiel",
              search: "temps_Partiel",
              hideable: true,
              sortable: true,
              exportable: true,
              minWidth: "150px",
              type: "text",
            },
            {
              Header: "SST",
              accessor: "sst",
              hideable: true,
              sortable: true,
              exportable: true,
              minWidth: "150px",
              type: "selectSearch",
              valueFieldToDisplay: "display",
              optionFieldToDisplay: "display",
              optionFieldToReturn: "value",
              options: [
                {
                  id: 1,
                  display: "Oui",
                  value: true,
                },
                {
                  id: 2,
                  display: "Non",
                  value: false,
                },
              ],
              Cell: (row) => {
                if (row.cell.value == true) {
                  return <div>Oui</div>;
                } else {
                  return <div>Non</div>;
                }
              },
            },
            {
              Header: "Télétravail",
              accessor: "teletravail",
              hideable: true,
              sortable: true,
              exportable: true,
              minWidth: "150px",
              type: "selectSearch",
              valueFieldToDisplay: "designation",
              optionFieldToDisplay: "designation",
              optionFieldToReturn: "designation",
              service: PersonnelModesTeletravailService.getAll,
              Cell: (row) => {
                return row.cell.value ? (
                  <span>{row.cell.value.designation}</span>
                ) : null;
              },
            },
            {
              Header: "Date Période Essai",
              accessor: "date_Fin_Periode_Essai",
              hideable: true,
              sortable: true,
              exportable: true,
              minWidth: "150px",
              type: "period",
              search: "dateEssai",
              Cell: (row) => {
                return row.cell.value ? (
                  <span>{ToLocaleDateString(row.cell.value)}</span>
                ) : null;
              },
            },
          ]}
        ></ReactTableServerSide>
      </>
    );
  }
}

BasePersonnel.propTypes = {
  history: PropTypes.object,
};

export { BasePersonnel };
