import http from "http-common";
import { uniqBy } from "lodash";
const TypeClientService = class {
  /**
   * Fonction permettant de récuperer tous les pays avec une possibilité de filtre via params
   * @returns la liste de tous les pays
   */
  static getAll(params) {
    let url = "/typeClients";
    
    //Gestion de la valeur par défaut de la propriété "isActive" des paramètres
    if (!params)
      return http.get(url, {params: {isActive: true}});
    else if (typeof params === "object" && params.isActive === undefined)
        return http.get(url, {params: {...params, isActive: true}});
    else if (typeof params === "object" && params.isActive !== undefined)
      return http.get(url, {params});
    else
      return http.get(url, {params: {isActive: true}});
  }

  /**
   * Fonction permettant de récuperer tous les pays avec une possibilité de filtre via params
   * @returns la liste de tous les pays
   */
  static getAllCategoryFromTypeClient() {
    return http.get("/typeClients").then((res) => {
      const categories = uniqBy(
        res.data
          .map((data) => {
            return data.categorie;
          })
          .filter((data) => {
            return data != null;
          }),
        "type"
      );
      return {data: categories};
    });
  }

  /**
   * Fonction permettant de récuperer un pays via son id
   * @param {*} id, id de du pays à récupérer
   * @returns le pays souhaité
   */
  static getById(id) {
    return http.get("/typeClients/" + id);
  }
};

export { TypeClientService };
