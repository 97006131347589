import React from "react";
import {
  ReactTableServerSide,
  IndeterminateCheckbox,
  CellWithRedirection,
} from "_components";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationTriangle } from "@fortawesome/free-solid-svg-icons";

import { PropTypes } from "prop-types";
import { ArticleService } from "_services/";
import { Helmet } from "react-helmet";

class BaseArticle extends React.PureComponent {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <>
        <Helmet>
          <title>Base article</title>
        </Helmet>
        <ReactTableServerSide
          title="Base Article"
          titreFiltre="Base Article"
          service={ArticleService.getAll}
          // redirectToCreate={() => {
          //   RedirectTo(this.props.history, "/articles/add");
          // }}
          orderByDefault="AR_Ref"
          localStorageKeyColumns="BaseArticleColumns"
          columns={[
            {
              id: "selection",
              Header: ({ getToggleAllRowsSelectedProps }) => (
                <div className="text-center align-middle" id="selectAll">
                  <IndeterminateCheckbox
                    className="align-middle"
                    {...getToggleAllRowsSelectedProps()}
                  />
                </div>
              ),
              Cell: ({ row }) => (
                <div className="text-center align-middle" id="selectOne">
                  <IndeterminateCheckbox
                    className="align-middle"
                    {...row.getToggleRowSelectedProps()}
                  />
                </div>
              ),
            },
            {
              Header: "Reférence",
              accessor: "aR_Ref",
              search: "AR_Ref",
              hideable: true,
              sortable: true,
              exportable: true,
              minWidth: "180px",
              type: "text",
              Cell: (row) => {
                return (
                  <CellWithRedirection
                    history={this.props.history}
                    redirection="/articles/"
                    id={row.cell.row.original.aR_Ref}
                    value={row.cell.value}
                  />
                );
              },
            },
            {
              Header: "Nom",
              accessor: "description",
              search: "description",
              hideable: true,
              sortable: true,
              exportable: true,
              minWidth: "150px",
              type: "text",
            },
            {
              Header: "Tarif France",
              accessor: "prixVenteFrance",
              search: "tarif_France",
              hideable: true,
              sortable: true,
              exportable: true,
              minWidth: "180px",
              type: "intervalDecimal",
              Cell: (row) => {
                return row.cell.value ? (
                  <span>{row.cell.value + " €"}</span>
                ) : null;
              },
            },
            {
              Header: "Tarif Export",
              accessor: "prixVenteExport",
              search: "tarif_Export",
              hideable: true,
              sortable: true,
              exportable: true,
              minWidth: "180px",
              type: "intervalDecimal",
              Cell: (row) => {
                return row.cell.value ? (
                  <span>{row.cell.value + " €"}</span>
                ) : null;
              },
            },
            {
              Header: "Actif",
              accessor: "actif",
              defaultValue: {
                id: 0,
                display: "Oui",
                value: true,
              },
              hideable: true,
              exportable: true,
              minWidth: "180px",
              type: "selectSearch",
              optionFieldToReturn: "value",
              optionFieldToDisplay: "display",
              valueFieldToDisplay: "display",
              options: [
                {
                  id: 0,
                  display: "Oui",
                  value: true,
                },
                {
                  id: 1,
                  display: "Non",
                  value: false,
                },
              ],
              Cell: (row) => {
                if (row.cell.value == true) {
                  return <div>Oui</div>;
                } else {
                  return (
                    <div>
                      Non{" "}
                      <FontAwesomeIcon
                        className="text-danger"
                        icon={faExclamationTriangle}
                      />
                    </div>
                  );
                }
              },
            },
          ]}
        ></ReactTableServerSide>
      </>
    );
  }
}

BaseArticle.propTypes = {
  history: PropTypes.object,
};

export { BaseArticle };
