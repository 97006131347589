import React from "react";
import { AlertDialog, Box, ButtonIcon, FormWithValidation } from "_components";
import { Input } from "_components/Input";
import { PropTypes } from "prop-types";

import {
  faExternalLinkAlt,
  faSpinner,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import {
  ClientService,
  ContratCategorieService,
  ContratFinanceurService,
  ContratPeriodiciteService,
  ContratService,
  ContratSortieService,
  PacksService,
  MaterielService,
} from "_services";
import { Helmet } from "react-helmet";
import produce from "immer";
import { ContratDureeService } from "_services/ContratDureeService";
import moment from "moment";

class AjoutContrat extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      loaded: false,
      errorText: null,
      error: false,
      isSubmitting: false,
      contrat: {
        financeur: null,
        numEtude: null,
        actif: true,
        materiels: [],
      },
      modeleNumEtude: "CATAAXXX",
    };

    this.handleStateChange = this.handleStateChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.form = this.form.bind(this);
    this.getAllMaterielProxy = this.getAllMaterielProxy.bind(this);
    this.computeMontantperiodique = this.computeMontantperiodique.bind(this);
  }

  componentDidMount() {
    this.setState((state) => ({
      loaded: true,
      contrat: {
        ...state.contrat,
        client: this.props.history.location.state
          ? this.props.history.location.state.client
          : null,
        materiels:
          this.props.history.location.state &&
          this.props.history.location.state.materiel
            ? [this.props.history.location.state.materiel]
            : [],
      },
    }));

    ContratFinanceurService.getByValue("Sol Solution").then((e) =>
      this.setState({ contrat: { ...this.state.contrat, financeur: e.data } })
    );
  }

  computeMontantperiodique(prevState) {
    if (
      prevState.contrat.duree &&
      prevState.contrat.montantAbonnementHT &&
      prevState.contrat.periodicite
    ) {
      return (
        (prevState.contrat.montantAbonnementHT /
          prevState.contrat.duree.nbMois) *
        prevState.contrat.periodicite.valeur
      );
    }
  }

  handleStateChange(accessor, value, afterUpdateFunction = null) {
    if (accessor.includes(".")) {
      var accessorSplit = accessor.split(".");

      this.setState(
        produce((prevState) => {
          prevState.contrat[accessorSplit[0]][accessorSplit[1]] = value;
        }),
        afterUpdateFunction
      );
    } else {
      // Shallow property
      if (accessor == "financeur") {
        this.setState(
          produce((prevState) => {
            prevState.contrat.financeur = value;
          })
        );
      } else if (accessor == "materiels") {
        if (value != null) {
          this.setState(
            produce((prevState) => {
              prevState.contrat.materiels.splice(0, 0, value);
            })
          );
        }
      } else if (accessor == "duree") {
        if (value != null) {
          this.setState(
            produce((prevState) => {
              prevState.contrat.duree = value;
              if (value != null) {
                if (prevState.contrat.debutAbonnement) {
                  prevState.contrat.finAbonnement = moment(
                    prevState.contrat.debutAbonnement
                  )
                    .add(prevState.contrat.duree.nbMois, "months")
                    .toISOString();
                }

                prevState.contrat.montantAbonnementPeriodiqueHT =
                  this.computeMontantperiodique(prevState);
              }
            })
          );
        }
      } else if (accessor == "debutAbonnement") {
        if (value != null) {
          this.setState(
            produce((prevState) => {
              prevState.contrat.debutAbonnement = value;
              if (value != null) {
                if (prevState.contrat.duree) {
                  prevState.contrat.finAbonnement = moment(
                    prevState.contrat.debutAbonnement
                  )
                    .add(prevState.contrat.duree.nbMois, "months")
                    .toISOString();
                }
              }
            })
          );
        }
      } else if (accessor == "montantAbonnementHT") {
        if (value != null) {
          this.setState(
            produce((prevState) => {
              prevState.contrat.montantAbonnementHT = value;

              prevState.contrat.montantAbonnementPeriodiqueHT =
                this.computeMontantperiodique(prevState);
            })
          );
        }
      } else if (accessor == "periodicite") {
        if (value != null) {
          this.setState(
            produce((prevState) => {
              prevState.contrat.periodicite = value;

              prevState.contrat.montantAbonnementPeriodiqueHT =
                this.computeMontantperiodique(prevState);
            })
          );
        }
      } else {
        this.setState(
          produce((prevState) => {
            prevState.contrat[accessor] = value;
          }),
          afterUpdateFunction
        );
      }
    }
  }

  handleSubmit(event) {
    event.preventDefault();
    this.setState({ isSubmitting: true });

    ContratService.post(this.state.contrat)
      .then((retour) => {
        this.props.history.push("/contrats/" + retour.data.id);
      })
      .catch((error) => {
        this.setState({
          error: true,
          isSubmitting: false,
          errorText: error?.response?.data?.cause,
        });
      });
  }

  getAllMaterielProxy(search) {
    return MaterielService.getAll({
      size: 25,
      global: search,
      en_sommeil: false,
    });
  }

  deleteMateriel(idMat) {
    this.setState(
      produce((prevState) => {
        prevState.contrat.materiels.splice(
          prevState.contrat.materiels.findIndex((el) => el.id === idMat),
          1
        );
      })
    );
  }

  form() {
    const informationsGeneral = [
      {
        value: this.state.contrat.client,
        label: "Client",
        accessor: "client",
        type: "selectSearch",
        service: ClientService.getForSelectSearch,
        optionFieldToDisplay: "nom_Client",
        valueFieldToDisplay: "nom_Client",
        showClearButton: false,
        colSize: 6,
      },

      {
        value: this.state.contrat.categorie,
        label: "Catégorie",
        accessor: "categorie",
        type: "selectSearch",
        service: ContratCategorieService.getAll,
        optionFieldToDisplay: "designation",
        valueFieldToDisplay: "designation",
        showClearButton: false,
        colSize: 6,
        required: true,
      },
      {
        value: this.state.contrat.financeur,
        label: "Financeur",
        accessor: "financeur",
        type: "selectSearch",
        service: ContratFinanceurService.getAll,
        optionFieldToDisplay: "designation",
        valueFieldToDisplay: "designation",
        showClearButton: false,
        colSize: 6,
        required: true,
      },
      {
        value: this.state.contrat.pack,
        label: "Pack",
        accessor: "pack",
        type: "selectSearch",
        service: PacksService.getAll,
        optionFieldToDisplay: "designation",
        valueFieldToDisplay: "designation",
        showClearButton: false,
        colSize: 6,
        required: this.state.contrat?.categorie?.designation == "LLD",
      },
      {
        value: this.state.contrat.sortie,
        label: "Choix sortie",
        accessor: "sortie",
        type: "selectSearch",
        service: ContratSortieService.getAll,
        optionFieldToDisplay: "designation",
        valueFieldToDisplay: "designation",
        showClearButton: false,
        colSize: 6,
      },
      {
        value: this.state.contrat.periodicite,
        label: "Periodicité",
        accessor: "periodicite",
        type: "selectSearch",
        service: ContratPeriodiciteService.getAll,
        optionFieldToDisplay: "designation",
        valueFieldToDisplay: "designation",
        showClearButton: false,
        colSize: 6,
      },
      {
        value: this.state.contrat.duree,
        label: "Durée",
        accessor: "duree",
        type: "selectSearch",
        service: ContratDureeService.getAll,
        optionFieldToDisplay: "designation",
        valueFieldToDisplay: "designation",
        showClearButton: false,
        colSize: 6,
      },
      {
        label: "Sortie Immo",
        accessor: "sortieImmo",
        type: "checkbox",
        colSize: 6,
      },
      {
        label: "RIB",
        accessor: "rib",
        type: "checkbox",
        colSize: 6,
      },
      {
        label: "Abonnement Sage",
        accessor: "abonnementSage",
        type: "checkbox",
        colSize: 6,
      },
      {
        value: this.state.contrat.numEtude,
        label: "N° Etude",
        accessor: "numEtude",
        type: "text",
        colSize: 6,
        required: this.state.contrat?.financeur?.designation != "Sol Solution",
        placeholder:
          this.state.contrat?.financeur?.designation == "Sol Solution"
            ? this.state.modeleNumEtude
            : "",
      },
      {
        label: "Immo",
        accessor: "immo",
        type: "text",
        colSize: 6,
      },
      {
        label: "Contrat Service envoyé le ",
        accessor: "contratServiceEnvoye",
        type: "date",
        colSize: 6,
      },
      {
        label: "Contrat Service retour le ",
        accessor: "contratServiceRetour",
        type: "date",
        colSize: 6,
      },
      {
        label: "Contrat exterieur envoyé le ",
        accessor: "contratExtEnvoye",
        type: "date",
        colSize: 6,
      },
      {
        label: "Contrat exterieur retour le ",
        accessor: "contratExtRet",
        type: "date",
        colSize: 6,
      },

      {
        label: "PV livraison envoyé le ",
        accessor: "pvlivraisonEnvoye",
        type: "date",
        colSize: 6,
        required:
          (this.state.contrat?.categorie?.designation == "ASS" &&
            this.state.contrat?.financeur?.designation != "Sol Solution") ||
          this.state.contrat?.financeur?.designation != "Sol Solution",
      },
      {
        label: "Pv livraison retour le ",
        accessor: "pvlivraisonRetour",
        type: "date",
        colSize: 6,
        required:
          (this.state.contrat?.categorie?.designation == "ASS" &&
            this.state.contrat?.financeur?.designation != "Sol Solution") ||
          this.state.contrat?.financeur?.designation != "Sol Solution",
      },
      {
        label: "Début abonnement ",
        accessor: "debutAbonnement",
        type: "date",
        colSize: 6,
      },
      {
        value: this.state.contrat.finAbonnement,
        label: "Fin abonnement ",
        accessor: "finAbonnement",
        type: "date",
        colSize: 6,
      },
      {
        value: this.state.contrat.montantAbonnementHT,
        label: "Montant HT de l'abonnement ",
        accessor: "montantAbonnementHT",
        type: "decimal",
        colSize: 6,
        handleBlur: this.handleStateChange,
      },
      {
        value: this.state.contrat.montantAbonnementPeriodiqueHT,
        label: "Montant HT périodique de l'abonnement ",
        accessor: "montantAbonnementPeriodiqueHT",
        type: "decimal",
        colSize: 6,
        handleBlur: this.handleStateChange,
      },
      {
        label: "Commentaire",
        accessor: "commentaire",
        type: "textArea",
        colSize: 12,
      },
    ];

    const infos = [
      { info: informationsGeneral, titre: "Informations générales" },
    ];
    return this.state.loaded ? (
      <>
        <FormWithValidation onSubmit={this.handleSubmit}>
          {infos.map((infoItem, index) => {
            return (
              <div className="mb-4" key={index}>
                <Box
                  header={
                    <div className="col-8 align-self-center py-2">
                      Liste des matériels
                    </div>
                  }
                  body={
                    <>
                      {this.state.contrat?.materiels?.map((mat, index) => {
                        return (
                          <div
                            className="d-flex align-items-center justify-content-between mt-2"
                            key={index}
                          >
                            <div>
                              {mat.type_Materiel?.designation +
                                " - " +
                                mat.num_Serie}
                            </div>
                            <div className="text-end">
                              <ButtonIcon
                                icon={faExternalLinkAlt}
                                className="btn btn-secondary btn-sm text-light ms-1"
                                onClick={() => {
                                  window.open("/materiels/" + mat.id);
                                }}
                              ></ButtonIcon>
                              <ButtonIcon
                                icon={faTrash}
                                className="btn btn-danger btn-sm text-light ms-1"
                                onClick={() => {
                                  this.deleteMateriel(mat.id);
                                }}
                              ></ButtonIcon>
                            </div>
                          </div>
                        );
                      })}

                      <div>
                        <div className="mt-4 solwayFont">
                          Ajouter un materiel :
                        </div>
                        <Input
                          type="selectSearch"
                          accessor="materiels"
                          service={(p) =>
                            MaterielService.getAll({ global: p, size: 20 })
                          }
                          valueFieldToDisplay={[
                            "type_Materiel.designation",
                            "num_Serie",
                          ]}
                          optionFieldToDisplay={[
                            "type_Materiel.designation",
                            "num_Serie",
                          ]}
                          handleChange={this.handleStateChange}
                        />
                      </div>
                    </>
                  }
                />
                <Box
                  header={
                    <>
                      <div className="col-12 align-self-center py-2">
                        <div>{infoItem.titre}</div>
                      </div>
                    </>
                  }
                  body={
                    <>
                      {infoItem.info.map((field, fieldsIndex) => {
                        return field ? (
                          <div
                            className={
                              "col-lg-" + field.colSize + " pt-2 align-self-end"
                            }
                            key={fieldsIndex}
                          >
                            <Input
                              label={field.label} // Titre du champ
                              value={field.value} // Valeur du champ
                              accessor={field.accessor} // Accesseur
                              placeholder={field.placeholder}
                              debounce={field.debounce}
                              service={field.service}
                              handleChange={(acc, val) =>
                                this.handleStateChange(
                                  acc,
                                  val,
                                  field.afterUpdateFunction
                                )
                              } // Fonction pour mettre à jour la valeur dans l'object via son accesseur
                              handleBlur={field.handleBlur} // Fonction pour mettre à jour la valeur dans l'object via son accesseur
                              type={field.type} // Type de l'input a utiliser
                              options={field.options} // options de l'input
                              optionFieldToDisplay={field.optionFieldToDisplay} // champ de l'objet a afficher
                              optionFieldToReturn={field.optionFieldToReturn} // champd de l'objet a utiliser en tant que valeur
                              valueFieldToDisplay={field.valueFieldToDisplay}
                              customFirstOption={field.customFirstOption}
                              required={field.required}
                              disabled={field.disabled}
                              validText={field.validText}
                              invalidText={field.invalidText}
                              specialRenderValue={field.specialRenderValue}
                              setForceUpdateFunction={
                                field.setForceUpdateFunction
                              }
                              colSize={field.colSize}
                            />
                          </div>
                        ) : null;
                      })}
                    </>
                  }
                />
              </div>
            );
          })}

          <div className="text-center">
            <ButtonIcon
              className="btn btn-success mt-4 w-25"
              type="submit"
              disabled={this.state.isSubmitting}
              textOption="Créer le contrat"
            ></ButtonIcon>
          </div>
        </FormWithValidation>
      </>
    ) : (
      <FontAwesomeIcon
        icon={faSpinner}
        size="lg"
        className="fa-spin flex-auto"
      />
    );
  }

  render() {
    return (
      <>
        <Helmet>
          <title>Création d'un Contrat</title>
        </Helmet>
        <AlertDialog
          title="Une erreur a été rencontrée"
          body={
            <>
              <div>La création du contrat a échouée.</div>
              <div>{this.state.errorText}</div>
            </>
          }
          valider="Ok"
          open={this.state.error}
          handleClose={() => this.setState({ error: false })}
        />
        <div className="p-3 m-4" id="ajoutDevis">
          <div className="row">
            <div className="d-flex overflow-auto justify-content-between py-1">
              <h3 className="nowrap pr-100 solwayFont">
                Création d&apos;un contrat
              </h3>

              <div>
                {this.props.history.length > 1 && (
                  <button
                    className="btn btn-primary nowrap mx-1"
                    onClick={() => {
                      this.props.history.goBack();
                    }}
                  >
                    Retour
                  </button>
                )}
              </div>
            </div>
          </div>
          <div className="row mt-4 ">{this.form()}</div>
        </div>
      </>
    );
  }
}

AjoutContrat.propTypes = {
  history: PropTypes.object,
};

export { AjoutContrat };
