import React from "react";
import { produce } from "immer";

import { LigneRefSimple } from "./LigneRefSimple";

import { PropTypes } from "prop-types";

import { Box, ButtonIcon, DialogForm, FormWithValidation } from "_components";

import {
  faPlus,
  faCheck,
  faTimes,
  faPen,
} from "@fortawesome/free-solid-svg-icons";

import { Input } from "_components/Input";
import {
  ActionsTacheService,
  PersonnelAttributionSpecifiqueService,
  CategorieService,
  CiviliteService,
  PersonnelCoefficientService,
  PersonnelDiplomeService,
  FicheDeSuiviEtatAvancementService,
  EtatClientService,
  FamilleAffaireService,
  PersonnelFonctionInformatiqueService,
  PersonnelFonctionService,
  FonctionService,
  FicheDeSuiviFournisseurService,
  InterneExterneService,
  JourNuitService,
  LangueService,
  PersonnelLienParenteService,
  MissionService,
  PersonnelModesTeletravailService,
  MotifNonReponseService,
  OrigineService,
  PacksService,
  PaysService,
  PersonnelPoleService,
  PersonnelPositionService,
  PrioritesTacheService,
  ProcessTacheService,
  ProduitInterneService,
  RaisonsPerteService,
  ReferentGeotechniqueService,
  ReferentielService,
  RegionService,
  ResultatService,
  ReussiteService,
  HeurePersonnelRubriqueService,
  ServiceContactService,
  PersonnelServiceService,
  PersonnelSituationFamilialeService,
  SourceService,
  StatutsCommandeService,
  StatutsConsultationService,
  PersonnelStatutsService,
  StatutsTacheService,
  TailleEntrepriseService,
  TypeClientService,
  PersonnelTypeContratService,
  TypeLigneService,
  NoteDeFraisTypeService,
  TypeOuvrageService,
  FicheDeSuiviTypePanneService,
  FicheDeSuiviUtilisationService,
  FicheDeSuiviStatutsService,
  ContratCategorieService,
  ContratFinanceurService,
  ContratPeriodiciteService,
  ContratSortieService,
  TypesTacheService,
  PersonnelSyntechService,
  FamilleArticleService,
  CouleurCalculetteService,
  VehiculeService,
  MotifsChantierAnnulationService,
  MaterielsChantierService,
  JoursFeriesService,
  FicheDeSuiviProvenanceService,
  FicheDeSuiviTypeService,
  HeurePersonnelCompetenceService,
} from "_services";
import { ContratDureeService } from "_services/ContratDureeService";

class ReferentielSimple extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      refName: null,
      refNomUsuel: null,
      service: null,
      referentiels: null,
      savedReferentiels: null,
      newRef: {},
      infoColonnes: [],
      loading: false,
      editable: false,
    };

    this.handleClickEdition = this.handleClickEdition.bind(this);
    this.handleClickUndo = this.handleClickUndo.bind(this);
    this.handleClickValidate = this.handleClickValidate.bind(this);
    this.handleChange = this.handleChange.bind(this);

    this.setOpenDialogCreationRef = this.setOpenDialogCreationRef.bind(this);
  }

  setOpenDialogCreationRef(openDialogFunc) {
    this.setState({ openDialogCreationRefFunc: openDialogFunc });
  }

  componentDidMount() {}

  handleClickEdition() {
    if (this.state.referentiels)
      this.setState({
        savedReferentiels: [...this.state.referentiels],
      });
    this.props.handleEditing(true);
  }

  handleClickUndo() {
    this.handleChange("referentiel", this.state.savedReferentiels);
    this.props.handleEditing(false);
  }

  handleClickValidate(event) {
    event.preventDefault();
    this.handleUpdate();
    this.props.handleEditing(false);
  }

  /**
   * Permet de mettre a jour l'etat du référentiel sans devoir le recharger
   * @param {*} accessor
   * @param {*} value
   */
  handleChange(accessor, value, callbackFunction) {
    if (accessor === "referentiel") {
      //Undo
      this.setState(
        produce((prevState) => {
          prevState.referentiels = value;
        })
      );
    } else if (accessor.includes(".")) {
      // Nested property
      var accessorSplit = accessor.split(".");

      if (accessorSplit[0] === "referentiel") {
        // Format referentiel.id.accessor
        var refId = accessorSplit[1];
        var refAccessor = accessorSplit[2];

        this.setState(
          produce((prevState) => {
            let prev = prevState.referentiels.find((el) => el.id == refId);
            prev[refAccessor] = value;
          }),
          callbackFunction
        );
      }
    }
  }

  handleUpdate() {
    //Pas d'update si pas de référentiel choisi
    if (this.state.refName) {
      this.state.referentiels.map((referentiel) => {
        //On update les référentiels un à un, uniquement s'ils ont été modifiés
        const prev = this.state.savedReferentiels.find(
          (el) => el.id == referentiel.id
        );

        if (referentiel != prev) {
          //On commence par mettre l'objet référentiel au bon format pour le back
          let referentielFormate = this.formatageReferentiel(referentiel);

          //On lance l'update
          ReferentielService.update(this.state.refName, referentielFormate);
        }
      });
    }
  }

  handleCreate() {
    //Pas de création si pas de référentiel choisi
    if (this.state.refName) {
      //Formatage pour le back
      let referentielFormate = this.formatageReferentiel(this.state.newRef);

      //On lance la création, suivie d'un rechargement des données
      ReferentielService.create(this.state.refName, referentielFormate).then(
        () =>
          this.state.service.getAll(null, true, null).then((res) =>
            this.setState(
              produce((prevState) => {
                prevState.referentiels = res.data;
              })
            )
          )
      );
    }
  }

  formatageReferentiel(referentiel) {
    let referentielFormate = {
      id: -1,
      proprietes: [],
    };

    Object.keys(referentiel).map((key) => {
      if (key == "id") referentielFormate.id = referentiel[key];
      else if (
        (this.state.refName == "Type_Clients" && key == "categorie") ||
        (this.state.refName == "ActionsTache" && key == "process") ||
        (this.state.refName == "CouleursCalculette" &&
          key == "produitInterne") ||
        (this.state.refName == "ChantierVehicules" &&
          key == "produitInterne") ||
        (this.state.refName == "ChantierMateriels" && key == "produitInterne")
      )
        referentielFormate.proprietes = [
          ...referentielFormate.proprietes,
          {
            nomPropriete: key.charAt().toUpperCase() + key.slice(1) + ".Id",
            valeur:
              referentiel[key] == null ? null : referentiel[key].id.toString(),
          },
        ];
      else if (
        this.state.refName == "Types_Panne" &&
        key == "familles_Article"
      ) {
        referentielFormate.proprietes = [
          ...referentielFormate.proprietes,
          {
            nomPropriete:
              key.charAt().toUpperCase() + key.slice(1) + ".ArrayId",
            valeur: referentiel[key].map((sousRef) => sousRef.id).toString(),
          },
        ];
      } else if (
        this.state.refName == "ChantierVehicules" &&
        key == "chantiers"
      ) {
        referentielFormate.proprietes = [
          ...referentielFormate.proprietes,
          {
            nomPropriete:
              key.charAt().toUpperCase() + key.slice(1) + ".ArrayId",
            valeur: "",
          },
        ];
      } else if (
        this.state.refName == "ChantierMateriels" &&
        key == "chantiers"
      ) {
        referentielFormate.proprietes = [
          ...referentielFormate.proprietes,
          {
            nomPropriete:
              key.charAt().toUpperCase() + key.slice(1) + ".ArrayId",
            valeur: "",
          },
        ];
      } else
        referentielFormate.proprietes = [
          ...referentielFormate.proprietes,
          {
            nomPropriete: key.charAt().toUpperCase() + key.slice(1),
            valeur:
              referentiel[key] == null ? null : referentiel[key].toString(),
          },
        ];
    });

    console.log(referentielFormate);

    return referentielFormate;
  }

  createNewRef(infosColonnes) {
    let newReferentiel = {};

    infosColonnes.map((colonne) => {
      switch (colonne.type) {
        case "text":
          newReferentiel[colonne.nom] = "";
          break;
        default:
          newReferentiel[colonne.nom] = null;
      }
    });
    newReferentiel.id = -1;
    newReferentiel.actif = true;

    return newReferentiel;
  }

  inputType(refName, propName) {
    return propName == "actif"
      ? "checkbox"
      : (refName == "Type_Clients" && propName == "categorie") ||
        (refName == "ActionsTache" && propName == "process") ||
        (refName == "CouleursCalculette" && propName == "produitInterne") ||
        (refName == "ChantierVehicules" && propName == "produitInterne") ||
        (refName == "ChantierMateriels" && propName == "produitInterne")
      ? "selectSearch"
      : refName == "Types_Panne" && propName == "familles_Article"
      ? "multipleSelect"
      : refName == "CouleursCalculette" && !propName.includes("Couleur")
      ? "decimal"
      : refName == "JoursFeries" && propName == "jour"
      ? "date"
      : "text";
  }

  inputService(refName, propName) {
    return refName == "Type_Clients" && propName == "categorie"
      ? CategorieService.getAll
      : refName == "ActionsTache" && propName == "process"
      ? ProcessTacheService.getAll
      : refName == "Types_Panne" && propName == "familles_Article"
      ? FamilleArticleService.getAll
      : (refName == "CouleursCalculette" && propName == "produitInterne") ||
        (refName == "ChantierVehicules" && propName == "produitInterne") ||
        (refName == "ChantierMateriels" && propName == "produitInterne")
      ? ProduitInterneService.getAll
      : null;
  }

  inputField(refName, propName) {
    return refName == "Type_Clients" && propName == "categorie"
      ? "type"
      : refName == "ActionsTache" && propName == "process"
      ? "designation"
      : refName == "Types_Panne" && propName == "familles_Article"
      ? "designation"
      : (refName == "CouleursCalculette" && propName == "produitInterne") ||
        (refName == "ChantierVehicules" && propName == "produitInterne") ||
        (refName == "ChantierMateriels" && propName == "produitInterne")
      ? "designation"
      : null;
  }

  inputRequired(refName, propName) {
    return refName == "ActionsTache" && propName == "process" ? false : true;
  }

  generateInfoColonnes(refListe, refName) {
    var infos = [];

    if (refListe)
      Object.keys(refListe[0]).map((key) => {
        infos = infos.concat([
          {
            nom: key,
            type: this.inputType(refName, key),
            service: this.inputService(refName, key),
            field: this.inputField(refName, key),
            required: this.inputRequired(refName, key),
          },
        ]);
      });

    return infos;
  }

  generateDialogForms() {
    return (
      <DialogForm
        classNameButton="btn btn-success"
        tooltip={"Création d'un(e) " + this.state.refNomUsuel}
        dialogTitle={"Création d'un(e) " + this.state.refNomUsuel}
        labelCancelButton="Annuler"
        labelValidateButton="Créer"
        setOpenDialog={this.setOpenDialogCreationRef}
        onValidate={() => {
          this.handleCreate(this.state.newRef);
          this.setState({ newRef: this.createNewRef(this.state.infoColonnes) });
        }}
        onClose={() => {
          this.setState({ newRef: this.createNewRef(this.state.infoColonnes) });
        }}
        body={
          <div
            id="PopupCommande"
            className={"col-lg-12 row"}
            style={{ minWidth: "1000px" }}
          >
            {this.state.infoColonnes.map((colonne) =>
              colonne.nom.includes("id") ||
              colonne.nom.includes("Id") ||
              colonne.nom.includes("dO_") ||
              colonne.nom.includes("actif") ||
              colonne.nom.includes("chantiers") ? null : (
                <Input
                  key={colonne.nom}
                  label={colonne.nom}
                  value={this.state.newRef[colonne.nom]}
                  accessor={"referentiel." + colonne.nom}
                  type={colonne.type}
                  service={colonne.service}
                  placeholder=""
                  handleChange={
                    colonne.type != "decimal"
                      ? (accessor, value) => {
                          this.setState(
                            produce((prevState) => {
                              prevState.newRef[colonne.nom] = value;
                            })
                          );
                        }
                      : null
                  }
                  handleBlur={(accessor, value) => {
                    this.setState(
                      produce((prevState) => {
                        prevState.newRef[colonne.nom] = value;
                      })
                    );
                  }}
                  showValidator={false}
                  showClearButton={false}
                  valueFieldToDisplay={colonne.field}
                  optionFieldToDisplay={colonne.field}
                  required={colonne.required}
                />
              )
            )}
          </div>
        }
      />
    );
  }

  render() {
    var i = 0;
    return (
      <>
        <div className="row">
          <div className="col-12">
            <Box
              header={
                <>
                  <div className="col-6 align-self-center py-2">
                    Centre des référentiels
                  </div>
                  <div className="col-6 text-end py-2">
                    <ButtonIcon
                      tooltip={"Création d'un(e) " + this.state.refNomUsuel}
                      icon={faPlus}
                      iconSize="sm"
                      onClick={() => {
                        this.state.openDialogCreationRefFunc();
                      }}
                      className="btn btn-success addReferentiel"
                      style={
                        this.props.editing || !this.state.editable
                          ? { display: "none" }
                          : { width: "42px" }
                      }
                    />
                    <ButtonIcon
                      tooltip="Valider"
                      icon={faCheck}
                      className="btn btn-success text-light ms-1"
                      style={
                        !this.props.editing
                          ? { display: "none" }
                          : { width: "42px" }
                      }
                      type="submit"
                      form="FormulaireReferentiels"
                    />
                    <ButtonIcon
                      tooltip="Annuler"
                      icon={faTimes}
                      className="btn btn-danger text-light ms-1"
                      onClick={this.handleClickUndo}
                      style={
                        !this.props.editing
                          ? { display: "none" }
                          : { width: "42px" }
                      }
                    />
                    <ButtonIcon
                      tooltip="Edition"
                      icon={faPen}
                      className="btn btn-secondary text-light ms-1"
                      onClick={this.handleClickEdition}
                      style={
                        this.props.editing
                          ? { display: "none" }
                          : { width: "42px" }
                      }
                    />
                  </div>
                </>
              }
              body={
                <>
                  <div className="col-3 align-self-center py-2">
                    <div
                      id="referentiel"
                      style={{
                        flexShrink: "0",
                      }}
                    >
                      Référentiel :
                    </div>
                    <Input
                      type="selectSearch"
                      value=""
                      accessor="refName"
                      valueFieldToDisplay={"nomUsuel"}
                      optionFieldToDisplay={"nomUsuel"}
                      showValidator={false}
                      showClearButton={false}
                      disabled={this.props.editing}
                      handleChange={(acc, value) => {
                        if (value) {
                          value.service
                            .getAll({ isActive: null }, true)
                            .then((res) =>
                              this.setState(
                                {
                                  service: value.service,
                                  referentiels: res.data,
                                  refName: value.referentiel,
                                  refNomUsuel: value.nomUsuel,
                                  infoColonnes: this.generateInfoColonnes(
                                    res.data,
                                    value.referentiel
                                  ),
                                  editable: value.editable,
                                  loading: true,
                                },
                                () => {
                                  this.setState({
                                    newRef: this.createNewRef(
                                      this.state.infoColonnes
                                    ),
                                  });
                                }
                              )
                            );
                        }
                      }}
                      functionAppliedToGroupByName={(option) => option.groupe}
                      options={[
                        [
                          {
                            groupe: "Transverse",
                            nomUsuel: "Civilité",
                            referentiel: "Civilites",
                            editable: false,
                            id: 0,
                            service: CiviliteService,
                          },

                          {
                            groupe: "Transverse",
                            nomUsuel: "Famille d'affaires",
                            referentiel: "Famille_Affaires",
                            editable: true,
                            service: FamilleAffaireService,
                            id: 1,
                          },
                          {
                            groupe: "Transverse",
                            nomUsuel: "Type de matériel",
                            referentiel: "Familles_Articles",
                            editable: false,
                            service: FamilleArticleService,
                            id: 2,
                          },
                          {
                            groupe: "Transverse",
                            nomUsuel: "Mission",
                            referentiel: "Missions",
                            editable: false,
                            service: MissionService,
                            id: 3,
                          },
                          {
                            groupe: "Transverse",
                            nomUsuel: "Pack",
                            referentiel: "Packs",
                            editable: true,
                            id: 4,
                            service: PacksService,
                          },
                          {
                            groupe: "Transverse",
                            nomUsuel: "Produit interne",
                            referentiel: "Produit_Internes",
                            editable: false,
                            id: 5,
                            service: ProduitInterneService,
                          },
                          {
                            groupe: "Transverse",
                            nomUsuel: "Type d'ouvrage",
                            referentiel: "Type_Ouvrages",
                            editable: true,
                            id: 6,
                            service: TypeOuvrageService,
                          },
                          {
                            groupe: "Transverse",
                            nomUsuel: "Type de note de frais",
                            referentiel: "TypeNoteDeFrais",
                            editable: false,
                            id: 7,
                            service: NoteDeFraisTypeService,
                          },
                          {
                            groupe: "Transverse",
                            nomUsuel: "Couleurs de calculette",
                            referentiel: "CouleursCalculette",
                            editable: true,
                            id: 8,
                            service: CouleurCalculetteService,
                          },
                        ],
                        [
                          {
                            groupe: "Affaire",
                            nomUsuel: "Interne/Externe",
                            referentiel: "InterneExterne",
                            editable: true,
                            service: InterneExterneService,
                            id: 10,
                          },
                          {
                            groupe: "Affaire",
                            nomUsuel: "Travail chantier",
                            referentiel: "JourNuit",
                            editable: true,
                            service: JourNuitService,
                            id: 11,
                          },
                        ],
                        [
                          {
                            groupe: "Chantier",
                            nomUsuel: "Motif annulation chantier",
                            referentiel: "ChantierMotifsAnnulation",
                            editable: true,
                            service: MotifsChantierAnnulationService,
                            id: 71,
                          },
                          {
                            groupe: "Chantier",
                            nomUsuel: "Vehicules",
                            referentiel: "ChantierVehicules",
                            editable: true,
                            service: VehiculeService,
                            id: 72,
                          },
                          {
                            groupe: "Chantier",
                            nomUsuel: "Matériels chantier",
                            referentiel: "ChantierMateriels",
                            editable: true,
                            service: MaterielsChantierService,
                            id: 73,
                          },
                          {
                            groupe: "Chantier",
                            nomUsuel: "Jours fériés",
                            referentiel: "JoursFeries",
                            editable: true,
                            service: JoursFeriesService,
                            id: 74,
                          },
                        ],
                        [
                          {
                            groupe: "Client",
                            nomUsuel: "Catégorie",
                            referentiel: "Categories",
                            editable: true,
                            service: CategorieService,
                            id: 20,
                          },
                          {
                            groupe: "Client",
                            nomUsuel: "Qualification",
                            referentiel: "Etat_Clients",
                            editable: false,
                            service: EtatClientService,
                            id: 21,
                          },
                          {
                            groupe: "Client",
                            nomUsuel: "Langue",
                            referentiel: "Langues",
                            editable: false,
                            id: 22,
                            service: LangueService,
                          },
                          {
                            groupe: "Client",
                            nomUsuel: "Origine",
                            referentiel: "Origines",
                            editable: true,
                            id: 23,
                            service: OrigineService,
                          },
                          {
                            groupe: "Client",
                            nomUsuel: "Pays",
                            referentiel: "Pays",
                            editable: false,
                            id: 24,
                            service: PaysService,
                          },
                          {
                            groupe: "Client",
                            nomUsuel: "Compétence géotechnique",
                            referentiel: "Referent_Geotechniques",
                            editable: true,
                            id: 25,
                            service: ReferentGeotechniqueService,
                          },
                          {
                            groupe: "Client",
                            nomUsuel: "Région",
                            referentiel: "Regions",
                            editable: false,
                            id: 26,
                            service: RegionService,
                          },
                          {
                            groupe: "Client",
                            nomUsuel: "Taille entreprise",
                            referentiel: "TailleEntreprises",
                            editable: true,
                            id: 27,
                            service: TailleEntrepriseService,
                          },
                          {
                            groupe: "Client",
                            nomUsuel: "Type client",
                            referentiel: "Type_Clients",
                            editable: true,
                            id: 28,
                            service: TypeClientService,
                          },
                          {
                            groupe: "Client",
                            nomUsuel: "Type ligne SNCF",
                            referentiel: "Type_Lignes",
                            editable: true,
                            id: 29,
                            service: TypeLigneService,
                          },
                        ],
                        [
                          {
                            groupe: "Commande",
                            nomUsuel: "Statut",
                            referentiel: "StatutsCommande",
                            editable: false,
                            id: 30,
                            service: StatutsCommandeService,
                          },
                        ],
                        [
                          {
                            groupe: "Consultation",
                            nomUsuel: "Motif de non réponse",
                            referentiel: "Motifs_Non_Reponse",
                            editable: true,
                            id: 40,
                            service: MotifNonReponseService,
                          },
                          {
                            groupe: "Consultation",
                            nomUsuel: "Source",
                            referentiel: "Sources",
                            editable: true,
                            id: 41,
                            service: SourceService,
                          },
                          {
                            groupe: "Consultation",
                            nomUsuel: "Statut",
                            referentiel: "StatutsConsultation",
                            editable: false,
                            id: 42,
                            service: StatutsConsultationService,
                          },
                        ],
                        [
                          {
                            groupe: "Contact",
                            nomUsuel: "Fonction",
                            referentiel: "Fonctions",
                            editable: true,
                            id: 50,
                            service: FonctionService,
                          },
                          {
                            groupe: "Contact",
                            nomUsuel: "Service",
                            referentiel: "ServiceContacts",
                            editable: true,
                            id: 51,
                            service: ServiceContactService,
                          },
                        ],
                        [
                          {
                            groupe: "Contrat",
                            nomUsuel: "Catégorie",
                            referentiel: "ContratCategories",
                            editable: false,
                            id: 60,
                            service: ContratCategorieService,
                          },
                          {
                            groupe: "Contrat",
                            nomUsuel: "Durée",
                            referentiel: "ContratDurees",
                            editable: true,
                            id: 61,
                            service: ContratDureeService,
                          },
                          {
                            groupe: "Contrat",
                            nomUsuel: "Financeur",
                            referentiel: "ContratFinanceurs",
                            editable: false,
                            id: 62,
                            service: ContratFinanceurService,
                          },
                          {
                            groupe: "Contrat",
                            nomUsuel: "Périodicité",
                            referentiel: "ContratPeriodicites",
                            editable: false,
                            id: 63,
                            service: ContratPeriodiciteService,
                          },
                          {
                            groupe: "Contrat",
                            nomUsuel: "Sortie",
                            referentiel: "ContratSorties",
                            editable: true,
                            id: 64,
                            service: ContratSortieService,
                          },
                        ],
                        [
                          {
                            groupe: "Devis",
                            nomUsuel: "Raison perte",
                            referentiel: "Raisons_Perte",
                            editable: true,
                            id: 70,
                            service: RaisonsPerteService,
                          },
                          {
                            groupe: "Devis",
                            nomUsuel: "Résultat",
                            referentiel: "Resultats",
                            editable: false,
                            id: 71,
                            service: ResultatService,
                          },
                          {
                            groupe: "Devis",
                            nomUsuel: "Réussite",
                            referentiel: "Reussites",
                            editable: false,
                            id: 72,
                            service: ReussiteService,
                          },
                        ],
                        [
                          {
                            groupe: "Fiche de suivi",
                            nomUsuel: "Etat avancement",
                            referentiel: "Etat_Avancements",
                            editable: false,
                            id: 80,
                            service: FicheDeSuiviEtatAvancementService,
                          },
                          {
                            groupe: "Fiche de suivi",
                            nomUsuel: "Fournisseur",
                            referentiel: "Fournisseurs",
                            editable: true,
                            id: 81,
                            service: FicheDeSuiviFournisseurService,
                          },
                          {
                            groupe: "Fiche de suivi",
                            nomUsuel: "Provenance",
                            referentiel: "FicheDeSuiviProvenances",
                            editable: true,
                            id: 87,
                            service: FicheDeSuiviProvenanceService,
                          },
                          {
                            groupe: "Fiche de suivi",
                            nomUsuel: "Statut",
                            referentiel: "StatutsFicheDeSuivi",
                            editable: false,
                            id: 82,
                            service: FicheDeSuiviStatutsService,
                          },
                          {
                            groupe: "Fiche de suivi",
                            nomUsuel: "Sujet",
                            referentiel: "FicheDeSuiviTypes",
                            editable: true,
                            id: 86,
                            service: FicheDeSuiviTypeService,
                          },
                          {
                            groupe: "Fiche de suivi",
                            nomUsuel: "Type de panne",
                            referentiel: "Types_Panne",
                            editable: true,
                            id: 83,
                            service: FicheDeSuiviTypePanneService,
                          },
                          {
                            groupe: "Fiche de suivi",
                            nomUsuel: "Utilisation",
                            referentiel: "Utilisations",
                            editable: true,
                            id: 85,
                            service: FicheDeSuiviUtilisationService,
                          },
                        ],
                        [
                          {
                            groupe: "Personnel",
                            nomUsuel: "Attribution spécifique",
                            referentiel: "Attribution_Specifiques",
                            editable: true,
                            id: 90,
                            service: PersonnelAttributionSpecifiqueService,
                          },
                          {
                            groupe: "Personnel",
                            nomUsuel: "Coefficient",
                            referentiel: "Coefficients",
                            editable: true,
                            id: 91,
                            service: PersonnelCoefficientService,
                          },
                          {
                            groupe: "Personnel",
                            nomUsuel: "Compétence",
                            referentiel: "HeurePersonnelCompetences",
                            editable: true,
                            id: 107,
                            service: HeurePersonnelCompetenceService,
                          },
                          {
                            groupe: "Personnel",
                            nomUsuel: "Diplôme",
                            referentiel: "Diplomes",
                            editable: true,
                            id: 92,
                            service: PersonnelDiplomeService,
                          },
                          {
                            groupe: "Personnel",
                            nomUsuel: "Fonction informatique",
                            referentiel: "Fonctions_Informatique",
                            editable: false,
                            id: 93,
                            service: PersonnelFonctionInformatiqueService,
                          },
                          {
                            groupe: "Personnel",
                            nomUsuel: "Fonction personnel",
                            referentiel: "Fonctions_Personnel",
                            editable: true,
                            id: 94,
                            service: PersonnelFonctionService,
                          },
                          {
                            groupe: "Personnel",
                            nomUsuel: "Lien de parenté",
                            referentiel: "LiensParente",
                            editable: true,
                            id: 95,
                            service: PersonnelLienParenteService,
                          },
                          {
                            groupe: "Personnel",
                            nomUsuel: "Mode de télétravail",
                            referentiel: "Modes_Teletravail",
                            editable: true,
                            id: 96,
                            service: PersonnelModesTeletravailService,
                          },
                          {
                            groupe: "Personnel",
                            nomUsuel: "Pôle",
                            referentiel: "Poles",
                            editable: true,
                            id: 97,
                            service: PersonnelPoleService,
                          },
                          {
                            groupe: "Personnel",
                            nomUsuel: "Position",
                            referentiel: "Positions",
                            editable: false,
                            id: 98,
                            service: PersonnelPositionService,
                          },

                          {
                            groupe: "Personnel",
                            nomUsuel: "Détails heure",
                            referentiel: "RubriquesHeurePersonnel",
                            editable: false,
                            id: 100,
                            service: HeurePersonnelRubriqueService,
                          },
                          {
                            groupe: "Personnel",
                            nomUsuel: "Service",
                            referentiel: "Services_Personnel",
                            editable: true,
                            id: 101,
                            service: PersonnelServiceService,
                          },
                          {
                            groupe: "Personnel",
                            nomUsuel: "Situation familiale",
                            referentiel: "SituationsFamiliales",
                            editable: true,
                            id: 102,
                            service: PersonnelSituationFamilialeService,
                          },

                          {
                            groupe: "Personnel",
                            nomUsuel: "Statut Personnel",
                            referentiel: "Statut_Personnels",
                            editable: false,
                            id: 104,
                            service: PersonnelStatutsService,
                          },
                          {
                            groupe: "Personnel",
                            nomUsuel: "Syntech",
                            referentiel: "Syntech",
                            editable: false,
                            id: 105,
                            service: PersonnelSyntechService,
                          },
                          {
                            groupe: "Personnel",
                            nomUsuel: "Type de contrat",
                            referentiel: "Types_Contrat",
                            editable: true,
                            id: 106,
                            service: PersonnelTypeContratService,
                          },
                        ],
                        [
                          {
                            groupe: "Tache",
                            nomUsuel: "Action",
                            referentiel: "ActionsTache",
                            editable: true,
                            id: 110,
                            service: ActionsTacheService,
                          },
                          {
                            groupe: "Tache",
                            nomUsuel: "Priorité",
                            referentiel: "PrioritesTache",
                            editable: true,
                            id: 111,
                            service: PrioritesTacheService,
                          },
                          {
                            groupe: "Tache",
                            nomUsuel: "Process",
                            referentiel: "ProcessTache",
                            editable: false,
                            id: 112,
                            service: ProcessTacheService,
                          },
                          {
                            groupe: "Tache",
                            nomUsuel: "Statut",
                            referentiel: "StatutsTache",
                            editable: false,
                            id: 113,
                            service: StatutsTacheService,
                          },
                          {
                            groupe: "Tache",
                            nomUsuel: "Type",
                            referentiel: "TypesTache",
                            editable: false,
                            id: 114,
                            service: TypesTacheService,
                          },
                        ],
                      ].map((refGroup) =>
                        refGroup
                          .map((refInfo) => {
                            if (!refInfo.editable)
                              refInfo.nomUsuel =
                                refInfo.nomUsuel + " \u{1F512}";

                            return refInfo;
                          })
                          .sort((a, b) => (a.nomUsuel > b.nomUsuel ? 1 : -1))
                      )}
                    />
                  </div>
                  <FormWithValidation
                    id="FormulaireReferentiels"
                    onSubmit={this.handleClickValidate}
                  >
                    <table className="table table-sm table-striped m-0">
                      <thead>
                        <tr>
                          {this.state.loading
                            ? this.state.infoColonnes.map((colonnne) =>
                                colonnne.nom.includes("dO_") ||
                                colonnne.nom.includes("typePanneId") ||
                                colonnne.nom.includes("familleArticleId") ||
                                colonnne.nom.includes("chantiers") ? null : (
                                  <th
                                    className="ps-2 position-sticky bg-light"
                                    key={colonnne.nom}
                                  >
                                    {colonnne.nom}
                                  </th>
                                )
                              )
                            : null}
                        </tr>
                      </thead>
                      <tbody>
                        {this.state.loading
                          ? this.state.referentiels.map((referentiel) => {
                              ++i;

                              return (
                                <LigneRefSimple
                                  key={referentiel.id + "." + i}
                                  refName={this.state.refName}
                                  referentiel={referentiel}
                                  infoColonnes={this.state.infoColonnes}
                                  editable={this.state.editable}
                                  editing={this.props.editing}
                                  handleChange={this.handleChange}
                                />
                              );
                            })
                          : null}
                      </tbody>
                    </table>
                  </FormWithValidation>
                </>
              }
            />
            {this.generateDialogForms()}
          </div>
        </div>
      </>
    );
  }
}

ReferentielSimple.propTypes = {
  stateFieldNameToUpdate: PropTypes.string,
  handleChange: PropTypes.func,
  history: PropTypes.object,
  service: PropTypes.func,
};

export { ReferentielSimple };
