import React from "react";
import { BlocChantierColonne } from "./BlocChantierColonne";

const ChantierPage = (props) => {
  let id = props.match.params.id;

  let chantierHtml = <BlocChantierColonne chantierId={id} />;

  return (
    <>
      <div className="shadow p-3 m-4 bg-body rounded bg-light">
        {chantierHtml}
      </div>
    </>
  );
};

export { ChantierPage };
