// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* .ArticleStock {
  overflow-x: auto;
}

.ArticleStock table {
  margin-top: 10px;
  width: 100%;
  white-space: nowrap;
}

.ArticleStock td:empty {
  visibility: hidden;
}

.ArticleStock th, .ArticleStock td {
  padding: 3px;
  border-width: 1px;

  min-width:120px;
}

.ArticleStock th {
  text-align: center;  
  border-color: #ddd;
}

.ArticleStock td {
  text-align: left;  
  border-color: #ddd;
} */`, "",{"version":3,"sources":["webpack://./src/FicheArticle/Stock/Stock.css"],"names":[],"mappings":"AAAA;;;;;;;;;;;;;;;;;;;;;;;;;;;;;GA6BG","sourcesContent":["/* .ArticleStock {\n  overflow-x: auto;\n}\n\n.ArticleStock table {\n  margin-top: 10px;\n  width: 100%;\n  white-space: nowrap;\n}\n\n.ArticleStock td:empty {\n  visibility: hidden;\n}\n\n.ArticleStock th, .ArticleStock td {\n  padding: 3px;\n  border-width: 1px;\n\n  min-width:120px;\n}\n\n.ArticleStock th {\n  text-align: center;  \n  border-color: #ddd;\n}\n\n.ArticleStock td {\n  text-align: left;  \n  border-color: #ddd;\n} */"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
