import React from "react";

import { Input } from "_components/Input";

// import { faTrash } from "@fortawesome/free-solid-svg-icons";
// import { ButtonIcon } from "_components";

function LigneSuivi(props) {
  return (
    <>
      <tr className="LigneSuivi" key={props.suivi.id}>
        <td>
          <Input
            accessor={"suivi.".concat(props.suivi.id, ".date")}
            value={props.suivi.date}
            type="date"
            placeholder=""
            handleChange={props.handleChange}
            showValidator={false}
            showClearButton={false}
            disabled={!props.editing}
          />
        </td>
        <td>
          <Input
            accessor={"suivi.".concat(props.suivi.id, ".etalonnePar")}
            value={props.suivi.etalonnePar}
            type="text"
            placeholder=""
            handleChange={props.handleChange}
            showValidator={false}
            showClearButton={false}
            disabled={!props.editing}
          />
        </td>
        <td></td>
        <td></td>
        {props.materiel.produitInterne?.designation === "GRZ" ? "" : <td></td>}
        {/* <td>
          <ButtonIcon
            smallText=""
            icon={faTrash}
            iconSize="sm"
            onClick={() => {
              props?.deleteBlocSuivi(props.suivi.id);
            }}
            className="btn btn-danger deleteBlocSuivi"
            style={
              !props.editing
                ? { display: "none" }
                : { float: "right", marginRight: "10px" }
            }
          />
        </td> */}
      </tr>
      {props.suivi?.lignes?.map((ligne) => (
        <tr key={ligne.id}>
          <td></td>
          <td></td>
          <td>
            <Input
              accessor={"ligne.".concat(ligne.id, ".typeElement")}
              value={ligne.typeElement}
              type="text"
              placeholder=""
              handleChange={props.handleChange}
              showValidator={false}
              showClearButton={false}
              disabled={!props.editing}
            />
          </td>
          <td>
            <Input
              accessor={"ligne.".concat(ligne.id, ".numSerieElement")}
              value={ligne.numSerieElement}
              type="text"
              placeholder=""
              handleChange={props.handleChange}
              showValidator={false}
              showClearButton={false}
              disabled={!props.editing}
            />
          </td>
          {props.materiel.produitInterne?.designation === "GRZ" ? (
            <td>
              <Input
                accessor={"ligne.".concat(ligne.id, ".erreurFidelite")}
                value={ligne.erreurFidelite}
                type="decimal"
                placeholder=""
                numberOfDecimals={2}
                handleBlur={props.handleChange}
                showValidator={false}
                showClearButton={false}
                disabled={!props.editing}
              />
            </td>
          ) : (
            <>
              <td>
                <Input
                  accessor={"ligne.".concat(ligne.id, ".erreurSensibilite")}
                  value={ligne.erreurSensibilite}
                  type="decimal"
                  placeholder=""
                  numberOfDecimals={2}
                  handleBlur={props.handleChange}
                  showValidator={false}
                  showClearButton={false}
                  disabled={!props.editing}
                />
              </td>
              <td>
                <Input
                  accessor={"ligne.".concat(ligne.id, ".erreurLinearite")}
                  value={ligne.erreurLinearite}
                  type="decimal"
                  placeholder=""
                  numberOfDecimals={2}
                  handleBlur={props.handleChange}
                  showValidator={false}
                  showClearButton={false}
                  disabled={!props.editing}
                />
              </td>
            </>
          )}
        </tr>
      ))}
    </>
  );
}

export { LigneSuivi };
