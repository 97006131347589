/**
 * Fonction permettant de remplacer une chaîne de caractères null ou undefined
 * @param {string} string, la chaîne à remplacer si null ou undefined
 * @param {string} alternatif, chaine de remplacement par défaut "NON RENSEIGNÉ"
 * @returns
 */
function IfNullToText(string, alternatif = "NON RENSEIGNÉ") {
  if (string && string != undefined && string != "") {
    return string;
  }
  return alternatif;
}

/**
 * Teste si la chaine de caractère fournie en paramètre est nulle, vide ou constituée uniquement de caractères vides.
 * @param {string} str, la chaîne à tester
 * @returns booléen
 */
function isNullOrWhiteSpaces(str) {
  return str == null || str.trim() === "";
}

function CompareStrings(a, b) {
  // Assuming you want case-insensitive comparison
  if (a && b) {
    var al = a.toLowerCase();
    var bl = b.toLowerCase();

    return al < bl ? -1 : al > bl ? 1 : 0;
  } else {
    //return a ? a : b;
    return a ? (b ? -1 : 0) : 1;
  }
}

function makeEmailClickable(email) {
  if (email) {
    return (
      <a
        href={"mailto:" + email}
        className="mailto fixWidthHover"
        data-text={email}
      >
        {email}
      </a>
    );
  }
  return null;
}

function IsoToEnglishDate(date) {
  return FrenchToEnglishDate(ToLocaleDateString(date));
}

function ToLocaleDateString(dateString, format = "fr-FR") {
  if (dateString == null) {
    return null;
  }

  return new Date(dateString).toLocaleDateString(format, {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
  });
}

function ToLocaleTimeString(dateString, format = "fr-FR") {
  if (dateString == null) {
    return null;
  }

  return new Date(
    dateString.endsWith("Z") ? dateString : dateString + "Z"
  ).toLocaleTimeString(format, {
    hour: "2-digit",
    minute: "2-digit",
  });
}

function FrenchToEnglishDate(date) {
  let tab = date.split("/");
  if (tab.length === 3) {
    let year = tab[2];
    if (year.length < 4) {
      let str = "";
      for (let i = tab[2].length; i < 4; i++) {
        str += "0";
      }
      year = str + year;
    }
    let res = year + "-" + tab[1] + "-" + tab[0];
    return res;
  }
  return date;
}

function EnglishToFrenchDate(date) {
  let tab = date.split("-");
  if (tab.length === 3) {
    let res = tab[2] + "/" + tab[1] + "/" + tab[0];
    return res;
  }
  return date;
}

export {
  IfNullToText,
  isNullOrWhiteSpaces,
  CompareStrings,
  makeEmailClickable,
  ToLocaleDateString,
  FrenchToEnglishDate,
  EnglishToFrenchDate,
  IsoToEnglishDate,
  ToLocaleTimeString,
};
