// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.TableModules {
  border-color: #ddd;
  border-width: 1px;
  border-style: solid;
  table-layout: fixed;
  width: 100%;
}

.TableModules thead, .TableModules tbody {
  border-style: inherit;
  border-width: inherit;
}

.TableModules tr {
  border-style: inherit;
  border-width: inherit;
}

.TableModules th, .TableModules td {
  border-color: #ddd;
  border-style: inherit;
  border-width: inherit;
}

.TableModules tr th:last-child {
  width: 190px;
}

.TableModules input:disabled {
  background-color: gray;
}`, "",{"version":3,"sources":["webpack://./src/AjoutLicences/AjoutLicence.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,iBAAiB;EACjB,mBAAmB;EACnB,mBAAmB;EACnB,WAAW;AACb;;AAEA;EACE,qBAAqB;EACrB,qBAAqB;AACvB;;AAEA;EACE,qBAAqB;EACrB,qBAAqB;AACvB;;AAEA;EACE,kBAAkB;EAClB,qBAAqB;EACrB,qBAAqB;AACvB;;AAEA;EACE,YAAY;AACd;;AAEA;EACE,sBAAsB;AACxB","sourcesContent":[".TableModules {\n  border-color: #ddd;\n  border-width: 1px;\n  border-style: solid;\n  table-layout: fixed;\n  width: 100%;\n}\n\n.TableModules thead, .TableModules tbody {\n  border-style: inherit;\n  border-width: inherit;\n}\n\n.TableModules tr {\n  border-style: inherit;\n  border-width: inherit;\n}\n\n.TableModules th, .TableModules td {\n  border-color: #ddd;\n  border-style: inherit;\n  border-width: inherit;\n}\n\n.TableModules tr th:last-child {\n  width: 190px;\n}\n\n.TableModules input:disabled {\n  background-color: gray;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
