import http from "http-common";

const CalculetteModeleService = class {

  /**
   * Fonction permettant de récuperer tous les modèles de devis avec une possibilité de filtre via params, regroupés en liste par produit interne.
   * @param {*} params, paramètres à utiliser pour la requête (page, nombre d'éléments par page et filtres sur le nom)
   * @returns une liste de liste des modèles de devis respectant les critères de params et le nombre de page pour ces critères, groupés par produit interne.
   */
  static getAllGrouped(params) {
    return http.get("/calculette/modele/grouped", { params });
  }


    /**
   * Fonction permettant de récuperer tous les modèles de devis avec une possibilité de filtre via params, regroupés en liste par produit interne.
   * @param {*} params, paramètres à utiliser pour la requête (page, nombre d'éléments par page et filtres sur le nom)
   * @returns une liste de liste des modèles de devis respectant les critères de params et le nombre de page pour ces critères, groupés par produit interne.
   */
    static getAll(params) {
      return http.get("/calculette/modele", { params });
    }
  /**
   * Fonction permettant de créer un nouveau modèle de devis
   * @param {*} devis, le modèle de devis à ajouter
   * @returns le modèle de devis créé avec son id
   */
  static post(devis) {
    return http.post("/calculette/modele", devis);
  }

  /**
   * Fonction permettant de récuperer un modèle de devis via son id
   * @param {*} id, id du modèle de devis à récupérer
   * @returns le modèle de devis souhaité
   */
  static getById(id) {
    return http.get("/calculette/modele/" + id);
  }


};

export { CalculetteModeleService };
