import http from "http-common";

const DeviseService = class {
  /**
   * Fonction permettant de récuperer tous les devises avec une possibilité de filtre via params
   * @returns la liste de tous les devises
   */
  static getAll() {
    return http.get("/devises");
  }

  /**
   * Fonction permettant de récuperer un devise via son id
   * @param {*} id, id de du devise à récupérer
   * @returns le devise souhaité
   */
  static getById(id) {
    return http.get("/devises/" + id);
  }

  static getByValue(value) {
    return http.get("/devises/get/" + value);
  }
};

export { DeviseService };
