import http from "http-common";

const ArticleASuivreDansBFAService = class {
  /**
   * Fonction permettant de récuperer toutes les actions en cours
   * @returns la liste de toutes les actions en cours
   */
  static getAll() {
    return http.get("/ArticleASuivreDansBFA");
  }

  /**
   * Fonction permettant de récupérer une action en cours via son id
   * @param {*} id, id de la action en cours à récupérer
   * @returns la action en cours souhaitée
   */
  static getById(id) {
    return http.get("/ArticleASuivreDansBFA/" + id);
  }
};

export { ArticleASuivreDansBFAService };
