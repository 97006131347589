import React from "react";
import { PropTypes } from "prop-types";
import { App } from "App";

import { ToLocaleDateString, IfNullToText } from "_utils";
import {
  PersonnelService,
  FamilleAffaireService,
  AffaireService,
  ClientService,
} from "_services";
import { BoxInformationsInline, WarningBar } from "_components";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationTriangle } from "@fortawesome/free-solid-svg-icons";

class InformationsCommande extends React.PureComponent {
  constructor(props) {
    super(props);

    this.getAllAffaireProxy = this.getAllAffaireProxy.bind(this);
  }

  getAllAffaireProxy(search) {
    return AffaireService.getAll({
      size: 25,
      global: search,
      soldee: false,
      nom_Client: this.props.commande?.client?.nom_Client,
    });
  }

  render() {
    const right = App.RightsGuard?.current?.hasRight("Devis", "General");
    if (right == "RW" || right == "RO") {
      const commande = this.props.commande;
      const informationsGeneral = [
        {
          label: "N° commande",
          accessor: "reference",
          required: true,
          colSize: 3,
        },

        {
          label: "Statut",
          accessor: "statut",
          functionAppliedToValue: (value) => {
            return value ? value.designation : null;
          },
          required: true,
          colSize: 3,
        },
        {
          label: "Document Sage associé",
          accessor: "doPieceDocSage",
          colSize: 3,
          functionAppliedToValue: (value) => {
            return (
              <div className="d-flex justify-content-start align-items-center">
                {value ?? "-"}
                {value ? null : (
                  <FontAwesomeIcon
                    className="text-danger"
                    icon={faExclamationTriangle}
                  />
                )}
              </div>
            );
          },
        },
        {
          label: "Client",
          accessor: "client",
          colSize: 3,
          required: true,
          functionAppliedToValue: (value) => {
            return (
              <div className="d-flex justify-content-start align-items-center">
                {value ? (
                  <a
                    href={"/clients/" + value.id}
                    target="_blank"
                    data-text={value.nom_Client}
                    className="fixWidthHover"
                  >
                    {value.nom_Client}
                  </a>
                ) : (
                  "-"
                )}
              </div>
            );
          },
        },

        {
          label: "Famille d'affaires",
          accessor: "famille_Affaire",
          functionAppliedToValue: (value) => {
            return value ? value.designation : null;
          },
          required: this.props.commande.produit_Interne?.isPresta ?? false,
          type: "selectSearch",
          service: FamilleAffaireService.getAll,
          optionFieldToDisplay: "designation",
          valueFieldToDisplay: "designation",
          colSize: 3,
        },
        {
          label: "Produit interne",
          accessor: "produit_Interne",
          functionAppliedToValue: (value) => {
            return value ? value.designation : null;
          },
          required: true,
          optionFieldToDisplay: "designation",
          valueFieldToDisplay: "designation",
          colSize: 3,
        },
        {
          label: "Devis",
          accessor: "devis",
          functionAppliedToValue: (value) => (
            <>
              <div className="d-flex justify-content-start align-items-center">
                {value ? (
                  <a
                    href={"/devis/" + value.id}
                    target="_blank"
                    data-text={value.reference + " - " + value.nom_Devis}
                    className="fixWidthHover"
                  >
                    {value.reference + " - " + value.nom_Devis}
                  </a>
                ) : (
                  "-"
                )}
              </div>
            </>
          ),
          colSize: 3,
        },
        {
          label: "Mission",
          accessor: "type_Mission",
          functionAppliedToValue: (value) => {
            return value ? value.designation : null;
          },
          required: true,
          optionFieldToDisplay: "designation",
          valueFieldToDisplay: "designation",
          colSize: 3,
        },
        {
          label: "International",
          accessor: "international",
          type: "checkbox",
          functionAppliedToValue: (value) => {
            return value ? "Oui" : "Non";
          },
          colSize: 3,
        },
        {
          label: "Montant HT",
          accessor: "prixVenteTotal",
          colSize: 3,
        },
        {
          label: "Montant TVA",
          accessor: "montantTVATotal",
          colSize: 3,
        },
        {
          label: "Montant TTC",
          accessor: "prixVenteTTCTotal",
          colSize: 3,
        },
      ];

      const informationsFinancieres = [
        {
          label: "Acompte ?",
          accessor: "acompte",
          type: "checkbox",
          colSize: 6,
          functionAppliedToValue: (value) => {
            return value ? "Oui" : "Non";
          },
        },

        {
          label: "Montant acompte à recevoir",
          accessor: "montant_Acompte_A_Recevoir",
          type: "decimal",
          colSize: 6,
          handleBlur: this.props.handleChange,
        },
        {
          label: "Montant acompte reçu",
          accessor: "montant_Acompte_Recu",
          type: "decimal",
          colSize: 6,
          handleBlur: this.props.handleChange,
        },
        {
          label: "Date réception acompte",
          accessor: "date_Acompte",
          type: "date",
          colSize: 6,
        },
      ];
      const informationsClient = [
        {
          label: "Num Arc Client",
          accessor: "num_Arc_Client",
          type: "text",
          colSize: 3,
        },
        {
          label: "Num Marché",
          accessor: "num_Marche",
          type: "text",
          colSize: 3,
        },
        {
          label: "Contact achats",
          accessor: "emetteur",
          type: "text",
          colSize: 3,
        },
        {
          label: "Date Arc",
          accessor: "date_Arc",
          type: "date",
          colSize: 3,
        },
      ];
      const informationsCommande = [
        {
          label: "Nom commande",
          accessor: "nom_Commande",
          type: "text",
          required: true,
          colSize: 4,
        },
        {
          label: "Affaire",
          accessor: "affaire",
          type: "selectSearch",
          service: this.getAllAffaireProxy,
          optionFieldToDisplay: [
            "produitInterne.designation",
            "reference",
            "nomAffaire",
          ],
          valueFieldToDisplay: ["reference", "nomAffaire"],
          colSize: 4,
          functionAppliedToValue: (value) => (
            <>
              <div className="d-flex justify-content-start align-items-center">
                {value ? (
                  <a
                    href={"/affaires/" + value.id}
                    target="_blank"
                    data-text={value.reference + " - " + value.nomAffaire}
                    className="fixWidthHover"
                  >
                    {value.reference + " - " + value.nomAffaire}
                  </a>
                ) : (
                  "-"
                )}
                {value ? null : (
                  <FontAwesomeIcon
                    className="text-danger"
                    icon={faExclamationTriangle}
                  />
                )}
              </div>
            </>
          ),
        },
        {
          label: "Date AR Commande",
          accessor: "date_Create",
          colSize: 4,
          functionAppliedToValue: (value) => {
            return value ? ToLocaleDateString(value) : "-";
          },
        },
        {
          label: "Chargé d'affaire",
          accessor: "charge_Affaire",
          functionAppliedToValue: (value) => {
            return value ? value.nom + " " + value.prenom : "-";
          },
          type: "selectSearch",
          service: PersonnelService.getForSelectSearch,
          optionFieldToDisplay: ["nom", "prenom"],
          valueFieldToDisplay: ["nom", "prenom"],
          colSize: 4,
        },
        {
          label: "Assistant(e)",
          accessor: "assistante",
          functionAppliedToValue: (value) => {
            return value ? value.nom + " " + value.prenom : "-";
          },
          type: "selectSearch",
          service: PersonnelService.getForSelectSearch,
          optionFieldToDisplay: ["nom", "prenom"],
          valueFieldToDisplay: ["nom", "prenom"],
          colSize: 4,
        },
        {
          label: "Maitre d'Oeuvre",
          accessor: "maitreOeuvre",
          type: "selectSearch",
          optionFieldToDisplay: "nom_Client",
          valueFieldToDisplay: "nom_Client",
          service: ClientService.getForSelectSearch,
          functionAppliedToValue: (value) => {
            return (
              <div className="d-flex justify-content-start align-items-center">
                {value ? (
                  <a
                    href={"/clients/" + value.id}
                    target="_blank"
                    data-text={value.nom_Client}
                    className="fixWidthHover"
                  >
                    {value.nom_Client}
                  </a>
                ) : (
                  "-"
                )}
              </div>
            );
          },
          colSize: 4,
        },
        {
          label: "Maitre d'Ouvrage",
          accessor: "maitreOuvrage",
          type: "selectSearch",
          optionFieldToDisplay: "nom_Client",
          valueFieldToDisplay: "nom_Client",
          service: ClientService.getForSelectSearch,
          functionAppliedToValue: (value) => {
            return (
              <div className="d-flex justify-content-start align-items-center">
                {value ? (
                  <a
                    href={"/clients/" + value.id}
                    target="_blank"
                    data-text={value.nom_Client}
                    className="fixWidthHover"
                  >
                    {value.nom_Client}
                  </a>
                ) : (
                  "-"
                )}
              </div>
            );
          },
          colSize: 4,
        },
        {
          label: "Entreprise",
          accessor: "entreprise",
          type: "selectSearch",
          optionFieldToDisplay: "nom_Client",
          valueFieldToDisplay: "nom_Client",
          service: ClientService.getForSelectSearch,
          functionAppliedToValue: (value) => {
            return (
              <div className="d-flex justify-content-start align-items-center">
                {value ? (
                  <a
                    href={"/clients/" + value.id}
                    target="_blank"
                    data-text={value.nom_Client}
                    className="fixWidthHover"
                  >
                    {value.nom_Client}
                  </a>
                ) : (
                  "-"
                )}
              </div>
            );
          },
          colSize: 4,
        },
        {
          label: "Date livraison prévue",
          accessor: "date_Livraison_Prevue",
          type: "date",
          required: this.props.commande.produit_Interne?.isMat,
          colSize: 4,
        },
        {
          label: "Commentaire",
          accessor: "commentaire",
          type: "textArea",
          functionAppliedToValue: (el) => {
            if (el != null) {
              return <div className="keepWhiteSpace">{el}</div>;
            }
          },
          colSize: 12,
        },
        {
          label: "Contrat",
          accessor: "contrat",
          functionAppliedToValue: (value) => {
            if (value) return <div>{value.numEtude}</div>;
          },
        },
      ];

      const checkboxParticulier = [
        {
          label: "Particulier ?",
          accessor: "isParticulier",
          type: "checkbox",
          functionAppliedToValue: (value) => {
            return value ? "Oui" : "Non";
          },
        },
      ];

      const informationsParticulier = [
        {
          label: "Nom",
          accessor: "nomParticulier",
          type: "text",
          colSize: 6,
        },
        {
          label: "Prénom",
          accessor: "prenomParticulier",
          type: "text",
          colSize: 6,
        },
        {
          label: "Mail",
          accessor: "adresseParticulier.email",
          value: this.props.commande.adresseParticulier?.email,
          type: "email",
          colSize: 6,
        },
        {
          label: "Téléphone",
          accessor: "adresseParticulier.telephone",
          value: this.props.commande.adresseParticulier?.telephone,

          type: "text",
          colSize: 6,
        },
        {
          label: "Adresse Particulier",
          functionAppliedToValue: () => {
            return (
              <>
                {this.props.commande.adresseParticulier?.intitule ?? ""}
                <br />
                {this.props.commande.adresseParticulier?.voie ?? ""}
                <br />
                {this.props.commande.adresseParticulier?.complement ?? ""}
                <br />
                {(this.props.commande.adresseParticulier?.cp ?? "") +
                  " " +
                  (this.props.commande.adresseParticulier?.ville ?? "")}
              </>
            );
          },
          accessor: "adresseParticulier",
          colSize: 12,
          type: "address",
          optionFieldToDisplay: "nom_Fr",
        },
      ];

      const informationsFiche = [
        {
          label: "Date de création",
          accessor: "date_Create",
          functionAppliedToValue: (value) => {
            return value
              ? ToLocaleDateString(commande.date_Create) +
                  " par " +
                  IfNullToText(commande.user_Create?.nom, "inconnu") +
                  " " +
                  IfNullToText(commande.user_Create?.prenom, "")
              : "-";
          },
          colSize: 6,
        },
        {
          label: "Date de modification",
          accessor: "date_Modif",
          functionAppliedToValue: (value) => {
            return value
              ? ToLocaleDateString(commande.date_Modif) +
                  " par " +
                  IfNullToText(commande.user_Modif?.nom, "inconnu") +
                  " " +
                  IfNullToText(commande.user_Modif?.prenom, "")
              : "-";
          },
          colSize: 6,
        },
      ];
      return (
        <>
          <div className="row">
            <div className="col-12">
              <BoxInformationsInline
                title="général"
                datas={informationsGeneral}
                state={this.props.commande}
                stateFieldNameToUpdate={this.props.stateFieldNameToUpdate}
                service={this.props.service}
                handleChange={this.props.handleChange}
                colSize="4"
                editing={this.props.editing}
                handleEditing={this.props.handleEditing}
                NoEdition={right == "RO"}
              />
            </div>
            <div className="col-lg-8 col-md-12">
              <BoxInformationsInline
                title="Informations client"
                datas={informationsClient}
                state={this.props.commande}
                stateFieldNameToUpdate={this.props.stateFieldNameToUpdate}
                service={this.props.service}
                handleChange={this.props.handleChange}
                colSize="4"
                editing={this.props.editing}
                handleEditing={this.props.handleEditing}
                NoEdition={right == "RO"}
              />
              <BoxInformationsInline
                title="Informations commande"
                datas={informationsCommande}
                state={this.props.commande}
                stateFieldNameToUpdate={this.props.stateFieldNameToUpdate}
                service={this.props.service}
                handleChange={this.props.handleChange}
                colSize="12"
                editing={this.props.editing}
                handleEditing={this.props.handleEditing}
                NoEdition={right == "RO"}
              />
            </div>
            <div className="col-lg-4 col-md-12">
              <BoxInformationsInline
                title="Informations financières"
                datas={informationsFinancieres}
                state={this.props.commande}
                stateFieldNameToUpdate={this.props.stateFieldNameToUpdate}
                service={this.props.service}
                handleChange={this.props.handleChange}
                colSize="4"
                editing={this.props.editing}
                handleEditing={this.props.handleEditing}
                NoEdition={right == "RO"}
              />
              <BoxInformationsInline
                title="Particulier ?"
                datas={
                  this.props.commande.isParticulier
                    ? checkboxParticulier.concat(informationsParticulier)
                    : checkboxParticulier
                }
                state={this.props.commande}
                stateFieldNameToUpdate={this.props.stateFieldNameToUpdate}
                service={this.props.service}
                handleChange={this.props.handleChange}
                editing={this.props.editing}
                handleEditing={this.props.handleEditing}
                NoEdition={right == "RO"}
              />

              <BoxInformationsInline
                title="Informations sur la fiche"
                datas={informationsFiche}
                state={this.props.commande}
                stateFieldNameToUpdate={this.props.stateFieldNameToUpdate}
                service={this.props.service}
                handleChange={this.props.handleChange}
                colSize="4"
                boxSize="small"
                NoEdition
              />
            </div>
          </div>
        </>
      );
    } else {
      return (
        <>
          <WarningBar
            active={false}
            content={"Vous n'avez pas le droit de voir cette page"}
          />
        </>
      );
    }
  }
}

InformationsCommande.propTypes = {
  commande: PropTypes.object,
  stateFieldNameToUpdate: PropTypes.string,
  handleChange: PropTypes.func,
  history: PropTypes.object,
  service: PropTypes.func,
};

export { InformationsCommande };
