import http from "http-common";

const CalculetteAdminService = class {
  /**
   * Fonction permettant de récuperer tous les contactss avec une possibilité de filtre via params
   * @param {*} params, paramètres à utiliser pour la requête (page, nombre d'éléments par page et filtres sur les champs)
   * @returns une liste des contactss respectant les critères de params et le nombre de page pour ces critères
   */
  static getAll() {
    return http.get("/calculette/admin");
  }

  /**
   * Fonction permettant de récuperer un contacts via son id
   * @param {*} id, id de du contacts à récupérer
   * @returns le contacts souhaité
   */
  static getById(id) {
    return http.get("/calculette/admin/" + id);
  }

  /**
   * Fonction permettant de mettre à jour un contact
   * @param {*} cal, le contact à mettre à jour
   * @returns
   */
  static put(cal) {
    return http.put("/calculette/admin/" + cal.id, cal);
  }

 
};

export { CalculetteAdminService };
