import React from "react";

import logoError from "_assets/404.jpg";
import { Helmet } from "react-helmet";

class NoMatchPage extends React.PureComponent {
  constructor(props) {
    super(props);
    this.handleUpdate = this.handleUpdate.bind(this);
  }

  handleUpdate(e) {
    e.preventDefault();
  }

  componentDidMount() {}

  render() {
    return (
      <>
        <Helmet>
          <title>Erreur 404</title>
        </Helmet>

        <div className="shadow p-3 m-4 bg-body rounded bg-light">
          <h1 className="text-center">Erreur 404 : Page non trouvée</h1>
          <img src={logoError} className="img-fluid mx-auto d-block m-5"></img>
        </div>
      </>
    );
  }
}

export { NoMatchPage };
