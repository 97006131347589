// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#tooltip-encapsulator > .tooltip-inner {
  max-width: 100%;
}

.tooltip {
  z-index: 1500;
}`, "",{"version":3,"sources":["webpack://./src/_components/Div/TooltipEncapsulator.css"],"names":[],"mappings":"AAAA;EACE,eAAe;AACjB;;AAEA;EACE,aAAa;AACf","sourcesContent":["#tooltip-encapsulator > .tooltip-inner {\n  max-width: 100%;\n}\n\n.tooltip {\n  z-index: 1500;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
