import React from "react";
import { PropTypes } from "prop-types";

class WarningBar extends React.PureComponent {
  constructor(props) {
    super(props);
  }

  render() {
    return this.props.active ? (
      <></>
    ) : (
      <h3
        className={
          "badge badge-pill badge-primary WarningBar " + this.props.background
        }
        style={{
          ...this.props.style,
        }}
      >
        {this.props.content}
      </h3>
    );
  }
}

/**
 * @param {bool} active, nécessaire pour déterminer si l'objet de la page est actif
 * @param {string} content, texte à afficher
 */
WarningBar.propTypes = {
  active: PropTypes.bool,
  content: PropTypes.string,
  background: PropTypes.string,
  style: PropTypes.object,
};

WarningBar.defaultProps = {
  background: "bg-warning",
  style: {},
};

export { WarningBar };
