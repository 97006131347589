import http from "http-common";

const ArticleService = class {
  static cache = null;
  static cacheSS = null;
  static loading = true;
  static loadingSS = true;

  /**
   * Fonction permettant de récuperer tous les articles avec une possibilité de filtre via params
   * @param {*} params, paramètres à utiliser pour la requête (page, nombre d'éléments par page et filtres sur les champs)
   * @returns une liste des articles respectant les critères de params et le nombre de page pour ces critères
   */
  static getAll(params) {
    if (params) {
      return ArticleService.get(params);
    } else {
      if (ArticleService.loading) {
        ArticleService.cache = ArticleService.get();
        ArticleService.loading = false;
      }
      return ArticleService.cache;
    }
  }

  static get(params) {
    return http.get("/articles/", { params });
  }

  /**
   * Fonction permettant de récuperer tous les articles avec une possibilité de filtre via params
   * @param {*} params, paramètres à utiliser pour la requête (page, nombre d'éléments par page et filtres sur les champs)
   * @returns une liste des articles respectant les critères de params et le nombre de page pour ces critères
   */
  static getForSelectSearch(params, langue) {
    if (params) {
      return ArticleService.getForSS(params, langue);
    } else {
      if (ArticleService.loadingSS) {
        ArticleService.cacheSS = ArticleService.getForSS(null, langue);
        ArticleService.loadingSS = false;
      }
      return ArticleService.cacheSS;
    }
  }

  static getForSS(params, langue) {
    return http.get("/articles/", {
      params: {
        size: 50,
        global: params,
        actif: true,
        langue: langue,
      },
    });
  }

  /**
   * Fonction permettant de récuperer un article via son id
   * @param {*} id, id de du article à récupérer
   * @returns le article souhaité
   */
  static getById(id) {
    return http.get("/articles/article/" + id);
  }

  static getAllMaterielsSousLicence() {
    return http.get("./articles/materielsSousLicence");
  }

  static getAllModelesLicences() {
    return http.get("/articles/modelesLicences");
  }

  static getAllModules() {
    return http.get("/articles/modules");
  }

  static postMaterielSousLicence(materielSousLicence) {
    return http.post("/articles/materielsSousLicence", materielSousLicence);
  }

  static postModeleLicence(licence) {
    return http.post("/articles/modelesLicences", licence);
  }

  static putMaterielSousLicence(ref, materielSousLicence) {
    return http.put("/articles/materielsSousLicence/" + ref, materielSousLicence);
  }

  static putModeleLicence(ref, modeleLicence) {
    return http.put("/articles/modelesLicences/" + ref, modeleLicence);
  }
};

export { ArticleService };
