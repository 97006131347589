export function sanitizeValue(value) {
  if (isAValue(value)) {
    let float = parseFloat(
      value.toString().replace(/\s+/g, "").replace(/,/g, ".")
    );
    if (Number.isNaN(float)) {
      return 0;
    } else {
      return float;
    }
  } else {
    return 0;
  }
}

export function formatValueDecimal(value, ignoredValues, numberOfDecimals) {
  if (!isAValue(value)) {
    return "";
  } else {
    let sanitizedValue = sanitizeValue(value);
    if (ignoredValues?.find((el) => parseFloat(el) === sanitizedValue)) {
      return "";
    } else {
      let formattedValue = Number(sanitizedValue).toLocaleString("fr", {
        minimumFractionDigits: numberOfDecimals,
        maximumFractionDigits: numberOfDecimals,
      });
      return Number.isNaN(formattedValue) || formattedValue === "NaN"
        ? ""
        : formattedValue;
    }
  }
}

export function isAValue(value) {
  return (
    value !== "null" &&
    value != "undefined" &&
    value != "NaN" &&
    !isNaN(parseInt(value))
  );
}
