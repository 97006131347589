// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.infosGeneralFichePerso {
    padding-right: 25px;
}


@media (max-width: 992px) {
    .infosGeneralFichePerso {
        padding-right: 0px;
    }
}`, "",{"version":3,"sources":["webpack://./src/FichePersonnel/InformationsPersonnelles/InformationsPersonnelles.css"],"names":[],"mappings":"AAAA;IACI,mBAAmB;AACvB;;;AAGA;IACI;QACI,kBAAkB;IACtB;AACJ","sourcesContent":[".infosGeneralFichePerso {\n    padding-right: 25px;\n}\n\n\n@media (max-width: 992px) {\n    .infosGeneralFichePerso {\n        padding-right: 0px;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
