import React from "react";
import { PropTypes } from "prop-types";
import { ToLocaleDateString, IfNullToText } from "_utils";
import { App } from "App";

import { BoxInformationsInline } from "_components/FicheComponents/BoxInformationsInline";
import { RubriqueCarteBancaireService } from "_services";
import {
  // BoxMapLeaflet,
  AlertDialog,
  WarningBar,
} from "_components";

class General extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      erreur: false,
    };
  }

  render() {
    const right = App.RightsGuard?.current?.hasRight(
      "Cartes bleues",
      "General"
    );
    if (right == "RW" || right == "RO") {
      let cartebancaire = this.props.cartebancaire;
      const informationsGeneral = [
        {
          label: "Nom",
          accessor: "name",
          type: "text",
          colSize: 4,
        },
        {
          label: "Nom Detailé",
          accessor: "nameDetail",
          type: "text",
          colSize: 4,
        },
        {
          label: "Compte tiers",
          accessor: "compteTiers",
          type: "text",
          showClearButton: true,
          colSize: 4,
        },
        {
          label: "CC Num",
          accessor: "cC_Num",
          colSize: 4,
        },
        {
          label: "JO Num",
          accessor: "jO_Num",
          service: RubriqueCarteBancaireService.getAll,
          functionAppliedToValue: (value) => {
            return value ? value.rubrique : "-";
          },
          optionFieldToDisplay: ["rubrique"],
          valueFieldToDisplay: "rubrique",
          type: "selectSearch",
          colSize: 4,
        },
        {
          label: "Actif",
          accessor: "actif",
          type: "checkbox",
          functionAppliedToValue: (value) => {
            return value ? "Oui" : "Non";
          },
          colSize: 2,
        },
      ];

      const informationsFiche = [
        {
          label: "Date de création",
          accessor: "date_Create",
          functionAppliedToValue: (value) => {
            return value
              ? ToLocaleDateString(cartebancaire.date_Create) +
                  " par " +
                  IfNullToText(cartebancaire.user_Create?.nom, "inconnu") +
                  " " +
                  IfNullToText(cartebancaire.user_Create?.prenom, "")
              : "-";
          },
          colSize: 6,
        },
        {
          label: "Date de modification",
          accessor: "date_Modif",
          functionAppliedToValue: (value) => {
            return value
              ? ToLocaleDateString(cartebancaire.date_Modif) +
                  " par " +
                  IfNullToText(cartebancaire.user_Modif?.nom, "inconnu") +
                  " " +
                  IfNullToText(cartebancaire.user_Modif?.prenom, "")
              : "-";
          },
          colSize: 6,
        },
      ];

      return (
        <>
          <AlertDialog
            title="Erreur"
            body="Vous ne pouvez pas supprimer la carte."
            valider="Ok"
            open={this.state.erreur}
            handleClose={() => {
              this.setState({ erreur: false });
            }}
          />
          <div className="row">
            <div className="col-12">
              <BoxInformationsInline
                title="Informations sur la carte"
                datas={informationsGeneral}
                state={this.props.cartebancaire}
                stateFieldNameToUpdate={this.props.stateFieldNameToUpdate}
                service={this.props.service}
                handleChange={this.props.handleChange}
                handleEditing={this.props.handleEditing}
                colSize="4"
                NoEdition={right != "RW"}
              />
            </div>
            <div className="col-12">
              <BoxInformationsInline
                title="Informations sur la fiche"
                datas={informationsFiche}
                state={this.props.cartebancaire}
                stateFieldNameToUpdate={this.props.stateFieldNameToUpdate}
                service={this.props.service}
                handleChange={this.props.handleChange}
                colSize="4"
                boxSize="small"
                NoEdition
              />
            </div>
          </div>
        </>
      );
    } else {
      return (
        <>
          <WarningBar
            active={false}
            content={"Vous n'avez pas le droit de voir cette page"}
          />
        </>
      );
    }
  }
}

General.propTypes = {
  cartebancaire: PropTypes.object,
  stateFieldNameToUpdate: PropTypes.string,
  handleChange: PropTypes.func,
  history: PropTypes.object,
  service: PropTypes.func,
};

export { General };
