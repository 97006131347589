import React from "react";
import { App } from "App";
import { ToLocaleDateString } from "_utils";
import { NoteDeFraisService } from "_services";
import { AlertDialog, Box, ButtonIcon, WarningBar } from "_components";
import { Helmet } from "react-helmet";
import { formatValueDecimal } from "_utils/DecimalFunctions.js";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

class NoteDeFraisHub extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      file: null,
      loading: false,
    };

    this.handleSubmit = this.handleSubmit.bind(this);
    this.handlePrevisualisation = this.handlePrevisualisation.bind(this);
  }

  handlePrevisualisation(event) {
    event.preventDefault();
    this.setState({ loading: true });
    let formData = new FormData();
    formData.append("file", this.state.file);

    NoteDeFraisService.previsualiser(formData)
      .then((res) => this.setState({ NdF: res.data, loading: false }))
      .catch((err) =>
        this.setState({ error: err.response?.data?.cause, loading: false })
      );
  }

  handleSubmit(event) {
    event.preventDefault();
    this.setState({ loading: true });
    let formData = new FormData();
    formData.append("file", this.state.file);

    NoteDeFraisService.post(formData)
      .then((res) => this.setState({ NdF: res.data, loading: false }))
      .catch((err) =>
        this.setState({ error: err.response?.data?.cause, loading: false })
      );
  }

  render() {
    const rightImport = App.RightsGuard?.current?.hasRight(
      "Affaire",
      "Import excel"
    );

    if (rightImport == "RW") {
      var ndf = this.state.NdF?.slice();
      if (!ndf) ndf = [];
      return (
        <>
          <Helmet>
            <title>Import notes de frais</title>
          </Helmet>
          <AlertDialog
            title="Erreur"
            body={
              <>
                <div>Une erreur a été rencontrée lors de l&apos;opération.</div>
                <div>{this.state.error}</div>
              </>
            }
            valider="Ok"
            open={this.state.error}
            handleClose={() => this.setState({ error: false })}
          />
          <div className="p-3 m-4 " id="homepage">
            <form onSubmit={this.handlePrevisualisation}>
              <label className="mt-2">
                <input
                  type="file"
                  onChange={(e) =>
                    this.setState({ file: e.target.files[0], NdF: null })
                  }
                  className="form-control"
                />
              </label>
              <button
                className="btn btn-success ms-1"
                type="submit"
                disabled={!this.state.file}
              >
                Previsualiser
              </button>
            </form>
            {this.state.NdF ? (
              <ButtonIcon
                onClick={(e) => {
                  this.handleSubmit(e);
                }}
                className="btn btn-success ms-1"
                textOption=" Enregistrer"
              ></ButtonIcon>
            ) : null}
            {this.state.loading ? (
              <div
                style={{
                  flexGrow: 1,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <FontAwesomeIcon
                  icon={faSpinner}
                  size="lg"
                  className="fa-spin"
                />
              </div>
            ) : (
              <>
                <Box
                  noPaddingBottom={true}
                  header={
                    <div className="col-12 align-self-center py-2">
                      <div>Notes De Frais</div>
                    </div>
                  }
                  body={
                    <table className="table table-sm table-striped table-bordered m-0">
                      <thead className="sticky-top">
                        <tr>
                          <th>Nombre NDF</th>
                          <th>Total TTC</th>
                          <th>Total TVA</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td className="tableIndent1">{ndf.length}</td>
                          <td className="tableIndent1">
                            {formatValueDecimal(
                              ndf
                                .map((e) => e.montant_TTC)
                                .reduce((a, b) => a + b, 0)
                            )}
                          </td>
                          <td className="tableIndent1">
                            {formatValueDecimal(
                              ndf
                                .map((e) => e.montant_TVA)
                                .reduce((a, b) => a + b, 0)
                            )}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  }
                />

                <Box
                  noPaddingBottom={true}
                  header={
                    <div className="col-12 align-self-center py-2">
                      <div>Notes De Frais</div>
                    </div>
                  }
                  body={
                    <table className="table table-sm table-striped table-bordered ">
                      <thead className="sticky-top">
                        <tr>
                          <th>Affaires</th>
                          <th>Libellé</th>
                          <th>Montant TTC</th>
                          <th>Montant TVA</th>
                          <th>Personnel</th>
                          <th>Rubrique</th>
                          <th>Nombre</th>
                          <th>Date de Frais</th>
                        </tr>
                      </thead>
                      <tbody>
                        {ndf.map((d, index) => {
                          return (
                            <tr
                              key={index}
                              style={
                                d.affaire == null
                                  ? { backgroundColor: "red" }
                                  : {}
                              }
                            >
                              <td className="tableIndent1">
                                {d.affaire?.nomAffaire}
                              </td>
                              <td className="tableIndent1">{d.libelle}</td>
                              <td className="tableIndent1">{d.montant_TTC}</td>
                              <td className="tableIndent1">{d.montant_TVA}</td>
                              <td className="tableIndent1">
                                {d.personnel?.initiales}
                              </td>
                              <td className="tableIndent1">
                                {d.typeNoteDeFrais?.code_Secteur}
                              </td>
                              <td className="tableIndent1">{d.nombre}</td>
                              <td className="tableIndent1">
                                {d.dateFrais != null
                                  ? ToLocaleDateString(d.dateFrais)
                                  : null}
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  }
                />
              </>
            )}
          </div>
        </>
      );
    } else {
      return (
        <WarningBar
          active={false}
          content={"Vous n'avez pas le droit de voir cette page"}
        />
      );
    }
  }
}

export { NoteDeFraisHub };
