import React from "react";
import { PropTypes } from "prop-types";
import { Link } from "react-router-dom";
import { App } from "App";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import "./FicheContact.css";

import { InformationsContact } from ".";
import { WarningBar } from "_components";

import { ContactService } from "_services";
import { ButtonIconWithValidation } from "_components";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { Helmet } from "react-helmet";

import produce from "immer";
import TabSelector from "_components/FicheComponents/TabSelector";

class FicheContact extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      tabs: ["Général"],
      active: "Général",
      editing: false,
      contact: {},
    };

    this.renderActive = this.renderActive.bind(this);
    this.generateTabs = this.generateTabs.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
    this.handleEditing = this.handleEditing.bind(this);
    this.updateClient = this.updateClient.bind(this);
  }

  componentDidMount() {
    if (sessionStorage.getItem("ContactOnglet") == null) {
      sessionStorage.setItem("ContactOnglet", this.state.active);
    } else {
      this.setState({ active: sessionStorage.getItem("ContactOnglet") });
    }

    let { id } = this.props.match.params;
    ContactService.getById(id).then((res) => {
      this.setState({ contact: res.data, loading: false });
    });
  }

  /**
   * Permet de mettre a jour l'etat du fiche de suivi sans devoir le recharger
   * @param {*} accessor
   * @param {*} value
   */
  handleChange(accessor, value) {
    if (accessor === "contact") {
      //Undo
      this.setState({ contact: value });
    } else {
      //Update
      if (accessor.includes(".")) {
        var accessorSplit = accessor.split(".");

        // Nested property (separated by a '.')
        this.setState(
          produce((prevState) => {
            prevState.contact[accessorSplit[0]][accessorSplit[1]] = value;
          })
        );
      } else {
        // Shallow property
        this.setState(
          produce((prevState) => {
            prevState.contact[accessor] = value;
          })
        );
      }
    }
  }

  handleDelete() {
    return ContactService.delete(this.state.contact).then(() => {
      this.props.history.push({ pathname: "/contacts" });
    });
  }

  handleEditing(editing) {
    this.setState((prevState) => ({
      ...prevState,
      editing: editing,
    }));
  }

  /**
   * Permet de mettre a jour la liste des clients
   * @param {*} client
   * @param {*} typeRequest
   */
  updateClient(client, typeRequest) {
    let clients = this.state.contact.clients;
    let newClient = {};
    let index = 0;

    for (let i = 0; i < clients.length; i++) {
      if (clients[i].id === client.id) {
        index = i;
        break;
      }
    }

    switch (typeRequest) {
      case "link":
        newClient = { ...client };
        clients.splice(index, 0, newClient);
        break;
      case "delete":
        clients = clients.splice(index, 1);
        break;
      default:
        console.log("le type de requete n'est pas encore géré.");
    }

    this.setState((prevState) => ({
      contact: {
        ...prevState.contact,
        [clients]: clients,
      },
    }));
  }

  /**
   * Génère les onglets de la fiche
   * @param {*} tabs
   * @returns
   */
  generateTabs(tabs) {
    return (
      <div>
        <TabSelector
          tabs={tabs}
          activeTab={this.state.active}
          onSelect={(tab) => {
            this.setState({ active: tab });
            sessionStorage.setItem("ContactOnglet", tab);
          }}
          notAllowed={this.state.editing}
        ></TabSelector>
      </div>
    );
  }

  renderActive() {
    switch (this.state.active) {
      case "Général":
        return (
          <InformationsContact
            contact={this.state.contact}
            stateFieldNameToUpdate="contact"
            handleChange={this.handleChange}
            service={ContactService}
            history={this.props.history}
            editing={this.state.editing}
            handleEditing={this.handleEditing}
            updateClient={this.updateClient}
          ></InformationsContact>
        );
      default:
        return (
          <h4 className="mt-5">Désolé la page n&apos;a pas réussi à charger</h4>
        );
    }
  }

  render() {
    const isContactEmpty = !Object.entries(this.state.contact).length;
    return (
      <>
        <Helmet>
          <title>
            {"Contact : "
              .concat(this.state.contact.nom_Contact)
              .concat(" ")
              .concat(this.state.contact.prenom_Contact)}
          </title>
        </Helmet>
        {this.state.loading ? (
          <div
            style={{
              flexGrow: 1,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <FontAwesomeIcon icon={faSpinner} size="lg" className="fa-spin" />
          </div>
        ) : (
          <div className="p-3 m-4" id="ficheClient">
            <div className="d-flex overflow-auto justify-content-between py-1">
              <h4 className="nowrap pr-100">
                {"Contact : "
                  .concat(this.state.contact.nom_Contact)
                  .concat(" ")
                  .concat(this.state.contact.prenom_Contact)}
              </h4>
              <div className="d-flex">
                <Link to="/contacts" className="btn btn-primary nowrap ms-1">
                  Base contact
                </Link>
                {App.RightsGuard.current?.hasRight("Contact", "Suppression") ==
                "RW" ? (
                  <ButtonIconWithValidation
                    icon={faTrash}
                    onClick={this.handleDelete}
                    className="form-control btn btn-danger btn-sm text-light ms-1"
                    alertTitle=" Suppression"
                    style={{ width: "40px" }}
                    alertBody={
                        <div>
                            Souhaitez-vous réellement supprimer ce contact ? <br />
                            (il sera supprimé pour tous ses clients, et les licences
                            logicielles dont il dispose deviendront des licences non
                            attribuées)
                        </div>
                    }
                    alertButtonValidationText="Oui, je veux supprimer."
                  ></ButtonIconWithValidation>
                ) : null}
                {/* {this.props.history.length > 1 && (
                  <button
                    className="btn btn-primary nowrap ms-1"
                    onClick={() => {
                      this.props.history.goBack();
                    }}
                  >
                    Retour
                  </button>
                )} */}
              </div>
            </div>
            {this.generateTabs(this.state.tabs)}
            <WarningBar
              active={this.state.contact.actif}
              content={"Ce contact n'est pas actif"}
            >
              {" "}
            </WarningBar>
            {!isContactEmpty && this.renderActive()}
          </div>
        )}
      </>
    );
  }
}

/**
 * Validation des props :
 */
FicheContact.propTypes = {
  history: PropTypes.object,
  match: PropTypes.object,
};

export { FicheContact };
