import http from "http-common";

const FactureService = class {
  /**
   * Fonction permettant de récuperer tous les factures avec une possibilité de filtre via params
   * @returns la liste de tous les factures
   */
  static getAll() {
    return http.get("/factures");
  }

  /**
   * Fonction permettant de récuperer un facture via son id
   * @param {*} id, id de du facture à récupérer
   * @returns le facture souhaité
   */
  static getById(id) {
    return http.get("/factures/" + id);
  }

  static getByValue(value) {
    return http.get("/factures/get/" + value);
  }
};

export { FactureService };
