import { ChantierService } from "_services";
import React, { useState, useEffect } from "react";
import { BlocChantierSynthese } from "./BlocChantierSynthese";

const MesChantierContainer = (props) => {
  let [chantiers, setChantiers] = useState([]);

  const handleGetAll = () => {
    ChantierService.getMesChantiers().then((e) => setChantiers(e.data));
  };

  const updateOne = (id) => {
    ChantierService.put(chantiers.filter((e) => e.id == id)[0])
      .then(() => {
        handleGetAll();
      })
      .catch();
  };

  useEffect(() => {
    handleGetAll();
  }, []);

  let chantierHtml = chantiers?.map((e) => (
    <BlocChantierSynthese
      key={e.id}
      history={props.history}
      chantier={e}
      affaire={props.affaire}
      handleGetAll={handleGetAll}
      update={updateOne}
    />
  ));

  return <>{chantierHtml}</>;
};

export { MesChantierContainer };
