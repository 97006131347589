import http from "http-common";

const PersonnelSyntechService = class {
  /**
   * Fonction permettant de récuperer toutes les informations Syntech des personnels
   * @returns la liste de toutes les informations Syntech
   */
  static getAll(params) {
    let url = "/PersonnelSyntech";

    //Gestion de la valeur par défaut de la propriété "isActive" des paramètres
    if (!params) return http.get(url, { params: { isActive: true } });
    else if (typeof params === "object" && params.isActive === undefined)
      return http.get(url, { params: { ...params, isActive: true } });
    else if (typeof params === "object" && params.isActive !== undefined)
      return http.get(url, { params });
    else return http.get(url, { params: { isActive: true } });
  }
};

export { PersonnelSyntechService };
