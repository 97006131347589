import http from "http-common";

const ReferentielService = class {
    /**
    * Fonction permettant de modifier un référentiel.
    * @param {*} table, le nom de la table dans laquelle faire la modification.
    * @param {*} referentiel, l'objet qui doit être modifié au format suivant :
    * {
    *    id,
    *    proprietes: [{nomPropriete, valeur}, {nomPropriete, valeur}, etc...]
    * }
    * @returns
    */
    static update(table, referentiel) {
        return http.put("/" + table, referentiel);
    }

    /**
    * Fonction permettant d'ajouter un référentiel.
    * @param {*} table, le nom de la table dans laquelle faire l'ajout.
    * @param {*} referentiel, l'objet qui doit être ajouté au format suivant :
    * {
    *    id,
    *    proprietes: [{nomPropriete, valeur}, {nomPropriete, valeur}, etc...]
    * }
    * @returns
    */
    static create(table, referentiel) {
        return http.post("/" + table, referentiel);
    }
};

export { ReferentielService };