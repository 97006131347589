import React from "react";
import { App } from "App";

import { Box, WarningBar } from "_components";
import { AccordionCommandesAchat } from "./AccordionCommandesAchat";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";

import { PropTypes } from "prop-types";

class CommandesAchat extends React.PureComponent {
  constructor(props) {
    super(props);
  }

  render() {
    const right = App.RightsGuard?.current?.hasRight(
      "Affaire",
      "CommandesAchat"
    );
    if (right == "RW" || right == "RO") {
      if (this.props.loading) {
        return (
          <div className="d-flex justify-content-center py-2">
            <FontAwesomeIcon icon={faSpinner} size="lg" className="fa-spin" />
          </div>
        );
      } else {
        return (
          <>
            <Box
              noPaddingBottom={true}
              header={
                <div className="col-12 align-self-center py-2">
                  <div>Bons de Commande/Livraison Achat</div>
                </div>
              }
              body={
                <>
                  <table className="table table-sm table-bordered">
                    <thead
                      className={
                        "sticky-top" +
                        (this.props.bonsAchats?.length == 0
                          ? " theadBorderRadius"
                          : "")
                      }
                    >
                      <tr>
                        <th>Num</th>
                        <th>Date</th>
                        <th>Réf. article</th>
                        <th>Description</th>
                        <th>Quantité</th>
                        <th>Prix Unitaire</th>
                        <th>Prix HT</th>
                        <th>Prix TTC</th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.props.bonsAchats
                        ?.slice()
                        .sort(
                          (a, b) =>
                            new Date(b.dateCreation) - new Date(a.dateCreation)
                        )
                        .map((bonAchat, index) => (
                          <AccordionCommandesAchat
                            key={index}
                            index={index}
                            bonAchat={bonAchat}
                          />
                        ))}
                    </tbody>
                  </table>
                </>
              }
            />
          </>
        );
      }
    } else {
      return (
        <>
          <WarningBar
            active={false}
            content={"Vous n'avez pas le droit de voir cette page"}
          />
        </>
      );
    }
  }
}

CommandesAchat.propTypes = {
  bonsAchats: PropTypes.any,
};

export { CommandesAchat };
