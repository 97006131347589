import http from "http-common";

const NoteDeFraisService = class {
  /**
   * Fonction permettant de récuperer tous les articles avec une possibilité de filtre via params
   * @param {*} params, paramètres à utiliser pour la requête (page, nombre d'éléments par page et filtres sur les champs)
   * @returns une liste des articles respectant les critères de params et le nombre de page pour ces critères
   */
  static previsualiser(params) {
    const config = {
      headers: { "content-type": "multipart/form-data" },
    };

    return http.post("/notedefrais/previsualisation", params, config);
  }

  static previsualiserCb(params) {
    const config = {
      headers: { "content-type": "multipart/form-data" },
    };

    return http.post("/notedefrais/previsualisationcb", params, config);
  }

  /**
   * Fonction permettant de récuperer tous les articles avec une possibilité de filtre via params
   * @param {*} params, paramètres à utiliser pour la requête (page, nombre d'éléments par page et filtres sur les champs)
   * @returns une liste des articles respectant les critères de params et le nombre de page pour ces critères
   */
  static post(params) {
    const config = {
      headers: { "content-type": "multipart/form-data" },
    };

    return http.post("/notedefrais/importer", params, config);
  }

  static postCb(params) {
    const config = {
      headers: { "content-type": "multipart/form-data" },
    };

    return http.post("/notedefrais/importercb", params, config);
  }

  static extract(params) {
    return http({
      url: "/notedefrais/extract",
      method: "PUT",
      responseType: "blob",
      data: params,
    });
  }
};

export { NoteDeFraisService };
