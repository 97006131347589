import http from "http-common";

const TVAService = class {
  /**
   * Fonction permettant de récuperer tous les tva avec une possibilité de filtre via params
   * @returns la liste de tous les tva
   */
  static getAll() {
    return http.get("/tva");
  }

  /**
   * Fonction permettant de récuperer un tva via son id
   * @param {*} id, id de du tva à récupérer
   * @returns le tva souhaité
   */
  static getById(id) {
    return http.get("/tva/" + id);
  }

  static getByValue(value) {
    return http.get("/tva/get/" + value);
  }
};

export { TVAService };
