import React from "react";
import { AlertDialog, Box, FormWithValidation } from "_components";
import { App } from "App";

import { Input } from "_components/Input";

import { PropTypes } from "prop-types";

import { ButtonIcon } from "_components";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { isEqual } from "lodash";
import {
  ClientService,
  ProduitInterneService,
  PersonnelService,
  FamilleAffaireService,
  AffaireService,
} from "_services";

import { Helmet } from "react-helmet";

import { faExternalLinkAlt } from "@fortawesome/free-solid-svg-icons";

import produce from "immer";

class AjoutAffaire extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loaded: false,
      errorText: null,
      error: false,
      isSubmitting: false,
      similarClients: [],
      alertOpened: false,
      affaire: {
        adresseChantier: {
          intitule: null,
          complement: null,
          voie: null,
          ville: null,
          pays: null,
        },
      },
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  async componentDidMount() {
    var geranT = await PersonnelService.getByOid(
      App.RightsGuard?.current?.getOid()
    ).then((response) => response.data);

    this.setState((state) => ({
      loaded: true,
      affaire: {
        ...state.affaire,

        chargeAffaire: geranT,
      },
    }));
  }

  handleChange(accessor, value, afterUpdateFunction = null) {
    if (accessor.includes(".")) {
      var accessorSplit = accessor.split(".");

      // Nested property (separated by a '.')
      this.setState(
        produce((prevState) => {
          prevState.affaire[accessorSplit[0]][accessorSplit[1]] = value;
        }),
        afterUpdateFunction
      );
    } else {
      // Shallow property
      this.setState(
        produce((prevState) => {
          prevState.affaire[accessor] = value;
        }),
        afterUpdateFunction
      );
    }
  }

  handleSubmit(event) {
    event.preventDefault();

    this.setState({ isSubmitting: true }, () =>
      AffaireService.post(this.state.affaire)
        .then((returned) => {
          this.props.history.push("/affaires/" + returned.data.id);
        })
        .catch((error) => {
          this.setState({
            error: true,
            isSubmitting: false,
            errorText: error.message,
          });
        })
    );
  }

  shouldComponentUpdate(prevProps, prevStates) {
    return (
      !(isEqual(prevProps, this.props) && isEqual(prevStates, this.state)) ||
      !isEqual(prevStates.affaire.produitInterne, this.state)
    );
  }

  form() {
    const informationsAffaire = [
      {
        label: "Nom",
        value: this.state.affaire.nomAffaire,
        accessor: "nomAffaire",
        required: true,
        invalidText: "Vous devez entrer un nom.",
      },

      {
        value: this.state.affaire.client,
        label: "Client",
        accessor: "client",
        type: "selectSearch",
        required: true,
        service: ClientService.getForSelectSearch,
        optionFieldToDisplay: "nom_Client",
        valueFieldToDisplay: "nom_Client",
        functionAppliedToValue: (value) => (
          <>
            <div className="d-flex justify-content-start align-items-center">
              {value && value.nom_Client ? value.nom_Client : "-"}
              <ButtonIcon
                icon={faExternalLinkAlt}
                className="btn btn-secondary btn-sm text-light ms-1"
                onClick={() => window.open("/clients/" + value.id, "_blank")}
                style={{ width: "32px" }}
              ></ButtonIcon>
            </div>
          </>
        ),
        colSize: 6,
      },
      {
        value: this.state.affaire.chargeAffaire,
        label: "Chargé d'affaire",
        accessor: "chargeAffaire",
        required: true,
        functionAppliedToValue: (value) => {
          return value ? value.nom + " " + value.prenom : "-";
        },
        type: "selectSearch",
        service: PersonnelService.getForSelectSearch,
        optionFieldToDisplay: ["nom", "prenom"],
        valueFieldToDisplay: ["nom", "prenom"],
        colSize: 6,
      },
      {
        value: this.state.affaire.famille_Affaire,
        label: "Famille d'affaires",
        accessor: "famille_Affaire",
        required: this.state.affaire.produitInterne?.isPresta ? true : false,
        type: "selectSearch",
        service: FamilleAffaireService.getAll,
        optionFieldToDisplay: "designation",
        valueFieldToDisplay: "designation",
        colSize: 6,
      },
      {
        value: this.state.affaire.produitInterne,
        label: "Produit interne",
        accessor: "produitInterne",
        required: true,
        type: "selectSearch",
        service: ProduitInterneService.getAll,
        optionFieldToDisplay: "designation",
        valueFieldToDisplay: "designation",
        colSize: 6,
      },
    ];

    const informationsChantier = [
      {
        value: this.state.affaire.presenceRapportIntermediaire,
        label: "Présence de rapport intermédiaire",
        accessor: "presenceRapportIntermediaire",
        type: "checkbox",
        functionAppliedToValue: (value) => {
          return value ? "Oui" : "Non";
        },
        colSize: 4,
      },
      {
        value: this.state.affaire.delaisSemaineRemiseRapport,
        label: "Delai de remise du rapport",
        accessor: "delaisSemaineRemiseRapport",
        type: "decimal",
        colSize: 6,
      },
      {
        value: this.state.affaire.labo,
        label: "Laboratoire",
        accessor: "labo",
        type: "checkbox",
        functionAppliedToValue: (value) => {
          return value ? "Oui" : "Non";
        },
        colSize: 4,
      },
      {
        value: this.state.affaire.plan,
        label: "Plan",
        accessor: "plan",
        type: "checkbox",
        functionAppliedToValue: (value) => {
          return value ? "Oui" : "Non";
        },
        colSize: 4,
      },

      {
        value: this.state.affaire.adresseChantier,
        label: "Adresse Chantier",
        required: true,
        functionAppliedToValue: () => {
          return (
            <>
              {this.state.affaire.adresseChantier.intitule ?? ""}
              <br />
              {this.state.affaire.adresseChantier.voie ?? ""}
              <br />
              {this.state.affaire.adresseChantier.complement ?? ""}
              <br />
              {(this.state.affaire.adresseChantier.cp ?? "") +
                " " +
                (this.state.affaire.adresseChantier.ville ?? "")}
            </>
          );
        },
        accessor: "adresseChantier",
        colSize: 12,
        type: "address",
      },
    ];

    const infos = [
      { info: informationsAffaire, titre: "Informations générales" },
      { info: informationsChantier, titre: "Informations sur le chantier" },
    ];
    return this.state.loaded ? (
      <>
        <FormWithValidation onSubmit={this.handleSubmit}>
          {infos.map((infoItem, index) => {
            return (
              <div className="mb-4" key={index}>
                <Box
                  header={
                    <>
                      <div className="col-12 align-self-center py-2">
                        <div>{infoItem.titre}</div>
                      </div>
                    </>
                  }
                  body={
                    <>
                      {infoItem.info.map((field, fieldsIndex) => {
                        return field ? (
                          <div className="col-lg-4 pt-2" key={fieldsIndex}>
                            <Input
                              label={field.label} // Titre du champ
                              value={field.value} // Valeur du champ
                              accessor={field.accessor} // Accesseur
                              placeholder={field.placeholder}
                              debounce={field.debounce}
                              service={field.service}
                              handleChange={(acc, val) =>
                                this.handleChange(
                                  acc,
                                  val,
                                  field.afterUpdateFunction
                                )
                              } // Fonction pour mettre à jour la valeur dans l'object via son accesseur
                              handleBlur={field.handleBlur} // Fonction pour mettre à jour la valeur dans l'object via son accesseur
                              type={field.type} // Type de l'input a utiliser
                              options={field.options} // options de l'input
                              optionFieldToDisplay={field.optionFieldToDisplay} // champ de l'objet a afficher
                              optionFieldToReturn={field.optionFieldToReturn} // champd de l'objet a utiliser en tant que valeur
                              valueFieldToDisplay={field.valueFieldToDisplay}
                              required={field.required}
                              disabled={field.disabled}
                              validText={field.validText}
                              invalidText={field.invalidText}
                              specialRenderValue={field.specialRenderValue}
                              setForceUpdateFunction={
                                field.setForceUpdateFunction
                              }
                            />
                          </div>
                        ) : null;
                      })}
                    </>
                  }
                />
              </div>
            );
          })}

          <div className="text-center">
            <ButtonIcon
              className="btn btn-success mt-4 w-25"
              type="submit"
              disabled={this.state.isSubmitting}
              textOption="Créer l'affaire"
            ></ButtonIcon>
          </div>
        </FormWithValidation>
      </>
    ) : (
      <FontAwesomeIcon
        icon={faSpinner}
        size="lg"
        className="fa-spin flex-auto"
      />
    );
  }

  render() {
    return (
      <>
        <Helmet>
          <meta charset="utf-8" />
          <title>Création d'une affaire</title>
        </Helmet>
        <AlertDialog
          title="Une erreur a été rencontrée"
          body={
            <>
              <div>La création de l'affaire a échouée.</div>
              <div>{this.state.errorText}</div>
            </>
          }
          valider="Ok"
          open={this.state.error}
          handleClose={this.handleClose}
        />
        <div className="p-3 m-4" id="ajoutClient">
          <div className="d-flex overflow-auto justify-content-between py-1">
            <h2 className="nowrap pr-100 solwayFont">
              Création d&apos;une Affaire
              {this.props.history.location.state
                ? " pour " + this.state.affaire.nomAffaire
                : null}
            </h2>
            <div>
              {this.props.history.length > 1 && (
                <button
                  className="btn btn-primary nowrap mx-1"
                  onClick={() => {
                    this.props.history.goBack();
                  }}
                >
                  Retour
                </button>
              )}
            </div>
          </div>
          <div className="row mt-4">{this.form()}</div>
        </div>
      </>
    );
  }
}

AjoutAffaire.propTypes = {
  history: PropTypes.object,
};

export { AjoutAffaire };
