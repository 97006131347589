import http from "http-common";

const CategorieService = class {
  /**
   * Fonction permettant de récuperer toutes les catégories de clients avec une possibilité de filtre via params
   * @returns la liste de toutes les catégories
   */
  static getAll(params) {
    let url = "/Categories";
    
    //Gestion de la valeur par défaut de la propriété "isActive" des paramètres
    if (!params)
      return http.get(url, {params: {isActive: true}});
    else if (typeof params === "object" && params.isActive === undefined)
        return http.get(url, {params: {...params, isActive: true}});
    else if (typeof params === "object" && params.isActive !== undefined)
      return http.get(url, {params});
    else
      return http.get(url, {params: {isActive: true}});
  }
};

export { CategorieService };