import http from "http-common";

const PersonnelServiceService = class {
  /**
   * Fonction permettant de récuperer toutes les services personnel
   * @returns la liste de toutes les services personnel
   */
  static getAll(params) {
    let url = "/PersonnelService";

    //Gestion de la valeur par défaut de la propriété "isActive" des paramètres
    if (!params) return http.get(url, { params: { isActive: true } });
    else if (typeof params === "object" && params.isActive === undefined)
      return http.get(url, { params: { ...params, isActive: true } });
    else if (typeof params === "object" && params.isActive !== undefined)
      return http.get(url, { params });
    else return http.get(url, { params: { isActive: true } });
  }

  /**
   * Fonction permettant de récupérer une service  personnelvia son id
   * @param {*} id, id de la service  personnelà récupérer
   * @returns la service  personnelsouhaitée
   */
  static getById(id) {
    return http.get("/PersonnelService/" + id);
  }
};

export { PersonnelServiceService };
