import { sanitizeValue, isAValue } from "_utils/DecimalFunctions";

export function computeDevisDetail(accessor, devisDetail, tauxTVA, cours) {
  switch (accessor) {
    case "article":
    // case "prixAchatUnitaire": // PrixAchat Obsolete
    // case "coefficient":
    //   computePrixVenteUnitaire(devisDetail);
    // No break, we continue calculating the following values.
    case "prixVenteUnitaire":
    case "quantite":
    case "remise":
      computePrixVenteTotal(devisDetail, cours);
    // No break, we continue calculating the following values.
    case "prixVenteTotal":
      computeMontantTVA(devisDetail, tauxTVA, cours);
      computePrixVenteTotalTTC(devisDetail, cours);
      break; // Break, we stop.
    case "montantTVA":
      computePrixVenteTotalTTC(devisDetail, cours);
      break;
  }
}

/** Obsolete */
// export function computePrixVenteUnitaire(devisDetail) {
//   var prixAchatUnitaire = sanitizeValue(devisDetail.prixAchatUnitaire);
//   var coefficient = sanitizeValue(devisDetail.coefficient);
//   if (!isAValue(coefficient) || coefficient == 0) {
//     coefficient = 1;
//   }
//   if (isAValue(prixAchatUnitaire) && parseFloat(prixAchatUnitaire) != 0) {
//     devisDetail.prixVenteUnitaire = coefficient * prixAchatUnitaire;
//   }
// }

export function computePrixVenteTotal(devisDetail, cours) {
  var remise = sanitizeValue(devisDetail.remise);
  var quantite = sanitizeValue(devisDetail.quantite);
  var prixVenteUnitaire = sanitizeValue(devisDetail.prixVenteUnitaire);

  if (isAValue(prixVenteUnitaire)) {
    devisDetail.prixVenteTotal =
      quantite * prixVenteUnitaire * (1 - remise / 100);
  }
  if (isAValue(prixVenteUnitaire)) {
    devisDetail.prixVenteTotalDevise = devisDetail.prixVenteTotal * cours;
    devisDetail.prixVenteUnitaireDevise = devisDetail.prixVenteUnitaire * cours;
  }
}

export function computeMontantTVA(devisDetail, tauxTVA, cours) {
  var prixVenteTotal = sanitizeValue(devisDetail.prixVenteTotal);
  var tva = 0;
  if (isAValue(tauxTVA)) {
    tva = sanitizeValue(tauxTVA);
  }
  devisDetail.montantTVA = prixVenteTotal * (tva / 100);
  devisDetail.montantTVADevise = devisDetail.montantTVA * cours;
}

export function computePrixVenteTotalTTC(devisDetail, cours) {
  var prixVenteTotal = sanitizeValue(devisDetail.prixVenteTotal);
  var montantTVA = sanitizeValue(devisDetail.montantTVA);
  devisDetail.prixVenteTotalTTC = prixVenteTotal + montantTVA;
  devisDetail.prixVenteTotalTTCDevise = devisDetail.prixVenteTotalTTC * cours;
}

export function computeTotals(devis) {
  var totalPrixVenteTotal = 0;
  var totalMontantTVA = 0;
  var totalPrixVenteTotalTTC = 0;

  var totalPrixVenteTotalDevise = 0;
  var totalMontantTVADevise = 0;
  var totalPrixVenteTotalTTCDevise = 0;

  for (let i in devis.devisDetails) {
    if (devis.devisDetails[i].type == 0) {
      totalPrixVenteTotal += sanitizeValue(
        devis.devisDetails[i].prixVenteTotal
      );
      totalMontantTVA += sanitizeValue(devis.devisDetails[i].montantTVA);
      totalPrixVenteTotalTTC += sanitizeValue(
        devis.devisDetails[i].prixVenteTotalTTC
      );
      totalPrixVenteTotalDevise += sanitizeValue(
        devis.devisDetails[i].prixVenteTotalDevise
      );
      totalMontantTVADevise += sanitizeValue(
        devis.devisDetails[i].montantTVADevise
      );
      totalPrixVenteTotalTTCDevise += sanitizeValue(
        devis.devisDetails[i].prixVenteTotalTTCDevise
      );
    }
  }

  devis.prixVenteTotal = totalPrixVenteTotal;
  devis.montantTVATotal = totalMontantTVA;
  devis.prixVenteTTCTotal = totalPrixVenteTotalTTC;

  devis.prixVenteTotalDevise = totalPrixVenteTotalDevise;
  devis.montantTVATotalDevise = totalMontantTVADevise;
  devis.prixVenteTTCTotalDevise = totalPrixVenteTotalTTCDevise;
}

export function computeSubTotals(devisDetails) {
  let prixVenteTotal = 0;
  let montantTVA = 0;
  let prixVenteTotalTTC = 0;

  let prixVenteTotalDevise = 0;
  let montantTVADevise = 0;
  let prixVenteTotalTTCDevise = 0;

  for (let i = 0; i < devisDetails.length; i++) {
    // Partant de la première ligne des devisDetails, on descend les lignes en sommant, jusqu'a tomber sur une ligne de sous-total (type = 2), où on place la somme.
    if (devisDetails[i].type === 2) {
      devisDetails[i].prixVenteTotal = prixVenteTotal;
      prixVenteTotal = 0;
      devisDetails[i].montantTVA = montantTVA;
      montantTVA = 0;
      devisDetails[i].prixVenteTotalTTC = prixVenteTotalTTC;
      prixVenteTotalTTC = 0;

      devisDetails[i].prixVenteTotalDevise = prixVenteTotalDevise;
      prixVenteTotalDevise = 0;
      devisDetails[i].montantTVADevise = montantTVADevise;
      montantTVADevise = 0;
      devisDetails[i].prixVenteTotalTTCDevise = prixVenteTotalTTCDevise;
      prixVenteTotalTTCDevise = 0;
    } else if (devisDetails[i].type == 0) {
      prixVenteTotal += devisDetails[i].prixVenteTotal;
      montantTVA += devisDetails[i].montantTVA;
      prixVenteTotalTTC += devisDetails[i].prixVenteTotalTTC;

      prixVenteTotalDevise += devisDetails[i].prixVenteTotalDevise;
      montantTVADevise += devisDetails[i].montantTVADevise;
      prixVenteTotalTTCDevise += devisDetails[i].prixVenteTotalTTCDevise;
    }
  }
}
