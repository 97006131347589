import { useEffect, useRef } from "react";

/**
 * Hook personalisé, similaire à useEffect de React mais n'est pas appelé lors de l'initialisation de la page
 * @param {*} effect, fonction à appliquer à chaque changement sur les variables observées
 * @param {*} deps, tableau des variables observées
 */
export const useNonInitialEffect = (effect, deps) => {
  const initialRender = useRef(true);

  useEffect(() => {
    let effectReturns = {};

    if (initialRender.current) {
      initialRender.current = false;
    } else {
      effectReturns = effect();
    }

    if (effectReturns && typeof effectReturns === "function") {
      return effectReturns;
    }
  }, deps);
};
