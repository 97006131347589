import React from "react";
import { PropTypes } from "prop-types";

import { BoxInformationsInline, Box, ButtonIcon } from "_components";

import { ToLocaleDateString, IfNullToText } from "_utils";

import {
  faPlus,
  faCheck,
  faTimes,
  faPen,
  faExclamationTriangle,
  faEyeSlash,
  faEye,
} from "@fortawesome/free-solid-svg-icons";
import { LigneElement } from "./LigneElement";

import {
  PacksService,
  ProduitInterneService,
  ContactService,
  ClientService,
  ArticleASuivreDansBFAService,
  FamilleArticleService,
} from "_services";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { Tooltip, OverlayTrigger } from "react-bootstrap";

class General extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      callbackForceUpdateContactUtilisateur: null,
      savedMateriel: null,
      showingElementsAuRebus: false,
    };

    this.handleClickEdition = this.handleClickEdition.bind(this);
    this.handleClickUndo = this.handleClickUndo.bind(this);
    this.handleClickValidate = this.handleClickValidate.bind(this);
    this.showElementsAuRebus = this.showElementsAuRebus.bind(this);
    this.setForceUpdateContactUtilisateur =
      this.setForceUpdateContactUtilisateur.bind(this);
  }

  handleClickEdition() {
    this.setState({
      savedMateriel: { ...this.props.materiel },
    });
    this.props.handleEditingElements(true);
  }

  handleClickUndo() {
    this.props.handleChange("materiel", this.state.savedMateriel);
    this.props.handleEditingElements(false);
  }

  handleClickValidate() {
    this.props.handleUpdate();
    this.props.handleEditingElements(false);
  }

  showElementsAuRebus(b) {
    this.setState({ showingElementsAuRebus: b });
  }

  setForceUpdateContactUtilisateur(callbackFunction) {
    this.setState({
      callbackForceUpdateContactUtilisateur: callbackFunction,
    });
  }

  render() {
    const bandeauEnHaut = [
      {
        label: "Numéro de Série",
        accessor: "num_Serie",
        type: this.props.materiel?.creeDepuisUnBFASage ? null : "text",
        colSize: 3,
      },
      {
        label: "Référence article",
        accessor: "articleASuivre",
        type: this.props.materiel?.creeDepuisUnBFASage ? null : "selectSearch",
        optionFieldToDisplay: "reference",
        valueFieldToDisplay: "reference",
        functionAppliedToValue: (value) => {
          return value?.reference ?? "-";
        },
        service: ArticleASuivreDansBFAService.getAll,
        colSize: 3,
        afterUpdateFunction: () => {
          this.props.handleChange(
            "type_Materiel",
            this.props.materiel.articleASuivre?.familleArticle,
            this.props.handleChange(
              "produitInterne",
              this.props.materiel.articleASuivre?.produitInterne
            )
          );
        },
      },
      {
        label: "Produit interne",
        accessor: "produitInterne",
        colSize: 3,
        functionAppliedToValue: (value) => {
          return value?.designation ?? "-";
        },
        type: this.props.materiel?.creeDepuisUnBFASage ? null : "selectSearch",
        optionFieldToDisplay: "designation",
        valueFieldToDisplay: "designation",
        service: ProduitInterneService.getAll,
      },

      {
        label: "Descriptif",
        accessor: "type_Materiel",
        type: this.props.materiel?.creeDepuisUnBFASage ? null : "selectSearch",
        optionFieldToDisplay: "designation",
        valueFieldToDisplay: "designation",
        functionAppliedToValue: (value) => value?.designation ?? "-",
        service: FamilleArticleService.getAll,
        colSize: 3,
      },
    ];
    let informationsMateriel = [
      {
        label: "Location",
        accessor: "isLocation",
        type: "checkbox",
        functionAppliedToValue: (value) => {
          return value ? "Oui" : "Non";
        },
        colSize: 3,
      },
      {
        label: "Au Rebus",
        accessor: "au_Rebus",
        type: "checkbox",
        functionAppliedToValue: (value) => {
          return value ? "Oui" : "Non";
        },
        colSize: 3,
      },
      {
        label: "Date rebus",
        accessor: "date_Rebus",
        type: "date",
        functionAppliedToValue: (date) => <div>{ToLocaleDateString(date)}</div>,
        colSize: 3,
      },
      {
        label: "Volé ",
        accessor: "isStolen",
        type: "checkbox",
        functionAppliedToValue: (value) => {
          return value ? "Oui" : "Non";
        },
        colSize: 3,
      },
      {
        label: "Date Fabrication",
        accessor: "date_Fabrication",
        type: this.props.materiel?.creeDepuisUnBFASage ? null : "date",
        functionAppliedToValue: (date) => <div>{ToLocaleDateString(date)}</div>,
        colSize: 3,
      },
      {
        label: "Pack",
        accessor: "pack",
        type: "selectSearch",
        optionFieldToDisplay: "designation",
        valueFieldToDisplay: "designation",
        service: PacksService.getAll,
        colSize: 3,
        functionAppliedToValue: (value) => {
          return value?.designation ?? "-";
        },
      },
      {
        label: "Date Fin Garantie",
        accessor: "date_Fin_Garantie",
        type: "date",
        functionAppliedToValue: (date) => <div>{ToLocaleDateString(date)}</div>,
        colSize: 3,
      },
      {
        label: "Num Fabrication",
        accessor: "numero_Fabrication",
        type: this.props.materiel?.creeDepuisUnBFASage ? null : "text",
        colSize: 3,
      },
    ];
    if (this.props.materiel.produitInterne?.designation === "GRZ") {
      informationsMateriel.push({
        label: "Energie variable",
        accessor: "energieVariable",
        type: "checkbox",
        functionAppliedToValue: (value) => {
          return value ? "Oui" : "Non";
        },
        colSize: 3,
      });
    }

    const blocCommentaire = [
      {
        label: "Commentaire",
        accessor: "commentaire",
        type: "textArea",
        functionAppliedToValue: (el) => {
          if (el != null) {
            return <div className="keepWhiteSpace">{el}</div>;
          }
        },
        colSize: 12,
      },
    ];

    const informationsLivraison = [
      {
        label: "Date livraison",
        accessor: "date_livraison",
        type: this.props.materiel?.misAJourDepuisUnBLSage ? null : "date",
        functionAppliedToValue: (date) => ToLocaleDateString(date),
        colSize: 3,
      },
      {
        label: "Client propriétaire",
        accessor: "proprietaire",
        colSize: 3,
        functionAppliedToValue: (value) => {
          return (
            <div className="d-flex justify-content-start align-items-center">
              <div>
                {value ? (
                  <a
                    href={"/clients/" + value.id}
                    target="_blank"
                    data-text={value.nom_Client}
                    className="fixWidthHover"
                  >
                    {value.nom_Client}
                  </a>
                ) : (
                  "-"
                )}
              </div>
              {this.props.materiel.multipleClientsPossible ? (
                <OverlayTrigger
                  placement="top"
                  delay={{ show: 1000, hide: 100 }}
                  overlay={
                    <Tooltip id="tooltip-top">
                      Ce client a été choisis automatiquement parmis plusieurs
                      clients ayant le même compte tier
                    </Tooltip>
                  }
                >
                  <span>
                    <FontAwesomeIcon
                      className="text-danger"
                      icon={faExclamationTriangle}
                    />
                  </span>
                </OverlayTrigger>
              ) : null}
            </div>
          );
        },
        type: "selectSearch",
        service: ClientService.getForSelectSearch,
        optionFieldToDisplay: "nom_Client",
        valueFieldToDisplay: "nom_Client",
        required: true,
      },
      {
        label: "Client utilisateur",
        accessor: "utilisateur",
        colSize: 3,
        functionAppliedToValue: (value) => {
          return (
            <div>
              {value ? (
                <a
                  href={"/clients/" + value.id}
                  target="_blank"
                  data-text={value.nom_Client}
                  className="fixWidthHover"
                >
                  {value.nom_Client}
                </a>
              ) : (
                "-"
              )}
            </div>
          );
        },
        type: "selectSearch",
        service: ClientService.getForSelectSearch,
        optionFieldToDisplay: "nom_Client",
        valueFieldToDisplay: "nom_Client",
        afterUpdateFunction: () => {
          this.props.handleChange(
            "contactUtilisateur",
            null,
            this.state.callbackForceUpdateContactUtilisateur
          );
        },
      },
      {
        label: "Contact Utilisateur",
        accessor: "contactUtilisateur",
        type: "selectSearch",
        service: () =>
          ContactService.getByIdClient(this.props.materiel.utilisateur?.id),
        optionFieldToDisplay: ["nom_Contact", "prenom_Contact"],
        valueFieldToDisplay: ["nom_Contact", "prenom_Contact"],
        colSize: 3,
        functionAppliedToValue: (value) => {
          return (
            <div className="d-flex justify-content-start align-items-center">
              <div>
                {value ? (
                  <a
                    href={"/contacts/" + value.id}
                    target="_blank"
                    data-text={
                      IfNullToText(value.nom_Contact, null) +
                      " " +
                      IfNullToText(value.prenom_Contact, null)
                    }
                    className="fixWidthHover"
                  >
                    {IfNullToText(value.nom_Contact, null) +
                      " " +
                      IfNullToText(value.prenom_Contact, null)}
                  </a>
                ) : (
                  "-"
                )}
              </div>
            </div>
          );
        },
        setForceUpdateFunction: this.setForceUpdateContactUtilisateur,
      },
      {
        label: "Num Livraison",
        accessor: "numero_Livraison",
        type: this.props.materiel?.misAJourDepuisUnBLSage ? null : "text",
        colSize: 3,
      },
    ];

    const informationsFiche = [
      {
        label: "Date de création",
        accessor: "date_Create",
        functionAppliedToValue: (value) => {
          return value
            ? ToLocaleDateString(this.props.materiel.date_Create) +
                " par " +
                IfNullToText(this.props.materiel.user_Create?.nom, "inconnu") +
                " " +
                IfNullToText(this.props.materiel.user_Create?.prenom, "")
            : "-";
        },
        colSize: 6,
      },
      {
        label: "Modification",
        accessor: "date_Modif",
        functionAppliedToValue: (value) => {
          return value
            ? ToLocaleDateString(this.props.materiel.date_Modif) +
                " par " +
                IfNullToText(this.props.materiel.user_Modif?.nom, "inconnu") +
                " " +
                IfNullToText(this.props.materiel.user_Modif?.prenom, "")
            : "-";
        },
        colSize: 6,
      },
    ];

    const elementLogiciel = [
      {
        label: "Reference",
        accessor: "elementLogiciel.reference",
        type: "text",
        colSize: 3,
      },
      {
        label: "Numéro de Version",
        accessor: "elementLogiciel.numVersion",
        type: "text",
        colSize: 3,
      },
    ];

    return (
      <>
        <div className="row ficheMateriel">
          <div className="col-12 boxBandeauEnHaut">
            <BoxInformationsInline
              title="Général"
              datas={bandeauEnHaut}
              state={this.props.materiel}
              stateFieldNameToUpdate={this.props.stateFieldNameToUpdate}
              service={this.props.service}
              handleChange={this.props.handleChange}
              handleEditing={this.props.handleEditing}
              editing={this.props.editing}
            />
          </div>
          <div className="col-12 boxInformationsMateriel">
            <BoxInformationsInline
              title="Informations Matériel"
              datas={informationsMateriel}
              state={this.props.materiel}
              stateFieldNameToUpdate={this.props.stateFieldNameToUpdate}
              service={this.props.service}
              handleChange={this.props.handleChange}
              handleEditing={this.props.handleEditing}
              editing={this.props.editing}
            />
            <BoxInformationsInline
              title="Informations Livraison"
              datas={informationsLivraison}
              state={this.props.materiel}
              stateFieldNameToUpdate={this.props.stateFieldNameToUpdate}
              service={this.props.service}
              handleChange={this.props.handleChange}
              handleEditing={this.props.handleEditing}
              editing={this.props.editing}
            />
          </div>
          <div className="col-12 boxCommentaire">
            <BoxInformationsInline
              title="Commentaire"
              datas={blocCommentaire}
              state={this.props.materiel}
              stateFieldNameToUpdate={this.props.stateFieldNameToUpdate}
              service={this.props.service}
              handleChange={this.props.handleChange}
              handleEditing={this.props.handleEditing}
              editing={this.props.editing}
            />
          </div>
          <div className="col-12 boxElementLogiciel">
            <BoxInformationsInline
              title="Element Logiciel"
              datas={elementLogiciel}
              state={this.props.materiel}
              stateFieldNameToUpdate={this.props.stateFieldNameToUpdate}
              service={this.props.service}
              handleChange={this.props.handleChange}
              handleEditing={this.props.handleEditing}
              editing={this.props.editing}
            />
          </div>
        </div>
        <div className="row ficheMateriel">
          <div className="col-12 boxElements">
            <Box
              noPaddingBottom={true}
              header={
                <>
                  <div className="col-6 align-self-center py-2">
                    <div>Elements</div>
                  </div>
                  <div className="col-6 text-end py-2">
                    <ButtonIcon
                      icon={faPlus}
                      iconSize="sm"
                      onClick={() => this.props.addLineElement?.()}
                      className="btn btn-success addElement"
                      style={
                        !this.props.editingElements ? { display: "none" } : {}
                      }
                    ></ButtonIcon>
                    <ButtonIcon
                      icon={faCheck}
                      className="btn btn-success text-light ms-1"
                      style={
                        !this.props.editingElements
                          ? { display: "none" }
                          : { width: "42px" }
                      }
                      onClick={this.handleClickValidate}
                    ></ButtonIcon>
                    <ButtonIcon
                      icon={faTimes}
                      className="btn btn-danger text-light ms-1"
                      onClick={this.handleClickUndo}
                      style={
                        !this.props.editingElements
                          ? { display: "none" }
                          : { width: "42px" }
                      }
                    ></ButtonIcon>
                    <ButtonIcon
                      icon={faPen}
                      className="btn btn-secondary text-light ms-1"
                      onClick={this.handleClickEdition}
                      style={
                        this.props.editing
                          ? { display: "none" }
                          : { width: "42px" }
                      }
                    ></ButtonIcon>
                  </div>
                </>
              }
              body={
                <>
                  <table className="mt-1 text-uppercase">
                    <thead
                      className={
                        "sticky-top" +
                        (this.props.materiel.elementsMateriels?.length == 0
                          ? " theadBorderRadius"
                          : "")
                      }
                    >
                      <tr>
                        <th>Reference</th>
                        <th>Type</th>
                        <th>Numéro de série</th>
                        <th>Date de fabrication</th>
                        <th>Date de fin de garantie</th>
                        <th>Au rebus ?</th>
                        <th>Date rebus</th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.props.materiel.elementsMateriels
                        ?.slice()
                        ?.filter(
                          (el) =>
                            this.state.showingElementsAuRebus || !el.au_Rebus
                        )
                        ?.sort(
                          (a, b) =>
                            a.au_Rebus - b.au_Rebus ||
                            new Date(b.date_Fabrication) -
                              new Date(a.date_Fabrication)
                        )
                        .map((element) => (
                          <LigneElement
                            key={element.id}
                            element={element}
                            handleChange={this.props.handleChange}
                            editing={this.props.editingElements}
                            showingElementsAuRebus={
                              this.props.showingElementsAuRebus
                            }
                            deleteLineElement={this.props.deleteLineElement}
                          />
                        ))}
                    </tbody>
                  </table>
                  {this.state.showingElementsAuRebus ? (
                    <div style={{ textAlign: "right" }}>
                      <ButtonIcon
                        icon={faEyeSlash}
                        iconSize="sm"
                        onClick={() => this.showElementsAuRebus?.(false)}
                        className="btn btn-secondary"
                        style={{ width: "40px" }}
                        tooltip="Afficher les éléments au rebus"
                      ></ButtonIcon>
                    </div>
                  ) : (
                    <div style={{ textAlign: "right" }}>
                      <ButtonIcon
                        icon={faEye}
                        iconSize="sm"
                        onClick={() => this.showElementsAuRebus?.(true)}
                        className="btn btn-secondary"
                        style={{ width: "40px" }}
                        tooltip="Afficher les éléments au rebus"
                      ></ButtonIcon>
                    </div>
                  )}
                </>
              }
            />
          </div>
        </div>
        <div className="row justify-content-end">
          <div className="col-lg-6 col-md-12 boxInformationsFiche">
            <BoxInformationsInline
              title="Informations sur la fiche"
              datas={informationsFiche}
              state={this.props.materiel}
              stateFieldNameToUpdate={this.props.stateFieldNameToUpdate}
              service={this.props.service}
              handleChange={this.props.handleChange}
              colSize="3"
              boxSize="small"
              NoEdition
            />
          </div>
        </div>
      </>
    );
  }
}

General.propTypes = {
  materiel: PropTypes.object,
  stateFieldNameToUpdate: PropTypes.string,
  handleChange: PropTypes.func,
  history: PropTypes.object,
  service: PropTypes.any,
};

export { General };
