import React from "react";
import { PropTypes } from "prop-types";

import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";

import { FormWithValidation } from "_components";

/**
 * Génère un composant Dialog de Material-UI avec son bouton associé
 * @param {*} props
 * @returns un composant Dialog de Material-UI avec son bouton associé
 */
class DialogForm extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      openedDialog: false,
    };

    this.openDialog = this.openDialog.bind(this);
    this.closeDialog = this.closeDialog.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  componentDidMount() {
    this.props.setOpenDialog?.(this.openDialog);
  }

  openDialog() {
    this.setState({ openedDialog: true });
  }

  closeDialog() {
    this.setState({ openedDialog: false });
    this.props.onClose?.();
  }

  onSubmit(event) {
    event.preventDefault();
    if (this.props.validation != null) {
      if (this.props.validation()) {
        this.props.onValidate?.();
        this.setState({ openedDialog: false });
      }
    } else {
      this.props.onValidate?.();
      this.setState({ openedDialog: false });
    }
  }

  render() {
    return (
      <>
        <Dialog
          maxWidth="xl"
          open={this.state.openedDialog}
          onClose={(event, reason) => {
            if (reason != "backdropClick") this.closeDialog();
          }}
          scroll="paper"
          fullScreen={this.props.fullScreen}
          PaperProps={{
            style: { overflowY: this.props.fullScreen ? "scroll" : "visible" },
          }}
        >
          <FormWithValidation
            className="d-flex flex-column"
            onSubmit={this.onSubmit}
          >
            <DialogTitle>{this.props.dialogTitle}</DialogTitle>
            <DialogContent className="overflow-visible">
              {this.props.body}
            </DialogContent>
            <DialogActions>
              <button
                onClick={this.closeDialog}
                className="btn btn-secondary"
                type="button"
              >
                {this.props.labelCancelButton ?? "Fermer"}
              </button>
              <button className="btn btn-primary" autoFocus type="submit">
                {this.props.labelValidateButton}
              </button>
            </DialogActions>
          </FormWithValidation>
        </Dialog>
      </>
    );
  }
}

DialogForm.propTypes = {
  body: PropTypes.any,
  dialogTitle: PropTypes.string,
  fullScreen: PropTypes.bool,
  labelCancelButton: PropTypes.string,
  labelValidateButton: PropTypes.string,
  style: PropTypes.any,
  onClick: PropTypes.func,
  onClose: PropTypes.func,
  onValidate: PropTypes.func,
  setOpenDialog: PropTypes.func,
  validation: PropTypes.func,
};

export { DialogForm };
