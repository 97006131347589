import SelectSearch, { fuzzySearch } from "react-select-search";
import { PropTypes } from "prop-types";
import { ButtonIcon } from "_components/Buttons/ButtonIcon";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import React from "react";

import "./ReactSelectSearch.css";
import { isEqual } from "lodash";

class ReactSelectSearch extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      options: [], // Format {value: 92, name: "France"}, liste des éléments affichés par le component react-select-search
      values: [], // Format {id: 92, nom_Fr: 'France', …}, données associées aux éléments du component react-select-search
    };

    this.getOptions.bind(this);
    this.handleChange.bind(this);
    this.renderValue.bind(this);
  }

  shouldComponentUpdate(nextProps, nextStates) {
    return !(isEqual(nextProps, this.props) && isEqual(nextStates, this.state));
  }

  /**
   * Fonction permettant de récupérer la valeur à afficher
   * @param {*} option, option dont on souhaite afficher la valeur
   * @returns la valeur de l'option
   */
  getFieldToDisplay(option) {
    // Si on utilise un objet
    if (this.props.displayField && option) {
      if (Array.isArray(this.props.displayField)) {
        // Si on passe un tableau de propriete
        let optionValue = "";
        optionValue = this.props.displayField
          .map((key) => option[key])
          .join(" ");
        return optionValue.toString();
      } else {
        // Si on passe une seul propriete
        return option[this.props.displayField].toString();
      }
    }
    // Si on utilise un type primitif
    return option ? option : "";
  }

  /**
   * Fonction appelée par le component react-select-search pour obtenir les options
   * @param {*} query
   * @returns Une promise qui retourne la liste des options de la liste
   */
  getOptions = (query) => {
    if (typeof this.props.service === "function") {
      return this.props
        .service(query)
        .then((res) => {
          // on check data.data si le back nous renvoie les données comme pour le composant "base de recherche"
          let resDatas = res.data && res.data.datas ? res.data.datas : res.data;
          // On enlève les valeurs null du tableau des résultats
          resDatas = resDatas.filter((n) => n);
          // On transforme le tableau des résultats en éléments exploitables
          let selectValues = resDatas.map((elem, index) => ({
            value: elem && elem.id ? elem.id : index,
            name: this.getFieldToDisplay(elem).toString(),
          }));
          selectValues.unshift({
            value: -1,
            name: "",
          });
          this.setState({ options: selectValues, values: resDatas });
          return selectValues;
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      this.setState({ options: [] });
      return [];
    }
  };

  /**
   * Handle appelée par le composant react-select-search.
   * Ne met pas à jour l'état du component lui-même.
   * Appelle la handleChange du formulaire, pour indiquer le changement de valeur (via accessor/value)
   * Suite à ça, l'état du component est récupéré du parent via props.value lors du render.
   *
   * @param {*} selectedOptionId Id de l'option séléctionnée.
   */
  handleChange = (selectedOptionId) => {
    this.props.handleChange(
      this.props.accessor,
      this.state.values.find((val, index) =>
        val.id ? val.id === selectedOptionId : index === selectedOptionId
      ),
      this.props.handleBlur
    );
  };

  // Controls the rendering of the value/input element
  renderValue = (valueProps) => (
    <div className="input-group has-validation">
      <input
        {...valueProps}
        placeholder={this.props.placeholder}
        className="form-select reactSelectSearch"
        required={this.props.required}
        disabled={this.props.disabled}
      />
      <ButtonIcon
        id={"inputGroup" + this.props.accessor}
        smallText=""
        icon={faTimes}
        iconSize="sm"
        onClick={() => {
          this.handleChange(-1);
        }}
        className="btn btn-danger"
        tabIndex="-1"
      ></ButtonIcon>
      <div id={"validation" + this.props.accessor} className="invalid-feedback">
        {this.props.invalidText}
      </div>
    </div>
  );

  render() {
    return (
      <>
        <div className=" text-uppercase text-muted">{this.props.label}</div>

        <SelectSearch
          options={[
            {
              name: this.getFieldToDisplay(this.props.value),
              value:
                this.props.value && this.props.value.id
                  ? this.props.value.id
                  : -1,
            },
          ]}
          getOptions={this.getOptions}
          emptyMessage="Aucun résultat trouvé."
          debounce={this.props.debounce}
          name={this.props.accessor}
          value={this.props.value ? this.props.value.id : -1}
          search
          filterOptions={fuzzySearch}
          renderOption={(props, option, snapshot, className) => (
            <button
              {...props}
              className={className}
              type="button"
              style={{ height: "auto", minHeight: "30px" }}
            >
              <span>
                <span>{option.name}</span>
              </span>
            </button>
          )}
          onChange={this.handleChange}
          renderValue={this.renderValue}
          aria-describedby={
            "inputGroup" +
            this.props.accessor +
            " validation" +
            this.props.accessor
          }
        />
      </>
    );
  }
}

ReactSelectSearch.propTypes = {
  service: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.object,
    PropTypes.array,
  ]),
  colSize: PropTypes.any,
  value: PropTypes.any,
  displayField: PropTypes.any,
  queryLimit: PropTypes.any,
  placeholder: PropTypes.string,
  debounce: PropTypes.number,
  label: PropTypes.any,
  accessor: PropTypes.any,
  handleChange: PropTypes.any,
  required: PropTypes.bool,
  validText: PropTypes.string,
  invalidText: PropTypes.string,
  // Non implémenté
  // creationRedirectionFunction: PropTypes.func,
  handleBlur: PropTypes.func,
  disabled: PropTypes.bool,
};

ReactSelectSearch.defaultProps = {
  service: new Promise(() => {
    return null;
  }),
  displayField: "nom_Fr",
  placeholder: "",
  debounce: 300,
  required: true,
  handleChange: () => {
    return null;
  },
  handleBlur: () => {
    return null;
  },
};

export { ReactSelectSearch };
