import React from "react";
import { PropTypes } from "prop-types";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import ImageUploading from "react-images-uploading";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

function ImageInput(props) {
  const [images, setImages] = React.useState([
    { data_url: props.value, file: { name: "photo.png" } },
  ]);
  const onChange = (imageList) => {
    // données pour soumissions
    setImages(imageList);
  };

  React.useEffect(() => {
    if (images.length) {
      // Si une ou plusieurs images ont été déposées
      props.handleChange(props.accessor, images[0].data_url);
    } else {
      // Si on supprimer la photo
      props.handleChange(props.accessor);
    }
  }, [images]);

  return (
    <ImageUploading value={images} onChange={onChange} dataURLKey="data_url">
      {({ onImageUpload, onImageRemoveAll, isDragging, dragProps }) => (
        <div className="upload__image-wrapper input-group">
          <button
            type="button"
            className="text-start form-control overflow-hidden"
            style={{ whiteSpace: "nowrap" }}
            onClick={onImageUpload}
            {...dragProps}
          >
            {isDragging ? (
              "Vous pouvez relacher !"
            ) : images.length && images[0].data_url ? (
              <div className="row">
                <div className="col-4">
                  <img src={images[0].data_url} width="auto" height="23px" />
                </div>
                <span className="col-8">Image téléchargée</span>
              </div>
            ) : (
              "Cliquer ou déposer ici"
            )}
          </button>
          <button
            className="btn btn-danger"
            type="button"
            onClick={onImageRemoveAll}
          >
            <FontAwesomeIcon {...dragProps} icon={faTimes} size="sm" />
          </button>
        </div>
      )}
    </ImageUploading>
  );
}

ImageInput.propTypes = {
  value: PropTypes.any,
  accessor: PropTypes.string,
  handleChange: PropTypes.func,
};

export { ImageInput };