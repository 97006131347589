import React, { Fragment } from "react";

import { ButtonIcon } from "_components";
import { faMinusSquare, faPlusSquare } from "@fortawesome/free-solid-svg-icons";

import { ToLocaleDateString } from "_utils";

import { PropTypes } from "prop-types";

class AccordionParAnnee extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isAccordionOpen: props.isInitiallyOpen,
    };

    this.renderAccordionFirstLine = this.renderAccordionFirstLine.bind(this);
  }

  renderAccordionFirstLine() {
    return (
      <>
        <tr key={this.props.index}>
          <td>
            <ButtonIcon
              icon={this.state.isAccordionOpen ? faMinusSquare : faPlusSquare}
              className="btn"
              onClick={() =>
                this.setState((prevState) => ({
                  isAccordionOpen: !prevState.isAccordionOpen,
                }))
              }
              style={{ width: "32px" }}
            ></ButtonIcon>
            {this.props.annee}
          </td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
        </tr>
      </>
    );
  }

  render() {
    if (!this.state.isAccordionOpen) {
      return <>{this.renderAccordionFirstLine()}</>;
    } else {
      return (
        <>
          {this.renderAccordionFirstLine()}
          {this.props.devis.map((devis) => {
            return (
              <Fragment key={devis.id}>
                <tr key={devis.id}>
                  <td className="tableIndent0"></td>
                  <td className="tableIndent0">
                    {"Devis : "}{" "}
                    <a
                      className="fixWidthHover"
                      href={"/devis/" + devis.id}
                      target={"_blank"}
                      data-text={devis.reference}
                    >
                      {devis.reference}
                    </a>{" "}
                    {" " + devis.nom_Devis}
                  </td>
                  <td className="tableIndent0">
                    {ToLocaleDateString(devis.date_Create)}
                  </td>
                  <td className="tableIndent0">{devis.prixVenteTotal}</td>
                  <td className="tableIndent0"></td>
                  <td className="tableIndent0"></td>
                </tr>
                {devis.commandes.map((commande) => {
                  return (
                    <Fragment key={commande.id}>
                      <tr key={commande.id}>
                        <td className="tableIndent1"></td>
                        <td className="tableIndent1">
                          {"Commande : "}
                          <a
                            href={"/commandes/" + commande.id}
                            target={"_blank"}
                          >
                            {commande.reference}
                          </a>
                          {" " + commande.nom_Commande}
                        </td>
                        <td className="tableIndent1">
                          {ToLocaleDateString(commande.date_Create)}
                        </td>
                        <td className="tableIndent1">
                          {commande.prixVenteTotal}
                        </td>
                        <td className="tableIndent1"></td>
                        <td className="tableIndent1"></td>
                      </tr>
                      {commande.factures.map((facture, index) => {
                        return (
                          <tr key={index}>
                            <td></td>
                            <td className="tableIndent2">
                              Facture : {facture.num_Facture}
                            </td>
                            <td className="tableIndent2">
                              {ToLocaleDateString(facture.dateCreation)}
                            </td>
                            <td className="tableIndent2">
                              {facture.prixTotalHT}
                            </td>
                            <td className="tableIndent2">
                              {facture.estReglee ? "Oui" : "Non"}
                            </td>
                            <td className="tableIndent2">
                              {facture.montantRegle}
                            </td>
                          </tr>
                        );
                      })}
                    </Fragment>
                  );
                })}
              </Fragment>
            );
          })}

          {this.props.commandes.map((commande) => {
            return (
              <Fragment key={commande.id}>
                <tr key={commande.id}>
                  <td className="tableIndent0"></td>
                  <td className="tableIndent0">
                    {"Commande : "}
                    <a
                      href={"/commandes/" + commande.id}
                      target={"_blank"}
                      data-text={commande.reference}
                      className="fixWidthHover"
                    >
                      {commande.reference}
                    </a>
                    {" " + commande.nom_Commande}
                  </td>
                  <td className="tableIndent0">
                    {ToLocaleDateString(commande.date_Create)}
                  </td>
                  <td className="tableIndent0">{commande.prixVenteTotal}</td>
                  <td className="tableIndent0"></td>
                  <td className="tableIndent0"></td>
                </tr>
                {commande.factures.map((facture) => {
                  return (
                    <tr key={facture.num_Facture}>
                      <td className="tableIndent1"></td>
                      <td className="tableIndent1">
                        Facture : {facture.num_Facture}
                      </td>
                      <td className="tableIndent1">
                        {ToLocaleDateString(facture.dateCreation)}
                      </td>
                      <td className="tableIndent1">{facture.prixTotalHT}</td>
                      <td className="tableIndent1">
                        {facture.estReglee ? "Oui" : "Non"}
                      </td>
                      <td className="tableIndent1">{facture.montantRegle}</td>
                    </tr>
                  );
                })}
              </Fragment>
            );
          })}

          {this.props.factures.map((facture, index) => {
            return (
              <tr key={index}>
                <td className="tableIndent0"></td>
                <td className="tableIndent0">
                  Facture : {facture.num_Facture}
                </td>
                <td className="tableIndent0">
                  {ToLocaleDateString(facture.dateCreation)}
                </td>
                <td className="tableIndent0">{facture.prixTotalHT}</td>
                <td className="tableIndent0">
                  {facture.estReglee ? "Oui" : "Non"}
                </td>
                <td className="tableIndent0">{facture.montantRegle}</td>
              </tr>
            );
          })}
        </>
      );
    }
  }
}

AccordionParAnnee.propTypes = {
  index: PropTypes.number,
  annee: PropTypes.string,
  isInitiallyOpen: PropTypes.bool,
  devis: PropTypes.any,
  commandes: PropTypes.any,
  factures: PropTypes.any,
};

export { AccordionParAnnee };
