import http from "http-common";

const FonctionService = class {
  /**
   * Fonction permettant de récuperer tous les Fonctions avec une possibilité de filtre via params
   * @returns la liste de tous les Fonctions
   */
  static getAll(params) {
    let url = "/Fonctions";
    
    //Gestion de la valeur par défaut de la propriété "isActive" des paramètres
    if (!params)
      return http.get(url, {params: {isActive: true}});
    else if (typeof params === "object" && params.isActive === undefined)
        return http.get(url, {params: {...params, isActive: true}});
    else if (typeof params === "object" && params.isActive !== undefined)
      return http.get(url, {params});
    else
      return http.get(url, {params: {isActive: true}});
  }

  /**
   * Fonction permettant de récuperer un Fonctions via son id
   * @param {*} id, id de du Fonctions à récupérer
   * @returns le Fonctions souhaité
   */
  static getById(id) {
    return http.get("/Fonctions/" + id);
  }
};

export { FonctionService };
