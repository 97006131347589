import http from "http-common";

const PersonnelMotifRuptureService = class {
  /**
   * Fonction permettant de récuperer toutes les civilités
   * @returns la liste de toutes les civilités
   */
  static getAll(params) {
    let url = "/PersonnelMotifRupture";

    //Gestion de la valeur par défaut de la propriété "isActive" des paramètres
    if (!params) return http.get(url, { params: { isActive: true } });
    else if (typeof params === "object" && params.isActive === undefined)
      return http.get(url, { params: { ...params, isActive: true } });
    else if (typeof params === "object" && params.isActive !== undefined)
      return http.get(url, { params });
    else return http.get(url, { params: { isActive: true } });
  }

  /**
   * Fonction permettant de récupérer une civilité via son id
   * @param {*} id, id de la civilité à récupérer
   * @returns la civilité souhaitée
   */
  static getById(id) {
    return http.get("/MotifRupture/" + id);
  }
};

export { PersonnelMotifRuptureService };
