import React from "react";
import { PropTypes } from "prop-types";

import { Box } from "_components";

import { ToLocaleDateString } from "_utils";

class SAV extends React.PureComponent {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <>
        <div className="row ficheMateriel">
          <div className="col-12 boxTableCVM">
            <Box
              noPaddingBottom={true}
              header={
                <div className="col-6 align-self-center py-2">
                  <div>SAV</div>
                </div>
              }
              body={
                <table className=" text-uppercase table table-sm table-striped table-bordered">
                  <thead>
                    <tr>
                      <th>Reference</th>
                      <th>Date de création</th>
                      <th>Etat d'avancement</th>
                      <th>Type de panne</th>
                      <th>Utilisation</th>
                      <th>Sous Contrat</th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.props.materiel.fichesDeSuivi?.map((fds) => {
                      return (
                        <tr key={fds.id}>
                          <td>
                            <a
                              href={"/fichesdesuivi/" + fds.id}
                              target="_blank"
                            >
                              {fds.reference}
                            </a>
                          </td>
                          <td>{ToLocaleDateString(fds.date_Create)}</td>
                          <td>{fds.etat_Avancement?.designation}</td>
                          <td>{fds.type_Panne?.panne}</td>
                          <td>{fds.utilisation?.designation}</td>
                          <td>{fds.isSousAbonnement ? "Oui" : "Non"}</td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              }
            />
          </div>
        </div>
      </>
    );
  }
}

SAV.propTypes = {
  materiel: PropTypes.object,
  stateFieldNameToUpdate: PropTypes.string,
  handleChange: PropTypes.func,
  history: PropTypes.object,
  service: PropTypes.any,
};

export { SAV };
