import React from "react";
import { App } from "App";

import { Box } from "_components";

import { WarningBar } from "_components";

import { ToLocaleDateString } from "_utils";
class Affaires extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = { processId: null, personnelId: null };
  }

  render() {
    let right = App.RightsGuard?.current?.hasRight("Client", "Historique");
    if (right == "RW" || right == "RO") {
      return (
        <>
          <Box
            noPaddingBottom={true}
            header={
              <div className="row text-align-center">
                <div className="col-6 py-2">Affaires</div>
              </div>
            }
            body={
              <table className="table table-striped table-bordered table-sm">
                <thead
                  className={
                    "sticky-top" +
                    (this.props.client?.affaires?.length == 0
                      ? " theadBorderRadius"
                      : "")
                  }
                >
                  <tr>
                    <th className="text-nowrap ps-2" style={{ width: "12%" }}>
                      Reference
                    </th>
                    <th className="text-nowrap ps-2">Nom</th>
                    <th className="text-nowrap ps-2" style={{ width: "12%" }}>
                      Produit interne
                    </th>
                    <th className="text-nowrap ps-2" style={{ width: "12%" }}>
                      Date de création
                    </th>
                    <th className="text-nowrap ps-2" style={{ width: "5%" }}>
                      Statut
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {this.props.client?.affaires
                    ?.slice()
                    ?.sort(
                      (a, b) => new Date(b.dateCreate) - new Date(a.dateCreate)
                    )
                    .map((affaire) => {
                      return (
                        <tr key={affaire.id}>
                          <td className="ps-2">
                            <a
                              href={"/affaires/" + affaire.id}
                              target="_blank"
                              data-text={affaire.reference ?? "-"}
                              className="fixWidthHover"
                            >
                              {affaire.reference ?? "-"}
                            </a>
                          </td>
                          <td className="ps-2">{affaire.nomAffaire ?? "-"}</td>
                          <td className="ps-2">
                            {affaire.produitInterne?.designation ?? "-"}
                          </td>
                          <td className="ps-2">
                            {ToLocaleDateString(affaire.dateCreate)}
                          </td>

                          <td className="ps-2" style={{ width: "60px" }}>
                            {affaire.statut?.designation}
                          </td>
                        </tr>
                      );
                    })}
                </tbody>
              </table>
            }
          />
        </>
      );
    } else
      return (
        <>
          <WarningBar
            active={false}
            content={"Vous n'avez pas le droit de voir cette page"}
          />
        </>
      );
  }
}

export { Affaires };
