import React from "react";

import { App } from "App";

import { Input } from "_components/Input";

import { faTrash, faCopy, faPaste } from "@fortawesome/free-solid-svg-icons";
import { ButtonIcon } from "_components";
import { customFuseSearch } from "_components/Input/InputTypes/SelectSearchFunctions";

import {
  AffaireService,
  HeurePersonnelCompetenceService,
  HeurePersonnelRubriqueService,
} from "_services";

//import moment from "moment";

function LigneHeure(props) {
  let backGroundColorsDictionnary = {
    DEPLA: "bgColorRed",
    PLT4: "bgColorRed",
    PLT5: "bgColorRed",
    REPAS: "bgColorGreen",
    RPSAM: "bgColorYellow",
    RPDIM: "bgColorYellow",
    RPFER: "bgColorYellow",
    RECUP: "bgColorYellow",
    NTSAM: "bgColorBlue",
    NTDIM: "bgColorBlue",
    NTFER: "bgColorBlue",
    NUIT: "bgColorBlue",
    RPNUI: "bgColorYellow",
  };

  let bgcolor = props.heure.rubrique?.code
    ? backGroundColorsDictionnary[props.heure.rubrique?.code]
    : "";

  let bgcolorFille = props.heure.heureLiee?.rubrique?.code
    ? backGroundColorsDictionnary[props.heure.heureLiee?.rubrique?.code]
    : "";

  const rightVisibiliteCocheHeure = App.RightsGuard?.current?.hasRight(
    "Personnel",
    "VisibiliteCoches"
  );

  const rightModifCocheResponsable = App.RightsGuard?.current?.hasRight(
    "Personnel",
    "Validation Heures Responsable"
  );

  const handleChange = (accessor, value) => {
    props.handleChange(accessor, value);
  };
  return (
    <>
      <tr className={"ligneHeurePersonnel " + bgcolor}>
        <td className="maxWidth130">
          <Input
            accessor={"heurePersonnel." + props.heure.id + ".rubrique"}
            value={props.heure.rubrique}
            type="selectSearch"
            placeholder=""
            service={HeurePersonnelRubriqueService.getAll}
            customFilter={(options) => {
              let filtered = options.filter(
                (e) =>
                  !(
                    e.name.startsWith("RPSAM") ||
                    e.name.startsWith("RPDIM") ||
                    e.name.startsWith("RPNUI") ||
                    e.name.startsWith("RPFER") ||
                    e.name.startsWith("PLT4") ||
                    e.name.startsWith("PLT5")
                  )
              );

              return customFuseSearch(filtered, {
                threshold: 0.4,
                ignoreLocation: true,
              });
            }}
            optionFieldToDisplay={["code", "designation"]}
            valueFieldToDisplay={["code"]}
            handleChange={handleChange}
            showValidator={false}
            showClearButton={false}
            disabled={
              !props.editing ||
              props.heure.validationResponsable ||
              props.heure.validationRH
            }
            required={true}
          />
        </td>
        <td>
          <Input
            accessor={"heurePersonnel." + props.heure.id + ".competence"}
            value={props.heure.competence}
            type="selectSearch"
            placeholder=""
            service={HeurePersonnelCompetenceService.getAll}
            optionFieldToDisplay={"designation"}
            valueFieldToDisplay={"designation"}
            handleChange={handleChange}
            showValidator={false}
            showClearButton={false}
            disabled={
              !props.editing ||
              props.heure.validationResponsable ||
              props.heure.validationRH
            }
          />
        </td>
        <td>
          <Input
            accessor={"heurePersonnel." + props.heure.id + ".affaire"}
            value={props.heure.affaire}
            type="selectSearch"
            placeholder=""
            service={AffaireService.getNonSoldees}
            optionFieldToDisplay={["reference", "nomAffaire"]}
            valueFieldToDisplay={["reference", "nomAffaire"]}
            handleChange={handleChange}
            showValidator={false}
            showClearButton={false}
            disabled={
              !props.editing ||
              props.heure.validationResponsable ||
              props.heure.validationRH
            }
            required={true}
          />
        </td>
        <td>
          <Input
            accessor={"heurePersonnel." + props.heure.id + ".libelle"}
            value={props.heure.libelle}
            type="text"
            placeholder=""
            handleChange={handleChange}
            showValidator={false}
            showClearButton={false}
            disabled={
              !props.editing ||
              props.heure.validationResponsable ||
              props.heure.validationRH
            }
          />
        </td>
        <td className="maxWidth130">
          <Input
            accessor={"heurePersonnel." + props.heure.id + ".date"}
            value={props.heure.date}
            type="date"
            placeholder=""
            handleChange={handleChange}
            showValidator={false}
            showClearButton={false}
            disabled={
              !props.editing ||
              props.heure.validationResponsable ||
              props.heure.validationRH
            }
          />
        </td>
        <td className="maxWidth75">
          <Input
            accessor={"heurePersonnel." + props.heure.id + ".nombre"}
            value={props.heure.nombre}
            type="decimal"
            placeholder=""
            handleBlur={handleChange}
            showValidator={false}
            showClearButton={false}
            disabled={
              !props.editing ||
              props.heure.validationResponsable ||
              props.heure.validationRH
            }
          />
        </td>
        <td>
          <div
            style={rightVisibiliteCocheHeure != "RW" ? { display: "none" } : {}}
          >
            <Input
              accessor={
                "heurePersonnel." + props.heure.id + ".validationResponsable"
              }
              value={props.heure.validationResponsable}
              type="checkbox"
              placeholder=""
              handleChange={handleChange}
              showValidator={false}
              showClearButton={false}
              disabled={
                !props.editing ||
                !(
                  rightModifCocheResponsable == "RW" ||
                  App.RightsGuard?.current?.isRH()
                ) ||
                props.heure.validationRH ||
                !props.heure.affaire
              }
            />
          </div>
        </td>
        <td>
          <div
            style={rightVisibiliteCocheHeure != "RW" ? { display: "none" } : {}}
          >
            <Input
              accessor={"heurePersonnel." + props.heure.id + ".validationRH"}
              value={props.heure.validationRH}
              type="checkbox"
              placeholder=""
              handleChange={handleChange}
              showValidator={false}
              showClearButton={false}
              disabled={
                !props.editing ||
                !App.RightsGuard?.current?.isRH() ||
                !props.heure.validationResponsable
              }
            />
          </div>
        </td>
        <td>
          <div
            style={rightVisibiliteCocheHeure != "RW" ? { display: "none" } : {}}
          >
            <Input
              accessor={"heurePersonnel." + props.heure.id + ".autonomie"}
              value={props.heure.autonomie}
              type="checkbox"
              placeholder=""
              handleChange={handleChange}
              showValidator={false}
              showClearButton={false}
              disabled={!props.editing || rightVisibiliteCocheHeure != "RW"}
            />
          </div>
        </td>
        <td
          style={rightVisibiliteCocheHeure != "RW" ? { display: "none" } : {}}
        >
          <Input
            accessor={"heurePersonnel." + props.heure.id + ".commentaire"}
            value={{
              id: props.heure.commentaire,
              value: props.heure.commentaire,
            }}
            type="selectSearch"
            options={[
              { id: 1, value: 1 },
              { id: 2, value: 2 },
              { id: 3, value: 3 },
              { id: 4, value: 4 },
              { id: 5, value: 5 },
            ]}
            handleChange={handleChange}
            handleBlur={handleChange}
            showValidator={false}
            showClearButton={false}
            optionFieldToDisplay={["value"]}
            valueFieldToDisplay={["value"]}
            optionFieldToReturn={"value"}
            disabled={!props.editing || rightVisibiliteCocheHeure != "RW"}
          />
        </td>
        <td>
          <ButtonIcon
            smallText=""
            icon={faCopy}
            iconSize="sm"
            tooltip="Copier"
            onClick={() => {
              props?.copierLigneHeure(props.heure.id);
            }}
            className="btn btn-success"
            style={
              !props.editing ||
              props.heure.validationRH ||
              props.heure.validationResponsable
                ? { display: "none" }
                : {}
            }
          />
        </td>
        <td>
          <ButtonIcon
            smallText=""
            icon={faPaste}
            iconSize="sm"
            tooltip="Coller"
            onClick={() => {
              props?.collerLigneHeure(props.heure.id);
            }}
            className="btn btn-success"
            style={
              !props.editing ||
              props.heure.validationRH ||
              props.heure.validationResponsable
                ? { display: "none" }
                : {}
            }
          />
        </td>
        <td>
          <div
            style={
              !props.heure.validationRH && !props.heure.validationResponsable
                ? { width: "55px" }
                : { display: "none" }
            }
          >
            <ButtonIcon
              smallText=""
              icon={faTrash}
              iconSize="sm"
              onClick={() => {
                props?.deleteLineHeurePersonnel(props.heure.id);
              }}
              className="btn btn-danger deleteLineHeurePersonnel"
              style={!props.editing ? { display: "none" } : {}}
            />
          </div>
        </td>
      </tr>
      {props.heure.heureLiee != null ? (
        <tr className={"ligneHeurePersonnel " + bgcolorFille}>
          <td className="maxWidth150">
            <Input
              accessor={"heurePersonnelLiee." + props.heure.id + ".rubrique"}
              value={props.heure.heureLiee.rubrique}
              type="selectSearch"
              placeholder=""
              service={HeurePersonnelRubriqueService.getAll}
              optionFieldToDisplay={["code", "designation"]}
              valueFieldToDisplay={["code", "designation"]}
              handleChange={handleChange}
              showValidator={false}
              showClearButton={false}
              disabled={true}
              required={true}
            />
          </td>
          <td></td>
          <td>
            <Input
              accessor={"heurePersonnelLiee." + props.heure.id + ".affaire"}
              value={props.heure.heureLiee.affaire}
              type="selectSearch"
              placeholder=""
              service={AffaireService.getNonSoldees}
              optionFieldToDisplay={["reference", "nomAffaire"]}
              valueFieldToDisplay={["reference", "nomAffaire"]}
              handleChange={handleChange}
              showValidator={false}
              showClearButton={false}
              disabled={true}
              required={true}
            />
          </td>
          <td>
            <Input
              accessor={"heurePersonnelLiee." + props.heure.id + ".libelle"}
              value={props.heure.libelle}
              type="text"
              placeholder=""
              handleChange={handleChange}
              showValidator={false}
              showClearButton={false}
              disabled={true}
            />
          </td>
          <td className="maxWidth130">
            <Input
              accessor={"heurePersonnelLiee." + props.heure.id + ".date"}
              value={props.heure.heureLiee.date}
              type="date"
              placeholder=""
              handleChange={handleChange}
              showValidator={false}
              showClearButton={false}
              disabled={true}
            />
          </td>
          <td className="maxWidth75">
            <Input
              accessor={"heurePersonnelLiee." + props.heure.id + ".nombre"}
              value={props.heure.heureLiee.nombre}
              type="decimal"
              placeholder=""
              handleBlur={handleChange}
              showValidator={false}
              showClearButton={false}
              disabled={true}
            />
          </td>

          <td
            style={rightVisibiliteCocheHeure != "RW" ? { display: "none" } : {}}
          >
            <Input
              accessor={
                "heurePersonnelLiee." +
                props.heure.id +
                ".validationResponsable"
              }
              value={props.heure.heureLiee.validationResponsable}
              type="checkbox"
              placeholder=""
              handleChange={handleChange}
              showValidator={false}
              showClearButton={false}
              disabled={
                !props.editing ||
                !(
                  rightModifCocheResponsable == "RW" ||
                  App.RightsGuard?.current?.isRH()
                ) ||
                props.heure.heureLiee.validationRH ||
                !props.heure.heureLiee.affaire
              }
            />
          </td>
          <td
            style={rightVisibiliteCocheHeure != "RW" ? { display: "none" } : {}}
          >
            <Input
              accessor={
                "heurePersonnelLiee." + props.heure.id + ".validationRH"
              }
              value={props.heure.heureLiee.validationRH}
              type="checkbox"
              placeholder=""
              handleChange={handleChange}
              showValidator={false}
              showClearButton={false}
              disabled={
                !props.editing ||
                !App.RightsGuard?.current?.isRH() ||
                !props.heure.heureLiee.validationResponsable
              }
            />
          </td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td
            style={
              !props.heure.validationRH && !props.heure.validationResponsable
                ? { width: "55px" }
                : { display: "none" }
            }
          ></td>
        </tr>
      ) : null}
    </>
  );
}

export { LigneHeure };
