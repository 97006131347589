import React from "react";
import { PropTypes } from "prop-types";
import { Link } from "react-router-dom";
import { App } from "App";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSpinner,
  faTrash,
  faFileInvoiceDollar,
  faPrint,
  faFileContract,
  faCopy,
  faCertificate,
} from "@fortawesome/free-solid-svg-icons";
import "./FicheDevis.css";

import {
  General,
  InformationsChantier,
  Details,
  Logistique,
  Calculette,
} from ".";

import {
  computeCalculette,
  importModeleIntoCalculette,
} from "./Calculette/CalculetteFunctions.js";
import {
  computeDevisDetail,
  computePrixVenteTotal,
  computeMontantTVA,
  computePrixVenteTotalTTC,
  computeTotals,
  computeSubTotals,
} from "./Details/DetailsFunctions.js";

import {
  AffaireService,
  ArticleService,
  CodeTarifService,
  CommandeService,
  ContratCategorieService,
  ContratFinanceurService,
  ContratPeriodiciteService,
  ContratService,
  ContratSortieService,
  DeviseService,
  DevisService,
  EcheanceService,
  FactureService,
  ModePaiementService,
  PacksService,
  TVAService,
  SageDocumentService,
} from "_services";

import {
  ButtonIconWithValidation,
  ButtonIcon,
  DialogForm,
  AlertDialog,
  WarningBar,
} from "_components";

import * as FileSaver from "file-saver";

import { Input } from "_components/Input";

import { Helmet } from "react-helmet";

import produce from "immer";
import { arrayMoveMutable } from "array-move";

import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import { ContratDureeService } from "_services/ContratDureeService";
import moment from "moment";
import TabSelector from "_components/FicheComponents/TabSelector";
import { ToLocaleDateString } from "_utils";

class FicheDevis extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      tabs: [
        "Général",
        "Informations chantier",
        "Détails",
        "Logistique",
        "Calculette",
      ],
      active: "Général",
      editing: false,
      open: false,
      devis: {},
      contrat: {},
      affaire: null,
      getCalculetteAtNextUpdate: false,
      openDialogCompteTierFunc: null,
      openDialogAcompteFunc: null,
      openDialogImpressionFunc: null,
      openDialogImpressionContratFunc: null,
      openDialogLicences: null,
      openedDialogDupplicationDevis: false,
      modeleNumEtude: "CATAAXXX",
      nouvelleAffaire: false,
      materielsSousLicence: null,
      matLicences: [],
    };

    this.renderActive = this.renderActive.bind(this);
    this.generateTabs = this.generateTabs.bind(this);
    this.changeLinePosition = this.changeLinePosition.bind(this);
    this.addLineDevisDetail = this.addLineDevisDetail.bind(this);
    this.deleteLineDevisDetail = this.deleteLineDevisDetail.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleUpdate = this.handleUpdate.bind(this);
    this.handleUpdateCalculette = this.handleUpdateCalculette.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
    this.handleEditing = this.handleEditing.bind(this);
    this.newLine = this.newLine.bind(this);
    this.importDevisModele = this.importDevisModele.bind(this);
    this.setOpenDialogCompteTier = this.setOpenDialogCompteTier.bind(this);
    this.setOpenDialogAcompte = this.setOpenDialogAcompte.bind(this);
    this.setOpenDialogContrat = this.setOpenDialogContrat.bind(this);
    this.generateDialogAssistance = this.generateDialogAssistance.bind(this);
    this.setOpenDialogImpressionAss =
      this.setOpenDialogImpressionAss.bind(this);
    this.setOpenDialogImpression = this.setOpenDialogImpression.bind(this);
    this.setOpenDialogImpressionContrat =
      this.setOpenDialogImpressionContrat.bind(this);
    this.setOpenDIalogLicences = this.setOpenDIalogLicences.bind(this);

    this.onClickOnTransformIntoCommandeButton =
      this.onClickOnTransformIntoCommandeButton.bind(this);
    this.getChampsObligatoireVide = this.getChampsObligatoireVide.bind(this);
    this.getAllAffaireProxy = this.getAllAffaireProxy.bind(this);
    this.getAllContratProxy = this.getAllContratProxy.bind(this);
    this.setAcompteLitige = this.setAcompteLitige.bind(this);

    this.duplicateDevis = this.duplicateDevis.bind(this);
    this.computeMontantperiodique = this.computeMontantperiodique.bind(this);

    this.importCalculetteModele = this.importCalculetteModele.bind(this);
    this.findMaterielLicence = this.findMaterielLicence.bind(this);
  }

  componentDidMount() {
    if (sessionStorage.getItem("DevisOnglet") == null) {
      sessionStorage.setItem("DevisOnglet", this.state.active);
    } else {
      this.setState({ active: sessionStorage.getItem("DevisOnglet") });
    }

    ArticleService.getAllMaterielsSousLicence().then((res) => {
      this.setState({ materielsSousLicence: res.data });
    });
    ArticleService.getAllModelesLicences().then((res) =>
      this.setState({ modelesLicences: res.data })
    );

    let { id } = this.props.match.params;
    DevisService.getById(id).then((res) => {
      let devis = res.data;
      devis.devisDetails.sort((a, b) => a.position - b.position);
      this.setState({
        devis: devis,
        loading: false,
      });
    });
  }

  getAllAffaireProxy(search) {
    return AffaireService.getAll({
      size: 25,
      global: search,
      soldee: false,
      idClient: this.state.devis?.client?.id,
    });
  }

  getAllContratProxy() {
    return ContratService.getAllNonLieByClient(this.state.devis?.client?.id);
  }

  changeLinePosition(indexFrom, indexTo) {
    this.setState(
      produce((prevState) => {
        // Swap the two lines position in the array devis.devisDetails
        arrayMoveMutable(prevState.devis.devisDetails, indexFrom, indexTo);

        // Recalcompute the position of each line
        for (let i = 0; i < prevState.devis.devisDetails.length; i++) {
          prevState.devis.devisDetails[i].position = i;
        }

        // By moving a line, we may have to recompute the subTotals.
        computeSubTotals(prevState.devis.devisDetails);
      })
    );
  }

  newLine(id, type, position) {
    // The new lines ids are negatives and unique (until the change gets applied, and then they got their id from the database).
    return {
      id: id,
      type: type,
      position: position,
      article: null,
      rubrique: null,
      description: null,
      quantite: 1,
      coefficient: 0,
      remise: null,
      prixVenteUnitaire: 0,
      prixVenteTotal: 0,
      prixVenteTotalTTC: 0,
      prixVenteUnitaireDevise: 0,
      prixVenteTotalDevise: 0,
      prixVenteTotalTTCDevise: 0,
      montantTVA: 0,
    };
  }

  addLineDevisDetail(type, idDevisDetail) {
    if (idDevisDetail === undefined) {
      // Add a new empty line before all the lines
      this.setState(
        produce((prevState) => {
          // To keep the ids unique, if there is no other new line (with negative id), the new line id is -1, else, it's the minimum id - 1.
          let newLineId =
            Math.min(...prevState.devis.devisDetails?.map((el) => el.id), 0) -
            1;

          // To be at the top of the list, the new line position will be the minimum position - 1.
          let newPosition =
            Math.min(
              ...prevState.devis.devisDetails?.map((el) => el.position),
              1
            ) - 1;

          // Unshift to put it at the front
          prevState.devis.devisDetails.unshift(
            this.newLine(newLineId, type, newPosition)
          );
        })
      );
    } else {
      // Add a new empty line after the line whose id is given.
      this.setState(
        produce((prevState) => {
          // To keep the ids unique, if there is no other new line (with negative id), the new line id is -1, else, it's the minimum id - 1.
          let newLineId =
            Math.min(...prevState.devis.devisDetails?.map((el) => el.id), 0) -
            1;

          let selectedLine = prevState.devis.devisDetails.findIndex(
            (el) => el.id === idDevisDetail
          ); // The new line will take be placed after the selected line

          let newPosition =
            prevState.devis.devisDetails[selectedLine].position + 1;

          for (
            // Then, we shift all the positions of the lines after the one to be inserted
            let i = selectedLine + 1;
            i < prevState.devis.devisDetails.length;
            i++
          ) {
            prevState.devis.devisDetails[i].position += 1;
          }

          prevState.devis.devisDetails.splice(
            // Splice to insert at index
            selectedLine + 1,
            0,
            this.newLine(newLineId, type, newPosition)
          );
        })
      );
    }

    if (type == 2) {
      // If the new line is of type subTotal, we need to compute the subtotals after inserting the line.
      this.setState(
        produce((prevState) => {
          computeSubTotals(prevState.devis.devisDetails);
        })
      );
    }
  }

  computeMontantperiodique(prevState) {
    if (
      prevState.contrat.duree &&
      prevState.contrat.montantAbonnementHT &&
      prevState.contrat.periodicite
    )
      return (
        (prevState.contrat.montantAbonnementHT /
          prevState.contrat.duree.nbMois) *
        prevState.contrat.periodicite.valeur
      );
  }

  deleteLineDevisDetail(idDevisDetail) {
    this.setState(
      produce((prevState) => {
        prevState.devis.devisDetails.splice(
          prevState.devis.devisDetails.findIndex(
            (el) => el.id === idDevisDetail
          ),
          1
        );
        computeTotals(prevState.devis);
        computeSubTotals(prevState.devis.devisDetails);
      })
    );
  }

  /**
   * Permet de mettre a jour l'etat du fiche de suivi sans devoir le recharger
   * @param {*} accessor
   * @param {*} value
   */
  handleChange(accessor, value, callBackFunction) {
    if (accessor === "devis") {
      //Undo
      this.setState({ devis: value }, callBackFunction);
    } else {
      //Update
      if (accessor.includes(".")) {
        // Nested property
        var accessorSplit = accessor.split(".");

        if (accessorSplit[0] === "devisDetail") {
          // Format devisDetail.id.accessor
          var devisDetailId = accessorSplit[1];
          var devisDetailAccessor = accessorSplit[2];
          let tauxTVA = this.state?.devis?.client?.tva?.valeur;
          let codeTarif = this.state?.devis?.client?.code_Tarif?.designation;
          let cours = this.state?.devis?.coursDevise;
          if (devisDetailAccessor === "article") {
            if (value) {
              // Si on chance l'article, on remplace les prix/designation par celui du nouvel article, et on réinitialise le reste.
              this.setState(
                produce((prevState) => {
                  let prevdd = prevState.devis.devisDetails.find(
                    (dd) => dd.id == devisDetailId
                  );
                  prevdd.article = value;
                  prevdd.description = value.description;
                  prevdd.coefficient = 1;
                  prevdd.quantite = 1;
                  prevdd.remise = 0;
                  if (codeTarif == "Tarif Export") {
                    prevdd.prixVenteUnitaire = value.prixVenteExport;
                    prevdd.prixVenteUnitaireDevise =
                      value.prixVenteExport * cours;
                  } else {
                    prevdd.prixVenteUnitaire = value.prixVenteFrance;
                    prevdd.prixVenteUnitaireDevise =
                      value.prixVenteFrance * cours;
                  }
                  computePrixVenteTotal(prevdd, cours);
                  computeMontantTVA(prevdd, tauxTVA, cours);
                  computePrixVenteTotalTTC(prevdd, cours);
                  computeTotals(prevState.devis);
                  computeSubTotals(prevState.devis.devisDetails);
                }),
                callBackFunction
              );
            } else {
              // Si l'accesseur est article, mais qu'il n'y a pas de valeur donnée, on veut réinitialiser la ligne.
              this.setState(
                produce((prevState) => {
                  let prevdd = prevState.devis.devisDetails.find(
                    (dd) => dd.id == devisDetailId
                  );
                  prevdd.article = null;
                  prevdd.description = "";
                  prevdd.prixAchatUnitaire = 0;
                  prevdd.coefficient = 1;
                  prevdd.prixVenteUnitaire = 0;
                  prevdd.quantite = 1;
                  prevdd.remise = 0;
                  prevdd.prixVenteTotal = 0;
                  prevdd.montantTVA = 0;
                  prevdd.prixVenteTotalTTC = 0;

                  prevdd.prixVenteTotalDevise = 0;
                  prevdd.montantTVADevise = 0;
                  prevdd.prixVenteTotalTTCDevise = 0;
                  computeTotals(prevState.devis);
                  computeSubTotals(prevState.devis.devisDetails);
                }),
                callBackFunction
              );
            }
          } else if (devisDetailAccessor === "quantite") {
            // Si l'accesseur est quantite, on ne peut pas avoir de quantité à 0 si la ligne est une ligne article.
            this.setState(
              produce((prevState) => {
                let prevdd = prevState.devis.devisDetails.find(
                  (dd) => dd.id == devisDetailId
                );

                if (prevdd.type == 0) {
                  prevdd.quantite = value == 0 ? 1 : value ?? 1;
                } else {
                  prevdd.quantite = value;
                }

                computeDevisDetail(devisDetailAccessor, prevdd, tauxTVA, cours);
                computeTotals(prevState.devis);
                computeSubTotals(prevState.devis.devisDetails);
              }),
              callBackFunction
            );
          } else {
            // Si l'accesseur est tout autre, on applique la valeur donnée dans le champ donné.
            this.setState(
              produce((prevState) => {
                let prevdd = prevState.devis.devisDetails.find(
                  (dd) => dd.id == devisDetailId
                );
                prevdd[devisDetailAccessor] = value;
                computeDevisDetail(devisDetailAccessor, prevdd, tauxTVA, cours);
                computeTotals(prevState.devis);
                computeSubTotals(prevState.devis.devisDetails);
              }),
              callBackFunction
            );
          }
        } else if (accessorSplit[0] === "calculette") {
          // On veut modifier une valeur de la calculette des prix.
          if (
            accessorSplit[1] == "autres_A_Label" ||
            accessorSplit[1] == "autres_B_Label" ||
            accessorSplit[1] == "autres_C_Label" ||
            accessorSplit[1] == "autres_D_Label" ||
            accessorSplit[1] == "autres_E_Label"
          ) {
            this.setState(
              (prevState) => ({
                devis: {
                  ...prevState.devis,
                  calculette: {
                    ...prevState.devis.calculette,
                    [accessorSplit[1]]: value,
                  },
                },
              }),
              callBackFunction
            );
          } else {
            this.setState(
              (prevState) => ({
                devis: {
                  ...prevState.devis,
                  calculette: computeCalculette(
                    prevState.devis.calculette,
                    accessorSplit[1],
                    value
                  ),
                },
              }),
              callBackFunction
            );
          }
        } else {
          // Nested property (separated by a '.')
          var accessorSplit = accessor.split(".");

          this.setState(
            (prevState) => ({
              devis: {
                ...prevState.devis,
                [accessorSplit[0]]: {
                  ...prevState.devis?.[accessorSplit[0]],
                  [accessorSplit[1]]: value,
                },
              },
            }),
            callBackFunction
          );
        }
      } else {
        // Shallow Property of Devis

        // Special case (if we change the produit_interne or the type_mission, it might affect the calculette,
        // so at the next update, we will get the calculette from the back)
        if (accessor === "produit_Interne" || accessor === "type_Mission") {
          this.setState(
            (prevState) => ({
              getCalculetteAtNextUpdate: true,
              devis: {
                ...prevState.devis,
                [accessor]: value,
              },
            }),
            callBackFunction
          );
        } else {
          // Normal case
          this.setState(
            (prevState) => ({
              devis: {
                ...prevState.devis,
                [accessor]: value,
              },
            }),
            callBackFunction
          );
        }
      }
    }
  }

  /**
   * Permet de renvoyer un tableau contenant le nom des champs vides obligatoires
   */
  getChampsObligatoireVide() {
    let tab = [];
    if (!this.state.devis?.client) {
      tab.push("Client");
    }
    if (!this.state.devis?.produit_Interne) {
      tab.push("Produit Interne");
    }
    if (!this.state.devis?.famille_Affaire && this.produit_Interne?.isPresta) {
      tab.push("Famille d'affaires");
    }
    if (!this.state.devis?.charge_Affaire) {
      tab.push("Chargé d'affaire");
    }
    if (!this.state.devis?.date_Commande_Previsionnelle) {
      tab.push("Date de commande previsionnelle");
    }

    return tab;
  }

  setOpenDialogCompteTier(openDialogFunc) {
    this.setState({ openDialogCompteTierFunc: openDialogFunc });
  }
  setOpenDialogAcompte(openDialogFunc) {
    this.setState({ openDialogAcompteFunc: openDialogFunc });
  }
  setOpenDialogContrat(openDialogFunc) {
    this.setState({ openDialogContratFunc: openDialogFunc });
  }

  setOpenDialogImpressionAss(openDialogFunc) {
    this.setState({ openDialogAssFunc: openDialogFunc });
  }
  setOpenDialogImpression(openDialogFunc) {
    this.setState({ openDialogImpressionFunc: openDialogFunc });
  }

  setOpenDialogImpressionContrat(openDialogFunc) {
    this.setState({ openDialogImpressionContratFunc: openDialogFunc });
  }

  setOpenDIalogLicences(openDialogFunc) {
    this.setState({ openDialogLicences: openDialogFunc });
  }

  onClickOnTransformIntoCommandeButton() {
    let materielSousLicence = this.findMaterielLicence();

    if (materielSousLicence.length > 0) {
      this.setState({
        matLicences: materielSousLicence.map((mat) => {
          return {
            posParent: mat.ligne.position,
            aR_Ref: mat.modele.modeleLicence.aR_Ref,
            type: mat.modele.modeleLicence.classification,
            quantite: mat.modele.quantiteLicence * mat.ligne.quantite,
            duree: mat.modele.modeleLicence.classification.dureeParDefaut,
          };
        }),
      });
    }

    if (this.state.devis.client && !this.state.editing) {
      if (this.state.devis.client?.compte_Tier === null) {
        this.state.openDialogCompteTierFunc();
      } else {
        this.setAcompteLitige();
        this.state.openDialogAcompteFunc();
      }
    }
  }

  setAcompteLitige() {
    if (
      this.state.devis.client?.echeance?.designation === "50% à la commande"
    ) {
      this.handleChange("acompte", true);
      this.handleChange(
        "montantAcomptePrev",
        this.state.devis.prixVenteTTCTotal / 2
      );
    } else if (
      this.state.devis.client?.echeance?.designation === "A la commande" ||
      this.state.devis.client?.litige
    ) {
      this.handleChange("acompte", true);
      this.handleChange(
        "montantAcomptePrev",
        this.state.devis.prixVenteTTCTotal
      );
    }
  }

  handleEditing(editing) {
    this.setState((prevState) => ({
      ...prevState,
      editing: editing,
    }));
  }

  handleDelete() {
    return DevisService.delete(this.state.devis).then(() => {
      this.props.history.push({ pathname: "/devis" });
    });
  }

  handleUpdate() {
    return DevisService.put(this.state.devis).then((res) => {
      this.setState({ devis: res.data });
    });
  }

  duplicateDevis(iddevis, annulerDevis) {
    return DevisService.duplicate(iddevis, annulerDevis).then((res) => {
      this.props.history.push({
        pathname: "/devis/" + res.data.id,
      });
      this.props.history.go();
    });
  }

  //Méthode chargée de détecter les lignes du devis contenant des articles sous licence.
  //Elle renvoie une liste des lignes en question accompagnées des paramètres par défaut des licences associées.
  findMaterielLicence() {
    const matSousLicence_Ref = this.state.materielsSousLicence.map(
      (mat) => mat.aR_Ref_Materiel
    );

    const lignesDetailsSousLicence = this.state.devis.devisDetails.filter(
      (ligne) => matSousLicence_Ref.includes(ligne.article?.aR_Ref)
    );

    return lignesDetailsSousLicence.map((ligne) => {
      return {
        ligne: ligne,
        modele: this.state.materielsSousLicence.find(
          (mat) => mat.aR_Ref_Materiel == ligne.article.aR_Ref
        ),
      };
    });
  }

  findLicencesToCreate() {
    let res = [];

    //Recherche directe parmis les lignes article du devis.
    this.state.devis.devisDetails.map((ligne) => {
      let modeleArticle = this.state.modelesLicences.find(
        (modele) => modele.aR_Ref == ligne.article?.aR_Ref
      );

      if (modeleArticle != null) {
        res.push({
          quantite: ligne.quantite,
          modele: {
            aR_Ref: modeleArticle.aR_Ref,
            classification: modeleArticle.classification.id,
            description:
              modeleArticle.classification.designation +
              " - " +
              modeleArticle.defaultModules
                .map((module) => module.nom)
                .join(", "),
          },
        });
      }
    });

    //Récupération des articles générés par les matériels sous licence.
    this.state.matLicences?.map((licence) => {
      let modeleArticle = this.state.modelesLicences.find(
        (modele) => modele.aR_Ref == licence.aR_Ref
      );

      if (modeleArticle != null) {
        res.push({
          quantite: licence.quantite,
          modele: {
            aR_Ref: modeleArticle.aR_Ref,
            classification: modeleArticle.classification.id,
            description:
              modeleArticle.classification.designation +
              " - " +
              modeleArticle.defaultModules
                .map((module) => module.nom)
                .join(", "),
          },
        });
      }
    });

    res = res.reduce((acc, val) => {
      const i = acc.findIndex((x) => x.modele.aR_Ref == val.modele.aR_Ref);

      if (i < 0) acc.push({ ...val });
      else acc[i].quantite += val.quantite;

      return acc;
    }, []);

    return res;
  }

  /**
   * Alternative à la méthode put sur DevisService pour récupérer la calculette côté back.
   * Est utilisée lors d'un changement sur produit_interne ou type_mission
   * qui induisent un changement de type de calculette côté back, et doit être reporté au front.
   *
   * TODO : retirer cette fonction. Un put sur DevisService devrait retourner l'objet modifié par le back,
   * et on devrait prendre cet objet retourné au lieu de refaire un get
   * @param {*} devis l'objet devis complet à mettre à jour
   * @returns
   */
  handleUpdateCalculette(devis) {
    return DevisService.put(devis).then(() => {
      if (this.state.getCalculetteAtNextUpdate) {
        DevisService.getById(this.state.devis.id).then((res) => {
          let devisG = res.data;
          if (devisG.calculette) {
            devisG.calculette = computeCalculette(
              devisG.calculette,
              "type",
              devisG.calculette.type
            );
            this.setState(
              produce((prevState) => {
                prevState.getCalculetteAtNextUpdate = false;
                prevState.devis.calculette = devisG.calculette;
              })
            );
            DevisService.put(devisG);
          }
        });
      }
    });
  }

  importDevisModele(modelToImport) {
    // Retirer toutes les lignes de devis details du devis actuel. Et les remplacer par celles du modèle
    modelToImport.devisDetails.sort((a, b) => a.position - b.position);
    let codeTarif = this.state?.devis?.client?.code_Tarif?.designation;
    this.setState(
      produce((prevState) => {
        prevState.devis.devisDetails = modelToImport.devisDetails;
        for (let i = 0; i < prevState.devis.devisDetails.length; i++) {
          let dd = prevState.devis.devisDetails[i];
          dd.id = -i - 1;
          if (dd.article) {
            //dd.prixAchatUnitaire = dd.article.prixAchat; // PrixAchat Obsolete
            dd.remise = 0;
            if (codeTarif == "Tarif Export") {
              dd.prixVenteUnitaire = dd.article.prixVenteExport;
            } else {
              dd.prixVenteUnitaire = dd.article.prixVenteFrance;
            }
            computePrixVenteTotal(dd);
            computeMontantTVA(dd, prevState.devis?.client?.tva?.valeur);
            computePrixVenteTotalTTC(dd);
          }
        }
        computeTotals(prevState.devis);
        computeSubTotals(prevState.devis.devisDetails);
      })
    );
  }

  importCalculetteModele(modelToImport) {
    // Retirer toutes les lignes de devis details du devis actuel. Et les remplacer par celles du modèle
    this.setState(
      produce((prevState) => {
        prevState.devis.calculette = importModeleIntoCalculette(
          prevState.devis.calculette,
          modelToImport
        );
      })
    );
  }

  /**
   * Génère les onglets de la fiche
   * @param {*} tabs
   * @returns
   */
  generateTabs(tabs) {
    return (
      <>
        <TabSelector
          tabs={tabs}
          activeTab={this.state.active}
          onSelect={(tab) => {
            this.setState({ active: tab });
            sessionStorage.setItem("DevisOnglet", tab);
          }}
          notAllowed={this.state.editing}
        ></TabSelector>
      </>
    );
  }

  generateDialogs() {
    let isLLD = false;
    this.state.devis.devisDetails.map(
      (dd) =>
        (isLLD =
          isLLD ||
          dd.article?.aR_Ref == "PORT-OA" ||
          dd.article?.aR_Ref == "PORT-ASS")
    );

    return (
      <>
        <DialogForm
          dialogTitle="Compte tiers du client"
          labelValidateButton="Lier le client à ce compte tiers"
          setOpenDialog={this.setOpenDialogCompteTier}
          onValidate={() => {
            SageDocumentService.setCompteTiers(this.state.devis.client)
              .then(() => {
                this.state.openDialogAcompteFunc();
              })
              .catch((e) =>
                this.setState({
                  errorComptetiers: true,
                  errorText: e.response?.data?.cause,
                })
              );
          }}
          body={
            <>
              <div>
                Le client ({this.state.devis?.client?.nom_Client}) n'a pas de
                compte tiers. <br />
              </div>
              <Input
                label="Compte tiers"
                accessor="compte_Tier"
                type="selectSearch"
                value={null}
                service={SageDocumentService.getCompteTiers}
                optionFieldToDisplay={[
                  "compte_Tier",
                  "adresse_Facturation.cp",
                  "adresse_Facturation.ville",
                ]}
                valueFieldToDisplay="compte_Tier"
                optionFieldToReturn="compte_Tier"
                showClearButton={false}
                customFirstOption={(query) => ({
                  name: "* Ajouter le compte : ".concat(query),
                  option: { compte_Tier: query },
                })}
                pattern="^C{1}[A-Z0-9]{1,16}$"
                tooltip={
                  <>
                    Vous pouvez lier ce client à un compte tier existant ou lui
                    en créer un nouveau. <br />
                    Pour rappel, un compte tier doit :<br />
                    - Commencer par un 'C' majuscule.
                    <br />
                    - Contenir uniquement des majuscules sans accents et des
                    chiffres.
                    <br />- Être d'une longueur maximale de 17 caractères.
                  </>
                }
                handleChange={(accessor, value) => {
                  this.setState(
                    produce((prevState) => {
                      if (
                        prevState.devis.client != null ||
                        prevState.devis.client != undefined
                      )
                        prevState.devis.client.compte_Tier = value;
                    })
                  );
                }}
                required={true}
              />
              <Input
                label="Echéance"
                accessor="echeance"
                type="selectSearch"
                value={this.state.devis.client?.echeance}
                service={EcheanceService.getAll}
                optionFieldToDisplay="designation"
                valueFieldToDisplay="designation"
                showClearButton={false}
                handleChange={(accessor, value) => {
                  this.setState(
                    produce((prevState) => {
                      prevState.devis.client.echeance = value;
                    })
                  );
                }}
                required={true}
              />
              <Input
                label="Mode de paiement"
                accessor="mode_Paiement"
                type="selectSearch"
                value={this.state.devis.client?.mode_Paiement}
                service={ModePaiementService.getAll}
                optionFieldToDisplay="mode"
                valueFieldToDisplay="mode"
                showClearButton={false}
                handleChange={(accessor, value) => {
                  this.setState(
                    produce((prevState) => {
                      prevState.devis.client.mode_Paiement = value;
                    })
                  );
                }}
                required={true}
              />
              <Input
                label="TVA"
                accessor="tva"
                type="selectSearch"
                value={this.state.devis.client?.tva}
                service={TVAService.getAll}
                optionFieldToDisplay="valeur"
                valueFieldToDisplay="valeur"
                showClearButton={false}
                handleChange={(accessor, value) => {
                  this.setState(
                    produce((prevState) => {
                      prevState.devis.client.tva = value;
                    })
                  );
                }}
                required={true}
              />
              <Input
                label="Code tarif"
                accessor="code_Tarif"
                type="selectSearch"
                value={this.state.devis.client?.code_Tarif}
                service={CodeTarifService.getAll}
                optionFieldToDisplay="designation"
                valueFieldToDisplay="designation"
                showClearButton={false}
                handleChange={(accessor, value) => {
                  this.setState(
                    produce((prevState) => {
                      prevState.devis.client.code_Tarif = value;
                    })
                  );
                }}
                required={true}
              />
              <Input
                label="Devise"
                accessor="devise"
                type="selectSearch"
                value={this.state.devis.client?.devise}
                service={DeviseService.getAll}
                optionFieldToDisplay="designation"
                valueFieldToDisplay="designation"
                showClearButton={false}
                handleChange={(accessor, value) => {
                  this.setState(
                    produce((prevState) => {
                      prevState.devis.client.devise = value;
                    })
                  );
                }}
                required={true}
              />
              <div id="PopupCommande">
                <Input
                  label="Facture"
                  accessor="facture"
                  type="selectSearch"
                  value={this.state.devis.client?.facture}
                  service={FactureService.getAll}
                  optionFieldToDisplay="designation"
                  valueFieldToDisplay="designation"
                  showClearButton={false}
                  handleChange={(accessor, value) => {
                    this.setState(
                      produce((prevState) => {
                        prevState.devis.client.facture = value;
                      })
                    );
                  }}
                  required={true}
                />
              </div>
            </>
          }
        />
        <DialogForm
          dialogTitle="Information de la commande"
          labelValidateButton="Valider"
          setOpenDialog={this.setOpenDialogAcompte}
          validation={() => {
            if (
              (this.state.affaire == null && this.state.nouvelleAffaire) ||
              (this.state.affaire != null && !this.state.nouvelleAffaire)
            )
              return true;
            else {
              App.Toaster.current?.createToast({
                body: "Vous devez cocher la case 'nouvelle affaire' ou sélectionner une affaire",
                header: "Echec",
                type: "failure",
              });

              return false;
            }
          }}
          onValidate={() => {
            DevisService.put(this.state.devis).then(() => {
              let licences = this.findLicencesToCreate();

              // if (this.state.devis.licence != null) {
              //   //Cas d'un devis d'ajout de licences(<=> cas classique)
              //   CommandeService.postFromDevis(
              //     this.state.devis.id,
              //     !this.state.nouvelleAffaire ? this.state.affaire?.id : -1
              //   )
              //     .then((retour) =>
              //       this.props.history.push("/commandes/" + retour.data.id)
              //     )
              //     .catch((error) =>
              //       this.setState({
              //         errorContrat: true,
              //         errorText: error.response?.data?.cause,
              //       })
              //     );
              // } else
              if (isLLD) {
                //Cas d'un devis classique à relier à un contrat
                this.state.openDialogContratFunc();
              } else if (this.state.matLicences?.length > 0) {
                //Cas d'un devis classique sans contrat avec des lignes matériel générant des licences
                this.state.openDialogLicences();
              } else if (licences.length > 0) {
                //Cas d'un devis classique sans contrat avec des lignes de licences
                this.props.history.push("/licences/add", {
                  modelesLicences: this.findLicencesToCreate(),
                  matLicences: this.state.matLicences,
                  client: this.state.devis.client,
                  devisId: this.state.devis.id,
                  nouvelleAffaireId: !this.state.nouvelleAffaire
                    ? this.state.affaire?.id
                    : -1,
                });
              } else {
                //Cas d'un devi classique sans contrat et sans licence
                CommandeService.postFromDevis(
                  this.state.devis.id,
                  !this.state.nouvelleAffaire ? this.state.affaire?.id : -1
                )
                  .then((retour) =>
                    this.props.history.push("/commandes/" + retour.data.id)
                  )
                  .catch((error) =>
                    this.setState({
                      errorContrat: true,
                      errorText: error.response?.data?.cause,
                    })
                  );
              }
            });
          }}
          body={
            <>
              <div>Voulez-vous demander un acompte ?</div>
              {this.state.devis.client?.echeance?.designation ===
                "A la commande" ||
              this.state.devis.client?.echeance?.designation ===
                "50% à la commande" ||
              this.state.devis.client?.litige ? (
                <div>Acompte : oui</div>
              ) : (
                <Input
                  label="Acompte"
                  accessor="acompte"
                  type="checkbox"
                  value={this.state.devis.acompte}
                  functionAppliedToValue={(value) => {
                    return value ? "Oui" : "Non";
                  }}
                  handleChange={this.handleChange}
                />
              )}
              <Input
                label="Montant acompte"
                accessor="montantAcomptePrev"
                type="decimal"
                value={this.state.devis.montantAcomptePrev}
                handleBlur={(accessor, value) => {
                  this.setState(
                    produce((prevState) => {
                      prevState.devis.montantAcomptePrev = value;
                    })
                  );
                }}
                handleChange={(accessor, value) => {
                  this.setState(
                    produce((prevState) => {
                      prevState.devis.montantAcomptePrev = value;
                    })
                  );
                }}
              />
              <Input
                value={this.state.nouvelleAffaire}
                type="checkbox"
                accessor="nouvelleAffaire"
                label="Créer une nouvelle affaire"
                handleChange={(accessor, value) => {
                  this.setState(
                    produce((prevState) => {
                      prevState.nouvelleAffaire = value;
                    })
                  );
                }}
                required={true}
              />
              <br />
              {this.state.nouvelleAffaire ? null : (
                <Input
                  accessor="affaire"
                  type="selectSearch"
                  label="Affaire"
                  service={this.getAllAffaireProxy}
                  optionFieldToDisplay={[
                    "produitInterne.designation",
                    "reference",
                    "nomAffaire",
                  ]}
                  valueFieldToDisplay={["reference", "nomAffaire"]}
                  showClearButton={false}
                  handleChange={(accessor, value) => {
                    this.setState(
                      produce((prevState) => {
                        prevState.affaire = value;
                      })
                    );
                  }}
                  required={!this.state.nouvelleAffaire}
                />
              )}
              {isLLD ? (
                <>
                  <Input
                    value={this.state.nouveauContrat}
                    type="checkbox"
                    accessor="nouveauContrat"
                    label="Créer un nouveau contrat"
                    handleChange={(accessor, value) => {
                      this.setState(
                        produce((prevState) => {
                          prevState.nouveauContrat = value;
                          if (value)
                            prevState.contrat = {
                              id: 0,
                              client: prevState.devis.client,
                            };
                        })
                      );
                    }}
                    required={true}
                  />
                  <br />
                </>
              ) : null}

              {this.state.nouveauContrat || !isLLD ? null : (
                <Input
                  accessor="contrat"
                  type="selectSearch"
                  label="Contrat"
                  service={this.getAllContratProxy}
                  optionFieldToDisplay={["numEtude"]}
                  valueFieldToDisplay={["numEtude"]}
                  showClearButton={false}
                  handleChange={(accessor, value) => {
                    this.setState(
                      produce((prevState) => {
                        prevState.contrat = value;
                      })
                    );
                  }}
                  required={!this.state.nouvelleContrat}
                />
              )}
            </>
          }
        />

        <DialogForm
          dialogTitle="Lier un contrat"
          labelValidateButton="Valider"
          setOpenDialog={this.setOpenDialogContrat}
          onValidate={() => {
            let licences = this.findLicencesToCreate();

            if (this.state.nouveauContrat) {
              ContratService.post(this.state.contrat)
                .then((res) => this.setState({ contrat: res.data }))
                .then(() => {
                  if (this.state.matLicences.length > 0) {
                    this.state.openDialogLicences();
                  } else if (licences.length > 0) {
                    this.props.history.push("/licences/add", {
                      modelesLicences: licences,
                      matLicences: this.state.matLicences,
                      client: this.state.devis.client,
                      devisId: this.state.devis.id,
                      contratId: this.state.contrat.id,
                      nouvelleAffaireId: !this.state.nouvelleAffaire
                        ? this.state.affaire?.id
                        : -1,
                    });
                  } else {
                    CommandeService.postFromDevisWithContrat(
                      this.state.devis.id,
                      !this.state.nouvelleAffaire ? this.state.affaire?.id : -1,
                      this.state.contrat.id
                    )
                      .then((retour) =>
                        this.props.history.push("/commandes/" + retour.data.id)
                      )
                      .catch((error) =>
                        this.setState({
                          errorContrat: true,
                          errorText: error.response?.data?.cause,
                        })
                      );
                  }
                })
                .catch((error) =>
                  this.setState({
                    errorContrat: true,
                    errorText: error.response?.data?.cause,
                  })
                );
            } else {
              ContratService.put(this.state.contrat)
                .then((res) => this.setState({ contrat: res.data }))
                .then(() => {
                  if (this.state.matLicences.length > 0) {
                    this.state.openDialogLicences();
                  } else if (licences.length > 0) {
                    this.props.history.push("/licences/add", {
                      modelesLicences: this.findLicencesToCreate(),
                      matLicences: this.state.matLicences,
                      client: this.state.devis.client,
                      devisId: this.state.devis.id,
                      contratId: this.state.contrat.id,
                      nouvelleAffaireId: !this.state.nouvelleAffaire
                        ? this.state.affaire?.id
                        : -1,
                    });
                  } else {
                    CommandeService.postFromDevisWithContrat(
                      this.state.devis.id,
                      !this.state.nouvelleAffaire ? this.state.affaire?.id : -1,
                      this.state.contrat.id
                    )
                      .then((retour) =>
                        this.props.history.push("/commandes/" + retour.data.id)
                      )
                      .catch((error) =>
                        this.setState({
                          errorContrat: true,
                          errorText: error.response?.data?.cause,
                        })
                      );
                  }
                })
                .catch((error) =>
                  this.setState({
                    errorContrat: true,
                    errorText: error.response?.data?.cause,
                  })
                );
            }
          }}
          body={
            <div id="PopupCommandeContrat" style={{ minWidth: "1000px" }}>
              <div className="row">
                <div className={"col-lg-4"}>
                  <Input
                    value={this.state.contrat?.numEtude}
                    label="N° Etude"
                    accessor="numEtude"
                    type="text"
                    showClearButton={false}
                    required={
                      this.state.contrat?.financeur?.designation !=
                      "Sol Solution"
                    }
                    handleChange={(accessor, value) => {
                      this.setState(
                        produce((prevState) => {
                          prevState.contrat.numEtude = value;
                        })
                      );
                    }}
                    placeholder={
                      this.state.contrat?.financeur?.designation ==
                      "Sol Solution"
                        ? this.state.modeleNumEtude
                        : ""
                    }
                  />
                  <Input
                    value={this.state.contrat?.categorie}
                    label="Catégorie"
                    accessor="categorie"
                    type="selectSearch"
                    service={ContratCategorieService.getAll}
                    optionFieldToDisplay="designation"
                    valueFieldToDisplay="designation"
                    showClearButton={false}
                    required={true}
                    handleChange={(accessor, value) => {
                      this.setState(
                        produce((prevState) => {
                          prevState.contrat.categorie = value;
                        })
                      );
                    }}
                  />
                  <Input
                    value={this.state.contrat?.sortie}
                    label="Choix Sortie"
                    accessor="sortie"
                    type="selectSearch"
                    service={ContratSortieService.getAll}
                    optionFieldToDisplay="designation"
                    valueFieldToDisplay="designation"
                    showClearButton={false}
                    handleChange={(accessor, value) => {
                      this.setState(
                        produce((prevState) => {
                          prevState.contrat.sortie = value;
                        })
                      );
                    }}
                  />
                  <Input
                    value={this.state.contrat?.pack}
                    label="Pack"
                    accessor="Pack"
                    type="selectSearch"
                    service={PacksService.getAll}
                    optionFieldToDisplay="designation"
                    valueFieldToDisplay="designation"
                    showClearButton={false}
                    required={
                      this.state.contrat?.categorie?.designation == "LLD"
                    }
                    handleChange={(accessor, value) => {
                      this.setState(
                        produce((prevState) => {
                          prevState.contrat.pack = value;
                        })
                      );
                    }}
                  />
                  <Input
                    value={this.state.contrat?.financeur}
                    label="Financeur"
                    accessor="financeur"
                    type="selectSearch"
                    service={ContratFinanceurService.getAll}
                    optionFieldToDisplay="designation"
                    valueFieldToDisplay="designation"
                    showClearButton={false}
                    required={true}
                    handleChange={(accessor, value) => {
                      this.setState(
                        produce((prevState) => {
                          prevState.contrat.financeur = value;
                        })
                      );
                    }}
                  />
                  <Input
                    value={this.state.contrat?.periodicite}
                    label="Periodicité"
                    accessor="periodicite"
                    type="selectSearch"
                    service={ContratPeriodiciteService.getAll}
                    optionFieldToDisplay="designation"
                    valueFieldToDisplay="designation"
                    showClearButton={false}
                    required={true}
                    handleChange={(accessor, value) => {
                      this.setState(
                        produce((prevState) => {
                          prevState.contrat.periodicite = value;
                          prevState.contrat.montantAbonnementPeriodiqueHT =
                            this.computeMontantperiodique(prevState);
                        })
                      );
                    }}
                  />
                  <Input
                    value={this.state.contrat?.duree}
                    label="Durée"
                    accessor="duree"
                    type="selectSearch"
                    service={ContratDureeService.getAll}
                    optionFieldToDisplay="designation"
                    valueFieldToDisplay="designation"
                    showClearButton={false}
                    required={true}
                    handleChange={(accessor, value) => {
                      this.setState(
                        produce((prevState) => {
                          prevState.contrat.duree = value;
                          if (prevState.contrat.debutAbonnement)
                            prevState.contrat.finAbonnement = moment(
                              prevState.contrat.debutAbonnement
                            )
                              .add(prevState.contrat.duree.nbMois, "months")
                              .toISOString();

                          prevState.contrat.montantAbonnementPeriodiqueHT =
                            this.computeMontantperiodique(prevState);
                        })
                      );
                    }}
                  />
                </div>
                <div className={"col-lg-4"}>
                  <Input
                    value={this.state.contrat?.montantAbonnementHT}
                    label="Montant HT"
                    accessor="montantAbonnementHT"
                    type="decimal"
                    showClearButton={false}
                    required={true}
                    handleBlur={(accessor, value) => {
                      this.setState(
                        produce((prevState) => {
                          prevState.contrat.montantAbonnementHT = value;
                          prevState.contrat.montantAbonnementPeriodiqueHT =
                            this.computeMontantperiodique(prevState);
                        })
                      );
                    }}
                  />

                  <Input
                    value={this.state.contrat?.contratServiceEnvoye}
                    label="Contrat Service envoyé le"
                    accessor="contratServiceEnvoye"
                    type="date"
                    showClearButton={false}
                    handleChange={(accessor, value) => {
                      this.setState(
                        produce((prevState) => {
                          prevState.contrat.contratServiceEnvoye = value;
                        })
                      );
                    }}
                  />
                  <Input
                    value={this.state.contrat?.contratExtEnvoye}
                    label="Contrat exterieur envoyé le "
                    accessor="contratExtEnvoye"
                    type="date"
                    showClearButton={false}
                    handleChange={(accessor, value) => {
                      this.setState(
                        produce((prevState) => {
                          prevState.contrat.contratExtEnvoye = value;
                        })
                      );
                    }}
                  />
                  <Input
                    value={this.state.contrat?.pvlivraisonEnvoye}
                    label="PV livraison envoyé le"
                    accessor="pvlivraisonEnvoye"
                    type="date"
                    showClearButton={false}
                    required={
                      (this.state.contrat?.categorie?.designation == "ASS" &&
                        this.state.contrat?.financeur?.designation !=
                          "Sol Solution") ||
                      this.state.contrat?.financeur?.designation !=
                        "Sol Solution"
                    }
                    handleChange={(accessor, value) => {
                      this.setState(
                        produce((prevState) => {
                          prevState.contrat.pvlivraisonEnvoye = value;
                        })
                      );
                    }}
                  />
                  <Input
                    value={this.state.contrat?.debutAbonnement}
                    label="Début abonnement"
                    accessor="debutAbonnement"
                    type="date"
                    showClearButton={false}
                    handleChange={(accessor, value) => {
                      this.setState(
                        produce((prevState) => {
                          prevState.contrat.debutAbonnement = value;
                          if (prevState.contrat.duree)
                            prevState.contrat.finAbonnement = moment(
                              prevState.contrat.debutAbonnement
                            )
                              .add(prevState.contrat.duree.nbMois, "months")
                              .toISOString();
                        })
                      );
                    }}
                  />
                  <Input
                    value={this.state.contrat?.immo}
                    label="Immo"
                    accessor="immo"
                    type="text"
                    showClearButton={false}
                    handleChange={(accessor, value) => {
                      this.setState(
                        produce((prevState) => {
                          prevState.contrat.immo = value;
                        })
                      );
                    }}
                  />

                  <div>
                    <Input
                      value={this.state.contrat?.abonnementSage}
                      type="checkbox"
                      accessor="abonnementSage"
                      label="Abonnement Sage"
                      handleChange={(accessor, value) => {
                        this.setState(
                          produce((prevState) => {
                            prevState.contrat.abonnementSage = value;
                          })
                        );
                      }}
                    />
                  </div>
                </div>
                <div className={"col-lg-4"}>
                  <Input
                    value={this.state.contrat?.montantAbonnementPeriodiqueHT}
                    label="Montant HT (périodique)"
                    accessor="montantAbonnementPeriodiqueHT"
                    type="decimal"
                    showClearButton={false}
                    required={true}
                    handleBlur={(accessor, value) => {
                      this.setState(
                        produce((prevState) => {
                          prevState.contrat.montantAbonnementPeriodiqueHT =
                            value;
                        })
                      );
                    }}
                  />
                  <Input
                    value={this.state.contrat?.contratServiceRetour}
                    label="Contrat Service retour le"
                    accessor="contratServiceRetour"
                    type="date"
                    showClearButton={false}
                    handleChange={(accessor, value) => {
                      this.setState(
                        produce((prevState) => {
                          prevState.contrat.contratServiceRetour = value;
                        })
                      );
                    }}
                  />
                  <Input
                    value={this.state.contrat?.contratExtRet}
                    label="Contrat exterieur retour le"
                    accessor="contratExtRet"
                    type="date"
                    showClearButton={false}
                    handleChange={(accessor, value) => {
                      this.setState(
                        produce((prevState) => {
                          prevState.contrat.contratExtRet = value;
                        })
                      );
                    }}
                  />
                  <Input
                    value={this.state.contrat?.pvlivraisonRetour}
                    label="Pv livraison retour le"
                    accessor="pvlivraisonRetour"
                    type="date"
                    showClearButton={false}
                    required={
                      (this.state.contrat?.categorie?.designation == "ASS" &&
                        this.state.contrat?.financeur?.designation !=
                          "Sol Solution") ||
                      this.state.contrat?.financeur?.designation !=
                        "Sol Solution"
                    }
                    handleChange={(accessor, value) => {
                      this.setState(
                        produce((prevState) => {
                          prevState.contrat.pvlivraisonRetour = value;
                        })
                      );
                    }}
                  />
                  <Input
                    value={this.state.contrat?.finAbonnement}
                    label="Fin abonnement"
                    accessor="finAbonnement"
                    type="date"
                    showClearButton={false}
                    handleChange={(accessor, value) => {
                      this.setState(
                        produce((prevState) => {
                          prevState.contrat.finAbonnement = value;
                        })
                      );
                    }}
                  />
                  <div>
                    <Input
                      value={this.state.contrat?.rib}
                      type="checkbox"
                      accessor="rib"
                      label="RIB"
                      handleChange={(accessor, value) => {
                        this.setState(
                          produce((prevState) => {
                            prevState.contrat.rib = value;
                          })
                        );
                      }}
                    />
                  </div>
                  <div>
                    <Input
                      value={this.state.contrat?.sortieImmo}
                      type="checkbox"
                      accessor="sortieImmo"
                      label="Sortie Immo"
                      handleChange={(accessor, value) => {
                        this.setState(
                          produce((prevState) => {
                            prevState.contrat.sortieImmo = value;
                          })
                        );
                      }}
                    />
                  </div>
                </div>
              </div>

              <Input
                value={this.state.contrat?.commentaire}
                label="Commentaire"
                accessor="commentaire"
                type="textArea"
                showClearButton={false}
                handleChange={(accessor, value) => {
                  this.setState(
                    produce((prevState) => {
                      prevState.contrat.commentaire = value;
                    })
                  );
                }}
              />
            </div>
          }
        />

        <DialogForm
          dialogTitle="Impression"
          labelValidateButton="Valider"
          setOpenDialog={this.setOpenDialogImpression}
          validation={() => {
            if (this.state.impression != null) return true;
            else {
              App.Toaster.current?.createToast({
                body: "Vous devez sélectionner un modèle",
                header: "Echec",
                type: "failure",
              });

              return false;
            }
          }}
          onValidate={() => {
            DevisService.printPack(
              this.state.impression.designation,
              this.state.devis.id
            ).then((res) => {
              var blob = new Blob([res?.data], {
                type: "	application/vnd.openxmlformats-officedocument.wordprocessingml.document",
              });
              FileSaver.saveAs(blob, this.state.devis.nom_Devis + ".docx");
            });
          }}
          body={
            <>
              <div>Veuillez choisir un modèle d'impression</div>
              <Input
                label="Modèle"
                accessor="impression"
                type="selectSearch"
                value={this.state.impression}
                valueFieldToDisplay="designation"
                optionFieldToDisplay="designation"
                functionAppliedToValue={(value) => {
                  if (value) return value.designation;
                  return null;
                }}
                handleChange={(acc, value) =>
                  this.setState({ impression: value })
                }
                required={true}
                options={[
                  {
                    designation: "First",
                    id: 1,
                  },
                  {
                    designation: "First PLAK",
                    id: 2,
                  },
                  {
                    designation: "Gold",
                    id: 3,
                  },
                  {
                    designation: "Platinium",
                    id: 4,
                  },
                  {
                    designation: "Platinium+",
                    id: 5,
                  },
                  {
                    designation: "Silver",
                    id: 6,
                  },
                  {
                    designation: "Silver PLAK",
                    id: 7,
                  },
                ]}
              />
            </>
          }
        />

        <DialogForm
          dialogTitle="Impression"
          tooltip="Imprimer contrat"
          classNameButton="btn btn-success"
          labelValidateButton="Valider"
          setOpenDialog={this.setOpenDialogImpressionContrat}
          validation={() => {
            if (this.state.impressionContrat != null) return true;
            else {
              App.Toaster.current?.createToast({
                body: "Vous devez sélectionner un modèle",
                header: "Echec",
                type: "failure",
              });

              return false;
            }
          }}
          onValidate={() => {
            DevisService.printContrat(
              this.state.impressionContrat.designation,
              this.state.devis.id
            ).then((res) => {
              var blob = new Blob([res?.data], {
                type: "	application/vnd.openxmlformats-officedocument.wordprocessingml.document",
              });
              FileSaver.saveAs(
                blob,
                "ContratLocation_" + this.state.devis.nom_Devis + ".docx"
              );
            });
          }}
          body={
            <>
              <div>Veuillez choisir un modèle d'impression</div>
              <Input
                label="Modèle"
                accessor="impressionContrat"
                type="selectSearch"
                value={this.state.impressionContrat}
                valueFieldToDisplay="designation"
                optionFieldToDisplay="designation"
                options={[
                  {
                    id: 0,
                    designation: "Batteuse électrique légère",
                  },
                  {
                    id: 1,
                    designation: "Grizzly",
                  },
                  {
                    id: 2,
                    designation: "Kodiak",
                  },
                  {
                    id: 3,
                    designation: "Panda",
                  },
                  {
                    id: 4,
                    designation: "Panda + Batteuse électrique légère",
                  },
                  {
                    id: 5,
                    designation: "Pandito",
                  },
                  {
                    id: 6,
                    designation: "Plaque dynamique légère",
                  },
                ]}
                functionAppliedToValue={(value) => {
                  if (value) return value.designation;
                  return null;
                }}
                handleChange={(acc, value) =>
                  this.setState({ impressionContrat: value })
                }
                required={true}
              />
            </>
          }
        />

        <DialogForm
          dialogTitle="Ajout des licences"
          labelValidateButton="Valider"
          setOpenDialog={this.setOpenDIalogLicences}
          fullScreen
          onValidate={() => {
            this.props.history.push("/licences/add", {
              modelesLicences: this.findLicencesToCreate(),
              matLicences: this.state.matLicences,
              client: this.state.devis.client,
              contratId: this.state.contrat.id,
              devisId: this.state.devis.id,
              nouvelleAffaireId: !this.state.nouvelleAffaire
                ? this.state.affaire?.id
                : -1,
            });
          }}
          body={
            <>
              <p>
                Veuillez sélectionner les licences et quantités à livrer avec
                les articles suivants
              </p>
              {this.state.matLicences?.map((licence) => (
                <div key={licence.posParent}>
                  <div className="py-2">
                    <b>
                      {
                        this.state.devis.devisDetails.find(
                          (e) => e.position == licence.posParent
                        ).description
                      }
                    </b>
                  </div>
                  <Input
                    type="selectSearch"
                    label="article licence"
                    value={licence}
                    valueFieldToDisplay="aR_Ref"
                    optionFieldToDisplay="aR_Ref"
                    required={true}
                    showClearButton={false}
                    service={ArticleService.getAllModelesLicences}
                    handleChange={(acc, value) => {
                      this.setState(
                        produce((prevState) => {
                          return {
                            matLicences: prevState.matLicences.map((e) =>
                              e.posParent != licence.posParent
                                ? e
                                : {
                                    ...e,
                                    type: value.classification,
                                    aR_Ref: value.aR_Ref,
                                    duree: value.classification.dureeParDefaut,
                                  }
                            ),
                          };
                        })
                      );
                    }}
                  />
                  {licence.type.id == 2 ? ( //Licence abonnement
                    <div>
                      {licence.type.designation}, prend fin à la date
                      anniversaire du client
                    </div>
                  ) : (
                    <div>{licence.type.designation}, durée fixe</div>
                  )}
                  <Input
                    type="decimal"
                    label="quantite"
                    value={licence.quantite}
                    required={true}
                    showClearButton={false}
                    numberOfDecimals={0}
                    handleBlur={(acc, value) => {
                      this.setState(
                        produce((prevState) => {
                          return {
                            matLicences: prevState.matLicences.map((e) =>
                              e.posParent != licence.posParent
                                ? e
                                : {
                                    ...e,
                                    quantite: value,
                                  }
                            ),
                          };
                        })
                      );
                    }}
                  />
                </div>
              ))}
            </>
          }
        />

        <Dialog
          open={this.state.openedDialogDupplicationDevis}
          onClose={() =>
            this.setState({
              openedDialogDupplicationDevis: false,
            })
          }
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogContent>
            <p>Vous allez dupliquer ce devis.</p>
            <p>Voulez-vous annuler le devis en cours?</p>
          </DialogContent>
          <DialogActions>
            <button
              className="btn btn-warning"
              type="button"
              onClick={() => this.duplicateDevis(this.state.devis.id, true)}
            >
              Oui
            </button>
            <button
              className="btn btn-primary"
              autoFocus
              type="button"
              onClick={() => this.duplicateDevis(this.state.devis.id, false)}
            >
              Non
            </button>
          </DialogActions>
        </Dialog>

        <Dialog
          open={this.state.error}
          onClose={() =>
            this.setState({
              openedDialogDupplicationDevis: false,
            })
          }
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogContent>
            <div>
              Le total du devis n'est pas à 0 alors qu'il est issu d'un matériel
              sous contrat.
            </div>
          </DialogContent>
          <DialogActions>
            <button
              className="btn btn-warning"
              type="button"
              onClick={() => {
                this.onClickOnTransformIntoCommandeButton();
                this.setState({ error: false });
              }}
            >
              Continuer
            </button>
            <button
              className="btn btn-danger"
              autoFocus
              type="button"
              onClick={() => this.setState({ error: false })}
            >
              Annuler
            </button>
          </DialogActions>
        </Dialog>

        <AlertDialog
          title="Une erreur a été rencontrée"
          body={
            <>
              <div>Un problème a été rencontré.</div>
              <div>{this.state.errorText}</div>
            </>
          }
          valider="Ok"
          open={this.state.errorContrat}
          handleClose={() => this.setState({ errorContrat: false })}
        />
        <AlertDialog
          title="Une erreur a été rencontrée"
          body={
            <>
              <div>La mise en place du compte tiers a échouée.</div>
              <div>{this.state.errorText}</div>
            </>
          }
          valider="Ok"
          open={this.state.errorComptetiers}
          handleClose={() => this.setState({ errorComptetiers: false })}
        />

        <AlertDialog
          open={this.state.open}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          title="Il manque des champs obligatoires"
          body={
            <>
              <div>Il manque des champs à remplir pour créer un devis : </div>
              <div> {this.getChampsObligatoireVide().map((e) => e + ", ")}</div>
            </>
          }
          valider="Ok"
          handleClose={() => this.setState({ open: false })}
        />
      </>
    );
  }

  generateDialogAssistance() {
    return (
      <DialogForm
        tooltip="Imprimer"
        classNameButton="btn btn-success"
        dialogTitle="Impression Assistance"
        labelValidateButton="Valider"
        setOpenDialog={this.setOpenDialogImpressionAss}
        validation={() => {
          if (this.state.impressionAssistance != null) return true;
          else {
            App.Toaster.current?.createToast({
              body: "Vous devez sélectionner un modèle",
              header: "Echec",
              type: "failure",
            });

            return false;
          }
        }}
        onValidate={() => {
          DevisService.printAssistance(
            this.state.impressionAssistance.designation,
            this.state.devis.id
          ).then((res) => {
            var blob = new Blob([res?.data], {
              type: "	application/vnd.openxmlformats-officedocument.wordprocessingml.document",
            });
            FileSaver.saveAs(
              blob,
              "Assistance_" + this.state.devis.nom_Devis + ".docx"
            );
          });
        }}
        onClose={() => {}}
        body={
          <div id="PopupCommande">
            <div>
              Veuillez choisir un modèle d'impression <br />
            </div>

            <Input
              label="Modèle"
              accessor="impressionAssistance"
              type="selectSearch"
              value={this.state.impressionAssistance}
              valueFieldToDisplay="designation"
              optionFieldToDisplay="designation"
              options={[
                {
                  id: 3,
                  designation: "Assistance",
                },
              ]}
              functionAppliedToValue={(value) => {
                if (value) return value.designation;
                return null;
              }}
              handleChange={(acc, value) =>
                this.setState({ impressionAssistance: value })
              }
              required={true}
            />
          </div>
        }
      />
    );
  }

  renderActive() {
    switch (this.state.active) {
      case "Général":
        return (
          <General
            devis={this.state.devis}
            stateFieldNameToUpdate="devis"
            handleChange={this.handleChange}
            // Overrides the put method for the changes to the calculette implied by changes on produit_interne or type_mission
            service={{
              ...DevisService,
              put: this.handleUpdateCalculette,
            }}
            history={this.props.history}
            editing={this.state.editing}
            handleEditing={this.handleEditing}
            handleUpdate={this.handleUpdate}
          ></General>
        );
      case "Informations chantier":
        return (
          <InformationsChantier
            devis={this.state.devis}
            stateFieldNameToUpdate="devis"
            handleChange={this.handleChange}
            service={DevisService}
            history={this.props.history}
            editing={this.state.editing}
            handleUpdate={this.handleUpdate}
            handleEditing={this.handleEditing}
          ></InformationsChantier>
        );
      case "Détails":
        return (
          <Details
            devis={this.state.devis}
            stateFieldNameToUpdate="devis"
            handleChange={this.handleChange}
            changeLinePosition={this.changeLinePosition}
            service={DevisService}
            history={this.props.history}
            editing={this.state.editing}
            handleEditing={this.handleEditing}
            handleUpdate={this.handleUpdate}
            addLineDevisDetail={this.addLineDevisDetail}
            deleteLineDevisDetail={this.deleteLineDevisDetail}
            importDevisModele={this.importDevisModele}
          ></Details>
        );
      case "Logistique":
        return (
          <Logistique
            devis={this.state.devis}
            stateFieldNameToUpdate="devis"
            handleChange={this.handleChange}
            service={DevisService}
            history={this.props.history}
            editing={this.state.editing}
            handleEditing={this.handleEditing}
            handleUpdate={this.handleUpdate}
          ></Logistique>
        );
      case "Calculette":
        return (
          <Calculette
            devis={this.state.devis}
            stateFieldNameToUpdate="devis"
            handleChange={this.handleChange}
            service={DevisService}
            history={this.props.history}
            editing={this.state.editing}
            handleEditing={this.handleEditing}
            handleUpdate={this.handleUpdate}
            importCalculetteModele={this.importCalculetteModele}
          ></Calculette>
        );
      default:
        return (
          <h4 className="mt-5">Désolé la page n&apos;a pas réussi à charger</h4>
        );
    }
  }

  render() {
    const isDevisEmpty = !Object.entries(this.state.devis).length;
    return (
      <>
        <Helmet>
          <title>
            {"Devis : "
              .concat(this.state.devis.reference)
              .concat(" | ")
              .concat(this.state.devis.nom_Devis)}
          </title>
        </Helmet>

        {this.state.loading ? (
          <div
            style={{
              flexGrow: 1,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <FontAwesomeIcon icon={faSpinner} size="lg" className="fa-spin" />
          </div>
        ) : (
          <div className="p-3 m-4 " id="ficheDevis">
            {this.generateDialogs()}
            {this.generateDialogAssistance()}
            <div className="d-flex overflow-auto justify-content-between py-1">
              <div className="d-flex align-items-start nowrap pr-100">
                <h4>
                  {"Devis : "
                    .concat(this.state.devis.reference)
                    .concat(" | ")
                    .concat(this.state.devis.nom_Devis)}

                  {this.state.devis?.ficheDeSuivi?.isSousAbonnement ? (
                    <FontAwesomeIcon
                      icon={faCertificate}
                      size="sm"
                      className=" ms-1 text-info"
                      title="Créé sous abonnement"
                    />
                  ) : null}
                </h4>
              </div>
              <div className="d-flex">
                <Link to="/devis" className="btn btn-primary nowrap ms-1">
                  Base devis
                </Link>
                <ButtonIcon
                  icon={faCopy}
                  tooltip={"Dupliquer ce devis"}
                  className="btn btn-success text-light ms-1"
                  onClick={() => {
                    this.setState({ openedDialogDupplicationDevis: true });
                  }}
                />
                <ButtonIcon
                  icon={faFileInvoiceDollar}
                  className="btn btn-success text-light ms-1"
                  onClick={() => {
                    if (this.getChampsObligatoireVide()?.length == 0) {
                      if (
                        (this.state.devis?.ficheDeSuivi?.isSousAbonnement &&
                          this.state.devis?.prixVenteTotal == 0) ||
                        this.state.devis?.ficheDeSuivi == null ||
                        !this.state.devis?.ficheDeSuivi?.isSousAbonnement
                      )
                        this.onClickOnTransformIntoCommandeButton();
                      else this.setState({ error: true });
                    } else {
                      this.setState({ open: true });
                    }
                  }}
                  tooltip={
                    App.RightsGuard?.current?.hasRightCompteTier() ||
                    this.state.devis.client?.compte_Tier != null
                      ? "Générer une commande"
                      : "Vous ne pouvez pas générer une commande car vous n'avez pas le droit d'associer un compte tier à un client"
                  }
                  disabled={
                    !(
                      App.RightsGuard?.current?.hasRightCompteTier() ||
                      this.state.devis.client?.compte_Tier != null
                    )
                  }
                />
                <ButtonIcon
                  icon={faPrint}
                  className="btn btn-success text-light ms-1"
                  tooltip="Impression devis"
                  onClick={() => {
                    let portOA = false;
                    let portAss = false;
                    this.state.devis.devisDetails?.map((e) => {
                      portOA = portOA || e.article?.aR_Ref === "PORT-OA";
                      portAss = portAss || e.article?.aR_Ref === "PORT-ASS";
                    });
                    if (portOA) {
                      this.state.openDialogImpressionFunc();
                    } else if (portAss) {
                      this.state.openDialogAssFunc();
                    } else {
                      DevisService.print(this.state.devis.id).then((res) => {
                        var blob = new Blob([res?.data], {
                          type: "	application/vnd.openxmlformats-officedocument.wordprocessingml.document",
                        });
                        FileSaver.saveAs(
                          blob,
                          this.state.devis.nom_Devis + ".docx"
                        );
                      });
                    }
                  }}
                />
                <ButtonIcon
                  icon={faFileContract}
                  className="btn btn-success text-light ms-1"
                  tooltip="Impression contrat location"
                  onClick={() => {
                    this.state.openDialogImpressionContratFunc();
                  }}
                />
                {this.state.devis?.resultat?.valeur !==
                "En cours" ? null : App.RightsGuard.current?.hasRight(
                    "Devis",
                    "Suppression"
                  ) == "RW" ? (
                  <ButtonIconWithValidation
                    icon={faTrash}
                    onClick={this.handleDelete}
                    className="form-control btn btn-danger text-light ms-1"
                    alertTitle=" Suppression"
                    alertBody={
                      <div>
                        Souhaitez-vous réellement supprimer ce devis ? <br />
                        (il sera supprimé pour tous ses clients)
                      </div>
                    }
                    alertButtonValidationText="Oui, je veux supprimer."
                  />
                ) : null}
                {/* {this.props.history.length > 1 && (
                  <button
                    className="btn btn-primary nowrap ms-1"
                    onClick={() => {
                      this.props.history.goBack();
                    }}
                  >
                    Retour
                  </button>
                )} */}
              </div>
            </div>
            {this.generateTabs(this.state.tabs)}
            <WarningBar
              active={!this.state.devis.client?.litige}
              content={"Client en litige"}
              background={"bg-danger"}
            />
            <WarningBar
              active={!this.state.devis.client?.tarif_Negocie}
              content={"Tarifs négociés"}
            />
            <WarningBar
              active={
                !(this.state.devis.licence?.classification.canalVente?.id == 1)
              }
              content={"Ajout de modules à un abonnement"}
              background={"bg-warning"}
            />
            <WarningBar
              active={
                !(
                  this.state.devis.licence?.classification.canalVente?.id ==
                    1 &&
                  this.state.devis.client?.dateAnniversaireLicences != null
                )
              }
              content={
                "Quantités au prorata de la date anniversaire : " +
                ToLocaleDateString(
                  this.state.devis.client?.dateAnniversaireLicences
                )
              }
              background={"bg-warning"}
            />
            {!isDevisEmpty && this.renderActive()}
          </div>
        )}
      </>
    );
  }
}

/**
 * Validation des props :
 */
FicheDevis.propTypes = {
  history: PropTypes.object,
  match: PropTypes.object,
};

export { FicheDevis };
