// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.nav-tabs {
  color: black;
}

.nav-tab:hover {
  color: black;
  cursor: pointer;
  font-weight: bold;
}
`, "",{"version":3,"sources":["webpack://./src/FicheArticle/FicheArticle.css"],"names":[],"mappings":"AAAA;EACE,YAAY;AACd;;AAEA;EACE,YAAY;EACZ,eAAe;EACf,iBAAiB;AACnB","sourcesContent":[".nav-tabs {\n  color: black;\n}\n\n.nav-tab:hover {\n  color: black;\n  cursor: pointer;\n  font-weight: bold;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
