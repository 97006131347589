import http from "http-common";

const FicheDeSuiviTypeService = class {
  /**
   * Fonction permettant de récuperer tous les Fonctions avec une possibilité de filtre via params
   * @returns la liste de tous les Fonctions
   */
  static getAll(params) {
    let url = "/FicheDeSuiviType";

    //Gestion de la valeur par défaut de la propriété "isActive" des paramètres
    if (!params) return http.get(url, { params: { isActive: true } });
    else if (typeof params === "object" && params.isActive === undefined)
      return http.get(url, { params: { ...params, isActive: true } });
    else if (typeof params === "object" && params.isActive !== undefined)
      return http.get(url, { params });
    else return http.get(url, { params: { isActive: true } });
  }
};

export { FicheDeSuiviTypeService };
