import http from "http-common";

const LicencesService = class {
  /**
   * Fonction permettant de récuperer toutes les licences avec une possibilité de filtre via params
   * @param {*} params, paramètres à utiliser pour la requête (page, nombre d'éléments par page et filtres sur les champs)
   * @returns une liste des licences respectant les critères de params et le nombre de page pour ces critères
   */
  static getAll(params) {
    return http.post("/licences/get", params);
  }

  static getOneById(id) {
    return http.get("/licences/" + id);
  }

  static getHistoriqueById(params) {
    return http.get("/licences/historiques", { params });
  }

  static getByClient(clientId, params) {
    return http.get("/licences/client/" + clientId, { params });
  }

  static getSoldByClient(clientId, params) {
    return http.get("/licences/soldByDistributor/" + clientId, { params });
  }

  static getSoldToClient(clientId, params) {
    return http.get("/licences/soldToDistributor/" + clientId, { params });
  }

  static getClientStats(clientId) {
    return http.get("/licences/client/" + clientId + "/stats/");
  }

  static getValidGeosprintUser(clientId) {
    return http.get(
      "/licences/client/" + clientId + "/utilisateursValides/geosprint"
    );
  }

  static getValidWebsprintUser(clientId) {
    return http.get(
      "/licences/client/" + clientId + "/utilisateursValides/websprint"
    );
  }

  static updateLicence(licence) {
    return http.put("/licences/" + licence.numero, licence);
  }

  static addUser(licenceId, userId) {
    return http.patch("/licences/" + licenceId, userId);
  }

  static removeUser(licenceId) {
    return http.patch("/licences/" + licenceId, null);
  }

  static suspend(licenceId) {
    return http.delete("/licences/" + licenceId);
  }

  static suspendAllByClient(clientId) {
    return http.delete("/licences/client/" + clientId);
  }

  static removeModules(licenceId, refs_modelesLicence) {
    return http.post(
      "/licences/" + licenceId + "/deleteModules/",
      refs_modelesLicence
    );
  }

  static changeClient(idDistri, newClientId, nomClientFinal) {
    let dataDTO = {
      futureClientId: newClientId,
      clientName: nomClientFinal.nom_Client,
    };
    return http.post(
      "/licences/client/" + idDistri + "/detachDistributor",
      dataDTO
    );
  }
};

export { LicencesService };
